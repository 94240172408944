import { z } from "zod";
export const NotificationBodySchema = z.union([
    z.object({
        type: z.literal(`newMessage`),
        details: z.object({
            fromUser: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
            chatId: z.string().uuid(),
            chatName: z.string().min(1).nullable(),
            numberOfUnreadMessages: z.number().int().min(1),
        }),
    }),
    z.object({
        type: z.literal(`newChat`),
        details: z.object({
            fromUser: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
            chatId: z.string().uuid(),
            chatName: z.string().min(1).nullable(),
        }),
    }),
    z.object({
        type: z.literal(`chatDeleted`),
        details: z.object({
            fromUser: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }).nullable(),
            chatId: z.string().uuid(),
            chatName: z.string().min(1).nullable(),
            chatArchiveId: z.string().uuid(),
        }),
    }),
    z.object({
        type: z.literal(`expelledFromGroupChat`),
        details: z.object({
            fromUser: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
            chatId: z.string().uuid(),
            chatName: z.string().min(1),
            chatArchiveId: z.string().uuid(),
        }),
    }),
    z.object({
        type: z.literal(`youAreModerator`),
        details: z.object({
            fromUser: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
            chatId: z.string().uuid(),
            chatName: z.string().min(1),
        }),
    }),
    z.object({
        type: z.literal(`renamedGroupChat`),
        details: z.object({
            fromUser: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
            chatId: z.string().uuid(),
            oldChatName: z.string().min(1),
            newChatName: z.string().min(1),
        }),
    }),
    z.object({
        type: z.literal(`noLicenseFound`),
        details: z.record(z.string(), z.never()),
    }),
    z.object({
        type: z.literal(`invalidLicenseFound`),
        details: z.record(z.string(), z.never()),
    }),
]);
