import { fetch } from "cross-fetch";
import { ApiResponseSchema } from "@trantor/vdesk-api-schemas/dist/common.js";
export class BaseClient {
    url;
    _token;
    constructor(endpoint, token) {
        this.url = `${endpoint}/api/v5`;
        this._token = token;
    }
    async call(fn, params = {}) {
        try {
            const response = await fetch(this.url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ovmp: "1.0",
                    fn,
                    token: this._token.get(),
                    params
                })
            });
            if (response.status !== 200) {
                throw new Error(`status response is ${response.status}`);
            }
            return ApiResponseSchema.parse(await response.json());
        }
        catch {
            return {
                ovmp: `1.0`,
                requestId: `00000000-0000-0000-0000-000000000000`,
                status: `ERROR`,
                body: {
                    errorCode: `NETWORK-ERROR`,
                },
            };
        }
    }
}
