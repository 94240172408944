export class AssertionFailed extends Error {
    constructor(message) {
        super(message);
    }
}
export function assert(condition) {
    if (condition === false) {
        throw new AssertionFailed();
    }
}
