import { z } from "zod";
export const FsChangeFileMetadataParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    perms: z.object({
        type: z.enum([`read-only`, `read-write`]),
        targetUser: z.string().uuid().nullable(),
        targetGroup: z.string().uuid().nullable(),
    }).array().optional(),
    linkedChat: z.string().uuid().nullable().optional(),
    userOwner: z.string().uuid().optional(),
});
export const FsChangeFileMetadataOutputSchema = z.record(z.string(), z.never());
