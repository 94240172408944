import { z } from "zod";
import { EnumOpSchema, NumberOpSchema, StringOpSchema } from "../filters.js";
export const StoragesConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`name`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
    z.object({
        field: z.literal(`online`),
        op: EnumOpSchema,
        value: z.boolean(),
    }),
    z.object({
        field: z.literal(`endpoint`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`accessKeyId`),
        op: StringOpSchema,
        value: z.string(),
    }),
]);
export const StoragesFiltersSchema = z.array(StoragesConditionSchema.or(z.array(StoragesConditionSchema)));
export const StoragesOrderBySchema = z.object({
    field: z.enum([`name`, `creationTime`, `endpoint`, `accessKeyId`]),
    order: z.enum([`asc`, `desc`]),
});
export const GetStoragesParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: StoragesOrderBySchema.optional().default({ field: `name`, order: `asc` }),
    filters: StoragesFiltersSchema.optional().default([]),
});
export const StorageItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    online: z.boolean(),
    name: z.string().min(1),
    endpoint: z.string().min(1),
    accessKeyId: z.string().min(1),
    secretAccessKey: z.string().min(1),
    bucket: z.string().min(1),
    region: z.string().min(1),
});
export const GetStoragesOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: StorageItemSchema.array(),
});
