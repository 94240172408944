import { z } from "zod";
export const FsCreateDirParamsSchema = z.union([
    z.object({
        name: z.string().min(1),
        parentDirectory: z.string().uuid(),
        storage: z.null().optional().default(null),
        perms: z.object({
            type: z.enum([`read-only`, `read-write`]),
            targetUser: z.string().uuid().nullable(),
            targetGroup: z.string().uuid().nullable(),
        }).array().optional().default([]),
        userOwner: z.string().uuid().optional(),
    }),
    z.object({
        name: z.string().min(1),
        parentDirectory: z.null().optional().default(null),
        storage: z.string().uuid(),
        perms: z.object({
            type: z.enum([`read-only`, `read-write`]),
            targetUser: z.string().uuid().nullable(),
            targetGroup: z.string().uuid().nullable(),
        }).array().optional().default([]),
        userOwner: z.string().uuid().optional(),
    }),
]);
export const FsCreateDirOutputSchema = z.object({
    id: z.string().uuid(),
    storage: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
    }),
});
