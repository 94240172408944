import { LoginOutput, MyselfOutput } from "@trantor/vdesk-api-client/dist/clients/auth";
import { ApiClient, AsyncResult, Result } from "@trantor/vdesk-api-client/dist/index";
import { FailedRequestError, SpecializedError } from "@trantor/vdesk-api-client/dist/libs/errors";
import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import { GlobalErrorType, SessionData, UserStatus, sessionDataFromJSON } from "../utils/GeneralTypes";
import exportedHooks from "../utils/HooksExporter";
import l from "../utils/Log";
import { themeFromUsername } from "../utils/Utils";
import { DateTime } from "luxon";
import { createApiClient } from "../utils/createApiClient";

const API_HOSTNAME = process.env.VUE_APP_API_HOSTNAME ?? "localhost";
const API_PORT = parseInt(process.env.VUE_APP_API_PORT ?? "8080", 10);

class MS {
    public readonly client: ApiClient;

    constructor() {
        this.client = createApiClient();
    }

    //#region Getters
    getToken(): string | null {
        const existing = getCookie("sessionData");
        const sessionData: SessionData = existing ? JSON.parse(existing) : null;
        return Boolean(existing) && sessionData ? sessionData.token : null;
    }
    // getDomain(): string {
    //   const domain = sessionStorage.getItem("vDeskDomain");
    //   return domain ? domain : "";
    // }
    //#endregion

    //#region Setters
    setToken(token: string) {
        // sessionStorage.setItem("atoken", token);
        const existing = getCookie("sessionData");
        const sessionData: SessionData = existing ? JSON.parse(existing) : {};
        const newSessionData: SessionData = { ...sessionData, token, mintingDate: new Date() };
        setCookie("sessionData", JSON.stringify(newSessionData), { sameSite: "Lax", path: "/" });
    }

    setLoginData(data: LoginOutput): void {
        const { token, lastLogin, lastFailedLogins } = data;
        const existing = getCookie("sessionData");
        const sessionData: SessionData = existing ? JSON.parse(existing) : {};
        const newSessionData: SessionData = { ...sessionData, token, mintingDate: new Date(), lastFailedLogins };
        if (lastLogin) {
            newSessionData.lastLogin = DateTime.fromMillis(lastLogin).toJSDate();
        }
        setCookie("sessionData", JSON.stringify(newSessionData), { sameSite: "Lax", path: "/" });
    }

    setLoggedUser(user: MyselfOutput) {
        // sessionStorage.setItem("atoken", token);
        const {
            userId,
            username,
            displayName,
            role,
            tokenExpiration,
        } = user;
        const existing = getCookie("sessionData");
        const sessionData: SessionData = sessionDataFromJSON(existing ? JSON.parse(existing) : {});
        const newSessionData: SessionData = {
            ...sessionData,
            loggedUser: {
                ...user,
                id: userId,
                username,
                displayName,
                role,
                enabled: true,
                lastLogin: 0,
                creationTime: 0
            },
            mintingDate: new Date(),
            expires: Boolean(tokenExpiration),
            expiryDate: new Date(tokenExpiration),
        };
        l.log(`SETTING loggedUser

user:
${JSON.stringify(user, null, 1)}

existing token:
${JSON.stringify(sessionData, null, 1)}

new session data:
${JSON.stringify(newSessionData, null, 1)}
`)
        setCookie("sessionData", JSON.stringify(newSessionData), { sameSite: "Lax", path: "/" });
    }

    deleteSession() {
        // sessionStorage.setItem("atoken", token);
        removeCookie("sessionData", { path: "/" });
    }
    // setDomain(domain: string) {
    //   sessionStorage.setItem("vDeskDomain", domain);
    // }
    //#endregion

    async checkToken(): Promise<boolean> {
        const token = this.getToken();
        if (!token) return true;

        const { data, error } = await this.client.misc.ping();

        if (data === undefined || error !== undefined) {
            removeCookie("sessionData", { path: "/" });
            return false;
        }
        return true;
    }

    /**
   * "call" calls an API function "fn" by:
   * 1. Setting the client token on the api-client before the call
   * 2. unwrapping the response (i.e. assert it isn't an error and type narrowing to the success response type)
   *
   * @param fn the function to be invoked
   * @returns Promise<T>
   */
    async call<T>(fn: () => AsyncResult<T>): Promise<T> {
        /**
         * "@trantor/vdesk-api-client" uses an internal token manager for convenience, so clients don't have to
         * manage it themselves.
         *
         * Since Dashboard stores the latest token in the session storage, we need to restore the token from the
         * session storage into api-client, otherwise authenticated requests receive a 403 if the client instance
         * is recreated (e.g. page refreshed)
         */
        this.client.setToken(this.getToken());

        return unwrap(await fn());
    }

    async callFullError<T>(fn: () => AsyncResult<T>): Promise<Result<T>> {
        this.client.setToken(this.getToken());
        return await fn();
    }

    async listFunctions(): Promise<string[]> {
        const { functions } = await this.call(() => this.client.misc.listFunctions());

        return functions;
    }
    //#endregion
}
const MainService = new MS();
export default MainService;

/**
 * "unwrap" processes a Result<T> type, by:
 * 1. if "error" is present, throw an exception
 * 2. if "data" is undefined, throw an exception
 * 3. Otherwise, return "data" type narrowed to the type "T"
 */
export function unwrap<T>({ data, error }: Result<T>): T {
    assert(error === undefined, errToCode(error!));
    // assert(data !== undefined, "GENERIC-ERROR");
    let r: T = {} as T;
    if (data !== undefined) return data;
    else return r
}

function errToCode(err: SpecializedError): string {
    if (err instanceof FailedRequestError) {
        return err.errorCode;
    }

    return "GENERIC-ERROR";
}

export function assert(value: unknown, message?: string | Error | undefined): asserts value {
    if (value === 0 || value === false) {
        const { triggerGlobalError } = exportedHooks;
        // if (triggerGlobalError && message === "INVALID-TOKEN") {
        //   l.log("ATTEMPTING REDIRECT (from assert failure of type INVALID-TOKEN)");
        //   MainService.deleteSession();
        //   triggerGlobalError(GlobalErrorType.SESSIONEXPIRED);
        // }
        if (triggerGlobalError != null) {
            switch (message) {
                case "INVALID-TOKEN":
                    l.error("ATTEMPTING REDIRECT (from assert failure of type INVALID-TOKEN)");
                    MainService.deleteSession();
                    triggerGlobalError(GlobalErrorType.BROKENSESSION);
                    break;

                default:
                    throw message !== undefined ? message : "assertion error";
            }
        }
        throw message !== undefined ? message : "assertion error";
    }
}
