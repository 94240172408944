import React, { Suspense, useLayoutEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";

import UserContactCardPage from "../pages/settings/UserContactCard";
import SettingsLayout from "../pages/settings/SettingsLayout";
import UserListCompact from "../pages/settings/UserListCompact";
import UserDetailsPage from "../pages/settings/UserDetailsRegular";

import { FileManagerContextProvider } from "../pages/app/file-manager/FileManagerContext";
import FileManager from "../pages/app/file-manager/FileManager";

import Chat from "../pages/app/chat/ChatContainer";

import WebAccess from "../pages/app/web-acess/WebAccess";

import P from "../utils/Path";

const Pages = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Suspense /* fallback={<div>Layout fallback</div>} */>
            <Routes>
                {/*Pre-built Pages*/}
                {/* <Route //Context Api added
          path={P.getPath("user-list-regular", true)}
          element={
              <UserListRegularPage />
          }
        /> */}
                <Route //Context Api added
                    path={P.getPath("user-list-compact")}
                    element={
                        <UserListCompact />
                    }
                />
                <Route //Context Api added
                    path={P.getPath("user-details-regular")}
                    element={
                        <UserDetailsPage />
                    }
                />
                <Route path="/settings/*" element={<SettingsLayout />} />
                <Route //Context api added
                    path={P.getPath("user-contact-card")}
                    element={
                        <UserContactCardPage />
                    }
                />

                {/*Demo Pages*/}
                <Route path={P.getPath("terms")} element={<Terms />} />
                <Route path={P.getPath("faq")} element={<Faq />} />

                {/*Application*/}
                <Route path={P.getPath("chat")} element={<Chat showArchive={false} />} />
                <Route path={P.getPath("archive")} element={<Chat showArchive={true} />} />
                <Route path={`${P.getPath("chat")}/:chatId`} element={<Chat showArchive={false} />} />
                <Route path={`${P.getPath("archive")}/:chatArchiveId`} element={<Chat showArchive={true} />} />

                <Route path={P.getPath("webAccess")} element={<WebAccess />} />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/files`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/shared`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/starred`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/recovery`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/settings`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/pricing`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/folder/:id`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />
                <Route
                    path={`${process.env.PUBLIC_URL}/file/*`}
                    element={
                        <FileManagerContextProvider>
                            <FileManager />
                        </FileManagerContextProvider>
                    }
                />

                <Route
                    path={`${process.env.PUBLIC_URL}/`}
                    element={<Navigate replace to={P.getPath("chat")} state={{ fromRoot: true, fromPrivateRoute: false }} />}
                />
                <Route path="*" element={<RedirectAs404 />}></Route>
            </Routes>
        </Suspense>
    );
};
export default Pages;
