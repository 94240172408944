import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { GetLicenseInfoOutputSchema } from "@trantor/vdesk-api-schemas/dist/license/getLicenseInfo.js";
import { ChangeLicenseOutputSchema } from "@trantor/vdesk-api-schemas/dist/license/changeLicense.js";
export class LicenseClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async getLicenseInfo(params) {
        const res = await this.call("getLicenseInfo", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetLicenseInfoOutputSchema.parse(res.body)
        };
    }
    async changeLicense(params) {
        const res = await this.call("changeLicense", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: ChangeLicenseOutputSchema.parse(res.body)
        };
    }
}
