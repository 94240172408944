import { z } from "zod";
import { EmptyOutputSchema, UserRoleSchema } from "../common.js";
export const EditUserParamsSchema = z.object({
    id: z.string().uuid(),
    username: z.string().min(1).optional(),
    displayName: z.string().min(1).optional(),
    password: z.string().min(1).optional(),
    role: UserRoleSchema.optional(),
    enabled: z.boolean().optional(),
    otp: z.enum([`enabled`, `disabled`, `new`]).optional(),
    memberOf: z.string().uuid().array().optional(),
    homeDir: z.string().uuid().nullable().optional(),
    storageQuotaInBytes: z.number().int().min(0).optional(),
});
export const EditUserOutputSchema = EmptyOutputSchema;
