import { GetLicenseInfoParams, GetLicenseInfoOutput } from "@trantor/vdesk-api-schemas/dist/license/getLicenseInfo";
import { ChangeLicenseParams, ChangeLicenseOutput } from "@trantor/vdesk-api-schemas/dist/license/changeLicense";
import MainService from "./MainService";

class LS {
    private client = MainService.client.license;

    async getLicenseInfo(params: GetLicenseInfoParams): Promise<GetLicenseInfoOutput> {
        return MainService.call(() => this.client.getLicenseInfo(params));
    }

    async changeLicense(params: ChangeLicenseParams): Promise<ChangeLicenseOutput> {
        return MainService.call(() => this.client.changeLicense(params));
    }
}
const LicenseService = new LS();
export default LicenseService;
