import { z } from "zod";
import { JSONObjectSchema } from "../json.js";
export const CallLiveActionParamsSchema = z.union([
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`sendRtcDescription`),
        details: z.object({
            peerId: z.string().uuid(),
            rtcDescription: JSONObjectSchema,
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`sendIceCandidate`),
        details: z.object({
            peerId: z.string().uuid(),
            iceCandidate: JSONObjectSchema,
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`leaveCall`),
        details: z.record(z.string(), z.never()).optional().default({}),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`makeModerator`),
        details: z.object({
            peerId: z.string().uuid(),
            moderator: z.boolean(),
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`expel`),
        details: z.object({
            peerId: z.string().uuid(),
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`closeCall`),
        details: z.record(z.string(), z.never()).optional().default({}),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`makeMuted`),
        details: z.object({
            peerId: z.string().uuid(),
            muted: z.boolean(),
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`raiseHand`),
        details: z.object({
            handRaised: z.boolean(),
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`sendMessage`),
        details: z.object({
            text: z.string().min(1),
        }),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`sendWritingStatus`),
        details: z.record(z.string(), z.never()).optional().default({}),
    }),
    z.object({
        peerToken: z.string().min(1),
        action: z.literal(`changeOpenToGuests`),
        details: z.object({
            openToGuests: z.boolean(),
        }),
    }),
]);
export const CallLiveActionOutputSchema = z.record(z.string(), z.never());
