import { z } from "zod";
export const StartCallParamsSchema = z.object({
    chatId: z.string().uuid().nullable().optional().default(null),
    openToGuests: z.boolean().optional().default(false),
    participants: z.object({
        id: z.string().uuid(),
        moderator: z.boolean().optional().default(false),
    }).array().optional().default([]),
    now: z.boolean().optional().default(true),
});
export const StartCallOutputSchema = z.object({
    callId: z.string().uuid(),
    shortCallId: z.string().min(1),
});
