import { z } from "zod";
export const CreateChatParamsSchema = z.union([
    z.object({
        type: z.literal(`p2p`),
        otherUserId: z.string().uuid(),
    }),
    z.object({
        type: z.literal(`group`),
        name: z.string().min(1),
        participants: z.object({
            userId: z.string().uuid(),
            moderator: z.boolean(),
        }).array(),
    }),
]);
export const CreateChatOutputSchema = z.object({
    id: z.string().uuid()
});
