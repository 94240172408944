import React, { useMemo } from "react";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  DataTableHead,
  DataTableRow,
  DataTable,
  DataTableBody,
  DataTableItem,
  Row,
  Col,
} from "../../components/Component";
import { Trans, useTranslation } from "react-i18next";
import { getCookie } from "typescript-cookie";
import { SessionDataJSON } from "../../utils/GeneralTypes";
import classNames from "classnames";
import { RoundedBadge } from "../../components/chat/Badge";
import { useCronState } from "../../utils/CronJob";
import { UsersService } from "../../services";
import { DefaultSpinner } from "../../components/default-spinner";
import { formatSizes } from "../../utils/Utils";

interface UserProfileActivityPageProps {
  sm: boolean;
  setSm: React.Dispatch<React.SetStateAction<boolean>>;
}
const UserProfileActivityPage: React.FC<UserProfileActivityPageProps> = ({ sm, setSm }) => {
    const cookie = getCookie("sessionData");
    const sessionData: SessionDataJSON = useMemo(() => cookie ? JSON.parse(cookie) : {}, [cookie]);
    const [myUserData] = useCronState(2000, null, async () => {
        const userResp = await UsersService.getUsers({
            filters: [{
                field: "id",
                op: "eq",
                value: sessionData.loggedUser.id
            }]
        });
        return userResp;
    })
    const { t } = useTranslation();

    const profileData = useMemo(() => {
        if (myUserData !== null && myUserData.items.length > 0 && myUserData.items[0] !== undefined) {
            return myUserData.items[0];
        }
        return sessionData.loggedUser;
    }, [sessionData, myUserData])
    return (
        <React.Fragment>
        <Head title={t("pageTitles.settingsActivity")}></Head>
        <BlockHead size="lg">
            <BlockBetween>
            <BlockHeadContent>
                <BlockTitle tag="h4"><Trans i18nKey="pageTitles.settingsActivity" /></BlockTitle>
                <BlockDes>
                {/* <p>
                    Here is your last 20 login activities log.{" "}
                    <span className="text-soft">
                    <Icon name="info" />
                    </span>
                </p> */}
                </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
                <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => setSm(!sm)}
                >
                <Icon name="menu-alt-r"></Icon>
                </Button>
            </BlockHeadContent>
            </BlockBetween>
        </BlockHead>
        <Row>
            <Col md={12} lg={10} offsetLg={1} xl={8} offsetXl={2} xxl={6} offsetXxl={3}>
                <div style={{position: "relative", minHeight: "5rem"}}>
                    {myUserData !== null
                    ? <DataTable>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.tableTitle" />
                                </DataTableRow>
                                <DataTableRow>&nbsp;</DataTableRow>
                            </DataTableHead>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.name" />
                                </DataTableRow>
                                <DataTableRow>
                                    {profileData.displayName}
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.username" />
                                </DataTableRow>
                                <DataTableRow>
                                    {profileData.username}
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.id" />
                                </DataTableRow>
                                <DataTableRow>
                                    {profileData.id}
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.role.title" />
                                </DataTableRow>
                                <DataTableRow>
                                    <Trans i18nKey={`settingsActivity.fieldName.role.${profileData.role}`} />
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.otp.title" />
                                </DataTableRow>
                                <DataTableRow>
                                    <ul className="list-status">
                                        <li>
                                            <Icon
                                                className={classNames({
                                                    "text-success": profileData.otp === "enabledAndConfigured",
                                                    "text-danger": profileData.otp !== "enabledAndConfigured",
                                                    "unselectable-text": true,
                                                })}
                                                name={`${profileData.otp === "enabledAndConfigured" ? "check-circle" : "cross-circle"}`}
                                            />
                                            &nbsp;
                                            <Trans
                                                i18nKey={`settingsActivity.fieldName.otp.${profileData.otp}`}
                                            />
                                        </li>
                                    </ul>
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.memberOf" />
                                </DataTableRow>
                                <DataTableRow>
                                    {profileData.memberOf.map((g, i) => {
                                        if (i === 0) {
                                            return <RoundedBadge>{g.groupName}</RoundedBadge>
                                        }
                                        return <RoundedBadge leaveSpace="before">{g.groupName}</RoundedBadge>
                                    })}
                                </DataTableRow>
                            </DataTableItem>
                            {profileData.ldapInfo !== null
                            ? <>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="settingsActivity.fieldName.ldap.id" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        <RoundedBadge>
                                            {profileData.ldapInfo.ldapId}
                                        </RoundedBadge>
                                    </DataTableRow>
                                </DataTableItem>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="settingsActivity.fieldName.ldap.dn" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        <RoundedBadge>
                                            {profileData.ldapInfo.ldapDn}
                                        </RoundedBadge>
                                    </DataTableRow>
                                </DataTableItem>
                            </>
                            : <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.ldap.title" />
                                </DataTableRow>
                                <DataTableRow>
                                    <RoundedBadge>
                                        <Trans i18nKey="settingsActivity.fieldName.ldap.noLdap" />
                                    </RoundedBadge>
                                </DataTableRow>
                            </DataTableItem>}
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.storageQuotaInBytes" />
                                </DataTableRow>
                                <DataTableRow>
                                    {formatSizes(profileData.storageQuotaInBytes.used, profileData.storageQuotaInBytes.available)}
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <Trans i18nKey="settingsActivity.fieldName.lastFailedLogins" />
                                </DataTableRow>
                                <DataTableRow>
                                    {sessionData.lastFailedLogins}
                                </DataTableRow>
                            </DataTableItem>
                        </DataTableBody>
                    </DataTable>
                    : <div className="absolute-overlay">
                        <DefaultSpinner />
                        <Trans i18nKey="statsTable.loading" />
                    </div>}
                </div>
            </Col>
        </Row>
        </React.Fragment>
    );
};
export default UserProfileActivityPage;
