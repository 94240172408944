import React, { CSSProperties } from "react";

import classNames from "classnames";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
    IconName,
    IconDefinition,
    faNotdef,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faPhone,
    faTableCellsLarge,
    faInfo,
    faBars,
    faHandPointUp,
    faMaximize,
    faMessage,
    faMinimize,
    faUsers,
    faDisplay,
    faGear,
    faUsersViewfinder,
    faHourglassEnd,
    faHourglassStart,
    faFileCircleQuestion,
    faTriangleExclamation,
    faPooStorm,
    faKeyboard,
    faBoxArchive,
    faUser,
    faUserShield,
    faUserMinus,
    faEyeSlash,
    faArrowsRotate,
    faChevronDown,
    faCheck,
    faSearch,
    faSpinner,
    faXmark,
    faTable,
    faCircleNodes,
    faChartBar,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faUserCheck,
    faClock,
    faCalendarWeek,
    faCalendarDays,
    faGlobe,
    faPlug,
    faComment,
    faComments,
    faTty,
    faBoxesStacked,
    fa6,
    fa3,
    faSpaghettiMonsterFlying,
    faSort,
    faSortDown,
    faSortUp,
    faCircleInfo,
    faCopy,
    faBan,
    faBug,
    faFile,
    faKey,
} from "@fortawesome/free-solid-svg-icons";
import {
    faTrashCan,
    faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
// import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

interface CallIconProps extends FontAwesomeIconProps {
    className?: string;
    off?: boolean;
    hidden?: boolean;
    id?: string;
    style?: CSSProperties;
    sizeDL?: "xs" | "sm" | "md" | "lg" | "xl";
}

const FaIcon: React.FC<CallIconProps> = ({ id, icon, className, style, off, hidden, sizeDL, spin, title, ...props }) => {
    // const { loggedInUser } = useContext(UserContext);
    // const [user] = loggedInUser;
    // const { activeCallStatus } = useContext(CallContext);
    const getIcon = (n: IconName, off: boolean = false): IconDefinition => {
        switch (n) {
            case "3":
                return fa3;
            case "6":
                return fa6;
            case "microphone":
                return !off ? faMicrophone : faMicrophoneSlash;
            case "video":
                return !off ? faVideo : faVideoSlash;
            case "phone":
                return faPhone;
            case "grid":
                return !off ? faUsersViewfinder : faTableCellsLarge;
            case "info":
                return faInfo;
            case "bars":
                return faBars;
            case "maximize":
                return !off ? faMinimize : faMaximize;
            case "hand-point-up":
                return faHandPointUp;
            case "message":
                return faMessage;
            case "users":
                return faUsers;
            case "display":
                return faDisplay;
            case "gear":
                return faGear;
            case "hourglass":
                return !off ? faHourglassStart : faHourglassEnd;
            case "file-circle-question":
                return faFileCircleQuestion;
            case "triangle-exclamation":
                return faTriangleExclamation;
            case "poo-storm":
                return faPooStorm;
            case "keyboard":
                return faKeyboard;
            case "box-archive":
                return faBoxArchive;
            case "user":
                return faUser;
            case "user-shield":
                return faUserShield;
            case "user-minus":
                return faUserMinus;
            case "eye-slash":
                return faEyeSlash;
            case "arrows-rotate":
                return faArrowsRotate;
            case "chevron-up":
                return faChevronUp;
            case "chevron-right":
                return faChevronRight;
            case "chevron-down":
                return faChevronDown;
            case "chevron-left":
                return faChevronLeft;
            case "sort":
                return faSort;
            case "sort-down":
                return faSortDown;
            case "sort-up":
                return faSortUp;
            case "check":
                return faCheck;
            case "search":
                return faSearch;
            case "spinner":
                return faSpinner;
            case "xmark":
                return faXmark;
            case "table":
                return faTable;
            case "circle-nodes":
                return faCircleNodes;
            case "chart-bar":
                return faChartBar;
            case "user-check":
                return faUserCheck;
            case "globe":
                return faGlobe;
            case "plug":
                return faPlug;
            case "clock":
                return faClock;
            case "calendar-week":
                return faCalendarWeek;
            case "calendar-days":
                return faCalendarDays;
            case "comment":
                return faComment;
            case "comments":
                return faComments;
            case "tty":
                return faTty;
            case "box":
                return faBoxArchive;
            case "boxes-stacked":
                return faBoxesStacked;
            case "spaghetti-monster-flying":
                return faSpaghettiMonsterFlying;
            case "trash":
                return faTrashCan;
            case "circle-info":
                return faCircleInfo;
            case "copy":
                return faCopy;
            case "ban":
                return faBan;
            case "circle-check":
                return faCircleCheck;
            case "bug":
                return faBug;
            case "file":
                return faFile;
            case "key":
                return faKey;
            default:
                return faNotdef;
        }
    };
    const iconClass = classNames({
        [`${className}`]: className,
        icon: true,
        // fa: true,
        // [`fa-${name}`]: !off,
        // [`fa-${name}-slash`]: off,
        "phone-icon-rotate-135-deg": icon === "phone" && off,
        [`sq-${sizeDL}`]: sizeDL,
    });
    const myIcon = getIcon(icon as IconName, off);  
    return <FontAwesomeIcon
        {...props}
        className={iconClass}
        icon={myIcon}
        visibility={hidden ? "hidden" : "inherit"}
    />;
    //<i className={iconClass} id={id} style={{ ...style, color: "#fff" }} {...props}></i>;
};

export default FaIcon;
