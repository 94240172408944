import React from "react";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { ChatsService } from "../../services";
import { GetChatMessagesContextType, MessageLoaderStatus } from "./GetChatMessagesContextTypes";
import { GetChatMessagesOutput } from "@trantor/vdesk-api-client/src/clients/chat";
import { isDevelopment } from "../../utils/Log";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { useCronState } from "../../utils/CronJob";
import { useDelayedLoading, useHandler } from "../../utils/handler";

const NOT_IMPL = "Function not implemented.";
export const GetChatMessagesContext = createContext<GetChatMessagesContextType>({
    messages: null,
    loading: false,
    manualLoading: false,
    canLoadMorePages: "canLoad",
    openChatId: ``,
    sendMessage: function (): Promise<void> {
        throw new Error(NOT_IMPL);
    },
    deleteMessage: function (): Promise<void> {
        throw new Error(NOT_IMPL);
    },
    markLastReadChatMessage: function (): Promise<void> {
        throw new Error(NOT_IMPL);
    },
    loadMoreMessages: function (): void {
        throw new Error(NOT_IMPL);
    }
});

const UPDATE_INTERVAL_MILLIS = 250;

interface GetChatMessagesContextProviderProps {
    openChat: ChatItem;
    children?: React.ReactNode;
}

export const GetChatMessagesProvider: React.FC<GetChatMessagesContextProviderProps> = ({ openChat, children }: GetChatMessagesContextProviderProps) => {
    const [page, setPage] = useState<number>(1);
    const [displayedData, setDisplayedData] = useState<GetChatMessagesOutput["items"] | null>(null);
    const prevOpenChatRef =  useRef<string | null>(null);
    const [itemsPerPageOfLastCall, setItemsPerPageOfLastCall] = useState<number>(isDevelopment() ? 10 : 100);
    const [totalPagesOfLastCall, setTotalPagesOfLastCall] = useState<number>(1);
    const messagesFetcher = async (chatId: string, page: number): Promise<GetChatMessagesOutput["items"]> => {
        const output: GetChatMessagesOutput["items"] = [];
        let lastResp: GetChatMessagesOutput = {
            page: 1,
            itemsPerPage: itemsPerPageOfLastCall,
            totalItems: 0,
            totalPages: totalPagesOfLastCall,
            items: [],
        };

        for (let i = 1; i <= (page > lastResp.totalPages ? lastResp.totalPages : page); i++) {
            lastResp = await ChatsService.getMessages({
                filters: [{ field: "chatId", op: "eq", value: chatId }],
                orderBy: { field: "creationTime", order: "desc" },
                itemsPerPage: lastResp.itemsPerPage,
                page: i,
            });
            
            output.push(...lastResp.items);
        }
        setItemsPerPageOfLastCall(lastResp.itemsPerPage);
        setTotalPagesOfLastCall(lastResp.totalPages);
        return output.reverse();
    };
    const [retrievedData, triggerRetrieveData,loading, manualLoading] = useCronState(UPDATE_INTERVAL_MILLIS, null, () => messagesFetcher(openChat.id, page));

    const delayedLoading = useDelayedLoading(loading, 0, 0);
    const delayedManualLoading = useDelayedLoading((manualLoading), 0, 0);

    useEffect(() => {
        if (openChat.id !== prevOpenChatRef.current) {
            setPage(2);
            setDisplayedData(null);
        }
        prevOpenChatRef.current = openChat.id;
    }, [openChat]);
    useEffect(() => {
        if (retrievedData !== null) setDisplayedData(retrievedData.slice(-itemsPerPageOfLastCall * (page - 1)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retrievedData]);


    const canLoadMorePages = useMemo<MessageLoaderStatus>(() => {
        if (displayedData !== null && totalPagesOfLastCall >= page && delayedManualLoading === false) {
            return "canLoad";
        }
        if (displayedData === null || delayedManualLoading) {
            return "notSure";
        }

        return "cannotLoad";
    }, [delayedManualLoading, displayedData, page, totalPagesOfLastCall]);

    function loadMoreMessages(): void {
        if (canLoadMorePages === "canLoad") {
            if (retrievedData != null) setDisplayedData(retrievedData);
            setPage(page + 1);
            triggerRetrieveData();
        }
    }
    async function deleteMessage(id: string): Promise<void> {
        await ChatsService.deleteMessage({ id });
        triggerRetrieveData();
    }
    const [sendMessage] = useHandler(async (content: string) => {
        await ChatsService.publishMessage({
            chatId: openChat.id,
            content: { text: content },
        });
        triggerRetrieveData();
    });
    async function markLastReadChatMessage(chatMessageId:string): Promise<void> {
        return await ChatsService.markLastReadChatMessage({chatMessageId});
    }
    return (
        <GetChatMessagesContext.Provider
            value={{
                messages: displayedData,
                loading: delayedLoading,
                manualLoading: delayedManualLoading,
                canLoadMorePages,
                sendMessage,
                deleteMessage,
                loadMoreMessages,
                markLastReadChatMessage,
                openChatId: openChat.id,
            }}
        >
            {children}
        </GetChatMessagesContext.Provider>
    );
};
