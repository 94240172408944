import { FC } from "react";
import { Trans } from "react-i18next";

interface GroupBagdeProps {
    leaveSpace?: "before" | "after" | "both";
}
export const RoundedBadge: FC<React.PropsWithChildren<GroupBagdeProps>> = ({ leaveSpace, children }) => (
    <>
        {(leaveSpace === "before" || leaveSpace === "both") && <>&nbsp;</>}
        <span className="badge rounded-pill bg-light my-0">
            {children}
        </span>
        {(leaveSpace === "after" || leaveSpace === "both") && <>&nbsp;</>}
    </>
);

export const GroupBadge: FC<GroupBagdeProps> = ({ leaveSpace }) => (
        <RoundedBadge leaveSpace={leaveSpace}>
            <Trans i18nKey="chat.groupBadgeLabel" />
        </RoundedBadge>
);