import { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { Button, RSelect } from "../../../../components/Component";
import Icon from "../../../../components/icon/Icon";
import l from "../../../../utils/Log";
import { ConnectionProtocol } from "@trantor/vdesk-api-schemas/dist/computers/common";
import { ComputersContext } from "../../../../contexts/get-computers/ComputersContext";
import { EditComputerParams } from "@trantor/vdesk-api-schemas/dist/computers/editComputer";
import { ComputersService } from "../../../../services";
import { ItemOfApi } from "../../../../components/ApiTable";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

export type EditComputerFormInputs = {
    mode: string;
    reValidateMode: string;

    id: string;
    name: string;
    connectionProtocol: ConnectionProtocol;
    connectionHostname: string;
    connectionPort: string;
    wolMacAddress: string | null;
    wolTimer: string | null;
    memberOfIds: string[];
};
const getComputers = ComputersService.getComputers.bind(ComputersService);
type ComputersItem = ItemOfApi<typeof getComputers>;
interface EditComputerModalProps {
    isOpen: boolean;
    onToggle: () => void;
    computerData: ComputersItem | null;
}

const toFormState = (data: ComputersItem | null): EditComputerFormInputs => ({
    mode: "onSubmit",
    reValidateMode: "onChange",
    
    id: data?.id ?? "",
    name: data?.name ?? "",
    connectionProtocol: data?.connectionParams.proto ?? "vdesk",
    connectionHostname: data?.connectionParams.hostname ?? "",
    connectionPort: data?.connectionParams.port.toString() ?? "",
    wolMacAddress: data?.macAddress ?? null,
    wolTimer: data?.wakeOnLanTimeInSeconds?.toString() ?? null,
    memberOfIds: data?.memberOf.map((item) => item.groupId) ?? [],
});

const EditComputerModal: React.FC<EditComputerModalProps> = ({ isOpen, onToggle, computerData }) => {
    const { t } = useTranslation();
    const prevComputerData = useRef<ComputersItem | null>(null);
    const myData = useMemo(() => {
        if (computerData !== null) {
            prevComputerData.current = computerData;
            return computerData;
        }
        return prevComputerData.current;
    }, [computerData])

    const updateComputer = ComputersService.editComputer.bind(ComputersService);

    const editComputerForm = useForm<EditComputerFormInputs>({
        defaultValues: toFormState(myData),
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });
    
    useEffect(() => {
        if (myData === null || myData.id === editComputerForm.getValues("id")) {
            return;
        }

        editComputerForm.reset(toFormState(myData));
    }, [editComputerForm, myData]);

    /**
     * Repopulate the form when the modal visibility changes
     */
    useEffect(() => {
        editComputerForm.reset(toFormState(myData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    // submit function to update a new item
    const onEditSubmit: SubmitHandler<EditComputerFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            const editedComputer: EditComputerParams = {
                id: submitData.id,
                name: submitData.name !== "" ? submitData.name : undefined,
                connectionParams: {
                    proto: submitData.connectionProtocol,
                    hostname: submitData.connectionHostname !== "" ? submitData.connectionHostname : myData?.connectionParams.hostname ?? "",
                    port: parseInt(submitData.connectionPort !== "" ? submitData.connectionPort : myData?.connectionParams.port.toString() ?? "", 10),
                },
                macAddress: submitData.wolMacAddress !== "" ? submitData.wolMacAddress : null,
                wakeOnLanTimeInSeconds: submitData.wolTimer !== "" && submitData.wolTimer !== null ? parseInt(submitData.wolTimer, 10) : null,
            }

            await updateComputer(editedComputer);

            onToggle();
        } catch (err) {
            l.error("failed to update computer", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };

    const [error, setError] = useState<string | null>(null);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            setDisableModal(false);
            onToggle();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    setError(null);
                    onToggle();
                }}
                className="close"
            >
                <Icon name="cross-sm" />
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="computersManagement.editModal.title" />
                </h5>
                <div className="mt-4">
                    <Form
                        className="row gy-4"
                        onSubmit={editComputerForm.handleSubmit(onEditSubmit, (p, e) => l.error("-- ERR:", p, e))}
                    >
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.name}
                                    {...editComputerForm.register("name")}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.connectionProtocol" />
                                </label>
                                <RSelect
                                    isDisabled={disableModal}
                                    options={[
                                        { value: "nx", label: "nx" },
                                    ]}
                                    defaultValue={{
                                        value: myData?.connectionParams.proto,
                                        label: myData?.connectionParams.proto,
                                    }}
                                    onChange={(e: { value: string }) =>
                                        editComputerForm.setValue("connectionProtocol", e.value as ConnectionProtocol)
                                    }
                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.connectionHostname" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.connectionParams.hostname}
                                    {...editComputerForm.register("connectionHostname")}
                                />
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.connectionPort" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="number"
                                    defaultValue={myData?.connectionParams.port}
                                    {...editComputerForm.register("connectionPort")}
                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.wolMacAddress" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="string"
                                    defaultValue={myData?.macAddress ?? undefined}
                                    {...editComputerForm.register("wolMacAddress")}
                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.wolTimer" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="number"
                                    defaultValue={myData?.wakeOnLanTimeInSeconds ?? undefined}
                                    {...editComputerForm.register("wolTimer")}
                                />
                            </div>
                        </Col>
                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={disableModal}>
                                            <Trans i18nKey="computersManagement.editModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    onToggle();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="computersManagement.editModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default EditComputerModal;
