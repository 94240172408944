import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Button } from "../../../components/Component";
import { ButtonColor } from "../../../utils/GeneralTypes";

type PrimaryActionProps = {
    primaryBtnText: string;
    primaryButtonColor?: ButtonColor;
    primaryActionCallback: () => void;
};

type SecondaryActionProps = {
    secondaryBtnText: string;
    secondaryButtonColor?: ButtonColor;
    secondaryActionCallback: () => void;
} | {
    secondaryBtnText?: never;
    secondaryButtonColor?: never;
    secondaryActionCallback?: never;
};

type ConfirmationModalProps = PrimaryActionProps & {
    isOpen: boolean;
    title: string;
    description: string;
} & SecondaryActionProps;

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
    const toggleCallback = props.secondaryActionCallback ?? props.primaryActionCallback;

    return (
        <Modal
            isOpen={props.isOpen}
            toggle={toggleCallback}
            className="modal-dialog-centered"
            size="lg"
        >
            <ModalHeader toggle={toggleCallback}>
                {props.title}
            </ModalHeader>
            <ModalBody>
                {props.description}
            </ModalBody>
            <ModalFooter>
                <Button color={props.primaryButtonColor ?? "primary"} onClick={props.primaryActionCallback}>
                    {props.primaryBtnText}
                </Button>
                
                {
                    props.secondaryActionCallback && (
                        <Button color={props.secondaryButtonColor ?? "secondary"} onClick={props.secondaryActionCallback}>
                            {props.secondaryBtnText}
                        </Button>
                    )
                }
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
