import { HostnameSchema } from "../common.js";
import { z } from "zod";
export const JoinDomainParamsSchema = z.object({
    name: z.string().min(1),
    wireguardPublicKey: z.string().min(1),
    wireguardEndpoint: HostnameSchema,
});
export const JoinDomainOutputSchema = z.object({
    serverId: z.string().uuid(),
    agentHostname: HostnameSchema,
    hadbToken: z.string().min(1),
    dbPassword: z.string().min(1),
    allServers: z.object({
        id: z.string().uuid(),
        agentHostname: HostnameSchema,
        wireguardPublicKey: z.string().min(1),
        wireguardEndpoint: HostnameSchema,
    }).array().min(2),
});
