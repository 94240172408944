import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";

const RSelect = ({ ...props }) => {
    const { t: origT } = useTranslation();
    const t = (code: string): string => {
        return origT(code).toString();
    };
    return (
        <div className="form-control-select">
            <Select
                className={`react-select-container ${props.className ? props.className : ""}`}
                classNamePrefix="react-select"
                noOptionsMessage={() => <Trans i18nKey="reactSelectNoOptions" />}
                placeholder={<Trans i18nKey="reactSelectPlaceholer" />}
                {...props}
            />
        </div>
    );
};

export default RSelect;
