import { z } from "zod";
export const JoinCallParamsSchema = z.object({
    shortCallId: z.string().min(1),
    moderatorCode: z.string().min(1).nullable().optional().default(null),
    displayName: z.string().min(1).nullable(),
    leaveOthers: z.boolean(),
    dryRun: z.boolean(),
});
export const JoinCallOutputSchema = z.object({
    callId: z.string().uuid(),
    peerId: z.string().uuid(),
    peerToken: z.string().min(1),
    startTimestamp: z.number().int(),
    turn: z.object({
        url: z.string().min(1),
        creds: z.object({
            username: z.string().min(1),
            password: z.string().min(1),
        }).nullable(),
    }).array(),
});
