import z from "zod";
const EnvironmentSchema = z.enum(["development", "production", "test"]);
type Environment = z.infer<typeof EnvironmentSchema>;

const normalizeEnv = (): Environment => {
    const parsed = EnvironmentSchema.safeParse(process.env.NODE_ENV);
    const env: Environment = parsed.success ? parsed.data : "production";
    return env;
}
const isDevelopment = (): boolean => {
    return normalizeEnv() === "development";
};
const isProduction = (): boolean => {
    return normalizeEnv() === "production";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const doItInDevOnly = <P extends any[]>(fn: (...args: P) => void): ((...args: P) => void) => {
    return isDevelopment() ? fn : (() => { /* do nothing */ });
};

const l = {
    log: doItInDevOnly(console.log),
    info: doItInDevOnly(console.info),
    warn: doItInDevOnly(console.warn),
    error: doItInDevOnly(console.error),
};

export { isDevelopment, isProduction, doItInDevOnly };
export default l;
