import { TempCallStreamData } from "../../../components/call/CallVideoScreens";
import { UserData } from "../../../utils/GeneralTypes";

export type CallContextType = {
  //   activeCallState: [CallData, React.Dispatch<React.SetStateAction<CallData>>];
  activeCallData: CallData;
  activeCallStatus: CallStatus;
  initActiveCall: () => void;
  destroyActiveCall: () => void;
  inviteUserToActiveCall: (id: string) => void;
  removeUserFromActiveCall: (id: string) => void;
  toggleMic: () => void;
  toggleVideo: () => void;
  toggleGridView: () => void;
  toggleInfo: () => void;
  toggleRaisedHand: () => void;
  toggleFullscreen: () => void;
  isCallStartable: () => boolean;
  isCallEndable: () => boolean;
  decrementVideoCalls: () => void;
  incrementVideoCalls: () => void;
  deleteNumber3: () => void;
};

export type CallData = {
  id: string;
  status: CallStatus;
  owner: UserData;
  participants: UserData[];
  videoStreams: TempCallStreamData[];
  invited: UserData[];
  callLog: CallEvent[];
  micOff: boolean;
  videoOff: boolean;
  gridOn: boolean;
  infoOff: boolean;
  risedHand: boolean;
  fullscreen: boolean;
};

export enum CallStatus {
  nonExistent = "nonExistent",
  created = "created",
  connecting = "connecting",
  inProgress = "inProgress",
  terminated = "terminated",
  broken = "broken",
}

export type CallEvent = {
  type: CallEventType;
  date: Date;
  data?: Object;
};

export enum CallEventType {
  callCreated = "callCreated",
  participantInvited = "participantInvited",
  participantAcceptedInvite = "participantAcceptedInvite",
  participantConnected = "participantConnected",
  participantDisconnected = "participantDisconnected",
  callEnded = "callEnded",
  error = "error",
}
/**
 * {
  chatState: [Array<IChatData>, React.Dispatch<React.SetStateAction<Array<IChatData>>>];
  chatData: Array<IChatData>;
  fav: Array<Array<IChatData>>;
  userData: Array<IChatUserData | IChatGroupUserData>;
  deleteConvo: (id: string) => void;
  propAction: (id: string, prop: keyof IChatData) => void;
  favAction: (id: string) => void;
  changeNickname: (id: string, nick: string) => void;
  changeTheme: (id: string, name: string) => void;
  deleteUser: (id: string, userId: string) => void;
  makeAdmin: (id: string, userId: string) => void;
  addUserToChat: (id: string, object: IChatGroupUserData) => void;
}
 */
