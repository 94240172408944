import { CreateUserParams } from "@trantor/vdesk-api-client/dist/clients/user";
import _ from "lodash";
import React, { lazy, useCallback, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserSubmit } from "../pages/settings/UserTypes";
import {
    GlobalErrorType,
    SessionData,
    UserData,
    UserDataFromUsers,
    UserRole,
    UserStatus,
    UserTheme,
    UserThemeArray,
} from "./GeneralTypes";
import { DateTime, Duration, DurationUnit } from "luxon";
import { getCurrentLang } from "./lang";
import { scope } from "./scope";
import { z } from "zod";

// Serialization / deserialization
/**
 * Temporary method to be used until UserTheme will be added to DB
 * @param s Username
 * @returns A non-random UserTheme based on the letters of the provided Username
 */
export const themeFromUsername = _.memoize((s?: string | null) => calculateThemeFromUsername(s));
function calculateThemeFromUsername(s?: string | null): UserTheme {
    const letters = "?@#$%&*-_=+<>.:~ QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm0123456789";
    const value: number = Array.from(s ?? "").reduce(
        (prevValue, curr) => prevValue + letters.split("").findIndex((letter) => letter === curr) || 0,
        0
    );
    const themeKeys = UserThemeArray;
    return themeKeys[value % themeKeys.length] ?? themeKeys[0];
}
export function deserializeUser(u: UserDataFromUsers): UserData {
    return {
        ...u,
        avatarBg: themeFromUsername(u.username),
        status: UserStatus.Active,
        checked: false,
    };
}
export function deserializeUsers(uu: UserDataFromUsers[]): UserData[] {
    return uu.map(deserializeUser);
}
export function serializeUserForCreate(sUser: UserSubmit): CreateUserParams {
    if (sUser.password !== undefined) {
        return {
            displayName: sUser.displayName, // TODO: Update BE to accept diplayName
            username: sUser.username,
            role: sUser.role,
            enabled: sUser.enabled,
            password: sUser.password,
        };
    } else {
        throw new Error("Must provide password");
    }
}

// Session data utils

export const checkTokenValidity = (tkn: string): boolean => {
    return !!tkn;
    // NOT NEEDED:
    // AuthService.myself().then((r) => {
    //   // if (r?.tokenExpiration) {
    //     // TODO: Add expiration update in cookie via MainService to-be-written custom function
    //   // }
    //   return true;
    // }).catch(()=>{
    //   return false;
    // })
}; //TODO: Put some other kind of check, if needed

export const checkSessionValidity = (sesh: SessionData): boolean => {
    const now: DateTime = DateTime.now();
    //     l.log(
    //         `checking session validity:
    // sesh.expiryDate:${sesh.expiryDate && DateTime.fromJSDate(sesh.expiryDate)}
    // now:${now}
    // DIFF:${sesh.expiryDate && now > DateTime.fromJSDate(sesh.expiryDate)}`
    //     );

    if (!sesh) {
        return false;
    }

    if (sesh.expires && sesh.expiryDate && now > DateTime.fromJSDate(sesh.expiryDate)) {
        return false;
    }

    return checkTokenValidity(sesh.token);
};

/**
 * Used to verify access to routes and functionalities
 * @param user User that can or cannot access
 * @param neededRole Role needed to access
 * @returns Wether User can access or not
 */
export const canAccess = (user: UserData, neededRole: UserRole): boolean => {
    const c: {
        [T in UserRole]: UserRole[];
    } = {
        admin: ["admin", "regular"],
        regular: ["regular"],
    };
    return c[user.role].includes(neededRole);
};

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
    url = "";
} else {
    url = window.location.host.split("/")[1] + "";
    if (url) {
        url = `/${window.location.host.split("/")[1]}`;
    } else url = process.env.PUBLIC_URL || ""; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData: any) => {
    let errorState: any = {};
    Object.keys(formData).forEach((item) => {
        if (formData[item] === null || formData[item] === "") {
            errorState[item] = "This field is required";
        }
    });
    return errorState;
};
export const hideMostOfString = (s:string, n?: number) => {
    let nS: string = "";
    const myN: number = n ?? 4;
    for (let i = 0; i < s.length; i++) {
        if (i < s.length - myN) {
            nS += "*"
        } else {
            nS += s[i]
        }
    }
    return nS;
}
/**
 * Searchs for the first two uppercase beginning-of-word
 * @param string The name to be evaluated
 * @returns the first or first two letters from a name or empty string
 */
export const findUpper = (string?: string | null): string => {
    const initials =
        string
            ?.match(/\b([A-Z])/g)
            ?.slice(0, 2)
            .join("") ?? "";
    return !(initials.length === 0) ? initials : (string ?? "").charAt(0).toUpperCase();
};
//Function that calculates the from current date
export const setDeadline = (days: any) => {
    let todayDate = new Date();
    var newDate = new Date(todayDate);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date: Date): string => {
    let d = date.getDate();
    let m = date.getMonth();
    let y = date.getFullYear();
    let final = monthNames[m] + " " + d + ", " + y;
    return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate: Date): string => {
    let d = rdate.getDate();
    let sd: string = "";
    (d < 10 && (sd = "0" + d)) || (sd = d + "");
    let m = rdate.getMonth() + 1;
    let sm: string = "";
    (m < 10 && (sm = "0" + m)) || (sm = m + "");
    let y = rdate.getFullYear();
    let final = y + "-" + sm + "-" + sd;

    return final;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline: Date): number => {
    var currentDate = new Date();
    var difference = deadline.getTime() - currentDate.getTime();
    var days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
};

//Date formatter function
export const dateFormatterAlt = (date: Date, reverse: boolean): string => {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let final = "";
    reverse ? (final = m + " - " + d + " - " + y) : (final = y + " - " + d + " - " + m);
    return final;
};
export const formatDate = (date: number | Date | DateTime | null, options?: { format?: "dateOnly" | "timeOnly"}): string | null => {
    let luxonizedDate;
    if (date === null) {
        return null;
    } else if (date instanceof Date) {
        luxonizedDate = DateTime.fromJSDate(date);
    } else if (typeof date === "number") {
        luxonizedDate = DateTime.fromMillis(date);
    } else {
        luxonizedDate = date;
    }
    if (options !== undefined) {
        if (options.format === "dateOnly") {
            return luxonizedDate.toFormat(`yyyy-LL-dd`);
        }
        if (options.format === "timeOnly") {
            return luxonizedDate.toFormat(`HH:mm`);
        }
    }
    return luxonizedDate.toFormat(`yyyy-LL-dd HH:mm`);
};

export const formatNumber = (value: number, decimals: number = 2): number => {
    // +(Math.round(parseInt(value + `e+${decimals}`, 10))  + `e-${decimals}`)
    const p = Math.pow(10, decimals);
    const n = (value * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
}



export function formatDuration(interval: number | Duration): string;
export function formatDuration(interval: null): null;
export function formatDuration(interval: number | Duration | null): string | null {
    if (interval === null) {
        return null;
    }

    const luxonizedDuration = scope(() => {
        if (typeof interval === `number`) {
            return Duration.fromMillis(Math.floor(interval));
        }

        return interval;
    });

    const units = [
        `years`, `months`, `days`, `hours`,
        `minutes`, `seconds`, `milliseconds`,
    ] as const satisfies DurationUnit[];

    const smallestUnit = luxonizedDuration.milliseconds > (60 * 1000) ? `minutes` : `seconds`;

    const smallestIdx = units.indexOf(smallestUnit);

    const entries = Object.entries(
        luxonizedDuration.shiftTo(...units).normalize().toObject()
    ).filter(([, amount], idx) => amount > 0 && idx <= smallestIdx);

    const finalDuration = Duration.fromObject(
        entries.length === 0 ? { [smallestUnit]: 0 } : Object.fromEntries(entries),
        { locale: getCurrentLang() },
    );

    return finalDuration.toHuman({ unitDisplay: `long` });
}

// TODO: translate
export function formatSize(bytes: number): string {
    if (bytes < 1024) {
        return `${bytes} byte`;
    }

    if (bytes < 1024 * 1024) {
        return `${(bytes / 1024).toFixed(2)} KiB`;
    }

    if (bytes < 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024).toFixed(2)} MiB`;
    }

    return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GiB`;
}

export function formatSizes(used: number, available: number): string {
    if (used < 1024 && available < 1024) {
        return `${used}/${available} byte`;
    }

    if (used < 1024 * 1024 && available < 1024 * 1024) {
        return `${(used / 1024).toFixed(2)}/${(available / 1024).toFixed(2)} KiB`;
    }

    if (used < 1024 * 1024 * 1024 && available < 1024 * 1024 * 1024) {
        return `${(used / 1024 / 1024).toFixed(2)}/${(available / 1024 / 1024).toFixed(2)} MiB`;
    }

    return `${(used / 1024 / 1024 / 1024).toFixed(2)}/${(available / 1024 / 1024 / 1024).toFixed(2)} GiB`;
}

//Date formatter function
// export const dateFormatter = (date: Date | string, reverse: boolean, string: string) => {
//   let dateformat = date.split("-");
//   //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
//   reverse
//     ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
//     : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

//   return date;
// };

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
    var hours = todaysDate.getHours();
    var minutes = todaysDate.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? 0 + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};

//Percentage calculation
export const calcPercentage = (str1: string, str2: string): number => {
    let result = Number(str2) / Number(str1);
    result = result * 100;
    return Math.floor(result);
};

export const truncate = (str: string, n: number): string => {
    return str.length > n ? str.slice(0, n - 1) + " " + truncate(str.slice(n - 1, str.length), n) : str;
};

export const RedirectAs404: React.FC = (...props: any) => {
    const location = useLocation();
    return (
        // <Navigate replace to={Object.assign({}, ...props, location, { state: { globalError: GeneralErrorType.IS404} })} />
        <Navigate replace to={location.pathname} state={{ ...props, location, globalError: GlobalErrorType.IS404 }} />
    );
};
export function useGlobalError() {
    const navigate = useNavigate();
    const location = useLocation();
    function triggerGlobalError(type: GlobalErrorType) {
        const { state: oldState } = location;
        navigate(location.pathname, {
            replace: true,
            state: {
                ...oldState,
                globalError: type,
            },
        });
    }
    function cleanGlobalError() {
        const { state: oldState } = location;
        delete oldState.globalError;
        navigate(location.pathname, {
            replace: true,
            state: {
                ...oldState,
            },
        });
    }
    return {
        triggerGlobalError,
        cleanGlobalError,
    };
}
const Error404Modern = lazy(() => import("../pages/error/404-modern"));
const ErrorExpiredSession = lazy(() => import("../pages/error/session-expired"));
const ErrorBrokenSession = lazy(() => import("../pages/error/session-broken"));
const ErrorGeneric = lazy(() => import("../pages/error/generic"));
interface IsGlobalErrorOrElementProps {
    element: React.FunctionComponent;
    [key: string]: any;
}
export const IsGlobalErrorOrElement: React.FC<IsGlobalErrorOrElementProps> = ({ element: Element, ...props }) => {
    const location = useLocation();
    if (location.state && location.state.globalError !== undefined && !location.state.fromSignout) {
        const ge: GlobalErrorType = location.state.globalError;
        switch (ge) {
            case GlobalErrorType.IS404:
                return <Error404Modern />;
            case GlobalErrorType.EXPIREDSESSION:
                return <ErrorExpiredSession />;
            case GlobalErrorType.BROKENSESSION:
                return <ErrorBrokenSession />;
            default:
                return <ErrorGeneric />;
        }
        // if (s.is404) {
        //   return <Error404Modern />
        // }
    }
    return <Element />;
};
// returns upload url
export const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
    { value: "suspend", label: "bulk.user.suspend" },
    { value: "delete", label: "bulk.user.delete" },
] as const;

export type BulkAction = (typeof bulkActionOptions)[number]["value"];

// Converts KB to MB
export const bytesToMegaBytes = (bytes: number) => {
    let result = bytes / (1024 * 1024);
    return result.toFixed(2);
};

export const monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
];

export const addDays = (date: Date | string, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};
enum Currency {
    USD = "usd",
    EUR = "eur",
    BTC = "btc",
    ETH = "eth",
}
interface CurrencyData {
    usd?: number;
    euro?: number;
    BTC?: number | Array<any>;
    ETH?: number;
}

// Returns Currency based value for invest panel
export const returnCurrency = (currency: Currency, data: CurrencyData, upperCase: boolean) => {
    if (currency === "usd") {
        return { value: data.usd?.toFixed(2), label: upperCase ? "USD" : "$" };
    } else if (currency === "eur") {
        return { value: data.euro?.toFixed(2), label: upperCase ? "EUR" : "euro" };
    } else if (currency === "btc") {
        return { value: (data.BTC as number).toFixed(6), label: "BTC" };
    } else {
        return { value: data.ETH?.toFixed(2), label: "ETH" };
    }
};

// Returns levels
export const returnLevel = (currency: Currency, data: CurrencyData, upperCase: boolean) => {
    if (currency === "usd") {
        return data.usd;
    } else if (currency === "eur") {
        return data.euro;
    } else if (currency === "btc") {
        let amount = (data.BTC as Array<any>).map((item) => {
            return item.toFixed(6);
        });
        return amount;
    } else {
        return data.ETH;
    }
};
// misc
export function makeid(length: number, simple?: boolean): string {
    let result = "";
    const characters = simple
        ? "abcdefghijklmnopqrstuvwxyz"
        : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const useIntervalAsync = <R = unknown,>(fn: () => Promise<R>, ms: number): [() => Promise<void>, () => void] => {
    const runningCount = useRef(0);
    const timeout = useRef<number>();
    const mountedRef = useRef(false);

    const next = useCallback(
        (handler: TimerHandler) => {
            if (mountedRef.current && runningCount.current === 0) {
                // l.log("useIntervalAsync\nsetting next timeout");
                timeout.current = window.setTimeout(handler, ms);
            }
        },
        [ms]
    );

    const run = useCallback(async () => {
        runningCount.current += 1;
        // l.log(`useIntervalAsync\nRunning ${runningCount.current} concurrents`);
        await fn().then((result) => {
            runningCount.current -= 1;
            next(run);
            return result;
        });
    }, [fn, next]);

    useEffect(() => {
        mountedRef.current = true;
        run();

        return () => {
            mountedRef.current = false;
            window.clearTimeout(timeout.current);
        };
    }, [run]);

    const flushAndRun = useCallback(() => {
        window.clearTimeout(timeout.current);
        return run();
    }, [run]);

    const destroy = useCallback(() => {
        window.clearTimeout(timeout.current);
    }, []);

    return [flushAndRun, destroy];
};

export function isDarkMode(): boolean {
    return localStorage.getItem("darkMode") === "enabled"; // && localStorage.getItem("darkMode") !== "disabled";
}
export function activateDarkMode(): void {
    localStorage.setItem("darkMode", "enabled");
}
export function deactivateDarkMode(): void {
    localStorage.removeItem("darkMode");
}
