import { ChatItem, ChatItemSchema } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { UserData } from "./GeneralTypes";
import { ChatArchiveItem, ChatArchiveItemSchema } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";

export const getModerators = (chat?: ChatItem | ChatArchiveItem | null): string[] => chat?.participants.filter((p) => p.moderator).map((p) => p.user.id) ?? [];

export const isUserMod = (chat?: ChatItem | ChatArchiveItem | null, user?: UserData) => {
    if (!chat) return false;
    if (!user) return false;
    const moderators = getModerators(chat);
    if (moderators.includes(user.id)) {
        return true;
    }
    return false;
}

export const isChatItem = (chat: ChatItem | ChatArchiveItem | null): chat is ChatItem => {
    return ChatItemSchema.safeParse(chat).success;
}

export const isChatArchiveItem = (chat: ChatItem | ChatArchiveItem | null): chat is ChatArchiveItem => {
    return ChatArchiveItemSchema.safeParse(chat).success;
}