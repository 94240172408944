import { z } from "zod";
import { EnumOpSchema, NumberOpSchema, StringOpSchema } from "../filters.js";
import { HostnameSchema, PortNumberSchema } from "../common.js";
import { ConnectionProtocolSchema } from "./common.js";
export const ComputersConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`name`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
]);
export const ComputersFiltersSchema = z.array(ComputersConditionSchema.or(z.array(ComputersConditionSchema)));
export const ComputersOrderBySchema = z.object({
    field: z.enum([`name`, `creationTime`]),
    order: z.enum([`asc`, `desc`]),
});
export const GetComputersParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: ComputersOrderBySchema.optional().default({ field: `name`, order: `asc` }),
    filters: ComputersFiltersSchema.optional().default([]),
});
export const ComputerItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    name: z.string().min(1),
    connectionParams: z.object({
        proto: ConnectionProtocolSchema,
        hostname: HostnameSchema,
        port: PortNumberSchema,
    }),
    online: z.boolean(),
    macAddress: z.string().min(1).nullable(),
    wakeOnLanTimeInSeconds: z.number().int().min(1).nullable(),
    ldapInfo: z.object({
        ldapId: z.string().min(1),
        ldapDn: z.string().min(1),
    }).nullable(),
    memberOf: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
});
export const GetComputersOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: ComputerItemSchema.array(),
});
