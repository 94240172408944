import React, { useEffect, useMemo, useRef, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { useTranslation, Trans } from "react-i18next";
import { Block, BlockContent, BlockHead, BlockTitle, Button, Icon, PreviewCard } from "../../components/Component";
import { Form, Spinner, Alert, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SessionData, SessionDataJSON } from "../../utils/GeneralTypes";
import { getCookie, removeCookie } from "typescript-cookie";
import AuthService from "../../services/AuthService";
import l, { isDevelopment } from "../../utils/Log";
import LanguageHead from "../../layout/header/dropdown/language/Language";
import { formatDate, isDarkMode } from "../../utils/Utils";
import { DateTime } from "luxon";
import { getUniqueSearchParam } from "../../utils/searchParams";
import { useOnEnter } from "../../utils/useOnEnter";
import { asyncVoid, useHandler } from "../../utils/handler";
import { scope } from "../../utils/scope";
import { z } from "zod";
import QRCode from "qrcode";

const Login: React.FC = () => {
    const { t } = useTranslation();
    const [passState, setPassState] = useState(false);
    const [errorVal, setError] = useState("");
    // const [cookies, setCookies, removeCookies] = useCookies(["sessionData"]);
    const [sessionData, setSessionData] = useState<SessionData>({} as SessionData);
    const navigate = useNavigate();
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const location = useLocation();
    const updateSessionDataFromCookie = (): void => {
        const sd = getCookie("sessionData");
        if (sd !== undefined && sd.length > 0) {
            const sessionData: SessionDataJSON = JSON.parse(sd);
            setSessionData({
                ...sessionData,
                lastLogin: DateTime.fromISO(sessionData.lastLogin).toJSDate(),
                mintingDate: DateTime.fromISO(sessionData.mintingDate).toJSDate(),
                expiryDate:
                    sessionData.expiryDate !== undefined && DateTime.fromISO(sessionData.expiryDate).isValid
                        ? DateTime.fromISO(sessionData.expiryDate).toJSDate()
                        : undefined,
            });
        }
    };

    useEffect(() => {
        const { fromSignout } = location?.state ?? {};
        if (fromSignout) {
            removeCookie("sessionData", { path: "/" });
        }
        if (isDarkMode()) {
            document.body.className = "dark-mode";
        }
    }, [location.state]);

    const toggleSuccessModal = () => {
        if (successModal) {
            setSuccessModal(!successModal);

            const goto = getUniqueSearchParam(`goto`);

            if (goto === null) {
                navigate("/", { replace: true, relative: "path" });
            }
            else {
                window.location.replace(`${window.location.protocol}//${window.location.host}/call/${encodeURIComponent(goto)}`);
            }
        }
    };

    const [firstTimeOtpModal, setFirstTimeOtpModal] = useState<{ otpKey: string, otpSetupURI: string, username: string, password: string } | null>(null);
    const firstTimeOtpModalRef = useRef<HTMLInputElement | null>(null);

    const [missingOtpModal, setMissingOtpModal] = useState<{ username: string, password: string } | null>(null);
    const missingOtpModalRef = useRef<HTMLInputElement | null>(null);

    const [onFormSubmit, loading] = useHandler(async (formData: { loginUsername: string, loginPassword: string }) => {
        setError(``);

        try {
            l.log(`Signing in`);

            await AuthService.login({
                username: formData.loginUsername,
                password: formData.loginPassword,
            });

            await AuthService.myself();

            updateSessionDataFromCookie();

            setSuccessModal(true);
        }
        catch (error) {
            const parsedError = scope(() => {
                const parsed = z.object({
                    errorCode: z.string().min(1),
                    body: z.object({
                        otpKey: z.unknown().optional(),
                        otpSetupURI: z.unknown().optional(),
                    }).optional(),
                }).safeParse(error);

                if (parsed.success) {
                    return parsed.data;
                }

                return { errorCode: `UNKNOWN-ERROR` };
            });

            if (parsedError.errorCode === `LOGIN:FIRST-TIME-OTP` && typeof parsedError.body?.otpKey === `string` && typeof parsedError.body?.otpSetupURI === `string`) {
                setFirstTimeOtpModal({
                    otpKey: parsedError.body.otpKey,
                    otpSetupURI: parsedError.body.otpSetupURI,
                    username: formData.loginUsername,
                    password: formData.loginPassword,
                });

                setTimeout(() => {
                    firstTimeOtpModalRef.current?.focus();
                }, 0);
            }
            else if (parsedError.errorCode === `LOGIN:MISSING-OTP`) {
                setMissingOtpModal({
                    username: formData.loginUsername,
                    password: formData.loginPassword,
                });

                setTimeout(() => {
                    missingOtpModalRef.current?.focus();
                }, 0);
            }
            else {
                setError(parsedError.errorCode);
                l.error(error);
            }
        }
    });

    const [otpQrCode, setOtpQrCode] = useState<string | null>(null);

    useEffect(() => {
        const uri = firstTimeOtpModal?.otpSetupURI;

        if (uri === undefined) {
            setOtpQrCode(null);
            return;
        }

        setOtpQrCode(null);

        QRCode.toDataURL(uri, (error, dataUrl) => {
            if (error !== null && error !== undefined) {
                return;
            }

            if (firstTimeOtpModal?.otpSetupURI !== uri) {
                return;
            }

            setOtpQrCode(dataUrl);
        });
    }, [firstTimeOtpModal?.otpSetupURI]);

    const [handleFirstTimeOtpContinue, handleFirstTimeOtpContinueLoading] = useHandler(async () => {
        const data = firstTimeOtpModal;

        if (data === null) {
            return;
        }

        const otpToken = firstTimeOtpModalRef.current?.value;

        if (otpToken === undefined) {
            return;
        }

        try {
            await AuthService.login({
                username: data.username,
                password: data.password,
                otp: otpToken,
            });

            await AuthService.myself();

            updateSessionDataFromCookie();

            setFirstTimeOtpModal(null);
            setSuccessModal(true);
        }
        catch (error) {
            const parsedError = scope(() => {
                const parsed = z.object({
                    errorCode: z.string().min(1),
                }).safeParse(error);

                if (parsed.success) {
                    return parsed.data;
                }

                return { errorCode: `UNKNOWN-ERROR` };
            });

            setError(parsedError.errorCode);
            l.error(error);

            setFirstTimeOtpModal(null);
        }
    });

    const [handleMissingOtpContinue, handleMissingOtpContinueLoading] = useHandler(async () => {
        const data = missingOtpModal;

        if (data === null) {
            return;
        }

        const otpToken = missingOtpModalRef.current?.value;

        if (otpToken === undefined) {
            return;
        }

        try {
            await AuthService.login({
                username: data.username,
                password: data.password,
                otp: otpToken,
            });

            await AuthService.myself();

            updateSessionDataFromCookie();

            setMissingOtpModal(null);
            setSuccessModal(true);
        }
        catch (error) {
            const parsedError = scope(() => {
                const parsed = z.object({
                    errorCode: z.string().min(1),
                }).safeParse(error);

                if (parsed.success) {
                    return parsed.data;
                }

                return { errorCode: `UNKNOWN-ERROR` };
            });

            setError(parsedError.errorCode);
            l.error(error);

            setMissingOtpModal(null);
        }
    });

    useEffect(() => {
        updateSessionDataFromCookie();
    }, [loading, location, navigate, successModal]);

    type FormInputs = {
        mode: string;
        reValidateMode: string;
        loginUsername: string;
        loginPassword: string;
    };
    const defaultFormInput = {
        defaultValues: {
            mode: "onSubmit",
            reValidateMode: "onChange",
            loginUsername: "",
            loginPassword: "",
        },
        resetOptions: {
            keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    };
    if (isDevelopment()) {
        defaultFormInput.defaultValues.loginUsername = "admin";
        defaultFormInput.defaultValues.loginPassword = "aaa";
    }
    const { register, handleSubmit } = useForm<FormInputs>(defaultFormInput);

    const realHandleSubmit = asyncVoid(async () => {
        await handleSubmit(onFormSubmit)();
    });

    const inputUsernameRef = useRef<HTMLInputElement | null>(null);
    const inputPasswordRef = useRef<HTMLInputElement | null>(null);

    const { ref: formInputUsernameRef, ...registerInputUsername } = register(`loginUsername`, { required: t("loginPage.requiredField").toString() });
    const { ref: formInputPasswordRef, ...registerInputPassword } = register(`loginPassword`, { required: t("loginPage.requiredField").toString() });

    useEffect(() => {
        inputUsernameRef.current?.focus();
    }, []);

    useOnEnter(inputUsernameRef, () => {
        inputPasswordRef.current?.focus();
    });

    useOnEnter(inputPasswordRef, () => {
        realHandleSubmit();
    });

    return (
        <>
            <Head title="Login" />
            <PageContainer>
                <Block className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        {isDevelopment()
                        ? <></>
                        : <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                            {/* <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" /> */}
                            {isDarkMode() ? (
                                <img className="logo-light logo-img logo-img-lg" src="/vendor.png" alt="Vendor logo" />
                            ) : (
                                <img
                                    className="logo-dark logo-img logo-img-lg"
                                    src="/vendor.png"
                                    alt="Vendor logo dark"
                                />
                            )}
                        </Link>}
                    </div>

                    <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                        <div style={{ position: "absolute", top: 0, right: 0 }}>
                            <LanguageHead />
                        </div>
                        <BlockHead>
                            <BlockContent>
                                <BlockTitle tag="h4">
                                    <div className="text-center">
                                        {isDarkMode() ? (
                                            <img
                                                className="logo-light logo-img logo-img-lg mb-3"
                                                src={Logo}
                                                alt="Logo"
                                            />
                                        ) : (
                                            <img
                                                className="logo-dark logo-img logo-img-lg mb-3"
                                                src={LogoDark}
                                                alt="Logo dark"
                                            />
                                        )}
                                    </div>
                                    <center>
                                        <Trans i18nKey={"loginPage.appTitle"} />
                                    </center>
                                </BlockTitle>
                            </BlockContent>
                        </BlockHead>
                        <div className="mb-3" style={{ opacity: `${errorVal && errorVal.length ? `1` : `0`}` }}>
                            <Alert color="danger" className="alert-icon">
                                <Icon name="alert-circle" />
                                <Trans i18nKey={`errorCode.${errorVal}`} />
                            </Alert>
                        </div>
                        <Form className="is-alter" onSubmit={e => e.preventDefault()}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="default-01">
                                        <Trans i18nKey={"loginPage.loginUsername"} />
                                    </label>
                                </div>
                                <div className="form-control-wrap">
                                    <input
                                        type="text"
                                        id="default-01"
                                        ref={(elem) => {
                                            formInputUsernameRef(elem);
                                            inputUsernameRef.current = elem;
                                        }}
                                        { ...registerInputUsername }
                                        placeholder={t("loginPage.loginUsernamePlaceholder").toString()}
                                        className="form-control-lg form-control"
                                        tabIndex={1}
                                    />
                                    {/* {errors && errors.loginUsername && <span className="invalid">{errors.loginUsername.message}</span>} */}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <label className="form-label" htmlFor="password">
                                        <Trans i18nKey={"loginPage.loginPassword"} />
                                    </label>
                                    {/* <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Password?
                  </Link> */}
                                </div>
                                <div className="form-control-wrap">
                                    <a
                                        href="#password"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setPassState(!passState);
                                        }}
                                        className={`form-icon lg form-icon-right passcode-switch ${
                                            passState ? "is-hidden" : "is-shown"
                                        }`}
                                    >
                                        {passState && <Icon name="eye" className="passcode-icon icon-show"></Icon>}

                                        {!passState && <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>}
                                    </a>
                                    <input
                                        type={passState ? "text" : "password"}
                                        id="password"
                                        ref={(elem) => {
                                            formInputPasswordRef(elem);
                                            inputPasswordRef.current = elem;
                                        }}
                                        { ...registerInputPassword }
                                        placeholder={t("loginPage.loginPasswordPlaceholder").toString()}
                                        className={`form-control-lg form-control ${
                                            passState ? "is-hidden" : "is-shown"
                                        }`}
                                        tabIndex={2}
                                    />
                                    {/* {errors && errors.loginPassword && <span className="invalid">{errors.loginPassword.message}</span>} */}
                                </div>
                            </div>
                            <div className="form-group">
                                <Button size="lg" className="btn-block" buttonType="button" color="primary" onClick={realHandleSubmit} tabIndex={3}>
                                    {loading ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        <Trans i18nKey={"loginPage.login"} />
                                    )}
                                </Button>
                            </div>
                            <div className="nk-block-content text-center text-lg-start">
                                <center>
                                    <p className="text-soft">
                                        <Trans i18nKey={"loginPage.footerCopy"} shouldUnescape />
                                    </p>
                                </center>
                            </div>
                        </Form>
                    </PreviewCard>
                </Block>



                <Modal isOpen={successModal} className="modal-md" keyboard={false} backdrop="static">
                    <ModalHeader>{t("loginPage.successModalTitle")}</ModalHeader>
                    <ModalBody>
                        <Row className="gy-3 py-1">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                                <h4>{t("loginPage.successModalText1")}</h4>
                            </Col>
                        </Row>
                        <Row className="gy-3 py-3">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                                <h6 className="overline-title">{t("loginPage.successModalText2")}</h6>
                                <p>
                                    {DateTime.fromJSDate(sessionData.lastLogin).isValid
                                        ? formatDate(sessionData.lastLogin)
                                        : t("loginPage.invalidLastLogin")}
                                </p>
                            </Col>
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                                <h6 className="overline-title">{t("loginPage.successModalText3")}</h6>
                                <p>{sessionData.lastFailedLogins}</p>
                            </Col>
                            {!!sessionData.lastFailedLogins && (
                                <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                                    <p>
                                        <i className="fa fa-icon fa-danger" />
                                        <Trans
                                            i18nKey="loginPage.successSecurityWarning"
                                            shouldUnescape
                                            values={{count: sessionData.lastFailedLogins}}
                                        />
                                    </p>
                                </Col>
                            )}
                        </Row>
                        <Row className="gy-3 py-3">
                            <Col size="12">
                                <ul className="align-center justify-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button
                                            color={!sessionData.lastFailedLogins ? "primary" : "danger"}
                                            size="lg"
                                            onClick={() => toggleSuccessModal()}
                                        >
                                            {t("loginPage.successModalConfirm")}
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>



                <Modal isOpen={missingOtpModal !== null} className="modal-md" keyboard={false} backdrop="static">
                    <ModalHeader>{t("loginPage.missingOtpModal.title")}</ModalHeader>
                    <ModalBody>
                        <Row className="gy-3 py-1">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                                <p style={{textAlign:"center"}}>{t("loginPage.missingOtpModal.enterOtpToken")}</p>
                            </Col>
                        </Row>
                        <Row className="gy-3 py-3">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                                <input type="text" ref={missingOtpModalRef} />
                            </Col>
                        </Row>
                        <Row className="gy-3 py-3">
                            <Col size="12">
                                <ul className="align-center justify-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button
                                            color="primary"
                                            size="lg"
                                            onClick={handleMissingOtpContinue}
                                            disabled={handleMissingOtpContinueLoading}
                                        >
                                            {t("loginPage.missingOtpModal.continue")}
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            color="danger"
                                            size="lg"
                                            onClick={() => void setMissingOtpModal(null)}
                                            disabled={handleMissingOtpContinueLoading}
                                        >
                                            {t("loginPage.missingOtpModal.cancel")}
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>



                <Modal isOpen={firstTimeOtpModal !== null} className="modal-md" keyboard={false} backdrop="static">
                    <ModalHeader>{t("loginPage.firstTimeOtpModal.title")}</ModalHeader>
                    <ModalBody>
                        <Row className="gy-3 py-1">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                                {
                                    otpQrCode === null
                                    ? <></>
                                    : <>
                                        <p style={{textAlign:"center"}}><strong>{t(`loginPage.firstTimeOtpModal.scanQrCode`)}</strong></p>
                                        <img src={otpQrCode} alt={firstTimeOtpModal?.otpKey ?? ``} />
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className="gy-3 py-1">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                                <p style={{textAlign:"center"}}><strong>{t(`loginPage.firstTimeOtpModal.registerKey`)} {firstTimeOtpModal?.otpKey ?? ``}</strong></p>
                            </Col>
                        </Row>
                        <Row className="gy-3 py-1">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                                <p style={{textAlign:"center"}}>{t("loginPage.firstTimeOtpModal.enterOtpToken")}</p>
                            </Col>
                        </Row>
                        <Row className="gy-3 py-3">
                            <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                                <input type="text" ref={firstTimeOtpModalRef} />
                            </Col>
                        </Row>
                        <Row className="gy-3 py-3">
                            <Col size="12">
                                <ul className="align-center justify-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button
                                            color="primary"
                                            size="lg"
                                            onClick={handleFirstTimeOtpContinue}
                                            disabled={handleFirstTimeOtpContinueLoading}
                                        >
                                            {t("loginPage.firstTimeOtpModal.continue")}
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            color="danger"
                                            size="lg"
                                            onClick={() => void setFirstTimeOtpModal(null)}
                                            disabled={handleFirstTimeOtpContinueLoading}
                                        >
                                            {t("loginPage.firstTimeOtpModal.cancel")}
                                        </Button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>



                {/* <AuthFooter /> */}
            </PageContainer>
        </>
    );
};
export default Login;
