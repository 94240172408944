import { useState, useMemo, useCallback } from "react";
import { Trans } from "react-i18next";
import { Button } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import l from "../../utils/Log";
import { ServersService } from "../../services";
import { ApiTable, ApiTableActionGroupElem, ApiTableApiService, ApiTableColMapper, ApiTableSearchMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import { formatDate } from "../../utils/Utils";
import classNames from "classnames";

const SettingsPage = () => <ServersTable />;

export default SettingsPage;


const getServers = ServersService.getServers.bind(ServersService);

type ServersItem = ItemOfApi<typeof getServers>;

type ServersOrderedCol = OrderedColOfApi<typeof getServers>;

type ServersFilterCond = FilterCondOfApi<typeof getServers>;

function ServersTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<ServersItem, ServersOrderedCol> = useMemo(() => {
        return [
            {
                colNameTranslationKey: `serversManagement.attributes.id`,
                hideInTable: true,
                render: ({ item }) => <span>{item.id}</span>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.name`,
                canOrder: `name`,
                render: ({ item }) => <span className="tb-lead">{item.name}</span>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.online`,
                canOrder:"online",
                render: ({ item }) => <ul className="list-status">
                                        <li>
                                            <Icon
                                                className={classNames({
                                                    "text-success": item.online,
                                                    "text-danger": !item.online,
                                                    "unselectable-text": true,
                                                })}
                                                name={`${item.online ? "check-circle" : "cross-circle"}`}
                                            ></Icon>{" "}
                                            <span>
                                                <Trans
                                                    i18nKey={`serversManagement.${item.online ? "online" : "offline"
                                                        }`}
                                                />
                                            </span>
                                        </li>
                                    </ul>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.maintenance`,
                canOrder:"maintenance",
                render: ({ item }) => <ul className="list-status">
                                        <li>
                                            <Icon
                                                className={classNames({
                                                    "text-success": !item.maintenance,
                                                    "text-orange": item.maintenance,
                                                    "unselectable-text": true,
                                                })}
                                                name={`${!item.maintenance ? "check-circle" : "alert-c"}`}
                                            ></Icon>{" "}
                                            <span>
                                                <Trans
                                                    i18nKey={`serversManagement.${item.maintenance ? "inMaintenance" : "notInMaintenance"
                                                        }`}
                                                />
                                            </span>
                                        </li>
                                    </ul>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.agentHostname`,
                hideInTable: true,
                render: ({ item }) => <span>{item.agentHostname}</span>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.agentVersion`,
                hideInTable: true,
                render: ({ item }) => <span>{item.agentVersion}</span>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.wireguardEndpoint`,
                hideInTable: true,
                render: ({ item }) => <span>{item.wireguardEndpoint}</span>,
            },
            {
                colNameTranslationKey: `serversManagement.attributes.wireguardPubKey`,
                hideInTable: true,
                render: ({ item }) => <span>{item.wireguardPubKey}</span>,
            },
        ];
    }, []);

    const searchMapper: ApiTableSearchMapper<ServersFilterCond> = useCallback((search, currentFilters) => {
        if (search.length === 0) {
            return currentFilters;
        }

        return [
            ...currentFilters,
            [
                {
                    field: `name`,
                    op: `inci`,
                    value: search,
                },
            ]
        ];
    }, []);

    const service: ApiTableApiService<ServersItem, ServersOrderedCol, ServersFilterCond> = {
        get: (params) => ServersService.getServers(params),
    };

    return <Content>
        <BlockHead size="sm">
            <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                        <Trans i18nKey="serversManagement.title" />
                    </BlockTitle>
                    <BlockDes className="text-soft">
                        <Trans i18nKey="serversManagement.description" />
                    </BlockDes>
                </BlockHeadContent>
                {/* <BlockHeadContent>
                    <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => {
                            setIsNewComputerModalOpen(true);
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </BlockHeadContent> */}
            </BlockBetween>
        </BlockHead>

        <Block>
            <ApiTable<ServersItem, ServersOrderedCol, ServersFilterCond, ServersTableFiltersState>
                service={service}
                colMapper={colMapper}
                defaultOrderByField="creationTime"
                defaultOrderByOrder="desc"
                searchMapper={searchMapper}
                cache={1}
                detailsModalTitleTransKey="serversManagement.detailsModal.title"
            />
        </Block>
    </Content>
}

type ServersTableFiltersState = {
    name: string,
};