import React, { useContext } from 'react';

import { RSelect } from '../../../components/Component';
import { UserContext } from '../UserContext';
import { UserData } from '../../../utils/GeneralTypes';
import { GroupData } from '../../../contexts/get-groups/GroupsContextTypes';
import { GroupsContext } from '../../../contexts/get-groups/GroupsContext';
import { ComputerData } from '../../../contexts/get-computers/ComputersContextTypes';
import { ComputersContext } from '../../../contexts/get-computers/ComputersContext';
import { DefaultSpinner } from '../../../components/default-spinner';
import { useCronState } from '../../../utils/CronJob';
import { ComputersService, GroupsService, UsersService } from '../../../services';
import { ItemOfApi } from '../../../components/ApiTable';
import { AuditLogBody } from "@trantor/vdesk-api-schemas/dist/audit";
import { keys } from 'lodash';
import { useTranslation } from 'react-i18next';

export type RSelectItem = {
    value: string;
    label: string;
}

export type MultiSelectDropdownProps<I> = {
    data: I[];
    selectedData?: string[];
    isDisabled?: boolean;
    render: (item: I) => RSelectItem;
    filter?: (item: I) => boolean;
    onChange?: (selected: I[]) => void;
}

export const MultiSelectDropdown = <I extends {id: string}>({ data, selectedData, isDisabled, render, filter, onChange }: MultiSelectDropdownProps<I>) => {
    const filteredData = filter ? data.filter(filter) : data;
    const options = filteredData.map(render);
    const selectedOptions = options.filter((option) => selectedData?.includes(option.value));

    const onSelectionChange = (selected: RSelectItem[]) => {
        const selectedIds = selected.map((item) => item.value);
        
        if (onChange) {
            onChange(data.filter((item) => selectedIds.includes(item.id)));
        }
    }

    return (
        <RSelect
            options={options}
            defaultValue={selectedOptions}
            isMulti
            isDisabled={isDisabled}
            onChange={onSelectionChange}
        />        
    )
}

export type SelectorProps<I> = Pick<MultiSelectDropdownProps<I>, "selectedData" | "onChange" | "isDisabled" | "filter">;


const getUsers = UsersService.getUsers.bind(UsersService);
type UsersItem = ItemOfApi<typeof getUsers>;
export const UsersSelector = (props: SelectorProps<UsersItem>) => {
    const [ users ] = useCronState(1000, null, async () => {
        const data = await getUsers({
            page: 1,
            itemsPerPage: 500,
            orderBy: {
                field: "creationTime",
                order: "desc",
            },
            filters: [{
                field: "enabled",
                op: "eq",
                value: true,
            }],
        })
        return data.items;
    })

    if (users === null) {
        return <DefaultSpinner />
    } else {
        return <MultiSelectDropdown
            {...props}
            data={users}
            render={(user) => ({
                value: user.id,
                label: user.username,
            })}
        />
    }
}

export const KindsSelector = (props: SelectorProps<{id: string}>) => {
    const { t } = useTranslation();
    const KINDS = [
        {
            id: "info:auth.login",
        },
        {
            id: "info:auth.logout",
        },
        {
            id: "info:auth.changePassword",
        },
        {
            id: "warn:auth.login.failed",
        },
        {
            id: "info:settings.changed",
        },
        {
            id: "info:users.create",
        },
        {
            id: "info:users.edit",
        },
        {
            id: "info:users.delete",
        },
        {
            id: "info:accessRules.create",
        },
        {
            id: "info:accessRules.edit",
        },
        {
            id: "info:accessRules.delete",
        },
        {
            id: "info:chats.create",
        },
        {
            id: "info:chats.edit",
        },
        {
            id: "info:chats.delete",
        },
        {
            id: "info:chats.leave",
        },
        {
            id: "info:chats.expel",
        },
        {
            id: "info:calls.create",
        },
        {
            id: "info:computers.create",
        },
        {
            id: "info:computers.edit",
        },
        {
            id: "info:computers.delete",
        },
        {
            id: "info:computers.connect",
        },
    ];
    return <MultiSelectDropdown
        {...props}
        data={KINDS}
        render={(k) => ({
            value: k.id,
            label: t("logsManagement.kinds." + k.id),
        })}
    />
}



const getGroups = GroupsService.getGroups.bind(GroupsService);
type GroupsItem = ItemOfApi<typeof getGroups>;
export const GroupsSelector = (props: SelectorProps<GroupsItem>) => {
    const [ groups ] = useCronState(1000, null, async () => {
        const data = await getGroups({
            page: 1,
            itemsPerPage: 500,
            orderBy: {
                field: "creationTime",
                order: "desc",
            },
        })
        return data.items;
    })

    if (groups === null) {
        return <DefaultSpinner />
    } else {
        return <MultiSelectDropdown
            {...props}
            data={groups}
            render={(user) => ({
                value: user.id,
                label: user.name,
            })}
        />
    }
}



const getComputers = ComputersService.getComputers.bind(ComputersService);
type ComputersItem = ItemOfApi<typeof getComputers>;
export const ComputersSelector = (props: SelectorProps<ComputersItem>) => {
    const [ computers ] = useCronState(1000, null, async () => {
        const data = await getComputers({
            page: 1,
            itemsPerPage: 500,
            orderBy: {
                field: "creationTime",
                order: "desc",
            },
        })
        return data.items;
    })

    if (computers === null) {
        return <DefaultSpinner />
    } else {
        return <MultiSelectDropdown
            {...props}
            data={computers}
            render={(computer) => ({
                value: computer.id,
                label: computer.name,
            })}
        />
    }
}