
import { z } from "zod";



export const SupportedLanguageSchema = z.enum([`en`, `it`]);
export type SupportedLanguage = z.infer<typeof SupportedLanguageSchema>;
export const supportedLanguages = Object.keys(SupportedLanguageSchema.Values);



export function getCurrentLang(): SupportedLanguage {
    const localValue = window.localStorage.getItem(`trantor.vdesk.locale`);
    const localValueParsed = SupportedLanguageSchema.safeParse(localValue);

    if (localValueParsed.success) {
        return localValueParsed.data;
    }

    const navigatorLanguage = window.navigator.language.toLowerCase();

    for (const lang of supportedLanguages) {
        if (navigatorLanguage === lang || navigatorLanguage.startsWith(`${lang}-`)) {
            return lang as SupportedLanguage;
        }
    }

    return `en`;
}
