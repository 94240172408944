import classNames from "classnames";
import React, { ReactNode } from "react";
import { Card } from "reactstrap";
interface DataTableProps {
    className?: string;
    bodyClassName?: string;
    scrollHorizontally?: true;
    title?: string;
    [key: string]: any;
}
export const DataTable: React.FC<DataTableProps> = ({ className, bodyClassName, title, scrollHorizontally, ...props }) => {
    return (
        <Card className={`card-bordered ${className ? className : ""}${scrollHorizontally ? ` table-body-horizontal-scroll` : ""}`}>
            <div className="card-inner-group">{props.children}</div>
        </Card>
    );
};
interface DataTableTitleProps {
    [key: string]: any;
}
export const DataTableTitle: React.FC<DataTableTitleProps> = ({ ...props }) => {
    return (
        <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group">{props.children}</div>
        </div>
    );
};
interface DataTableBodyProps {
    compact?: boolean;
    className?: string;
    bodyclass?: string;
    scrollHorizontally?: true;
    style?: React.CSSProperties;
    [key: string]: any;
}
export const DataTableBody: React.FC<DataTableBodyProps> = ({ compact, className, bodyclass, scrollHorizontally,...props }) => {
    return (
        <div className={`card-inner p-0${className ? ` ${className}` : ""}${scrollHorizontally ? ` table-body-horizontal-scroll` : ""}`} style={props.style}>
            <div className={`nk-tb-list nk-tb-ulist${bodyclass ? ` ${bodyclass}` : ""}${compact ? " is-compact" : ""}`}>
                {props.children}
            </div>
        </div>
    );
};
interface DataTableHeadProps {
    [key: string]: any;
}
export const DataTableHead: React.FC<DataTableHeadProps> = ({ ...props }) => {
    return <div className="nk-tb-item nk-tb-head">{props.children}</div>;
};
interface DataTableRowProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    size?: string;
    children: ReactNode;
}
export const DataTableRow: React.FC<DataTableRowProps> = ({ className, size, children, ...props }) => {
    const rowClass = classNames({
        "nk-tb-col": true,
        [`${className}`]: className,
        [`tb-col-${size}`]: size,
    });
    return (
        <div className={rowClass} {...props}>
            {children}
        </div>
    );
};
interface DataTableItemProps extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}
export const DataTableItem: React.FC<DataTableItemProps> = ({ className, children, ...props }) => {
    return (
        <div className={`nk-tb-item ${className ? className : ""}`} {...props}>
            {children}
        </div>
    );
};
