import { z } from "zod";
export const EnumOpSchema = z.enum([`eq`, `neq`]);
export const NumberOpSchema = z.enum([`eq`, `neq`, `gt`, `lte`, `lt`, `gte`]);
export const StringOpSchema = z.enum([
    `eq`, `neq`,
    `eqi`, `neqi`,
    `inc`, `ninc`,
    `inci`, `ninci`,
    `sw`, `nsw`,
    `swi`, `nswi`,
    `ew`, `new`,
    `ewi`, `newi`,
]);
export const JsonStringOpSchema = z.enum([
    `eq`,
    `inc`,
    `sw`,
    `ew`,
]);
