import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { ChatItem, ChatItemParticipant } from "@trantor/vdesk-api-schemas/src/chats/getChats";
import { ChatArchiveItem, ChatArchiveItemParticipant } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";
import { NotificationItem } from "@trantor/vdesk-api-schemas/src/notifications/getNotifications";
import { ChatMessageItem } from "@trantor/vdesk-api-schemas/src/chats/messages/getChatMessages";
import { ChatArchiveMessageItem } from "@trantor/vdesk-api-schemas/src/chats/archives/getChatArchiveMessages";
import l, { isDevelopment } from "../../../utils/Log";
import P from "../../../utils/Path";
import { findUpper, formatDate, formatSize, themeFromUsername } from "../../../utils/Utils";
import { UserContext } from "../../settings/UserContext";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Icon, UserAvatar, Button } from "../../../components/Component";
import {
    ModalControls,
    UserData,
} from "../../../utils/GeneralTypes";
import FaIcon from "../../../components/icon/FaIcon";
import ChatItemContextMenu from "../../../components/contextMenus/ChatItemContextMenu";
import {GroupBadge} from "../../../components/chat/Badge";
import { ChatItemParticipantSchema } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { GetMyCallInvitesOutput } from "@trantor/vdesk-api-schemas/dist/calls/getMyCallInvites";
import { asyncVoid, useHandler } from "../../../utils/handler";
import { createApiClient } from "../../../utils/createApiClient";
import { assert, unwrap } from "../../../services/MainService";
import { randomBytesHex, u8arrToBase64 } from "../../../utils/crypto";
import { scope } from "../../../utils/scope";

const handleCopyToClipboard = (
    /* status:"ready" | "done" | "error", */
    setStatus: React.Dispatch<React.SetStateAction<"ready" | "done" | "error">>,
    txt: string
) => {
    navigator.clipboard
        .writeText(txt)
        .then(() => {
            setStatus("done");
        })
        .catch((reason) => {
            setStatus("error");
            l.error(`Copy to clipboard failed`, reason);
        })
        .finally(() => {
            setTimeout(() => {
                setStatus("ready");
            }, 2500);
        });
};
interface MeChatProps {
    onRemoveMessage: ((id: string) => void) | null;
    // chat: IChatData;
    loggedUser: UserData;
    msg: ChatMessageItem | ChatArchiveMessageItem;
    hideDeleteButton: boolean;
}
export const MeChat: React.FC<MeChatProps> = ({ msg, loggedUser, hideDeleteButton = false, onRemoveMessage }) => {
    const { t } = useTranslation();
    const [copyToClipboardStatus, setCopyToClipboardStatus] = useState<"ready" | "done" | "error">("ready");
    let isRead = false;
    if ("read" in msg) {
        isRead = msg.read;
    }
    return (
        <div className="chat is-me" id={`message-id-${msg.id}`}>
            <div className="chat-content">
                <div className="chat-bubbles">
                    <div className={`chat-bubble`}>
                        {/* {msg === "deleted" ? (
                            <div className="chat-msg border bg-white text-muted">
                                <Trans i18nKey="chat.partials.deleted" />
                            </div>
                        ) : ( */}
                        <>
                            {/* <div className={`chat-msg bg-${chat.chatTheme}`}>{msg}</div> */}
                            {/* <UncontrolledDropdown direction="start"> */}
                            <div className={"chat-msg"}>
                                <ChatMessageContent content={msg.content} />
                            </div>
                            {/* <DropdownMenu> */}
                            <ul className="chat-msg-more">
                                {!hideDeleteButton && (
                                    <li>
                                        <a
                                            href="#delete"
                                            className="btn btn-icon btn-sm btn-trigger"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                onRemoveMessage && onRemoveMessage(msg.id);
                                            }}
                                        >
                                            <Icon name="trash" />
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <a
                                        href="#copy"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            copyToClipboardStatus === "ready" &&
                                                handleCopyToClipboard(setCopyToClipboardStatus, renderMessageContentAsString(msg.content));
                                        }}
                                        className={`btn btn-icon btn-sm btn-trigger${copyToClipboardStatus !== "ready" ? " unclickable-text" : ""
                                            }`}
                                    >
                                        {copyToClipboardStatus === "ready" && <Icon name="copy" />}
                                        {copyToClipboardStatus === "done" && <Icon name="done" />}
                                        {copyToClipboardStatus === "error" && <Icon name="cross" />}
                                    </a>
                                </li>
                            </ul>
                            {/* </DropdownMenu> */}
                            {/* </UncontrolledDropdown> */}
                        </>
                        {/* )} */}
                    </div>
                </div>
                <ul className="chat-meta">
                    <li>{loggedUser.displayName}</li>
                    <li>{formatDate(msg.creationTime)}</li>
                    {isRead && <li>
                        <FaIcon icon="check" title={t("chat.partials.isRead").toString()}/>
                    </li>}
                </ul>
            </div>
        </div>
    );
};

interface YouChatProps {
    // chat: IChatData;
    // item: IChatPartial;
    msg: ChatMessageItem | ChatArchiveMessageItem;
    sender: { id: string; displayName: string; username: string; avatarBg: string } | null;
}
export const YouChat: React.FC<YouChatProps> = ({ msg, sender }) => {
    const { t } = useTranslation();
    const [copyToClipboardStatus, setCopyToClipboardStatus] = useState<"ready" | "done" | "error">("ready");
    let isRead = false;
    if ("read" in msg) {
        isRead = msg.read;
    }

    return (
        <div className="chat is-you" id={`message-id-${msg.id}`}>
            <div className="chat-avatar">
                {/* {chat.group ? (
                    <UserAvatar image={item.user?.image} theme={item.user?.theme} text={findUpper(item.user?.name)}>
                        {" "}
                        {chat.active === true ? (
                            <span className="status dot dot-lg dot-success"></span>
                        ) : (
                            <span className="status dot dot-lg dot-gray"></span>
                        )}
                    </UserAvatar>
                ) : ( */}
                <UserAvatar
                    image={undefined}
                    theme={sender?.avatarBg ?? "pink"}
                    text={sender === null ? "?" : findUpper(sender.displayName)}
                >
                    {" "}
                    {/* {chat.active === true ? ( */}
                    <span className="status dot dot-lg dot-success"></span>
                    {/* ) : ( */}
                    {/* <span className="status dot dot-lg dot-gray"></span> */}
                    {/* )} */}
                </UserAvatar>
                {/* )} */}
            </div>
            <div className="chat-content">
                <div className="chat-bubbles">
                    <div className="chat-bubble">
                        <div className="chat-msg">
                            <ChatMessageContent content={msg.content} />
                        </div>
                        <ul className="chat-msg-more">
                            <li>
                                <a
                                    href="#copy"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        copyToClipboardStatus === "ready" &&
                                            handleCopyToClipboard(setCopyToClipboardStatus, renderMessageContentAsString(msg.content));
                                    }}
                                    className={`btn btn-icon btn-sm btn-trigger${copyToClipboardStatus !== "ready" ? " unclickable-text" : ""
                                        }`}
                                >
                                    {copyToClipboardStatus === "ready" && <Icon name="copy" />}
                                    {copyToClipboardStatus === "done" && <Icon name="done" />}
                                    {copyToClipboardStatus === "error" && <Icon name="cross" />}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul className="chat-meta">
                    {isRead && <li>
                        <FaIcon icon="check" title={t("chat.partials.isRead").toString()}/>
                    </li>}
                    <li>{sender?.displayName ?? "?"}</li>
                    <li>{formatDate(msg.creationTime)}</li>
                </ul>
            </div>
        </div>
    );
};

interface MetaChatProps {
    item: string;
}
export const MetaChat: React.FC<MetaChatProps> = ({ item }) => {
    return (
        <div className="chat-sap">
            <div className="chat-sap-meta">
                <span>{item}</span>
            </div>
        </div>
    );
};
const ChatItemWrapper: React.FC<{
    item: NotificationItem;
    onItemClick?: ()=>void;
    onItemClose: ()=>void;
    children: React.ReactNode;
}> = ({ item, onItemClick, onItemClose, children }) => {
    function deriveNavigationUrl(item: NotificationItem): string {
        switch (item.body.type) {
            case "newChat":
            case "newMessage":
            case "youAreModerator":
            case "renamedGroupChat": {
                return `${P.getPath("chat")}/${item.body.details.chatId}`;
            }
            case "expelledFromGroupChat":
            case "chatDeleted":
                return `${P.getPath("archive")}/${item.body.details.chatArchiveId}`;
            case "invalidLicenseFound":
            case "noLicenseFound":
                return "N/A";
        }
    }
    function deriveNameOrUsername(item: NotificationItem): string {
        switch (item.body.type) {
            case "newChat":
            case "newMessage":
            case "expelledFromGroupChat":
            case "youAreModerator":
            case "chatDeleted": {
                const details = item.body.details;
                if (details.chatName) {
                    return details.chatName;
                } else if (details.fromUser) {
                    return details.fromUser.username;
                } else {
                    return `ERR: ${item.body.type}`;
                }
            }
            case "renamedGroupChat": {
                const details = item.body.details;
                if (details.newChatName) {
                    return details.newChatName;
                } else if (details.fromUser) {
                    return details.fromUser.username;
                } else {
                    return `ERR: ${item.body.type}`;
                }
            }
            case "invalidLicenseFound":
            case "noLicenseFound":
                return "N/A";
        }
    }
    function deriveName(item: NotificationItem): string {
        switch (item.body.type) {
            case "newChat":
            case "newMessage":
            case "expelledFromGroupChat":
            case "youAreModerator":
            case "chatDeleted": {
                const details = item.body.details;
                if (details.chatName) {
                    return details.chatName;
                } else if (details.fromUser) {
                    return details.fromUser.displayName;
                } else {
                    return `ERR: ${item.body.type}`;
                }
            }
            case "renamedGroupChat": {
                const details = item.body.details;
                if (details.newChatName) {
                    return details.newChatName;
                } else if (details.fromUser) {
                    return details.fromUser.displayName;
                } else {
                    return `ERR: ${item.body.type}`;
                }
            }
            case "invalidLicenseFound":
            case "noLicenseFound":
                return "N/A";
        }
    }

    const navigate = useNavigate();
    return (
        <div className="chat-link">
            <a
                href="#markNotificationAsRead"
                onClick={(e) => {
                    e.preventDefault();
                    onItemClose();
                }}
            >
                <FaIcon icon="eye-slash" size="sm" />
            </a>
            <a
                className="chat-link"
                href={deriveNavigationUrl(item)}
                onClick={(e) => {
                    e.preventDefault();
                    try {
                        navigate(deriveNavigationUrl(item));
                        onItemClick && onItemClick();
                        onItemClose();
                    } catch (error) {
                        if (isDevelopment()) l.error(`ChatPartials/ChatItemWrapper onClick error: ${JSON.stringify(error)}`);
                    }
                }}
            >
                <UserAvatar
                    theme={themeFromUsername(deriveNameOrUsername(item))}
                    text={findUpper(deriveName(item))}
                    // image={item.image}
                    className="chat-media"
                >
                    {/* <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span> */}
                </UserAvatar>
                <div className="chat-info">
                    <div className="chat-from">
                        <div className="name">{deriveName(item)}</div>
                        <span className="time">{formatDate(item.creationTime)}</span>
                    </div>
                    <div className="chat-context">
                        <div className="text">{children}</div>
                        {/* <div className="status delivered">
                            <Icon
                            name={`${
                                item.delivered === true
                                ? "check-circle-fill"
                                : item.delivered === "sent"
                                ? "check-circle"
                                : ""
                            }`}
                            ></Icon>
                        </div> */}
                    </div>
                </div>
            </a>
        </div>
    );
};
interface ChatItemHeaderProps {
    item: NotificationItem;
    onItemClick?: ()=>void;
    onItemClose: ()=>void;
}
export const ChatItemHeader: React.FC<ChatItemHeaderProps> = ({ item, onItemClick, onItemClose }) => {
    switch (item.body.type) {
        case "newChat":
        case "chatDeleted":
        case "youAreModerator":
            return (
                <ChatItemWrapper item={item} onItemClick={onItemClick} onItemClose={onItemClose}>
                    <Trans i18nKey={`chat.partials.headerItem.${item.body.type}.context`} shouldUnescape />
                </ChatItemWrapper>
            );
        case "newMessage":
            return (
                <ChatItemWrapper item={item} onItemClick={onItemClick} onItemClose={onItemClose}>
                    <Trans
                        i18nKey={`chat.partials.headerItem.${item.body.type}.context`}
                        shouldUnescape
                        values={{count: item.body.details.numberOfUnreadMessages}}
                    />
                </ChatItemWrapper>
            );
        case "expelledFromGroupChat":
            return (
                <ChatItemWrapper item={item} onItemClick={onItemClick} onItemClose={onItemClose}>
                    {item.body.details.fromUser.displayName}
                    <Trans i18nKey={`chat.partials.headerItem.${item.body.type}.context`} shouldUnescape />
                </ChatItemWrapper>
            );
        case "renamedGroupChat":
            return (
                <ChatItemWrapper item={item} onItemClick={onItemClick} onItemClose={onItemClose}>
                    {item.body.details.fromUser.displayName}
                    <Trans i18nKey={`chat.partials.headerItem.${item.body.type}.context`} shouldUnescape />
                    {`"`}
                    {item.body.details.oldChatName}
                    {`"`}
                </ChatItemWrapper>
            );
    }
        return (
            <ChatItemWrapper item={item} onItemClick={onItemClick} onItemClose={onItemClose}>
                <Trans i18nKey={`chat.partials.headerItem.${item.body.type}.context`} shouldUnescape />
            </ChatItemWrapper>
        );
};
interface ChatItemProps {
    item: ChatItem | ChatArchiveItem;
    isArchived?: boolean;
    chatItemClick: (item: ChatItem | ChatArchiveItem) => void;
    chatItemMenuClick: (item: ChatItem | ChatArchiveItem) => void;
    chatItemContextMenuClick: (item: ChatItem | ChatArchiveItem) => void;
    openItem: ChatItem | ChatArchiveItem | null;
    selectedItem: ChatItem | ChatArchiveItem | null;
    modalControls: ModalControls;
}
/**
 * Ui component to display a chat in the ChatAsideBody list in Chat, on the left
 * @param {ChatItem} item  Item to be displayed
 * @param {boolean} isArchived  Item to be displayed
 * @param {function} chatItemClick
 * @param {function} chatItemMenuClick
 * @param {ChatItem} openItem  ChatItem to be displayed
 * @param {ModalControls} modalControls `ModalControls` to populate contextual menu. A `function` will display the menu voice, `null` will display it disabled, not passing it will not display the menu voice
 * @returns
 */
export const ChatListItem: React.FC<ChatItemProps> = ({
    item,
    isArchived,
    chatItemClick,
    chatItemMenuClick,
    chatItemContextMenuClick,
    openItem,
    selectedItem,
    modalControls,
}) => {
    const { t } = useTranslation();
    const loggedInUser = useContext(UserContext).loggedInUser[0];
    const getTitle = () => {
        const name = item.name ?? null;
        const participants: ChatItemParticipant[] | ChatArchiveItemParticipant[] = item.participants ?? [];
        const foundUsers = participants
            .map((p) => p.user.displayName)
            .filter((u) => u !== loggedInUser.displayName)
            .sort((a, b) => (a > b ? 1 : -1));
        return name ?? (foundUsers.length > 0 ? [...foundUsers].join(", ") : t("nobody"));
    };
    const generateThemeForChatRoom = () => {
        const name = item.name ?? null;
        const participants: ChatItemParticipant[] | ChatArchiveItemParticipant[] = item.participants ?? [];
        const foundUsers = participants
            .map((p) => p.user.username)
            .filter((u) => u !== loggedInUser.username)
            .sort((a, b) => (a > b ? 1 : -1));
        const username = name ?? (foundUsers.length > 0 ? [...foundUsers].join(", ") : t("nobody"));
        return themeFromUsername(username);
    };
    // const title =
    //     item.name ??
    //     (() => {
    //         const foundUsers = item.participants
    //             .map((p) => users.find((u) => u.id === p.userId)?.displayName ?? t("unknown"))
    //             .filter((u) => u !== loggedInUser.displayName)
    //             .sort((a, b) => (a > b ? 1 : -1));

    //         return foundUsers.length > 0 ? [...foundUsers].join(", ") : t("nobody");
    //     })();
    // const { deleteConvo, propAction } = useContext(ChatContext);

    // const checkBeforeDelete = (id: string) => {
    //     deleteConvo(id);
    //     if (selectedId === id) {
    //         setSelectedId(null);
    //     }
    // };
    const [contextMenuCoords, setContextMenuCoords] = useState<{ x: number; y: number; item: ChatItem | ChatArchiveItem } | null>(null);
    const closeAllContextMenus = () => {
        setContextMenuCoords(null);
    };
    const handleContextMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, item: ChatItem | ChatArchiveItem): void => {
        e.preventDefault();
        chatItemContextMenuClick(item);
        setContextMenuCoords({ x: e.clientX, y: e.clientY, item });
    };
    useEffect(() => {
        // Closes the context menu whenever you click or scroll
        window.addEventListener("click", closeAllContextMenus);
        window.addEventListener("drag", closeAllContextMenus);
        window.addEventListener("resize", closeAllContextMenus);
        window.addEventListener("scroll", closeAllContextMenus);
        return () => {
            window.removeEventListener("click", closeAllContextMenus);
            window.removeEventListener("drag", closeAllContextMenus);
            window.removeEventListener("resize", closeAllContextMenus);
            window.removeEventListener("scroll", closeAllContextMenus);
        };
    }, []);
    const isChatAGroup = (): boolean => item !== null && typeof item.name === "string" && item.name !== null;
    const getUnreadMessages = (): number => {
        const myId = loggedInUser.id;
        const found = item.participants.find((p) => p.user.id === myId);

        const parsed = ChatItemParticipantSchema.safeParse(found);
        if (parsed.success && parsed.data.numberOfUnreadMessages > 0) {
            return parsed.data.numberOfUnreadMessages;
        }
        return 0;
    }
    const renderUnreadMessages = (): React.ReactNode => {
        const unread = getUnreadMessages();
        if (unread > 0) {
            if (unread > 10) {
                return <>&nbsp;<span className="badge rounded-pill bg-light my-0">10+</span>&nbsp;</>//<>(10+)&nbsp;</>;
            } else {
                return <>&nbsp;<span className="badge rounded-pill bg-light my-0">{unread}</span>&nbsp;</>//<>({unread})&nbsp;</>;
            }
        }
        return <></>;
    }
    return (
        // <li className={`chat-item ${openItem && item.id === openItem.id ? "is-unread" : ""}`}>
        <li
            className={classNames({
                "chat-item":true,
                "is-open": openItem && item.id === openItem.id,
                "is-unread" : getUnreadMessages() > 0,
            })}
            onContextMenu={(ev) => handleContextMenu(ev, item)}
        >
            {/* {`contextMenuCoords.item.id:${contextMenuCoords?.item.id}\nselectedItem?.id:${selectedItem?.id}`} */}
            <a
                className="chat-link"
                href="#chat-link"
                onClick={(ev) => {
                    ev.preventDefault();
                    chatItemClick(item);
                }}
            >
                {contextMenuCoords && contextMenuCoords.item.id === selectedItem?.id && (
                    <ChatItemContextMenu
                        key={`context-menu-${item.id}`}
                        coords={contextMenuCoords}
                        modalControls={modalControls}
                        isArchived={isArchived === true}
                    />
                )}
                <UserAvatar
                    theme={generateThemeForChatRoom()}
                    text={findUpper(getTitle())}
                    image={undefined}
                    className="chat-media"
                >
                    {/* <span className={`status dot dot-lg dot-${active === true ? "success" : "gray"}`}></span> */}
                    {/* <span className={`status dot dot-lg dot-success`}></span> */}
                </UserAvatar>
                {/* {item.group === true ? (
                    <div className="chat-media user-avatar user-avatar-multiple">
                        {item.user?.slice(0, 2).map((user, idx) => {
                            return (
                                <UserAvatar
                                    key={idx}
                                    theme={user.theme}
                                    text={findUpper(user.name)}
                                    image={user.image}
                                    className="chat-media"
                                ></UserAvatar>
                            );
                        })}
                        <span className={"status dot dot-lg dot-success"}></span>
                    </div>
                ) : (
                    <UserAvatar
                        theme={item.theme}
                        text={findUpper(item.name)}
                        image={item.image}
                        className="chat-media"
                    >
                        <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span>
                    </UserAvatar>
                )} */}
                <div className="chat-info">
                    <div className="chat-from">
                        <div className="name">
                            {isChatAGroup() && <GroupBadge leaveSpace="after" />}
                            {renderUnreadMessages()}
                            {getTitle()}
                        </div>
                        {/* {isArchived === true && (
                            <div className="time">
                                <Trans i18nKey="chatPartials.chatItem.archivedWhen" />
                                <br />
                                {DateTime.fromMillis(item.creationTime).toFormat("yyyy-LL-dd")}
                                &nbsp;
                                {DateTime.fromMillis(item.creationTime).toFormat("HH:mm")}
                            </div>
                        )} */}
                        {/* <span className="time">{"[Time goes here]"}</span> */}
                    </div>
                    <div className="chat-context">
                        {/* <div className="text">
                            <p>
                                {item.convo.length !== 0 &&
                                    (item.convo[item.convo.length - 1] as IChatPartial).chat.at(-1)}
                            </p>
                        </div> */}
                        {/* <div className="status delivered">
                            <Icon
                                name={`${
                                    item.delivered === true
                                        ? "check-circle-fill"
                                        : item.delivered === "sent"
                                        ? "check-circle"
                                        : ""
                                }`}
                            ></Icon>
                        </div> */}
                    </div>
                    {isArchived === true && (
                        <div className="sub-text">
                            {/* <span>(</span> */}
                            <Trans i18nKey="chatPartials.chatItem.archivedWhen" />
                            {formatDate(item.creationTime)}
                            {/* <span>)</span> */}
                        </div>
                    )}
                </div>
            </a>
            <div className="chat-actions">
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="a"
                        className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
                        onClick={() => chatItemMenuClick(item)}
                    >
                        <Icon name="more-h"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                            {(modalControls.rename === null || typeof modalControls.rename === "function") && (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!modalControls.rename ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            modalControls.rename && modalControls.rename();
                                        }}
                                    >
                                        <FaIcon icon="keyboard" />
                                        <span>{t("chat.body.rename")}</span>
                                    </DropdownItem>
                                </li>
                            )}
                            {(modalControls.delete === null || typeof modalControls.delete === "function") && (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!modalControls.delete ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            modalControls.delete && modalControls.delete();
                                        }}
                                    >
                                        {isArchived ? <Icon name="trash"></Icon> : <FaIcon icon="box-archive" />}
                                        {isArchived ? <span>{t("chat.body.remove")}</span> : <span>{t("chat.body.archive")}</span>}
                                    </DropdownItem>
                                </li>
                            )}
                            {(modalControls.leave === null || typeof modalControls.leave === "function") && (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!modalControls.leave ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            modalControls.leave && modalControls.leave();
                                        }}
                                    >
                                        <Icon name="signout"></Icon>
                                        <span>{t("chat.body.leave")}</span>
                                    </DropdownItem>
                                </li>
                            )}
                            {/* <li onClick={() => checkBeforeDelete(item.id)}>
                                <DropdownItem
                                    tag="a"
                                    href="#delete"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <Trans i18nKey="chat.partials.delete" />
                                </DropdownItem>
                            </li>
                            <li onClick={() => propAction(item.id, "unread")}>
                                <DropdownItem
                                    tag="a"
                                    href="#unread"
                                    className={item.unread ? "disabled" : ""}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <Trans i18nKey="chat.partials.markUnread" />
                                </DropdownItem>
                            </li> */}
                            {/* <li onClick={() => propAction(item.id, "archive")}>
                <DropdownItem
                  tag="a"
                  href="#archive"
                  className={item.archive ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Trans i18nKey="chat.partials.archive" />
                </DropdownItem>
              </li> */}
                        </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </li>
    );
};

interface CallItemProps {
    item: GetMyCallInvitesOutput["calls"][number];
    callItemClick: (item: GetMyCallInvitesOutput["calls"][number]) => void;
}
/**
 * Ui component to display a chat in the ChatAsideBody list in Chat, on the left
 * @param {CallItem} item  Item to be displayed
 * @returns
 */
export const CallListItem: React.FC<CallItemProps> = ({
    item,
    callItemClick,
}) => {
    const { t } = useTranslation();
    const loggedInUser = useContext(UserContext).loggedInUser[0];
    const getTitle = () => {
        const hasLinkedChat = item.linkedChatRoom !== undefined;
        const myName = loggedInUser.displayName;
        if (hasLinkedChat) {
            if (item.linkedChatRoom?.type === "group") {
                return item.linkedChatRoom.name;
            } else if (item.linkedChatRoom?.type === "p2p") {
                if (!myName.toLocaleLowerCase().localeCompare(item.startedBy.displayName.toLocaleLowerCase())) {
                    return item.linkedChatRoom.otherUser.displayName;
                } else {
                    return item.startedBy.displayName;
                }
            }
        }
        const { displayName } = item.startedBy;
        return t("callListItem.anon") + displayName;
    };
    // const generateThemeForChatRoom = () => {
    //     const name = item.name ?? null;
    //     const participants: ChatItemParticipant[] | ChatArchiveItemParticipant[] = item.participants ?? [];
    //     const foundUsers = participants
    //         .map((p) => p.user.username)
    //         .filter((u) => u !== loggedInUser.username)
    //         .sort((a, b) => (a > b ? 1 : -1));
    //     const username = name ?? (foundUsers.length > 0 ? [...foundUsers].join(", ") : t("nobody"));
    //     return themeFromUsername(username);
    // };
    // const getUnreadMessages = (): number => {
    //     const myId = loggedInUser.id;
    //     const found = item.participants.find((p) => p.user.id === myId);

    //     const parsed = ChatItemParticipantSchema.safeParse(found);
    //     if (parsed.success && parsed.data.numberOfUnreadMessages > 0) {
    //         return parsed.data.numberOfUnreadMessages;
    //     }
    //     return 0;
    // }
    // const renderUnreadMessages = (): React.ReactNode => {
    //     const unread = getUnreadMessages();
    //     if (unread > 0) {
    //         if (unread > 10) {
    //             return <>&nbsp;<span className="badge rounded-pill bg-light my-0">10+</span>&nbsp;</>//<>(10+)&nbsp;</>;
    //         } else {
    //             return <>&nbsp;<span className="badge rounded-pill bg-light my-0">{unread}</span>&nbsp;</>//<>({unread})&nbsp;</>;
    //         }
    //     }
    //     return <></>;
    // }
    return (
        // <li className={`chat-item ${openItem && item.id === openItem.id ? "is-unread" : ""}`}>
        <li
            className={classNames({
                "chat-item":true,
                "is-open": false,
                "is-unread" : false,
            })}
        >
            {/* {`contextMenuCoords.item.id:${contextMenuCoords?.item.id}\nselectedItem?.id:${selectedItem?.id}`} */}
            <a
                className="chat-link"
                href="#chat-link"
                onClick={(ev) => {
                    ev.preventDefault();
                    callItemClick(item);
                }}
            >
                {/* <UserAvatar
                    theme={generateThemeForChatRoom()}
                    text={findUpper(getTitle())}
                    image={undefined}
                    className="chat-media"
                > */}
                    {/* <span className={`status dot dot-lg dot-${active === true ? "success" : "gray"}`}></span> */}
                    {/* <span className={`status dot dot-lg dot-success`}></span> */}
                {/* </UserAvatar> */}
                <div className="user-avatar chat-media">
                    <Icon name="call-alt" className="icon-circle bg-light"></Icon>
                </div>
                <div className="chat-info">
                    <div className="chat-from">
                        <div className="name">
                            {/* {isChatAGroup() && <GroupBadge leaveSpace="after" />}
                            {renderUnreadMessages()} */}
                            {getTitle()}
                        </div>
                        {/* {isArchived === true && (
                            <div className="time">
                                <Trans i18nKey="chatPartials.chatItem.archivedWhen" />
                                <br />
                                {DateTime.fromMillis(item.creationTime).toFormat("yyyy-LL-dd")}
                                &nbsp;
                                {DateTime.fromMillis(item.creationTime).toFormat("HH:mm")}
                            </div>
                        )} */}
                        {/* <span className="time">{"[Time goes here]"}</span> */}
                    </div>
                    <div className="chat-context">
                    </div>
                    {item.startedAt !== null && (
                        <div className="sub-text">
                            {/* <span>(</span> */}
                            <Trans i18nKey="callListItem.startedAt" />
                            {formatDate(item.startedAt)}
                            {/* <span>)</span> */}
                        </div>
                    )}
                </div>
            </a>
            {/* <div className="chat-actions">
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="a"
                        className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
                        onClick={() => chatItemMenuClick(item)}
                    >
                        <Icon name="more-h"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                            {(modalControls.rename === null || typeof modalControls.rename === "function") && (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!modalControls.rename ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            modalControls.rename && modalControls.rename();
                                        }}
                                    >
                                        <FaIcon icon="keyboard" />
                                        <span>{t("chat.body.rename")}</span>
                                    </DropdownItem>
                                </li>
                            )}
                            {(modalControls.delete === null || typeof modalControls.delete === "function") && (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!modalControls.delete ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            modalControls.delete && modalControls.delete();
                                        }}
                                    >
                                        {isArchived ? <Icon name="trash"></Icon> : <FaIcon icon="box-archive" />}
                                        {isArchived ? <span>{t("chat.body.remove")}</span> : <span>{t("chat.body.archive")}</span>}
                                    </DropdownItem>
                                </li>
                            )}
                            {(modalControls.leave === null || typeof modalControls.leave === "function") && (
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!modalControls.leave ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            modalControls.leave && modalControls.leave();
                                        }}
                                    >
                                        <Icon name="signout"></Icon>
                                        <span>{t("chat.body.leave")}</span>
                                    </DropdownItem>
                                </li>
                            )}
                            {/* <li onClick={() => checkBeforeDelete(item.id)}>
                                <DropdownItem
                                    tag="a"
                                    href="#delete"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <Trans i18nKey="chat.partials.delete" />
                                </DropdownItem>
                            </li>
                            <li onClick={() => propAction(item.id, "unread")}>
                                <DropdownItem
                                    tag="a"
                                    href="#unread"
                                    className={item.unread ? "disabled" : ""}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                >
                                    <Trans i18nKey="chat.partials.markUnread" />
                                </DropdownItem>
                            </li> */}
                            {/* <li onClick={() => propAction(item.id, "archive")}>
                <DropdownItem
                  tag="a"
                  href="#archive"
                  className={item.archive ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <Trans i18nKey="chat.partials.archive" />
                </DropdownItem>
              </li> */}
                        {/* </ul>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div> */}
        </li>
    );
};
interface ContactItemProps {
    contact: UserData;
    setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
    onStartChat: (contact: UserData) => void;
}
export const ContactItem: React.FC<ContactItemProps> = ({ contact, /* setTab, */ setSelectedId, onStartChat }) => {
    return (
        <ul className="contacts-list">
            <li>
                {/* <h6 className="title overline-title-alt">{item.contacts.length > 0 && item.title}</h6> */}
                <h6 className="title overline-title-alt">{contact.username}</h6>
            </li>
            <li
                key={contact.id}
                onClick={() => {
                    if (!!contact.id) {
                        // setTab(ChatTab.Chats);
                        setSelectedId(contact.id);
                    }
                }}
            >
                <div className="user-card">
                    <a href="#card" onClick={(ev) => ev.preventDefault()}>
                        <UserAvatar text={findUpper(contact.username)} theme={undefined} image={undefined}></UserAvatar>
                        <div className="user-name">{contact.username}</div>
                    </a>
                    <div className="user-actions">
                        <Button onClick={() => onStartChat(contact)}>
                            <Trans i18nKey="chat.partials.start" />
                        </Button>
                    </div>
                </div>
            </li>
        </ul>
    );
};



function ChatMessageContent(props: { content: ChatMessageItem[`content`] }): React.ReactElement {
    const { t } = useTranslation();
    const { content } = props;

    const [handleDownload] = useHandler(async () => {
        if (content.type !== `file` || content.file === null) {
            return;
        }

        const fileId = content.file.id;
        const checksum = content.file.checksum;

        const client = createApiClient({ tokenFromCookies: true });

        try {
            const { downloadId } = unwrap(await client.fs.fsDownloadUrl({
                id: fileId,
                checksum,
            }));
    
            const urlProto = isDevelopment() ? `http:` : window.location.protocol;
            const urlHost = isDevelopment() ? `localhost:8080` : window.location.host;
    
            const url = `${urlProto}//${urlHost}/download-file/${encodeURIComponent(downloadId)}`;

            const a = document.createElement(`a`);
            a.href = url;
            a.download = normalizeTalkFileName(content.file.name);
            a.click();
        }
        catch {
            alert(t("chatPartials.content.notAvailable"));
        }
    });

    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const currentIdentifierRef = useRef(``);

    const contentFile = useMemo<ChatMessageItem[`content`][`file`]>(() => {
        const id = content.file?.id;
        const name = content.file?.name;
        const checksum = content.file?.checksum;
        const sizeInBytes = content.file?.sizeInBytes;

        if (id === undefined || name === undefined || checksum === undefined || sizeInBytes === undefined) {
            return null;
        }

        return { id, name, checksum, sizeInBytes };
    }, [content.file?.id, content.file?.checksum, content.file?.name, content.file?.sizeInBytes]);

    useEffect(() => {
        const myIdentifier = randomBytesHex(8);
        currentIdentifierRef.current = myIdentifier;

        setImagePreview(null);

        const file = contentFile;

        if (file === null) {
            return;
        }

        const fileName = normalizeTalkFileName(file.name);

        if (file.sizeInBytes > (5 * 1024 * 1024)) {
            return;
        }

        const extension = scope(() => {
            const name = fileName.toLowerCase();

            if (name.endsWith(`.png`)) {
                return `png`;
            }

            if (name.endsWith(`.jpg`) || name.endsWith(`.jpeg`)) {
                return `jpeg`;
            }

            if (name.endsWith(`.bmp`)) {
                return `bmp`;
            }

            return null;
        });

        if (extension === null) {
            return;
        }

        asyncVoid(async () => {
            try {
                const client = createApiClient({ tokenFromCookies: true });
    
                const { downloadId } = unwrap(await client.fs.fsDownloadUrl({
                    id: file.id,
                    checksum: file.checksum,
                }));
        
                const urlProto = isDevelopment() ? `http:` : window.location.protocol;
                const urlHost = isDevelopment() ? `localhost:8080` : window.location.host;
        
                const url = `${urlProto}//${urlHost}/download-file/${encodeURIComponent(downloadId)}`;

                const response = await fetch(url, { method: `GET` });

                assert( response.status === 200 );

                const blob = await response.blob();
                const abuff = await blob.arrayBuffer();
                const u8arr = new Uint8Array(abuff);
                const base64 = await u8arrToBase64(u8arr);

                if (currentIdentifierRef.current === myIdentifier) {
                    setImagePreview(`data:image/${extension};base64,${base64}`);
                }
            }
            catch (error) {
                l.error(`IMAGE PREVIEW FETCH ERROR:`, error);
            }
        })();
    }, [contentFile]);

    if (content.type === `text`) {
        return <>{content.text}</>;
    }

    if (content.file === null) {
        return <><strong><Trans i18nKey="chatPartials.content.removed" /></strong></>;
    }

    const fileName = normalizeTalkFileName(content.file.name);

    if (imagePreview !== null) {
        return <img src={imagePreview} alt={fileName} width={`400px`} style={{ cursor: `pointer` }} onClick={handleDownload} />;
    }

    return <>
        <strong>
            <Trans i18nKey="chatPartials.content.attachment" />
        </strong>
        <br />
        {fileName}
        <hr />
        <Button color={`light`} size={`sm`} onClick={handleDownload}>
            <Trans i18nKey="chatPartials.content.downloadButton" />
        </Button>
        <>
            (
            <Trans i18nKey="chatPartials.content.size" />
            &nbsp;
            {formatSize(content.file.sizeInBytes)}
            )
        </>
    </>;
}



function renderMessageContentAsString(content: ChatMessageItem[`content`]): string {
    if (content.type === `text`) {
        return content.text;
    }

    if (content.file === null) {
        return ``;
    }

    return normalizeTalkFileName(content.file.name);
}

function normalizeTalkFileName(orig: string): string {
    if (orig.startsWith(`$talk$-`) !== true) {
        return orig;
    }

    const split = orig.split(`-`);

    if (split.length < 3) {
        return orig;
    }

    return split.slice(2).join(`-`);
}
