import { z } from "zod";
import { EmptyOutputSchema } from "../common.js";
export const EditGroupParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    memberUsers: z.string().uuid().array().optional(),
    memberGroups: z.string().uuid().array().optional(),
    memberComputers: z.string().uuid().array().optional(),
    memberOf: z.string().uuid().array().optional(),
});
export const EditGroupOutputSchema = EmptyOutputSchema;
