import { isHostname, isNetPort } from "@trantor/vdesk-api-schemas/dist/utils/validators.js";
import { AuthClient } from "./clients/auth.js";
import { Miscellaneous } from "./clients/misc.js";
import { UserClient } from "./clients/user.js";
import { ChatClient } from "./clients/chat.js";
import { ChatArchiveClient } from "./clients/chatArchive.js";
import { assert } from "./libs/assert.js";
import { genHost } from "./libs/genHost.js";
import { Token } from "./libs/token.js";
import { NotificationClient } from "./clients/notifications.js";
import { GroupClient } from "./clients/groups.js";
import { ComputerClient } from "./clients/computers.js";
import { AccessRulesClient } from "./clients/accessRules.js";
import { CallsClient } from "./clients/calls.js";
import { FsClient } from "./clients/fs.js";
import { LogsClient } from "./clients/logs.js";
import { StatsClient } from "./clients/stats.js";
import { ServersClient } from "./clients/servers.js";
import { LicenseClient } from "./clients/license.js";
export class ApiClient {
    protocol;
    hostname;
    port;
    endpoint;
    token;
    auth;
    misc;
    users;
    chat;
    chatArchive;
    notifications;
    groups;
    computers;
    accessRules;
    calls;
    fs;
    logs;
    stats;
    servers;
    license;
    constructor({ protocol, hostname, port }) {
        assert(isHostname(hostname));
        assert(isNetPort(port));
        this.protocol = protocol;
        this.hostname = hostname;
        this.port = port;
        this.endpoint = `${protocol}://${genHost(hostname, port)}`;
        this.token = new Token();
        this.auth = new AuthClient(this.endpoint, this.token);
        this.misc = new Miscellaneous(this.endpoint, this.token);
        this.users = new UserClient(this.endpoint, this.token);
        this.chat = new ChatClient(this.endpoint, this.token);
        this.chatArchive = new ChatArchiveClient(this.endpoint, this.token);
        this.notifications = new NotificationClient(this.endpoint, this.token);
        this.groups = new GroupClient(this.endpoint, this.token);
        this.computers = new ComputerClient(this.endpoint, this.token);
        this.accessRules = new AccessRulesClient(this.endpoint, this.token);
        this.calls = new CallsClient(this.endpoint, this.token);
        this.fs = new FsClient(this.endpoint, this.token);
        this.logs = new LogsClient(this.endpoint, this.token);
        this.stats = new StatsClient(this.endpoint, this.token);
        this.servers = new ServersClient(this.endpoint, this.token);
        this.license = new LicenseClient(this.endpoint, this.token);
    }
    getToken() {
        return this.token.get();
    }
    setToken(token) {
        this.token.set(token);
    }
}
