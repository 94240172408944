import { Trans, useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Icon from "../icon/Icon";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import { Block, Button } from "../Component";
import Content from "../../layout/content/Content";
import l from "../../utils/Log";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

interface CofirmModalProps {
    mode?: "generic"
    | "app-logout"
    | "chat-leave"
    | "chat-delete"
    | "archived-chat-delete"
    | "participant-delete"
    | "new-chat-with-participant"
    | "user-delete"
    | "call-display-url"
    | "change-product-key"
    | "add-product-key";
    translationData?: {
        who?: string;
        link?: string;
        productKey?: string;
    }
    isOpen: boolean;
    onToggle: () => void;
    onConfirm?: () => Promise<void>;
    onCallback?: () => void;
}
const ConfirmModal: React.FC<CofirmModalProps> = ({ mode = "generic", isOpen, onToggle, onConfirm, onCallback, translationData }) => {
    const { t } = useTranslation();

    const resetModal = useCallback(() => {
        setDisableModal(false);
        setError(null);
    }, []);
    useEffect(() => {
        resetModal();
    }, [isOpen, resetModal]);
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    const handleToggle = () => {
        resetModal();
        onToggle();
    };
    const handleConfirm = () => {
        onConfirm &&
            onConfirm()
                .then(() => {
                    handleToggle();
                })
                .catch((err) => {
                    setError(`${err}`);
                    setDisableModal(true);
                });
        if (onCallback) {
            onCallback();
            handleToggle();
        }
    };
    const [copyToClipboardStatus, setCopyToClipboardStatus] = useState<"ready" | "done" | "error">("ready");
    const handleCopyToClipboard = (
        /* status:"ready" | "done" | "error", */
        setStatus: React.Dispatch<React.SetStateAction<"ready" | "done" | "error">>,
        txt: string
    ) => {
        navigator.clipboard
            .writeText(txt)
            .then(() => {
                setStatus("done");
            })
            .catch((reason) => {
                setStatus("error");
                l.error(`Copy to clipboard failed`, reason);
            })
            .finally(() => {
                setTimeout(() => {
                    setStatus("ready");
                }, 2500);
            });
    };
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleToggle();
                }}
                className="modal-dialog-centered"
                size={mode === "change-product-key" ? "xl" : "xs"}
                scrollable={true}
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey={[`confirmModal.${mode}.title`, `confirmModal.generic.title`]} />
                        </h5>
                        <div className="mt-4">
                            <Content className="compact">
                                <Block>
                                    <Row className="gy-3 py-3">
                                        <Col sm={12}>
                                            {mode === "call-display-url" ?
                                                // <a href={translationData?.link}>
                                                    <>
                                                        <Trans
                                                            i18nKey={[
                                                                `confirmModal.${mode}.content`,
                                                                `confirmModal.generic.content`,
                                                            ]}
                                                            values={translationData}
                                                            shouldUnescape
                                                            />
                                                        <br/>
                                                        <a 
                                                            href="#copy"
                                                            onClick={(ev) => {
                                                            ev.preventDefault();
                                                            copyToClipboardStatus === "ready"
                                                                && translationData?.link !== undefined
                                                                && handleCopyToClipboard(setCopyToClipboardStatus, translationData.link);
                                                            }}
                                                        >
                                                            {copyToClipboardStatus === "ready" && <Icon name="copy" />}
                                                            {copyToClipboardStatus === "done" && <Icon name="done" />}
                                                            {copyToClipboardStatus === "error" && <Icon name="cross" />}
                                                            &nbsp;
                                                            {translationData?.link}
                                                        </a>
                                                    </>
                                                // </a>
                                            : mode === "change-product-key" || mode === "add-product-key"?
                                                <div style={{wordBreak: "break-all"}}>{translationData?.productKey ?? t("settingsLicense.noProductKey")}</div>
                                                :
                                                <Trans
                                                    i18nKey={[
                                                        `confirmModal.${mode}.content`,
                                                        `confirmModal.generic.content`,
                                                    ]}
                                                    values={translationData}
                                                    shouldUnescape
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Block>
                            </Content>
                            <Row className="gy-3 pt-2">
                                <Col size={12}>
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button
                                                color="primary"
                                                size="md"
                                                type="button"
                                                disabled={disableModal}
                                                onClick={() => {
                                                    handleConfirm();
                                                }}
                                            >
                                                <Trans
                                                    i18nKey={[
                                                        `confirmModal.${mode}.confirm`,
                                                        `confirmModal.generic.confirm`,
                                                    ]}
                                                />
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (!disableModal) {
                                                        handleToggle();
                                                    }
                                                }}
                                                className="link link-light"
                                            >
                                                <Trans
                                                    i18nKey={[
                                                        `confirmModal.${mode}.cancel`,
                                                        `confirmModal.generic.cancel`,
                                                    ]}
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ConfirmModal;
