import { Trans, useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { TelephoneMinus, Ban, TelephoneInbound, TelephoneOutbound } from "react-bootstrap-icons";
import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
    UserAvatar,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { findUpper, formatDate, themeFromUsername } from "../../../../utils/Utils";
import { UserContext } from "../../../settings/UserContext";
import FaIcon from "../../../../components/icon/FaIcon";
import { createApiClient } from "../../../../utils/createApiClient";
import { DateTime } from "luxon";
import { useCronState } from "../../../../utils/CronJob";

import { GetCallHistoryOutput, GetCallHistoryOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/getCallHistory";
import { DefaultSpinner } from "../../../../components/default-spinner";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

interface CallsHistoryModalProps {
    isOpen: boolean;
    onToggle: () => void;
}
const CallsHistoryModal: React.FC<CallsHistoryModalProps> = ({ isOpen, onToggle }) => {
    const { t } = useTranslation();
    const loggedUser = useContext(UserContext).loggedInUser[0];
    const [data, setData] = useState<GetCallHistoryOutput["endedCalls"]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const debouncingTimeout = useRef<NodeJS.Timeout>();

    const [calls, triggerCalls,, manualLoading] = useCronState(1000, [], async () => {
        const client = createApiClient({ tokenFromCookies: true });
        const res = await client.calls.getCallHistory({});
        const parsed = GetCallHistoryOutputSchema.safeParse(res.data);
        if (parsed.success) {
            return parsed.data.endedCalls
        } else {
            setError("DESERIALIZATION-ERROR");
        }
        return [];
    });
    
    const [error, setError] = useState<string | null>(null);
    const [whichChatIsOpen, setWhichChatIsOpen] = useState<GetCallHistoryOutput["endedCalls"][number]["callChat"] | null>(null);
    const [chatIsOpen, setChatIsOpen] = useState(false);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    const resetModal = () => {
        setSearchText("");
        setDisableModal(false);
        setError(null);
    };
    const handleToggle = () => {
        resetModal();
        onToggle();
    };
    const handleChatToggle = () => {
        setChatIsOpen(false);
        setTimeout(() => {
            setWhichChatIsOpen(null);
        }, 300);
    };

    const deriveConnectedChatName = useCallback((call: GetCallHistoryOutput["endedCalls"][number]): string => {
        const {displayName: myName} = loggedUser;
        const hasLinkedChat = call.linkedChatRoom !== undefined;
        if (hasLinkedChat) {
            if (call.linkedChatRoom?.type === "group") {
                return call.linkedChatRoom.name;
            } else if (call.linkedChatRoom?.type === "p2p") {
                if (!myName.toLocaleLowerCase().localeCompare(call.startedBy.displayName.toLocaleLowerCase())) {
                    return call.linkedChatRoom.otherUser.displayName;
                } else {
                    return call.startedBy.displayName;
                }
            }
        } 
        return "";
    }, [loggedUser]);

    const drawDismissed = (call: GetCallHistoryOutput["endedCalls"][number]): React.ReactNode => {
        const mySize: string = "1rem";
        let myIcon = <>
            <TelephoneMinus size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/>
        </>;
        if (call.dismissed === "rejected") {
            myIcon = <>
                <Ban size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/>
            </>;
        }
        if (call.dismissed === "accepted") {
            if (call.startedBy.id === loggedUser.id) {
                myIcon = <>
                    <TelephoneOutbound size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/>
                </>;
                return <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", gap: ".5rem"}}>
                    {myIcon}
                    <span>
                        <Trans i18nKey="callsHistory.dismissedLabel.initiated" />
                    </span>
                </div>;
            } else {
                myIcon = <>
                    <TelephoneInbound size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/>
                </>;
            }
        }
        return <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", gap: ".5rem"}}>
            {myIcon}
            <span>
                <Trans i18nKey={"callsHistory.dismissedLabel." + call.dismissed} />
            </span>
        </div>;
    }

    useEffect(() => {
        if (debouncingTimeout.current !== undefined) {
            clearTimeout(debouncingTimeout.current);
            debouncingTimeout.current = undefined;
        }
        debouncingTimeout.current = setTimeout(() => {
            setData(calls.filter((i) => i.shortCallId.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || deriveConnectedChatName(i).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        }, 500);
    }, [loggedUser.id, searchText, calls, triggerCalls, manualLoading, deriveConnectedChatName]);

    const renderContent = (): React.ReactNode => {
        return <Content className="compact">
            <Row>
                <Col md={12} className="pb-3">
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="callsHistory.search" />
                        </label>
                        <input
                            disabled={disableModal}
                            className="form-control"
                            type="text"
                            placeholder={t("callsHistory.searchPlaceholder").toString()}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
            <DataTable className="overflow-x-auto">
                <DataTableBody compact>
                    <DataTableHead>
                        {/* <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                    type="checkbox"
                                    disabled={disableModal}
                                    className="custom-control-input"
                                    onChange={(e) => onSelectAllChange(e)}
                                    checked={selectAllValue()}
                                    id="uid"
                                />
                                <label className="custom-control-label" htmlFor="uid"></label>
                            </div>
                        </DataTableRow> */}
                        <DataTableRow>
                            <Trans i18nKey="callsHistory.dismissed" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="callsHistory.endedAt" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="callsHistory.startedAt" />
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">
                                <Trans i18nKey="callsHistory.shortCallName" />
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="callsHistory.connectedChat" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="callsHistory.startedBy" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="callsHistory.openChat" />
                        </DataTableRow>
                        {/* <DataTableRow>
                                <span className="sub-text">
                                    <Trans i18nKey="callsHistory.username" />
                                </span>
                            </DataTableRow> */}
                    </DataTableHead>
                    {/*Head*/}
                    {data.map((call) => {
                        const startedAt = call.startedAt === null ? `` : DateTime.fromMillis(call.startedAt ?? NaN).isValid
                            ? formatDate(call.startedAt)
                            : t("callsHistory.invalidStartedAt");
                        const endedAt = DateTime.fromMillis(call.endedAt ?? NaN).isValid
                            ? formatDate(call.endedAt)
                            : t("callsHistory.invalidEndedAt");
                        return (
                            <DataTableItem key={call.shortCallId + call.callId}>
                                <DataTableRow>
                                    {drawDismissed(call)}
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{endedAt}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{startedAt}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{call.shortCallId}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    {/* <div
                                        className={
                                            "user-card unselectable-text"
                                        }
                                        >
                                        <div className="user-info">
                                        <span className="tb-lead">{deriveNameFromCall(call)}</span>
                                        </div>
                                    </div> */}
                                    <span className="unselectable-text">{deriveConnectedChatName(call)}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{call.startedBy.displayName}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <Button
                                        onClick={() => {
                                            setWhichChatIsOpen(call.callChat);
                                            setTimeout(() => {
                                                setChatIsOpen(true);
                                            }, 50);
                                        }}
                                        disabled={call.callChat.length === 0}
                                    >
                                        <Trans i18nKey="callsHistory.openChatButtonLabel" />
                                    </Button>
                                </DataTableRow>
                                {/* <DataTableRow>
                                        <div
                                            className={
                                                "user-card unselectable-text" +
                                                (disableModal ? "" : " clickable-text")
                                            }
                                            onClick={(e) => onSelectChange(item)}
                                        >
                                            <div className="user-info">
                                                <span className="tb-lead">{item.username}</span>
                                            </div>
                                        </div>
                                    </DataTableRow> */}
                            </DataTableItem>
                        );
                    })}
                    {manualLoading && <DefaultSpinner />}
                </DataTableBody>
                {!manualLoading && data.length === 0 && (
                    <div className="card-inner">
                        <div className="text-center">
                            <span className="text-silent">
                                <Trans i18nKey="callsHistory.noDataFound" />
                            </span>
                        </div>
                    </div>
                )}
            </DataTable>
        </Content>
    }
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleToggle();
                }}
                className="modal-dialog-centered"
                scrollable={true}
                fullscreen="xl"
                size="xl"
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="callsHistory.title" />
                        </h5>

                        <div className="mt-4">
                            {renderContent()}
                            
                            {/* <Row className="gy-3 pt-2">
                                <Col size={12}>
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button
                                                color="primary"
                                                type="button"
                                                disabled={disableModal}
                                                onClick={() => {
                                                    // handleConfirm();
                                                }}
                                            >
                                                <Trans i18nKey="callsHistory.createChat" />
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (!disableModal) {
                                                        handleToggle();
                                                    }
                                                }}
                                                className="link link-light"
                                            >
                                                <Trans i18nKey="callsHistory.cancel" />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row> */}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={chatIsOpen}
                toggle={() => {
                    handleChatToggle();
                }}
                className="modal-dialog-centered"
                scrollable={true}
                fullscreen="xl"
                size="xl"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleChatToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="callsHistory.callChat.title" />
                        </h5>

                        <div className="mt-4">
                            {whichChatIsOpen && whichChatIsOpen.map(c => <div className="chat is-you" id={`message-id-${c.id}`}>
                                <div className="chat-avatar">
                                    {/* {chat.group ? (
                                        <UserAvatar image={item.user?.image} theme={item.user?.theme} text={findUpper(item.user?.name)}>
                                            {" "}
                                            {chat.active === true ? (
                                                <span className="status dot dot-lg dot-success"></span>
                                            ) : (
                                                <span className="status dot dot-lg dot-gray"></span>
                                            )}
                                        </UserAvatar>
                                    ) : ( */}
                                    <UserAvatar
                                        image={undefined}
                                        theme={themeFromUsername(c.fromDisplayName)}
                                        text={findUpper(c.fromDisplayName)}
                                    >
                                        {" "}
                                        {/* {chat.active === true ? ( */}
                                        <span className="status dot dot-lg dot-success"></span>
                                        {/* ) : ( */}
                                        {/* <span className="status dot dot-lg dot-gray"></span> */}
                                        {/* )} */}
                                    </UserAvatar>
                                    {/* )} */}
                                </div>
                                <div className="chat-content">
                                    <div className="chat-bubbles">
                                        <div className="chat-bubble">
                                            <div className="chat-msg">{c.text}</div>
                                        </div>
                                    </div>
                                    <ul className="chat-meta">
                                        <li>
                                            <FaIcon icon="check" title={t("chat.partials.isRead").toString()}/>
                                        </li>
                                        <li>{c.fromDisplayName}</li>
                                        <li>{formatDate(c.timestamp)}</li>
                                    </ul>
                                </div>
                            </div>)}
                            {whichChatIsOpen === null && <DefaultSpinner />}
                            {whichChatIsOpen && whichChatIsOpen.length === 0 &&<Trans i18nKey="callsHistory.callChat.chatNoData" />}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default CallsHistoryModal;
