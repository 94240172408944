import { DateTime } from "luxon";
import { ItemOfApi } from "../components/ApiTable";
import { UsersService } from "../services";

export enum GlobalErrorType {
    IS404,
    BROKENSESSION,
    EXPIREDSESSION,
    GENERIC
}
export type SessionData = {
    token: string;
    lastLogin: Date;
    lastFailedLogins: number;
    loggedUser: UserData;
    expires: boolean;
    mintingDate: Date;
    expiryDate?: Date;
};
export type SessionDataJSON = {
    token: string;
    lastLogin: string;
    lastFailedLogins: number;
    loggedUser: UserData;
    expires: boolean;
    mintingDate: string;
    expiryDate?: string;
};
export const sessionDataFromJSON = (jSesh: SessionDataJSON): SessionData => {
    const newSesh: SessionData = {
        token: jSesh.token,
        lastLogin: DateTime.fromISO(jSesh.lastLogin).toJSDate(),
        lastFailedLogins: jSesh.lastFailedLogins,
        loggedUser: jSesh.loggedUser,
        expires: jSesh.expires,
        mintingDate: DateTime.fromISO(jSesh.mintingDate).toJSDate(),
    }
    if (jSesh.expiryDate) {
        newSesh.expiryDate = DateTime.fromISO(jSesh.expiryDate).toJSDate()
    }
    return newSesh;
}

export const UserThemeArray = [`primary`, `secondary`, `success`, `warning`, `info`, `danger`, `grey`, `black`, `blue`, `orange`, `purple`, `pink`] as const;
export type UserTheme = (typeof UserThemeArray)[number];

export type UserRole = "regular" | "admin";

export enum UserStatus {
    Active = "Active",
    Pending = "Pending",
    Suspend = "Suspend",
    Inactive = "Inactive",
}
export enum EmailStatus {
    Success = "success",
    Pending = "pending",
    Alert = "alert",
}
export type UserDataFromUsers = {
    username: string;
    displayName: string;
    id: string;
    lastLogin: number | null;
    //role: "admin" | "regular";
    role: "admin" | "regular";
    enabled: boolean;
    creationTime: number;
};

const getUsers = UsersService.getUsers.bind(UsersService);
export type UserData = ItemOfApi<typeof getUsers>;

export type ButtonColor =
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "info"
    | "danger"
    | "gray"
    | "dark"
    | "light"
    | "lighter"
    | "transparent";
export type ButtonOutline =
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "info"
    | "danger"
    | "gray"
    | "dark"
    | "light"
    | "transparent";
export type Sizes = "xs" | "sm" | "md" | "lg" | "xl";

export type ModalControls = {
    // leave?: (() => void) | null;
    // delete?: (() => void) | null;
    // rename?: (() => void) | null;

    [key in "leave" | "delete" | "rename"]?: (() => void) | null;
}