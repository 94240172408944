import React, { ReactElement } from "react";
import PrivateRoute from "./route/PrivateRoute";
import Layout from "./layout/Layout";
import Error404Classic from "./pages/error/404-classic";
import Error404ModernPage from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";
import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Success from "./pages/auth/Success";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useGlobalError } from "./utils/Utils";
import P from "./utils/Path";
import exportedHooks from "./utils/HooksExporter";
import { isDevelopment } from "./utils/Log";
import Sandbox from "./utils/sandboxes/ExternalSandbox";

const App = ():ReactElement => {
    /* DO NOT REMOVE - START */
    const navigate = useNavigate();
    const location = useLocation();
    const { triggerGlobalError, cleanGlobalError } = useGlobalError();
    exportedHooks.navigate = navigate;
    exportedHooks.location = location;
    exportedHooks.triggerGlobalError = triggerGlobalError;
    exportedHooks.cleanGlobalError = cleanGlobalError;
    /* DO NOT REMOVE - END */

    return (
        <Routes>
            {/* Auth Pages */}
            {isDevelopment() && <Route path="/sandbox" element={<Sandbox />} />}
            <Route path={P.getPath("success", true)} element={<Success />}></Route>
            <Route path={P.getPath("reset", true)} element={<ForgotPassword />}></Route>
            <Route path={P.getPath("register", true)} element={<Register />}></Route>
            <Route path={P.getPath("login", true)} element={<Login />}></Route>

            {/* Print Pages */}
            {/* <Route path={`${process.env.PUBLIC_URL}/invoice-print/:id`} element={<InvoicePrint />}></Route> */}

            {/* Helper pages */}
            <Route path={P.getPath("no-auth-terms", true)} element={<Terms />}></Route>
            <Route path={P.getPath("no-auth-faq", true)} element={<Faq />}></Route>

            {/* <Route path={`${process.env.PUBLIC_URL}/invoice-print`} element={<InvoicePrint />}></Route> */}

            {/*Error Pages*/}
            <Route path={P.getPath("404c", true)} element={<Error404Classic />}></Route>
            <Route path={P.getPath("504c", true)} element={<Error504Classic />}></Route>
            <Route path={P.getPath("404m", true)} element={<Error404ModernPage />}></Route>
            <Route path={P.getPath("504m", true)} element={<Error504Modern />}></Route>

            {/*Main Routes*/}
            {/* <Route path="">
        <PrivateRoute element={<Layout />}></PrivateRoute>
      </Route> */}
            <Route path="*" element={<PrivateRoute element={Layout}></PrivateRoute>} />

            {/* <Route element={<RedirectAs404 />}></Route> */}
        </Routes>
    );
};
export default App;
