import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { UserData } from "../../../utils/GeneralTypes";
import { formatDate } from "../../../utils/Utils";

interface UserDetailsModalProps {
    isOpen: boolean;
    onToggle: () => void;
    userData: UserData | null;
}
const UserDetailsModal: React.FC<UserDetailsModalProps> = ({ isOpen, onToggle, userData }) => {
    const { t } = useTranslation();
    const tempLastLogin: number =
        userData?.lastLogin !== null && userData?.lastLogin !== undefined ? userData.lastLogin : NaN;
    const lastLogin = !Number.isNaN(tempLastLogin)
        ? formatDate(tempLastLogin)
        : t("usersManagement.detailsModal.field.invalidLastLogin");
    const tempCreationTime: number =
        userData?.creationTime !== null && userData?.creationTime !== undefined ? userData.creationTime : NaN;
    const creationTime = formatDate(tempCreationTime);
    return (
        <>
            <Modal isOpen={isOpen} toggle={onToggle}>
                <ModalHeader toggle={onToggle}>{t("usersManagement.detailsModal.title")}</ModalHeader>
                <ModalBody>
                    {/* <Row className="gy-3 py-1">
                        <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                            <h4>{t("loginPage.successModalText1")}</h4>
                        </Col>
                    </Row> */}
                    <Row className="gy-3 py-3">
                        <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.id")}</h6>
                            <p>{userData?.id}</p>
                        </Col>
                        <Col xs="12" sm={{ offset: 1, size: 5 }} md={{ offset: 2, size: 4 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.username")}</h6>
                            <p>{userData?.username}</p>
                        </Col>
                        <Col xs="12" sm={{ size: 5 }} md={{ size: 4 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.displayName")}</h6>
                            <p>{userData !== null ? userData.displayName : "N/A"}</p>
                        </Col>
                        <Col xs="12" sm={{ offset: 1, size: 5 }} md={{ offset: 2, size: 4 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.role")}</h6>
                            <p>{t(`roleLabels.${userData?.role}`)}</p>
                        </Col>
                        <Col xs="12" sm={{ size: 5 }} md={{ size: 4 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.lastLogin")}</h6>
                            <p>{lastLogin}</p>
                        </Col>
                        <Col xs="12" sm={{ offset: 1, size: 5 }} md={{ offset: 2, size: 4 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.enabledLabel")}</h6>
                            <p>
                                {userData?.enabled
                                    ? t("usersManagement.detailsModal.field.enabled")
                                    : t("usersManagement.detailsModal.field.disabled")}
                            </p>
                        </Col>
                        <Col xs="12" sm={{ size: 5 }} md={{ size: 4 }}>
                            <h6 className="overline-title">{t("usersManagement.detailsModal.field.creationTime")}</h6>
                            <p>{creationTime}</p>
                        </Col>
                        {/* <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                            <h6 className="overline-title">{t("usersManagement.modal.field.id")}</h6>
                            <p>formatDate(sessionData.lastLogin)</p>
                        </Col> */}
                    </Row>
                    {/* <Row className="gy-3 py-3">
                        <Col size="12">
                            <ul className="align-center justify-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button color="primary" size="lg" onClick={() => onToggle()}>
                                        {t("loginPage.successModalConfirm")}
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row> */}
                </ModalBody>
            </Modal>
        </>
    );
};

export default UserDetailsModal;
