
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import l from "./Log";
import { JSONLiteral, JSONLiteralSchema, JSONObject, JSONObjectSchema, JSONValue } from "@trantor/vdesk-api-schemas/dist/json";
import { scope } from "./scope";
import { unreachable } from "./unreachable";
import { useDelayedLoading } from "./handler";



export type AnyFunction = ((...params: any[]) => any) | (new (...params: any[]) => any);
export type NonFunction<T> = T extends AnyFunction ? never : T;



export function useTimer(intervalMs: number): number {
    const intervalMsRef = useRef(intervalMs);

    if (!Number.isInteger(intervalMsRef.current) || intervalMsRef.current < 1) {
        throw new TypeError();
    }

    const [timer] = useCronState(
        intervalMsRef.current,
        Date.now(),
        () => Date.now(),
    );

    return timer;
}



export type CronAction = () => void | Promise<void>;

export type CronResult = {
    triggerMe: () => void,
    loading: boolean,
    manualLoading: boolean,
    firstTime: boolean,
};

/**
 * 
 * @param intervalMs How frequently it runs (we ignore changes to `intervalMs`)
 * @param action what is gonna run (we track changes to `action`)
 * @param onStop CB when it's deactivated (we track changes to `onStop`)
 * @returns 
 */
export function useCron(
    intervalMs: number,
    action: CronAction,
): CronResult {
    const intervalMsRef = useRef(intervalMs); // we ignore changes to `intervalMs`

    const actionRef = useRef(action);
    actionRef.current = action;

    const [triggering, setTriggering] = useState(false);
    const triggeringRef = useRef(false);

    const activeRef = useRef(false);
    const lastTimeoutRef = useRef<number | null>(null);
    const [manualLoading, setManualLoading] = useState(false);

    const [firstTime, setFirstTime] = useState(true);

    const mustManualTriggerRef = useRef(false);

    const cb1Ref = useRef(async () => {
        if (activeRef.current !== true || triggeringRef.current) {
            return;
        }

        setTriggering(true);
        triggeringRef.current = true;

        lastTimeoutRef.current = null;

        try {
            await actionRef.current();
            setFirstTime(false);
        }
        catch (err) {
            l.warn(`Cron job task failed:`, err);
        }

        setTriggering(false);
        triggeringRef.current = false;
        setManualLoading(false);

        const timeout = window.setTimeout(() => {
            cb1Ref.current().catch(() => {
                // do nothing
            });
        }, intervalMsRef.current);

        lastTimeoutRef.current = timeout;
    });

    const cb2Ref = useRef(async () => {
        if (activeRef.current !== true) {
            return;
        }

        if (triggeringRef.current) {
            setManualLoading(true);
            return;
        }

        setTriggering(true);
        triggeringRef.current = true;
        setManualLoading(true);

        const lastTimeout = lastTimeoutRef.current;

        if (lastTimeout !== null) {
            window.clearTimeout(lastTimeout);
        }

        try {
            await actionRef.current();
            setFirstTime(false);
        }
        catch (err) {
            l.warn(`Cron job task failed:`, err);
        }

        setTriggering(false);
        triggeringRef.current = false;
        setManualLoading(false);

        const timeout = window.setTimeout(() => {
            cb1Ref.current().catch(() => {
                // do nothing
            });
        }, intervalMsRef.current);

        lastTimeoutRef.current = timeout;
    });

    useEffect(() => {
        activeRef.current = true;

        cb1Ref.current().catch(() => {
            // do nothing
        });

        return () => {
            activeRef.current = false;

            const lastTimeout = lastTimeoutRef.current;

            if (lastTimeout !== null) {
                window.clearTimeout(lastTimeout);
            }
        };
    }, []);

    if (mustManualTriggerRef.current) {
        mustManualTriggerRef.current = false;
        cb2Ref.current().catch(() => { /* do nothing */ });
    }

    const triggerMe = useCallback(() => {
        mustManualTriggerRef.current = true;
    }, []);

    return {
        loading: triggering,
        triggerMe,
        manualLoading,
        firstTime,
    };
}



export type CronStateAction<T> = (prev: NonFunction<T>) => NonFunction<T> | Promise<NonFunction<T>>;

/**
 * 
 * @param intervalMs How frequently it runs (we ignore changes to `intervalMs`)
 * @param initialValue Initial value of State
 * @param action what is gonna run (we track changes to `action`)
 * @param onStop CB when it's deactivated (we track changes to `onStop`)
 * @returns 
 */
export function useCronState<T>(
    intervalMs: number,
    initialValue: NonFunction<T>,
    action: CronStateAction<T>,
): [
        data: NonFunction<T>,
        triggerMe: () => void,
        loading: boolean,
        manualLoading: boolean,
        firstTime: boolean,
] {
    const intervalMsRef = useRef(intervalMs);
    const somethingChangedRef = useRef(false);
    const [, setFakeState] = useState(0);
    
    const actionRef = useRef(action);
    actionRef.current = action;

    const [value, setValue] = useState(initialValue);
    const valueRef = useRef(initialValue);
    valueRef.current = value;

    const { triggerMe, loading, manualLoading, firstTime } = useCron(intervalMsRef.current, async () => {
        while (true) {
            somethingChangedRef.current = false;

            const newValue = await actionRef.current(valueRef.current);

            if (somethingChangedRef.current) {
                continue;
            }

            setValue(newValue);
            valueRef.current = newValue;
            break;
        }
    });

    const myTrigger = useRef(() => {
        setFakeState(c => c + 1);
        somethingChangedRef.current = true;
        triggerMe();
    });

    return [value, myTrigger.current, loading, manualLoading, firstTime];
}



export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}



export type ApiGetterParams<Item extends JSONValue, Filters extends JSONValue, OrderBy extends JSONValue> = {
    fetcher: (params: { page: number, filters?: Filters, orderBy?: OrderBy, itemsPerPage: number, search: string }) => Promise<{
        items: Item[],
        page: number,
        totalPages: number,
        totalItems: number,
    }>,
    delayLoading?: { afterMs: number, minDuration: number },
    filters?: Filters,
    orderBy?: OrderBy,
    itemsPerPage: number,
    intervalMs?: number,
    initialPage?: number,
    cache?: number,
    // cacheFirstAndLast?: boolean,
    search?: string,
    // minIntervalMs: 1000,
};

export type ApiGetter<Item extends JSONValue> = {
    data: Item[],
    currentPage: number,
    totalPages: number,
    totalItems: number,
    loading: boolean,
    triggerMe: () => void,
    requestPage: (pageOrCb: number | ((currentPage: number) => number)) => void,
    firstTime: boolean,
};



export function useApiGetter<Item extends JSONValue, Filters extends JSONValue, OrderBy extends JSONValue>(params: ApiGetterParams<Item, Filters, OrderBy>): ApiGetter<Item> {
    type CronData = Awaited<ReturnType<ApiGetterParams<Item, Filters, OrderBy>[`fetcher`]>>;

    const somethingChangedRef = useRef(false);

    const fetcherRef = useRef(params.fetcher);
    fetcherRef.current = params.fetcher;

    const delayLoadingRef = useRef(params.delayLoading === undefined ? undefined : {
        afterMs: params.delayLoading.afterMs,
        minDurationMs: params.delayLoading.minDuration,
    });

    const cacheRef = useRef(params.cache ?? 0);
    const intervalMsRef = useRef(params.intervalMs ?? 1000);
    const requestedPageRef = useRef(params.initialPage ?? 1);
    const searchRef = useRef(params.search ?? ``);
    const orderByRef = useRef(params.orderBy);
    const filtersRef = useRef(params.filters);
    const itemsPerPageRef = useRef(params.itemsPerPage);

    const [cached, setCached] = useState<CronData[]>([]);

    const { firstTime, manualLoading, triggerMe } = useCron(intervalMsRef.current, async () => {
        while (true) {
            somethingChangedRef.current = false;

            const result: CronData[] = [];

            const startPage = scope(() => {
                const page = requestedPageRef.current - cacheRef.current;
                return page < 1 ? 1 : page;
            });

            const res = await fetcherRef.current({
                page: startPage,
                itemsPerPage: itemsPerPageRef.current,
                filters: filtersRef.current,
                orderBy: orderByRef.current,
                search: searchRef.current,
            });

            if (somethingChangedRef.current) {
                continue;
            }

            result.push(res);

            const { totalPages, totalItems } = res;

            const endPage = scope(() => {
                const page = requestedPageRef.current + cacheRef.current;
                return page > totalPages ? totalPages : page;
            });

            for (let page = startPage + 1; page <= endPage; page += 1) {
                const res = await fetcherRef.current({
                    page,
                    itemsPerPage: itemsPerPageRef.current,
                    filters: filtersRef.current,
                    orderBy: orderByRef.current,
                    search: searchRef.current,
                });

                if (res.totalPages !== totalPages || res.totalItems !== totalItems || somethingChangedRef.current) {
                    somethingChangedRef.current = true;
                    break;
                }

                result.push(res);
            }

            if (somethingChangedRef.current === false) {
                setCached(result);
                return;
            }
        }
    });

    const [, setFakeState] = useState(0);

    const triggerMeRef = useRef(() => {
        triggerMe();
        setFakeState(c => c + 1);
    });

    useEffect(() => {
        const newSeach = params.search ?? ``;

        if (searchRef.current !== newSeach) {
            searchRef.current = newSeach;
            requestedPageRef.current = 1;
            somethingChangedRef.current = true;
            triggerMeRef.current();
        }
    }, [params.search]);

    useEffect(() => {
        const newValue = params.itemsPerPage;

        if (itemsPerPageRef.current !== newValue) {
            itemsPerPageRef.current = newValue;
            requestedPageRef.current = 1;
            somethingChangedRef.current = true;
            triggerMeRef.current();
        }
    }, [params.itemsPerPage]);

    useEffect(() => {
        const newValue = params.orderBy;

        if (orderByRef.current !== newValue) {
            orderByRef.current = newValue;
            requestedPageRef.current = 1;
            somethingChangedRef.current = true;
            triggerMeRef.current();
        }
    }, [params.orderBy]);

    useEffect(() => {
        const newValue = params.filters;

        if (filtersRef.current !== newValue) {
            filtersRef.current = newValue;
            requestedPageRef.current = 1;
            somethingChangedRef.current = true;
            triggerMeRef.current();
        }
    }, [params.filters]);

    const prevDataRef = useRef<CronData>({
        page: 1,
        items: [],
        totalItems: 0,
        totalPages: 1,
    });

    const data = useMemo(() => {
        const newValue = scope(() => {
            const c = [...cached];
            const totalPages = c[0]?.totalPages ?? unreachable();
            const rp = requestedPageRef.current <= totalPages ? requestedPageRef.current : totalPages;
            return c.find(e => e.page === rp) ?? unreachable();
        }, () => ({
            page: 1,
            items: [],
            totalItems: 0,
            totalPages: 1,
        }));

        if (compareJSON(prevDataRef.current, newValue) !== true) {
            prevDataRef.current = newValue;
            return newValue;
        }

        return prevDataRef.current;
    }, [cached]);

    const loadingOrig = firstTime || manualLoading;

    const loading = useDelayedLoading(loadingOrig, delayLoadingRef.current?.afterMs ?? 0, delayLoadingRef.current?.minDurationMs ?? 0);

    const prevItemsRef = useRef<Item[]>([]);

    const items = useMemo(() => {
        const newValue = data.items;

        if (compareJSON(prevItemsRef.current, newValue) !== true) {
            prevItemsRef.current = newValue;
            return newValue;
        }

        return prevItemsRef.current;
    }, [data]);

    const totalItems = data.totalItems;
    const totalPages = data.totalPages;
    const currentPage = data.page;
    
    const currentPageRef = useRef(currentPage);
    currentPageRef.current = currentPage;

    const cachedRef = useRef(cached);
    cachedRef.current = cached;

    return {
        data: items,
        currentPage,
        loading,
        totalItems,
        totalPages,
        firstTime,
        triggerMe: useCallback(() => {
            somethingChangedRef.current = true;
            triggerMeRef.current();
        }, []),
        requestPage: useCallback((pageOrCb) => {
            const page = typeof pageOrCb === `function` ? pageOrCb(currentPageRef.current) : pageOrCb;

            if (Number.isInteger(page) !== true || page < 1) {
                l.error(`An invalid page has been requested: ${page}`);
                return;
            }

            if (page !== requestedPageRef.current) {
                requestedPageRef.current = page;
                const inCache = cachedRef.current.some(e => e.page === page);
                if (inCache) {
                    setCached([...cachedRef.current]);
                    return;
                }
                somethingChangedRef.current = true;
                triggerMeRef.current();
            }
        }, []),
    };
}



export function useDebounced<T>(value: T, delayMs: number): T {
    const delayMsRef = useRef(delayMs);

    if (Number.isInteger(delayMsRef.current) !== true || delayMsRef.current < 0) {
        throw new TypeError(`delayMs must be an integer >= 0`);
    }

    const [output, setOutput] = useState(value);

    const firstTimeRef = useRef(true);

    useEffect(() => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        }

        const timeout = window.setTimeout(() => {
            setOutput(value);
        }, delayMsRef.current);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [value]);

    return output;
}



export function isJSONObject(input: unknown): input is JSONObject {
    return JSONObjectSchema.safeParse(input).success;
}

export function isJSONLiteralOrUndefined(v: unknown): v is JSONLiteral | undefined {
    return v === undefined || JSONLiteralSchema.safeParse(v).success;
}

export function compareJSON<L extends JSONValue | undefined, R extends JSONValue | undefined>(left: L, right: R): boolean {
    if (isJSONLiteralOrUndefined(left)) {
        if (isJSONLiteralOrUndefined(right)) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return (left as unknown) === (right as unknown);
        }
        else {
            return false;
        }
    }

    if (Array.isArray(left)) {
        if (Array.isArray(right) && right.length === left.length) {
            return left.every((elem, i) => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                return compareJSON(elem, right[i] as JSONValue);
            });
        }
        else {
            return false;
        }
    }

    if (isJSONObject(right)) {
        const leftKeys = Object.keys(left);
        const rightKeys = Object.keys(right);

        if (leftKeys.length !== rightKeys.length || leftKeys.every(e => rightKeys.includes(e)) !== true || rightKeys.every(e => leftKeys.includes(e)) !== true) {
            return false;
        }

        return leftKeys.every((key) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return compareJSON(left[key] as JSONValue, right[key] as JSONValue);
        });
    }
    
    return false;
}




