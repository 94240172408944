
import React, { useContext, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent } from "../../../components/Component";
import { ComputersContext, ComputersContextProvider } from "../../../contexts/get-computers/ComputersContext";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { isDevelopment } from "../../../utils/Log";
import { formatDate } from "../../../utils/Utils";
import { ComputersFilters } from "@trantor/vdesk-api-schemas/dist/computers/getComputers";
import FaIcon from "../../../components/icon/FaIcon";
import { DefaultSpinner } from "../../../components/default-spinner";
import { ComputersContextType } from "../../../contexts/get-computers/ComputersContextTypes";
import { useHandler } from "../../../utils/handler";
import { createApiClient } from "../../../utils/createApiClient";
import { unwrap } from "../../../services/MainService";
import { sleep, useCronState } from "../../../utils/CronJob";
import { unreachable } from "../../../utils/unreachable";
import classNames from "classnames";



const Component = ({ computersContext }: { computersContext: NonNullable<ComputersContextType> }) => {
    const { t: origT } = useTranslation();
    const t = (code: string): string => {
        return origT(code).toString();
    };

    const {
        state: { computers, page, itemsPerPage, totalItems },
        changeItemsPerPage,
        changeFilters,
        changePage
     } = computersContext;

    const [onSearch, setOnSearch] = useState(false);
    const [tableSm, setTableSm] = useState(false);
    const nameSearchRef = useRef<HTMLInputElement>(null);

    // function to toggle the search option
    const toggleSearch = (): void => {
        setOnSearch((prev) => {
            const willOpen = !prev;

            if (willOpen) {
                // Make the input field on focus when opening the search bar
                nameSearchRef.current?.focus();
            }

            return willOpen;
        });
    };

    const clearNameSearchAndClose = (): void => {
        nameSearchRef.current!.value = "";
        triggerFiltering();
        toggleSearch();
    }

    const triggerFiltering = (): void => {
        const filters: ComputersFilters = [];

        /**
         * Filter by group name
         */
        const name = nameSearchRef.current?.value;
        if (name && name.length > 0) {
            filters.push({
                field: "name",
                op: "inci",
                value: name,
            });
        }

        changeFilters(filters);
    }

    const [connectingModal, setConnectingModal] = useState(false);

    const [handleConnectComputer, handleConnectComputerLoading] = useHandler(async (computerId: string) => {
        const client = createApiClient({ tokenFromCookies: true });

        const { connectionId } = unwrap(await client.computers.connect({ computerId }));

        setConnectingModal(true);

        try {
            let remConnId: string;

            while (true) {
                await sleep(1000);

                const { remoteConnectionId } = unwrap(await client.computers.getConnectionInfo({ connectionId }));

                if (remoteConnectionId !== null) {
                    remConnId = remoteConnectionId;
                    break;
                }
            }

            const a = document.createElement(`a`);
            a.href = `${window.location.protocol}//${window.location.host}/conn/${encodeURIComponent(connectionId)}/?connId=${encodeURIComponent(remConnId)}`;
            a.target = `_blank`;

            a.click();
        }
        catch {
            // do nothing
        }

        setConnectingModal(false);
    });

    return (
        <>
            <Head title={t("webAccess.title")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                {t("webAccess.title")}
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <Trans
                                    i18nKey="computersManagement.computersFound"
                                    shouldUnescape
                                    values={{count: totalItems}}
                                />
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="card-title-group">
                                <div className="card-tools">
                                </div>

                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <a
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggleSearch();
                                                }}
                                                className="btn btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </a>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon btn-trigger toggle ${tableSm ? "active" : ""}`}
                                                    onClick={() => setTableSm(true)}
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tableSm ? "content-active" : ""}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => setTableSm(true)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="setting"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>Show</span>
                                                                        </li>
                                                                        {(isDevelopment()
                                                                            ? [1, 10, 15, 25, 50, 75, 100]
                                                                            : [10, 15, 25, 50, 75, 100]
                                                                        ).map((pageSize) => (
                                                                            <li
                                                                                key={pageSize}
                                                                                className={
                                                                                    itemsPerPage === pageSize
                                                                                        ? "active"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        changeItemsPerPage(pageSize);
                                                                                    }}
                                                                                >
                                                                                    {pageSize}
                                                                                </DropdownItem>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={`card-search search-wrap ${onSearch ? "active" : ""}`}>
                                <div className="card-body">
                                    <div className="search-content">
                                        <Button
                                            className="search-back btn-icon toggle-search active"
                                            onClick={clearNameSearchAndClose}
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            ref={nameSearchRef}
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    triggerFiltering();
                                                } else if (e.key === "Escape") {
                                                    clearNameSearchAndClose()
                                                }
                                            }}
                                        />
                                        <Button className="search-submit btn-icon" onClick={triggerFiltering}>
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DataTableBody compact>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="sub-text">
                                        <Trans i18nKey="computersManagement.attributes.name" />
                                    </span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <span className="sub-text">
                                        <Trans i18nKey="computersManagement.attributes.creationTime" />
                                    </span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <span className="sub-text">
                                        { /* TODO: translate */ }
                                        Online
                                    </span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-tools text-end unselectable-text">
                                    &nbsp;
                                </DataTableRow>
                            </DataTableHead>

                            {computers.map((computer) => {
                                return (
                                    <DataTableItem key={computer.id}>
                                        <DataTableRow size="md">
                                            <strong className="unselectable-text">{computer.name}</strong>
                                        </DataTableRow>
                                        <DataTableRow size="md">
                                            <span className="unselectable-text">{formatDate(computer.creationTime)}</span>
                                        </DataTableRow>
                                        <DataTableRow size="md">
                                            <span>
                                                <Icon
                                                    className={classNames({
                                                        "text-success": computer.online,
                                                        "text-danger": !computer.online,
                                                        "unselectable-text": true,
                                                    })}
                                                    name={`${computer.online ? "check-circle" : "cross-circle"}`}
                                                ></Icon>
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow className="nk-tb-col-tools">
                                            <ul className="nk-tb-actions gx-1">
                                                <li onClick={() => handleConnectComputer(computer.id)} style={{ cursor: !handleConnectComputerLoading ? `pointer` : undefined }}>
                                                    <FaIcon icon="display" />
                                                </li>
                                            </ul>
                                        </DataTableRow>
                                    </DataTableItem>
                                );
                            })}
                        </DataTableBody>
                        {(totalItems > itemsPerPage || (totalItems === itemsPerPage && page > 1)) && (
                            <div className="card-inner">
                                <PaginationComponent
                                    itemPerPage={itemsPerPage}
                                    totalItems={totalItems}
                                    paginate={(page: number) => changePage(page)}
                                    currentPage={page}
                                />
                            </div>
                        )}
                        {totalItems === 0 && (
                            <div className="card-inner">
                                <div className="text-center">
                                    <span className="text-silent">
                                        <Trans i18nKey="webAccess.noDataFound" />
                                    </span>
                                </div>
                            </div>
                        )}
                    </DataTable>

                    { isDevelopment() ? <FsSection /> : <></> }
                </Block>
            </Content>

            { /* TODO: translate */ }
            <Modal isOpen={connectingModal} className="modal-md" keyboard={false} backdrop="static">
                <ModalHeader>Connessione in corso...</ModalHeader>
                <ModalBody>
                    <Row className="gy-3 py-1">
                        <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }} className="d-flex flex-column center">
                            <p style={{textAlign:"center"}}><strong>Connessione al computer in corso...</strong></p>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

const Container = () => {
    const computersContext = useContext(ComputersContext);

    if (computersContext === undefined) {
        return <DefaultSpinner />;
    }

    return <Component computersContext={computersContext} />;
}

const ComputersSettingsPage = () => (
    <ComputersContextProvider>
        <Container />
    </ComputersContextProvider>
);

export default ComputersSettingsPage;



function FsSection(): React.ReactElement {
    const [currentDirId, setCurrentDirId] = useState<string | null>(null);
    const [currentDirPath, setCurrentDirPath] = useState<string>(`/`);
    const [currentPrevDirId, setCurrentPrevDirId] = useState<string | null>(null);

    const [storages] = useCronState(1000, [], async () => {
        const client = createApiClient({ tokenFromCookies: true });

        return unwrap(await client.fs.getStorages({})).items;
    });

    const [dirs, dirsTriggerMe] = useCronState(1000, [], async () => {
        const client = createApiClient({ tokenFromCookies: true });

        const result = unwrap(await client.fs.fsListDir({
            directoryId: currentDirId,
            orderBy: { field: `name`, order: `asc` },
        }));

        const path = `/` + [...result.path, { id: currentDirId, name: result.name }].map(e => e.name).join(`/`);
        setCurrentDirPath(path);

        setCurrentPrevDirId(result.parentDirectory?.id ?? null);

        return result.dirChildren;
    });

    const [files, filesTriggerMe] = useCronState(1000, [], async () => {
        const client = createApiClient({ tokenFromCookies: true });

        return unwrap(await client.fs.fsListDir({
            directoryId: currentDirId,
            orderBy: { field: `name`, order: `asc` },
        })).fileChildren;
    });

    const [handleAddStorage] = useHandler(async () => {
        const client = createApiClient({ tokenFromCookies: true });

        const { id: storageId } = unwrap(await client.fs.createStorage({
            name: `Storage 1`,
            endpoint: `http://127.0.0.1:8000`,
            accessKeyId: `accessKey1`,
            secretAccessKey: `verySecretKey1`,
            bucket: `bucket1`,
            region: `us-east-1`,
        }));

        const { id: homesDirId } = unwrap(await client.fs.fsCreateDir({
            name: `homes`,
            parentDirectory: null,
            storage: storageId,
        }));

        unwrap(await client.misc.changeSettings({
            defaultUsersHomeDirParent: homesDirId,
        }));

        unwrap(await client.fs.fsCreateHomeDir({
            users: `all`,
        }));
    });

    const [handleViewFile] = useHandler(async (fileId: string, checksum: string, sizeInBytes: number) => {
        const client = createApiClient({ tokenFromCookies: true });

        let arr: Uint8Array = new Uint8Array([]);

        for (let i = 0; i < sizeInBytes; i += 1) {
            const result = unwrap(await client.fs.fsDownloadFile({
                id: fileId,
                checksum,
                startOffset: i,
                sizeInBytes: 1,
            }));
    
            const u8arr = await base64ToU8Arr(result.chunkBase64);

            arr = new Uint8Array([...arr, ...u8arr]);
        }

        const decoded = (() => {
            const decoder = new TextDecoder();
            return decoder.decode(arr);
        })();

        alert(JSON.stringify(decoded));
    }, (error) => {
        console.error(`HANDLE VIEW FILE ERROR:`, error);
    });

    const [handleDeleteFile] = useHandler(async (fileId: string) => {
        const client = createApiClient({ tokenFromCookies: true });

        unwrap(await client.fs.fsDeleteFile({
            id: fileId,
        }));
    });

    const dirNameRef = useRef<HTMLInputElement | null>(null);

    const [handleAddDir] = useHandler(async () => {
        const client = createApiClient({ tokenFromCookies: true });

        if (currentDirId === null) {
            const storageId = storages.find(e => e.name === `Storage 1`)?.id ?? unreachable();

            await client.fs.fsCreateDir({
                name: dirNameRef.current?.value ?? ``,
                storage: storageId,
            });
        }
        else {
            await client.fs.fsCreateDir({
                name: dirNameRef.current?.value ?? ``,
                parentDirectory: currentDirId,
            });
        }
    });

    const fileNameRef = useRef<HTMLInputElement | null>(null);

    const [handleAddFile] = useHandler(async () => {
        const client = createApiClient({ tokenFromCookies: true });

        await client.fs.fsCreateFile({
            name: fileNameRef.current?.value ?? unreachable(),
            parentDirectory: currentDirId ?? unreachable(),
        });
    });

    const changeDir = (id: string | null) => {
        setCurrentDirId(id);
        dirsTriggerMe();
        filesTriggerMe();
    };

    const fileContentRef = useRef<HTMLInputElement | null>(null);

    const handleWriteFileRef = useRef(async (fileId: string) => {
        try {
            const client = createApiClient({ tokenFromCookies: true });

            const content = fileContentRef.current?.value ?? unreachable();

            const contentUtf8 = (() => {
                const encoder = new TextEncoder();
                return encoder.encode(content);
            })();

            const { fileUploadId } = unwrap(await client.fs.fsStartUploadFile({
                fileId,
                totalSizeInBytes: contentUtf8.byteLength,
            }));

            if (contentUtf8.byteLength === 0) {
                const result = unwrap(await client.fs.fsUploadFileChunk({
                    fileUploadId,
                    operation: `sendChunk`,
                    chunkId: 1,
                    chunkBase64: ``,
                }));

                console.log(`HANDLE WRITE FILE RESULT:`, result);

                return;
            }

            await sleep(5000);

            let lastChunkId = 0;

            for (const byte of contentUtf8) {
                const base64 = await u8arrToBase64(new Uint8Array([byte]));

                const result = unwrap(await client.fs.fsUploadFileChunk({
                    fileUploadId,
                    operation: `sendChunk`,
                    chunkId: lastChunkId + 1,
                    chunkBase64: base64,
                }));
        
                console.log(`HANDLE WRITE FILE RESULT:`, result);

                lastChunkId += 1;
            }
        }
        catch (error) {
            console.log(`HANDLE WRITE FILE ERROR:`, error);
        }
    });

    const handleWriteFile = handleWriteFileRef.current;

    const fileUploadRef = useRef<HTMLInputElement | null>(null);

    const [uploadInfoSize, setUploadInfoSize] = useState<number | null>(null);
    const [uploadInfoTime, setUploadInfoTime] = useState<number | null>(null);
    const [uploadInfoSpeed, setUploadInfoSpeed] = useState<number | null>(null);

    const [handleFileUpload] = useHandler(async () => {
        const client = createApiClient({ tokenFromCookies: true });
        const files = fileUploadRef.current?.files;

        if (files === null || files === undefined || files.length !== 1) {
            alert(`ERRORE: devi scegliere un file!`);
            return;
        }

        const file = files.item(0) ?? unreachable();

        const fileName = file.name;

        const startTime = performance.now();

        const { id: fileId } = unwrap(await client.fs.fsCreateFile({
            name: fileName,
            parentDirectory: currentDirId ?? unreachable(),
        }));

        const { fileUploadId } = unwrap(await client.fs.fsStartUploadFile({
            fileId,
            totalSizeInBytes: file.size,
        }));

        let lastChunkId = 0;

        while (true) {
            const blobEnd = (lastChunkId * 4096) + 4096;
            const blob = file.slice(lastChunkId * 4096, blobEnd > file.size ? file.size : blobEnd);

            const result = unwrap(await client.fs.fsUploadFileChunk({
                fileUploadId,
                chunkId: lastChunkId + 1,
                operation: `sendChunk`,
                chunkBase64: await u8arrToBase64(new Uint8Array(await blob.arrayBuffer())),
            }));

            if (result.fileUploadStatus !== `readyForMoreChunks`) {
                break;
            }

            lastChunkId += 1;
        }

        const endTime = performance.now();

        setUploadInfoSize(file.size);
        setUploadInfoTime(Math.floor((endTime - startTime) / 1000));
        setUploadInfoSpeed(parseFloat(((file.size * 8 / 1000 / 1000) / Math.floor((endTime - startTime) / 1000)).toFixed(2)));
    }, (error) => {
        console.log(`FILE UPLOAD ERROR:`, error);
    });

    const [handleSaveFile] = useHandler(async (fileId: string, checksum: string, sizeInBytes: number) => {
        const client = createApiClient({ tokenFromCookies: true });
        const showSaveFilePicker = (window as unknown as { showSaveFilePicker: () => Promise<FileSystemFileHandle> }).showSaveFilePicker;
        
        const handle = await showSaveFilePicker();

        const stream = await handle.createWritable();

        let writtenBytes = 0;

        while (writtenBytes < sizeInBytes) {
            const bytesToWrite = (sizeInBytes - writtenBytes <= 2048) ? (sizeInBytes - writtenBytes) : 2048;

            const result = unwrap(await client.fs.fsDownloadFile({
                id: fileId,
                checksum,
                startOffset: writtenBytes,
                sizeInBytes: bytesToWrite,
            }));

            const u8arr = await base64ToU8Arr(result.chunkBase64);

            await stream.write(u8arr);

            writtenBytes += bytesToWrite;
        }

        await stream.close();

        alert(`Download complete`);
    }, (error) => {
        console.log(`HANDLE SAVE FILE ERROR:`, error);
    });

    return <>
        <hr />

        <button onClick={handleAddStorage}>Add storage</button>

        <ul>
            {storages.map(e => <li key={e.id}>[{e.id}] {e.name} (online? {e.online ? `yes` : `no`})</li>)}
        </ul>

        <hr />

        <p>Current path: {currentDirPath}</p>

        <p><input ref={dirNameRef} type="text" placeholder="Directory name" /> <button onClick={handleAddDir}>Create dir</button></p>
        <p><input ref={fileNameRef} type="text" placeholder="File name" /> <button onClick={handleAddFile}>Create file</button></p>
        <p><input ref={fileContentRef} type="text" placeholder="File content" /></p>
        <p><input ref={fileUploadRef} type="file" multiple={false} /> <button onClick={handleFileUpload}>Upload File</button></p>
        <p>Uploaded size: {uploadInfoSize} bytes, Time elapsed: {uploadInfoTime} seconds, Speed: {uploadInfoSpeed} Mbit/s</p>

        <ul>
            <li><button onClick={() => { changeDir(currentPrevDirId); }}>..</button></li>

            {dirs.map((dir) => {
                return <li key={dir.id}>
                    [DIR] {dir.name} <button onClick={() => { changeDir(dir.id); }}>Open</button>
                </li>;
            })}

            {files.map((file) => {
                return <li key={file.id}>
                    [FILE] {file.name}
                    ({file.sizeInBytes} bytes)
                    (uploadInProgress? {file.uploadInProgress ? `yes` : `no`})
                    <button onClick={() => handleViewFile(file.id, file.checksum, file.sizeInBytes)}>View</button>
                    <button onClick={() => handleWriteFile(file.id)}>Write</button>
                    <button onClick={() => handleSaveFile(file.id, file.checksum, file.sizeInBytes)}>Download</button>
                    <button onClick={() => handleDeleteFile(file.id)}>Delete</button>
                </li>;
            })}
          </ul>
    </>;
}



async function u8arrToBase64(buffer: Uint8Array): Promise<string> {
    const base64url = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(new Blob([buffer]));
    });

    return base64url.slice(base64url.indexOf(`,`) + 1);
}



async function base64ToU8Arr(base64: string): Promise<Uint8Array> {
    const arrayBuffer = await (await fetch(`data:application/octet;base64,` + base64)).arrayBuffer();
    return new Uint8Array(arrayBuffer);
}
