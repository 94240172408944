
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { Link } from "react-router-dom";
import l from "../../../../utils/Log";
import { Trans } from "react-i18next";
import { useCron, useCronState } from "../../../../utils/CronJob";
import { NotificationsService } from "../../../../services";
import { ChatItemHeader } from "../../../../pages/app/chat/ChatPartials";
// import { NotificationItem } from "@trantor/vdesk-api-schemas/dist/notifications/getNotifications";
import { GetNotificationsOutput } from "@trantor/vdesk-api-client/dist/clients/notifications";
import FaIcon from "../../../../components/icon/FaIcon";
import { useDelayedLoading, useHandler } from "../../../../utils/handler";
import { createApiClient } from "../../../../utils/createApiClient";
import { unwrap } from "../../../../services/MainService";
import { unreachable } from "../../../../utils/unreachable";
import ringMp3 from "../../../../assets/ring.mp3";
import noiseMp3 from "../../../../assets/noise.mp3";
import { playSound } from "../../../../utils/playSound";

type NotificationItem = GetNotificationsOutput["items"][number];

interface ChatDropdownProps {
    isMobile?: boolean;
    sidebarToggle?: () => void;
}

type RingModalData = {
    callId: string,
    shortCallId: string,
    startedByDisplayName: string,
    linkedChatRoomName: string | null,
    canLeaveOthers: boolean,
};

const ChatDropdown: React.FC<ChatDropdownProps> = ({ isMobile, sidebarToggle }) => {
    useCron(1000, () => {
        playSound(noiseMp3, { volume: 0.1, stopAfterMs: 1000 });
    });

    const currentTabIdRef = useRef(``);

    useCron(1000, () => {
        if (currentTabIdRef.current === ``) {
            currentTabIdRef.current = Math.random().toString().slice(2).padEnd(22, `0`).slice(0, 22);
        }

        const currentTabId = currentTabIdRef.current;

        window.localStorage.setItem(`trantor.vdesk.tabid.${currentTabId}`, Date.now().toString());
    });

    useCron(2000, () => {
        const tabs = Object.keys(window.localStorage)
            .filter(e => e.startsWith(`trantor.vdesk.tabid.`))
            .map(e => {
                const value = window.localStorage.getItem(e) ?? `0`;
                const lastKeepalive = parseInt(value, 10);
                return {
                    id: e.replace(`trantor.vdesk.tabid.`, ``),
                    lastKeepalive,
                };
            })
            .filter(e => Math.abs(Date.now() - e.lastKeepalive) > 3000);

        for (const tab of tabs) {
            window.localStorage.removeItem(`trantor.vdesk.tabid.${tab.id}`);
        }
    });



    const myUserIdRef = useRef<string | null>(null);

    const ringModalCallToDismissRef = useRef<{ callId: string, rejected: boolean } | null>(null);

    const [ringModal, ringModalTrigger] = useCronState<RingModalData | null>(1000, null, async () => {
        const client = createApiClient({ tokenFromCookies: true });

        if (ringModalCallToDismissRef.current !== null) {
            try {
                unwrap(await client.calls.dismissCallInvite({
                    callId: ringModalCallToDismissRef.current.callId,
                    rejected: ringModalCallToDismissRef.current.rejected,
                }));
            }
            catch {
                // do nothing
            }

            ringModalCallToDismissRef.current = null;
            return null;
        }

        const response = unwrap(await client.calls.getMyCallInvites({}));

        const myUserId = await (async () => {
            if (myUserIdRef.current !== null) {
                return myUserIdRef.current;
            }

            const myself = unwrap(await client.auth.myself());

            myUserIdRef.current = myself.userId;

            return myself.userId;
        })();

        const invite = response.calls.filter(e => e.dismissed === false && e.startedAt !== null && e.startedBy.id !== myUserId && e.joined !== true).at(-1);

        if (invite === undefined) {
            return null;
        }

        const tabs = Object.keys(window.localStorage)
            .filter(e => e.startsWith(`trantor.vdesk.tabid.`))
            .map(e => {
                const value = window.localStorage.getItem(e) ?? `0`;
                const lastKeepalive = parseInt(value, 10);
                return {
                    id: e.replace(`trantor.vdesk.tabid.`, ``),
                    lastKeepalive,
                };
            })
            .filter(e => Math.abs(Date.now() - e.lastKeepalive) <= 3000);

        tabs.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);

        const tabIdToPlay = tabs.at(0)?.id;

        const myTabId = currentTabIdRef.current;

        if (tabIdToPlay !== undefined && myTabId !== `` && tabIdToPlay === myTabId) {
            playSound(ringMp3, { volume: 0.5 });
        }

        const joinedOthers = response.calls.some(e => e.joined);

        return {
            callId: invite.callId,
            shortCallId: invite.shortCallId,
            startedByDisplayName: invite.startedBy.displayName,
            linkedChatRoomName: invite.linkedChatRoom?.name ?? null,
            canLeaveOthers: joinedOthers,
        };
    });

    const handleRingAnswer = (leaveOthers: boolean): void => {
        ringModalCallToDismissRef.current = {
            callId: ringModal?.callId ?? unreachable(),
            rejected: false,
        };

        ringModalTrigger();

        const url = `/call/${encodeURIComponent(ringModal?.shortCallId ?? unreachable())}/setup`;

        window.open(url + (leaveOthers ? `?leaveOthers=true` : ``), `_blank`);
    };

    const handleRingIgnore = (): void => {
        ringModalCallToDismissRef.current = {
            callId: ringModal?.callId ?? unreachable(),
            rejected: true,
        };

        ringModalTrigger();
    };



    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!open);
    };
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [notifications, triggerGetNotifications, , manualLoading] = useCronState<{
        totalItems: number,
        items: NotificationItem[],
    }>(
        500,
        {
            totalItems: 0,
            items: [],
        },
        async () => {
            try {
                const resp = await NotificationsService.getNotifications({
                    page: 1,
                    itemsPerPage: 10,
                    orderBy: {
                        field: "creationTime",
                        order: "asc",
                    },
                    filters: [
                        {
                            field: "read",
                            op: "eq",
                            value: false,
                        },
                    ],
                });
                const items = resp?.items ?? [];
                const totalItems = resp?.totalItems ?? 0;
                return {
                    totalItems,
                    items: items.reverse(),
                };
            } catch (e) {
                l.error("Error in Notifications poller: ", e);
                return { totalItems: 0, items: [] };
            } finally {
                setFirstLoading(false);
            }
        },
        () => {
            console.log(`onStop called`);
        }
    );
    const manualLoadingOrFirstLoading = useMemo(() => {
        return manualLoading || firstLoading;
    }, [firstLoading, manualLoading]);
    const showManualLoadingSpinner = useDelayedLoading(manualLoadingOrFirstLoading, 100, 600);

    // const handleMarkAllAsRead = async () => {
    //     try {
    //         await NotificationsService.markNotificationsAsRead({ notificationsIds: notifications.map((n) => n.id) });
    //         triggerGetNotifications();
    //     } catch (e) {
    //         l.error("Error in Notifications mark all as read: ", e);
    //     }
    // };
    const [handleMarkAllAsRead] = useHandler(
        async () => {
            await NotificationsService.markNotificationsAsRead({
                notificationsIds: notifications.items.map((n) => n.id),
            });
            triggerGetNotifications();
        },
        (e) => l.error("Error in Notifications mark all as read: ", e)
    );
    const handleMarkAsRead = async (notification: NotificationItem) => {
        try {
            await NotificationsService.markNotificationsAsRead({ notificationsIds: [notification.id] });
            triggerGetNotifications();
        } catch (e) {
            l.error("Error in marking as read one notification: ", JSON.stringify(e));
        }
    };
    const renderBody = () => {
        return <>
            <div className="dropdown-head">
                <span className="sub-title nk-dropdown-title">
                    <Trans i18nKey="dropdown.chat.recentConvos" />
                </span>
                {/* <Link to={P.getPath("user-profile-setting", true)}>
                    <Trans i18nKey="dropdown.chat.settings" />
                </Link> */}
            </div>
            <div className="dropdown-body">
                <ul className="chat-list">
                    {notifications.items.length === 0 && (
                        <div className="chat-link unclickable-text unselectable-text center">
                            <div className="text">
                                <Trans i18nKey="dropdown.chat.noNotifications" />
                            </div>
                        </div>
                    )}
                    {notifications.items.map((item, i) => {
                        return (
                            <ChatItemHeader
                                key={i}
                                item={item}
                                onItemClose={async () => {
                                    await handleMarkAsRead(item);
                                }}
                            />
                        );
                    })}
                    {notifications.totalItems - 10 > 0 && (
                        <div className="chat-link unclickable-text unselectable-text center">
                            <div className="chat-context">
                                <Trans
                                    i18nKey="dropdown.chat.moreNotifications"
                                    shouldUnescape
                                    values={{ count: notifications.totalItems - 10 }}
                                />
                            </div>
                        </div>
                    )}
                </ul>
            </div>
            <div className="dropdown-foot border-bottom-0">
                {/* <Link to={`${process.env.PUBLIC_URL}/chat`} onClick={(ev) => {
                    ev.preventDefault(); //TODO: Remove this linne and add routing to Notifications page
                    //isMobile === true && sidebarToggle !== undefined && sidebarToggle();
                }}>
                    <Trans i18nKey="dropdown.chat.viewAll" />
                </Link> */}
                &nbsp;
                <Link
                    to={`#markAllAsRead`}
                    onClick={async (e) => {
                        e.preventDefault();
                        await handleMarkAllAsRead();
                    }}
                >
                    <Trans i18nKey="dropdown.chat.markAsRead" />
                </Link>
            </div>
            {showManualLoadingSpinner && (
                <div className="chat-loader-overlay">
                    <FaIcon className="spinner" icon="spinner" size="3x" pulse />
                </div>
            )}
        </>
    }
    return <>
        <Dropdown isOpen={open} toggle={toggle}>
            <DropdownToggle
                tag="a"
                href="#dropdown"
                onClick={(ev) => ev.preventDefault()}
                className="nk-quick-nav-icon"
            >
                <div className="icon-status icon-status-na">
                    <Icon name="bell"></Icon>
                    {/* <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span>{" "} */}
                    {notifications && notifications.items.length > 0 && (
                        <div className={`status dot dot-xl dot-gray`}>
                            <span>{notifications.totalItems <= 10 ? notifications.items.length : `10+`}</span>
                        </div>
                    )}
                </div>
            </DropdownToggle>
            {isMobile !== true ?
                <DropdownMenu end className="dropdown-menu-xl">
                    {renderBody()}
                </DropdownMenu>
                :
                <Modal
                    isOpen={open}
                    size="xl"
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                >
                    {renderBody()}
                </Modal>
            }
        </Dropdown>

        <Modal isOpen={ringModal !== null} className="modal-md" keyboard={false} backdrop="static">
            <ModalHeader>Chiamata in arrivo...</ModalHeader>
            <ModalBody>
                <Row className="gy-3 py-1">
                    <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                        {
                            ringModal?.linkedChatRoomName === null ?
                                <h4>{ringModal?.startedByDisplayName ?? ``} ti sta chiamando!</h4> :
                                <h4>{ringModal?.startedByDisplayName ?? ``} ha iniziato una chiamata nel gruppo {ringModal?.linkedChatRoomName ?? ``}!</h4>
                        }
                    </Col>
                </Row>
                <Row className="gy-3 py-3">
                    <Col size="12">
                        <ul className="align-center justify-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                                <Button
                                    color="primary"
                                    size="lg"
                                    onClick={() => handleRingAnswer(false)}
                                >
                                    {ringModal?.linkedChatRoomName === null ? `Rispondi` : `Partecipa`}
                                </Button>
                            </li>
                            {
                                ringModal?.canLeaveOthers === true ? <li>
                                    <li>
                                        <Button
                                            color="primary"
                                            size="lg"
                                            onClick={() => handleRingAnswer(true)}
                                        >
                                            {ringModal?.linkedChatRoomName === null ? `Rispondi` : `Partecipa`}, ed esci dalle chiamate in corso
                                        </Button>
                                    </li>
                                </li> : <></>
                            }
                            <li>
                                <Button
                                    color="danger"
                                    size="lg"
                                    onClick={handleRingIgnore}
                                >
                                    Ignora
                                </Button>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    </>;
};

export default ChatDropdown;

// const SubComp = () => {
//     const [counter, triggerMe, loading] = useCronState(
//         1000,
//         0,
//         async (prev) => {
//             await sleep(100);
//             return prev + 1;
//         },
//         () => {
//             console.log(`onStop called`);
//         }
//     );

//     const timer = useTimer(250);

//     useEffect(() => {
//         if (counter % 2 === 0) {
//             l.log(`Counter is even (${counter})`);
//         }
//     }, [counter]);

//     useCron(2000, () => {
//         console.log(`YAY`);
//     });

//     const [page, setPage, getPage] = useSignal(1);

//     const [users /* , triggerMe, loading */] = useCronState<string[]>(1000, [], async () => {
//         return await backendGetUsers({ page: getPage() });
//     });

//     useEffect(() => {
//         console.log(`Page has changed: ${page}`);
//     }, [page]);

//     return (
//         <>
//             <button
//                 onClick={() => {
//                     setPage((p) => p + 1);
//                 }}
//             >
//                 Next Page
//             </button>
//             <br />
//             <span>Current page: {page}</span>
//             <br />
//             <span>Users: {users.join(`, `)}</span>
//             <br />
//             <span>Date and time: {new Date(timer).toLocaleString()}</span>
//             <br />
//             <button
//                 onClick={() => {
//                     triggerMe();
//                 }}
//             >
//                 Trigger Me
//             </button>
//             <br />
//             <span>{loading ? `Loading` : ``}</span>
//             <br />
//             <span>Counter: {counter}</span>
//         </>
//     );
// };

// async function backendGetUsers({ page }: { page: number }): Promise<string[]> {
//     return await Promise.resolve(`abc`.repeat(page).split(``));
// }
