import { z } from "zod";
export const FsFileInfoParamsSchema = z.object({
    id: z.string().uuid(),
});
export const FsFileInfoOutputSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1),
    creationTime: z.number().int(),
    storage: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
    }),
    path: z.object({ id: z.string().uuid(), name: z.string().min(1) }).array(),
    perms: z.object({
        type: z.enum([`read-only`, `read-write`]),
        targetUser: z.object({ id: z.string().uuid(), username: z.string().min(1), displayName: z.string().min(1) }).nullable(),
        targetGroup: z.object({ id: z.string().uuid(), name: z.string().min(1) }).nullable(),
    }).array(),
    sizeInBytes: z.number().int().min(0),
    checksum: z.string().min(1),
    initialUpload: z.boolean(),
    uploadInProgress: z.boolean(),
    linkedChat: z.union([
        z.object({
            type: z.literal(`p2p`),
            id: z.string().uuid(),
            name: z.null(),
            peers: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }).array().length(2),
        }),
        z.object({
            type: z.literal(`group`),
            id: z.string().uuid(),
            name: z.string().min(1),
        }),
    ]).nullable(),
    userOwner: z.object({
        id: z.string().uuid(),
        username: z.string().min(1),
        displayName: z.string().min(1),
    }),
});
