import { createContext, useState } from "react";
import { ComputersService } from "../../services";
import { ComputersContextType } from "./ComputersContextTypes";
import { useCronState } from "../../utils/CronJob";
import { CreateComputerOutput, CreateComputerParams, EditComputerParams, GetComputersParams } from "@trantor/vdesk-api-client/dist/clients/computers";

export const ComputersContext = createContext<ComputersContextType>(undefined);

const INITIAL_GET_COMPUTERS_PARAMS: GetComputersParams = {
    page: 1,
    itemsPerPage: 10,
    orderBy: {
        field: "creationTime",
        order: "desc",
    },
} as const;

const UPDATE_INTERVAL_MILLIS = 5000;

interface ComputersContextProviderProps {
    children?: React.ReactNode;
}
export const ComputersContextProvider: React.FC<ComputersContextProviderProps> = (props) => {
    const [queryParams, setQueryParams] = useState(INITIAL_GET_COMPUTERS_PARAMS);
    const [data, refetchComputers] = useCronState(UPDATE_INTERVAL_MILLIS, undefined, () => ComputersService.getComputers(queryParams));

    async function createComputer(data: CreateComputerParams): Promise<CreateComputerOutput> {
        const { id } = await ComputersService.createComputer(data);
        refetchComputers();
        return { id };
    }

    async function updateComputer(params: EditComputerParams): Promise<void> {
        await ComputersService.editComputer(params);
        refetchComputers();
    }

    async function deleteComputer(id: string): Promise<void> {
        await ComputersService.deleteComputer({ id });
        refetchComputers();
    }

    const changePage = (page: number): void => {
        setQueryParams((params) => ({
            ...params,
            page,
        }));
        refetchComputers();
    };

    const changeItemsPerPage = (itemsPerPage: number): void => {
        setQueryParams((params) => ({
            ...params,
            itemsPerPage,
            page: 1, // Added to avoid asking for a page that doesn't exist and to align pagination behaviour
        }));
        refetchComputers();
    };

    const changeFilters = (filters: GetComputersParams["filters"]): void => {
        setQueryParams((params) => ({
            ...params,
            filters,
            page: 1, // Added to avoid asking for a page that doesn't exist and to align pagination behaviour
        }));
        refetchComputers();
    }

    const contextValue: ComputersContextType = data !== undefined ? {
        state: {
            computers: data.items,
            page: data.page,
            itemsPerPage: data.itemsPerPage,
            totalItems: data.totalItems,
            totalPages: data.totalPages,
        },
        changePage,
        changeFilters,
        changeItemsPerPage,
        createComputer,
        updateComputer,
        deleteComputer,
        refetchComputers,
        params: queryParams,
    } : undefined;

    return (
        <ComputersContext.Provider value={contextValue}>
            {props.children}
        </ComputersContext.Provider>
    );
};
