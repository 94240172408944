import { z } from "zod";
import { EnumOpSchema, NumberOpSchema } from "../../filters.js";
export const ChatArchiveMessagesConditionSchema = z.union([
    z.object({
        field: z.literal("id"),
        op: EnumOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("chatArchiveId"),
        op: EnumOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("senderId"),
        op: EnumOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("creationTime"),
        op: NumberOpSchema,
        value: z.number().int()
    }),
]);
export const ChatArchiveMessagesFiltersSchema = z.array(ChatArchiveMessagesConditionSchema.or(z.array(ChatArchiveMessagesConditionSchema)));
export const ChatArchiveMessagesOrderBySchema = z.object({
    field: z.enum(["creationTime"]),
    order: z.enum(["asc", "desc"])
});
export const GetChatArchiveMessagesParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: ChatArchiveMessagesOrderBySchema.optional().default({ field: "creationTime", order: "asc" }),
    filters: ChatArchiveMessagesFiltersSchema.optional().default([])
});
export const ChatArchiveMessageItemSchema = z.object({
    id: z.string().uuid(),
    chatArchiveId: z.string().uuid(),
    sender: z.object({
        id: z.string().uuid(),
        displayName: z.string().min(1),
        username: z.string().min(1),
    }).nullable(),
    content: z.union([
        z.object({
            type: z.literal(`text`),
            text: z.string().min(1),
            file: z.null(),
        }),
        z.object({
            type: z.literal(`file`),
            text: z.null(),
            file: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
                sizeInBytes: z.number().int().min(0),
                checksum: z.string().min(1),
            }).nullable(),
        }),
    ]),
    creationTime: z.number().int(),
});
export const GetChatArchiveMessagesOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: ChatArchiveMessageItemSchema.array(),
});
