import { z } from "zod";
export const SearchChatsParamsSchema = z.object({
    searchText: z.string().min(1),
});
export const SearchChatsResultSchema = z.union([
    z.object({
        isGroupChat: z.literal(false),
        isArchive: z.literal(false),
        name: z.string().min(1),
        chatId: z.string().uuid(),
    }),
    z.object({
        isGroupChat: z.literal(false),
        isArchive: z.literal(false),
        name: z.string().min(1),
        chatId: z.literal(null),
        userId: z.string().uuid(),
    }),
    z.object({
        isGroupChat: z.literal(false),
        isArchive: z.literal(true),
        name: z.string().min(1),
        chatId: z.string().uuid(),
        creationTime: z.number().int(),
    }),
    z.object({
        isGroupChat: z.literal(true),
        isArchive: z.literal(false),
        name: z.string().min(1),
        chatId: z.string().uuid(),
    }),
    z.object({
        isGroupChat: z.literal(true),
        isArchive: z.literal(true),
        name: z.string().min(1),
        chatId: z.string().uuid(),
        creationTime: z.number().int(),
    }),
]);
export const SearchChatsOutputSchema = z.object({
    items: SearchChatsResultSchema.array(),
});
