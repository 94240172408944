import { z } from "zod";
import { EnumOpSchema, NumberOpSchema } from "./filters.js";
import { AuditLogBodySchema } from "./audit.js";
export const LogsConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string().uuid(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
    z.object({
        field: z.literal(`fromUserId`),
        op: EnumOpSchema,
        value: z.string().uuid(),
    }),
    z.object({
        field: z.literal(`kind`),
        op: EnumOpSchema,
        value: z.string().min(1),
    }),
]);
export const LogsFiltersSchema = z.union([
    LogsConditionSchema,
    LogsConditionSchema.array(),
]).array();
export const LogsOrderBySchema = z.object({
    field: z.enum([`creationTime`]),
    order: z.enum([`asc`, `desc`]),
});
export const GetLogsParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: LogsOrderBySchema.optional().default({ field: `creationTime`, order: `desc` }),
    filters: LogsFiltersSchema.optional().default([]),
});
export const LogItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    fromUser: z.object({
        id: z.string().uuid().nullable(),
        username: z.string().min(1),
        displayName: z.string().min(1).nullable(),
    }).nullable(),
    remoteIP: z.string().min(1).nullable(),
    remoteUA: z.string().min(1).nullable(),
    body: AuditLogBodySchema,
});
export const GetLogsOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: LogItemSchema.array(),
});
