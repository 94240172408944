
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Icon from "../../../../components/icon/Icon";
import l from "../../../../utils/Log";
import { ComputersSelector, GroupsSelector, UsersSelector } from "../MultiSelectDropdown";
import { AccessRulesService } from "../../../../services";
import { ItemOfApi } from "../../../../components/ApiTable";
import { EditAccessRuleParams } from "@trantor/vdesk-api-schemas/dist/accessRules/editAccessRule";
import { AccessRuleItem } from "@trantor/vdesk-api-schemas/dist/accessRules/getAccessRules";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};
type EditAccessRuleFormInputs = {
    mode: string;
    reValidateMode: string;
    id: string;
    name: string;
    users: string[];
    userGroups: string[];
    computers: string[];
    computerGroups: string[];
};
const getAccessRules = AccessRulesService.getAccessRules.bind(AccessRulesService);
type GetAccessRulesOutput = ItemOfApi<typeof getAccessRules>;
interface EditAccessRuleModalProps {
    isOpen: boolean;
    onClose: () => void;
    accessRules: GetAccessRulesOutput | null;
}

const getIds = (data: { id: string }[] | undefined): string[] => (data ?? []).map((item) => item.id);
const getGroupIds = (data: { groupId: string }[] | undefined): string[] => (data ?? []).map((item) => item.groupId);

const toFormState = (data: AccessRuleItem | undefined): EditAccessRuleFormInputs => ({
    mode: "onSubmit",
    reValidateMode: "onChange",
    
    id: data?.id ?? "",
    name: data?.name ?? "",
    users: getIds(data?.users),
    userGroups: getGroupIds(data?.userGroups),
    computers: getIds(data?.computers),
    computerGroups: getGroupIds(data?.computerGroups),
});
const EditAccessRuleModal: React.FC<EditAccessRuleModalProps> = ({ isOpen, onClose, accessRules }) => {
    const { t } = useTranslation();
    const editAccessRule = AccessRulesService.editAccessRule.bind(AccessRulesService);
    const prevAccessRules = useRef<AccessRuleItem | null>(null);
    const myData = useMemo(() => {
        if (accessRules !== null) {
            prevAccessRules.current = accessRules;
            return accessRules;
        }
        return prevAccessRules.current;
    }, [accessRules])

    const editAccessRuleForm = useForm<EditAccessRuleFormInputs>({
        defaultValues: toFormState(myData ?? undefined),
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    });

    // const resetForm = useCallback((): void => {
    //     setFormData({
    //         name: "",
    //         users: [],
    //         userGroups: [],
    //         computers: [],
    //         computerGroups: [],
    //     });
    //     editAccessRuleForm.setValue("name", "");
    //     editAccessRuleForm.setValue("users", []);
    //     editAccessRuleForm.setValue("userGroups", []);
    //     editAccessRuleForm.setValue("computers", []);
    //     editAccessRuleForm.setValue("computerGroups", []);
    // }, [editAccessRuleForm]);
    useEffect(() => {
        if (myData === undefined || myData?.id === editAccessRuleForm.getValues("id")) {
            return;
        }

        editAccessRuleForm.reset(toFormState(myData ?? undefined));
    }, [editAccessRuleForm, myData]);

    const onFormNewSubmit: SubmitHandler<EditAccessRuleFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            console.log("submitData", submitData);
            const editedAccessRules: EditAccessRuleParams = {
                id: submitData.id,
                name: submitData.name,
                users: submitData.users,
                userGroups: submitData.userGroups,
                computers: submitData.computers,
                computerGroups: submitData.computerGroups,
            }
            await editAccessRule(editedAccessRules);

            l.info("access rule edited");
            onClose();
        } catch (err) {
            l.error("failed to create access rule", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            onClose();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    onClose();
                }}
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="accessRulesManagement.editModal.title" />
                </h5>
                <div className="mt-4">
                    <Form className="row gy-4" onSubmit={editAccessRuleForm.handleSubmit(onFormNewSubmit)}>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.name}
                                    placeholder={t("accessRulesManagement.attributes.namePlaceholder").toString()}
                                    {...editAccessRuleForm.register("name", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.users" />
                                </label>
                                <UsersSelector
                                    selectedData={getIds(myData?.users)}
                                    onChange={(selected) => {
                                        editAccessRuleForm.setValue("users", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.userGroups" />
                                </label>
                                <GroupsSelector
                                    selectedData={getGroupIds(myData?.userGroups)}
                                    onChange={(selected) => {
                                        editAccessRuleForm.setValue("userGroups", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.computers" />
                                </label>
                                <ComputersSelector
                                    selectedData={getIds(myData?.computers)}
                                    onChange={(selected) => {
                                        editAccessRuleForm.setValue("computers", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.computerGroups" />
                                </label>
                                <GroupsSelector
                                    selectedData={getGroupIds(myData?.computerGroups)}
                                    onChange={(selected) => {
                                        editAccessRuleForm.setValue("computerGroups", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button disabled={disableModal} color="primary" size="md" type="submit">
                                            <Trans i18nKey="accessRulesManagement.editModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    onClose();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="accessRulesManagement.editModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default EditAccessRuleModal;
