import { z } from "zod";
import { JSONObjectSchema } from "../json.js";
export const CallKeepaliveParamsSchema = z.object({
    peerToken: z.string().min(1),
    videoOn: z.boolean(),
    audioOn: z.boolean(),
});
export const CallKeepaliveOutputSchema = z.object({
    callId: z.string().uuid(),
    shortCallId: z.string().min(1),
    startTimestamp: z.number().int(),
    youAreModerator: z.boolean(),
    youAreMuted: z.boolean(),
    youHaveHandRaised: z.boolean(),
    members: z.object({
        peerId: z.string().uuid(),
        userInfo: z.object({
            id: z.string().uuid(),
            username: z.string().min(1),
        }).nullable(),
        displayName: z.string().min(1),
        moderator: z.boolean(),
        muted: z.boolean(),
        handRaised: z.boolean(),
        isWriting: z.boolean(),
        videoOn: z.boolean(),
        audioOn: z.boolean(),
    }).array(),
    receivedRtcDescriptions: z.object({
        peerId: z.string().uuid(),
        rtcDescription: JSONObjectSchema,
    }).array(),
    receivedIceCandidates: z.object({
        peerId: z.string().uuid(),
        iceCandidate: JSONObjectSchema,
    }).array(),
    chatMessages: z.object({
        id: z.string().uuid(),
        from: z.object({
            peerId: z.string().uuid().nullable(),
            displayName: z.string().min(1),
        }),
        timestamp: z.number().int(),
        text: z.string(),
    }).array(),
    invitedUsers: z.object({
        id: z.string().uuid(),
        username: z.string().min(1),
        displayName: z.string().min(1),
        moderator: z.boolean(),
        dismissed: z.union([z.literal(false), z.literal(`accepted`), z.literal(`rejected`)]),
    }).array(),
    openToGuests: z.boolean(),
});
