import { z } from "zod";
export const CreateAccessRuleParamsSchema = z.object({
    name: z.string().min(1),
    users: z.string().uuid().array().optional().default([]),
    userGroups: z.string().uuid().array().optional().default([]),
    computers: z.string().uuid().array().optional().default([]),
    computerGroups: z.string().uuid().array().optional().default([]),
});
export const CreateAccessRuleOutputSchema = z.object({
    id: z.string().uuid(),
});
