import MainService from "./MainService";

import {
    DeleteChatArchiveOutput,
    DeleteChatArchiveParams,
    GetChatArchiveMessagesOutput,
    GetChatArchiveMessagesParams,
    GetChatArchivesOutput,
    GetChatArchivesParams
} from "@trantor/vdesk-api-client/dist/clients/chatArchive";

class CS {
    private client = MainService.client.chatArchive;

    async getArchivedChats(params: GetChatArchivesParams): Promise<GetChatArchivesOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async deleteArchivedChat(params: DeleteChatArchiveParams): Promise<DeleteChatArchiveOutput> {
        return MainService.call(() => this.client.delete(params));
    }

    async getArchivedMessages(params: GetChatArchiveMessagesParams): Promise<GetChatArchiveMessagesOutput> {
        return MainService.call(() => this.client.getMessages(params));
    }
}
const ChatsArchiveService = new CS();
export default ChatsArchiveService;
