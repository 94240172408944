
export function getUniqueSearchParam(paramName: string): string | null {
    const searchParams = new URLSearchParams(window.location.search);

    const values = searchParams.getAll(paramName);

    if (values.length !== 1) {
        return null;
    }

    const value = values[0];

    if (value === undefined || value.length === 0) {
        return null;
    }

    return value;
}
