import MainService from "./MainService";

import {
    GetNotificationsParams,
    GetNotificationsOutput,
    MarkNotificationAsReadParams
} from "@trantor/vdesk-api-client/dist/clients/notifications";


class NS {
    private client = MainService.client.notifications;

    async getNotifications(params: GetNotificationsParams): Promise<GetNotificationsOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async markNotificationsAsRead(params: MarkNotificationAsReadParams): Promise<void> {
        return MainService.call(() => this.client.markAsRead(params));
    }
}
const NotificationsService = new NS();
export default NotificationsService;
