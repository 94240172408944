import { z } from "zod";
export const CreateStorageParamsSchema = z.object({
    name: z.string().min(1),
    endpoint: z.string().min(1),
    accessKeyId: z.string().min(1),
    secretAccessKey: z.string().min(1),
    bucket: z.string().min(1),
    region: z.string().min(1),
});
export const CreateStorageOutputSchema = z.object({
    id: z.string().uuid(),
});
