import { z } from "zod";
export const CreateGroupParamsSchema = z.object({
    name: z.string().min(1),
    memberUsers: z.string().uuid().array().optional().default([]),
    memberGroups: z.string().uuid().array().optional().default([]),
    memberComputers: z.string().uuid().array().optional().default([]),
    memberOf: z.string().uuid().array().optional().default([]),
});
export const CreateGroupOutputSchema = z.object({
    id: z.string().uuid(),
});
