import { GetUsersParams } from "@trantor/vdesk-api-client/dist/clients/user";
import React, { useState, createContext, useCallback, useEffect } from "react";
import { UsersService } from "../../services";
import { SessionDataJSON, UserData } from "../../utils/GeneralTypes";
import l from "../../utils/Log";
import { deserializeUsers, serializeUserForCreate, useIntervalAsync } from "../../utils/Utils";
import { UserContextType, UserSubmit, UsersState } from "./UserTypes";
import { getCookie } from "typescript-cookie";

const UPDATE_INTERVAL_MILLIS = 5000;

export const UserContext = createContext({} as UserContextType);

interface UserContextProviderProps {
    children?: React.ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = (props) => {
    const cookie = getCookie("sessionData");
    const sessionData: SessionDataJSON = cookie ? JSON.parse(cookie) : {};

    const [loggedIn, setLoggedIn] = useState<UserData>(sessionData.loggedUser);
    const [getUsersParams /* setGetUsersParams */] = useState<GetUsersParams>({});
    const [usersState, setUsersState] = useState<UsersState>({
        users: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: getUsersParams.itemsPerPage ?? 10,
        page: getUsersParams.page ?? 1,
    });

    const updateUsersState = useCallback(() => {
        return Promise.resolve(
            UsersService.getUsers(getUsersParams)
                .then((d) => {
                    setUsersState((prev) => ({
                        ...prev,
                        users: deserializeUsers(d.items),
                        totalItems: d.totalItems,
                        totalPages: d.totalPages,
                    }));
                })
                .catch((e) => {
                    l.error(e);
                })
        );
    }, [getUsersParams]);

    const [updateUsers, destroyUpdateUsers] = useIntervalAsync(updateUsersState, UPDATE_INTERVAL_MILLIS);

    useEffect(() => {
        return destroyUpdateUsers;
    }, [destroyUpdateUsers]);

    async function createUser(user: UserSubmit): Promise<{ id: string }> {
        const { id } = await UsersService.createUser(serializeUserForCreate(user));
        await updateUsers();
        return { id };
    }

    return (
        <UserContext.Provider
            value={{
                state: usersState,
                updateUsers,
                createUser,
                loggedInUser: [loggedIn, setLoggedIn],
                params: getUsersParams,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
