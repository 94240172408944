import { z } from "zod";
import { EmptyOutputSchema } from "../common.js";
export const EditChatParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    participants: z.object({
        userId: z.string().uuid(),
        moderator: z.boolean(),
    }).array().optional(),
});
export const EditChatOutputSchema = EmptyOutputSchema;
