import { z } from "zod";
import { UserRoleSchema } from "../common.js";
export const MyselfParamsSchema = z.record(z.string(), z.never());
export const MyselfOutputSchema = z.object({
    userId: z.string().uuid(),
    username: z.string().min(1),
    displayName: z.string().min(1),
    role: UserRoleSchema,
    tokenExpiration: z.number().int(),
    otp: z.enum([`enabledAndConfigured`, `enabledButNotYetConfigured`, `disabled`]),
    memberOf: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    ldapInfo: z.object({
        ldapId: z.string().min(1),
        ldapDn: z.string().min(1),
    }).nullable(),
    homeDir: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        path: z.object({ id: z.string().uuid(), name: z.string().min(1) }).array(),
    }).nullable(),
    storageQuotaInBytes: z.object({
        available: z.number().int().min(0),
        used: z.number().int().min(0),
    }),
});
