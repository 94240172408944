import { z } from "zod";
export const FsCreateFileParamsSchema = z.object({
    name: z.string().min(1),
    parentDirectory: z.string().uuid(),
    perms: z.object({
        type: z.enum([`read-only`, `read-write`]),
        targetUser: z.string().uuid().nullable(),
        targetGroup: z.string().uuid().nullable(),
    }).array().optional().default([]),
    linkedChat: z.string().uuid().nullable().optional().default(null),
    userOwner: z.string().uuid().optional(),
    startUpload: z.object({
        totalSizeInBytes: z.number().int().min(0),
    }).nullable().optional().default(null),
});
export const FsCreateFileOutputSchema = z.object({
    id: z.string().uuid(),
    checksum: z.string().min(1),
    storage: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
    }),
    fileUploadId: z.string().uuid().nullable(),
});
