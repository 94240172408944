import MainService from "./MainService";
import { LoginParams, LoginOutput, LogoutOutput, MyselfOutput } from "@trantor/vdesk-api-client/dist/clients/auth";
import l from "../utils/Log";

class AS {
  private client = MainService.client.auth;

  async login(params: LoginParams): Promise<LoginOutput> {
    const data = await MainService.callFullError(() => this.client.login(params));
    if (typeof data.data === "undefined" || typeof data.error !== "undefined") {
      l.error("componentError", JSON.stringify(data.error));
      throw data.error;
    }
    MainService.setLoginData(data.data);
    return data.data;
  }

  async logout(): Promise<LogoutOutput> {
    if (MainService.getToken() === null) {
      return {};
    }
    const data = await MainService.call(() => this.client.logout());
    MainService.deleteSession();
    return data;
  }

  async myself(): Promise<MyselfOutput | null> {
    if (MainService.getToken() === null) {
      return null;
    }
    const myself: MyselfOutput = await MainService.call(() => this.client.myself());
    MainService.setLoggedUser(myself);
    return myself;
  }

  // async editMyself(params: EditMyselfParams): Promise<EditMyselfOutput | null> {
  //   if (MainService.getToken() === null) {
  //     return null;
  //   }

  //   return MainService.call(() => this.client.editMyself(params));
  // }
}

const AuthService = new AS();
export default AuthService;
