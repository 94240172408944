import { GetUsersParams } from "@trantor/vdesk-api-client/dist/clients/user";
import { createContext, useState } from "react";
import { UserDelete, UserSubmit, UserUpdate } from "../../pages/settings/UserTypes";
import { UsersService } from "../../services";
import { deserializeUsers, serializeUserForCreate } from "../../utils/Utils";
import { GetUsersContextType } from "./GetUsersContextTypes";
import { useCronState } from "../../utils/CronJob";

export const GetUsersContext = createContext({} as GetUsersContextType);

const INITIAL_GET_USERS_PARAMS: GetUsersParams = {
    page: 1,
    itemsPerPage: 10,
    orderBy: {
        field: "creationTime",
        order: "asc",
    },
} as const;

const UPDATE_INTERVAL_MILLIS = 5000;

interface GetUsersContextProviderProps {
    children?: React.ReactNode;
}
export const GetUsersContextProvider: React.FC<GetUsersContextProviderProps> = (props) => {
    const [queryParams, setQueryParams] = useState(INITIAL_GET_USERS_PARAMS);
    const [data, triggerUsersRefetch] = useCronState(UPDATE_INTERVAL_MILLIS, undefined, () => UsersService.getUsers(queryParams));

    async function createUser(user: UserSubmit): Promise<{ id: string }> {
        const { id } = await UsersService.createUser(serializeUserForCreate(user));
        triggerUsersRefetch();
        return { id };
    }

    async function updateUser(params: UserUpdate): Promise<void> {
        await UsersService.editUser(params);
        triggerUsersRefetch();
    }

    async function deleteUser(params: UserDelete): Promise<void> {
        await UsersService.deleteUser(params.id);
        triggerUsersRefetch();
    }

    const changePage = (page: number): void => {
        setQueryParams((params) => ({
            ...params,
            page,
        }));
        triggerUsersRefetch();
    };

    const changeItemsPerPage = (itemsPerPage: number): void => {
        setQueryParams((params) => ({
            ...params,
            itemsPerPage,
            page: 1, // Added to avoid asking for a page that doesn't exist and to align pagination behaviour
        }));
        triggerUsersRefetch();
    };

    const changeFilters = (filters: GetUsersParams["filters"]): void => {
        setQueryParams((params) => ({
            ...params,
            filters,
            page: 1, // Added to avoid asking for a page that doesn't exist and to align pagination behaviour
        }));
        triggerUsersRefetch();
    }
    const changeOrderBy = (orderBy: GetUsersParams["orderBy"]): void => {
        setQueryParams((params) => ({
            ...params,
            orderBy,
            page: 1, // Added to avoid asking for a page that doesn't exist and to align pagination behaviour
        }));
        triggerUsersRefetch();
    }

    return (
        <GetUsersContext.Provider
            value={{
                // TODO: sent "error" and "loading" from SWR here
                state: {
                    users: deserializeUsers(data?.items ?? []),
                    page: data?.page ?? 1,
                    itemsPerPage: data?.itemsPerPage ?? 10,
                    totalItems: data?.totalItems ?? 0,
                    totalPages: data?.totalPages ?? 1,
                },
                updateUsers: triggerUsersRefetch,
                changePage,
                changeFilters,
                changeOrderBy,
                changeItemsPerPage,
                createUser,
                updateUser,
                deleteUser,
                params: queryParams,
            }}
        >
            {props.children}
        </GetUsersContext.Provider>
    );
};
