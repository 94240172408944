import { ReactElement } from "react";
import { IChatUserData } from "../chat/ChatTypes";

export interface IFileManagerContext {
  contextData: any;
  planData: any;
  createFolder: Function;
  createFile: Function;
  onStarClick: Function;
  deleteFolder: Function;
  selectorDeleteFolder: Function;
  restoreFolder: Function;
  removeShare: Function;
  shareFiles: Function;
  onFileCheck: Function;
  downloadFile: Function;
  selectorDownloadFile: Function;
  copyToFolder: Function;
  selectorCopyToFolder: Function;
  moveFolder: Function;
  permanentDelete: Function;
  getTotalSize: Function;
}
export interface IFilePointer {
  fileId: string;
}
export enum FileType {
  none = "",
  folder = "folder",
  in = "incoming",
  out = "outgoing",
  file = "file",
}
export type FileAsideData = {
  id: string;
  text: string;
  icon: string;
  link: string;
  sub?: string[];
};
export type FileData = {
  id: string;
  meta: {
    type: FileType;
    name: string;
    ext?: string;
    svg: any;
    time: string;
    date: string;
    size: number;
    starred: boolean;
    checked: boolean;
    members?: IChatUserData[];
    link?: unknown;
    shared?: boolean;
  };
  shared?: {
    sharedTime: string;
    sharedDate: string;
  };
  recovery?: unknown;
  subFolder?: IFilePointer[];
};
export enum SVGIcon {
  zip = "application/x-zip-compressed",
  pdf = "application/pdf",
  txt = "text/plain",
  excel = "application/vnd.ms-excel",
  png = "img/png",
  jpeg = "img/jpeg",
  jpg = "img/jpg",
  others = "others",
}
export type SVGSelect = {
  [key in SVGIcon]: ReactElement;
};

export type Pricing = {
  id: string;
  title: string;
  logo: string;
  desc: string;
  amount: number;
  memory: number;
  userNumber: number | string;
  tags: boolean;
};

export type SelectLanguage = {
  id: string;
  label: string;
  value: string;
};

export type SelectDateFormat = {
  id: string;
  label: string;
  value: string;
};

export type SelectTimezoneFormat = {
  id: string;
  label: string;
  value: string;
};

export type SelectFolderType = {
  id: string;
  label: string;
  value: string;
  svg: React.ReactNode;
};

export type GenericSelectOption = {
  id: string;
  label: string;
  value: string;
};
