import React, { useState, useContext, useRef, ChangeEvent, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import { Icon, Button, ConfirmModal } from "../../../components/Component";

import { MeChat, YouChat } from "./ChatPartials";
import { Trans, useTranslation } from "react-i18next";
import { UserContext } from "../../settings/UserContext";
import {
    GetChatMessagesContext,
    GetChatMessagesProvider,
} from "../../../contexts/get-chat-messages/GetChatMessagesContext";
import { GetChatsContext } from "../../../contexts/get-chats/GetChatsContext";
import ChatSideBar from "./ChatSideBar";
import FaIcon from "../../../components/icon/FaIcon";
import RenameChatModal from "./modals/RenameChatModal";
import { getModerators, isChatItem, isUserMod } from "../../../utils/Chat";
import { GetChatArchivesContext } from "../../../contexts/get-chat-archives/GetChatArchivesContext";
import {
    GetChatArchivedMessagesContext,
    GetChatArchivedMessagesProvider,
} from "../../../contexts/get-chat-archived-messages/GetChatArchivedMessagesContext";
import { GroupBadge } from "../../../components/chat/Badge";
import l, { isDevelopment } from "../../../utils/Log";
import { formatDate, formatDuration, themeFromUsername, formatSize } from "../../../utils/Utils";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { ChatArchiveItem } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";
import { asyncVoid, useDelayedLoading, useHandler } from "../../../utils/handler";
import { GetChatMessagesOutput } from "@trantor/vdesk-api-schemas/dist/chats/messages/getChatMessages";
import { GetChatArchiveMessagesOutput } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchiveMessages";
import { unwrap } from "../../../services/MainService";
import { createApiClient } from "../../../utils/createApiClient";
import { unreachable } from "../../../utils/unreachable";
import { sleep } from "../../../utils/CronJob";
import { scope } from "../../../utils/scope";
import { assert } from "@trantor/vdesk-ts-utils/dist/asserts";
import { randomBytesHex, u8arrToBase64 } from "../../../utils/crypto";
import { DateTime } from "luxon";

type GenericChatBodyProps = {
    onChatQuit: () => void;
    mobileView: boolean;
    setMobileView: React.Dispatch<React.SetStateAction<boolean>>;
}

type ChatBodyProps = GenericChatBodyProps & {
    openChat: ChatItem;
}
const ChatBody: React.FC<ChatBodyProps> = ({
    openChat,
    onChatQuit,
    mobileView,
    setMobileView,
}) => {
    const { t } = useTranslation();
    const handleChatQuit = () => {
        onChatQuit();
    };
    const [sidebar, setSidebar] = useState(false);
    const [inputText, setInputText] = useState("");
    // const [chatOptions, setChatOptions] = useState(false);

    const { deleteChat, leaveChat, editChat } = useContext(GetChatsContext);
    const { 
        messages,
        loading,
        manualLoading,
        canLoadMorePages,
        sendMessage,
        deleteMessage,
        loadMoreMessages,
        markLastReadChatMessage
    } = useContext(GetChatMessagesContext);
    const delayedLoading = useDelayedLoading(loading, 0, 0);
    const delayedManualLoading = useDelayedLoading(manualLoading, 0, 0);
    const loggedInUser = useContext(UserContext).loggedInUser[0];

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const endOfSimpleBarRef = useRef<HTMLSpanElement>(null);

    const [oldScrollTop, setOldScrollTop] = useState(0)

    const [/* isUserDescendingSimpleBar */, setIsUserDescendingSimpleBar] = useState<boolean>(false);
    const [isUserAtBottomOfMessageEndRef, setIsUserAtBottomOfMessageEndRef] = useState<boolean>(true);
    const updateIsUserAtBottomOfMessageEndRef = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 1 < e.target.clientHeight;
        if (bottom) {
            setIsUserAtBottomOfMessageEndRef(true);
            markLastMessageAsRead();
        } else {
            setIsUserAtBottomOfMessageEndRef(false);
            setIsUserDescendingSimpleBar(e.target.scrollTop - oldScrollTop > 0);
        }
        setOldScrollTop(e.target.scrollTop);
    }
    const handleDeleteMessage = (id: string): Promise<void> =>{
        setIsUserAtBottomOfMessageEndRef(false);
        return deleteMessage(id);
    }
    const showMiniDrawer = useMemo(() => {
        return !isUserAtBottomOfMessageEndRef/* || isUserDescendingSimpleBar */;
    }, [isUserAtBottomOfMessageEndRef]);

    const prevMessagesRef = useRef<GetChatMessagesOutput["items"] | null>(null);
    const prevMessages = prevMessagesRef.current;
    const prevLastMessageRef = useRef<GetChatMessagesOutput["items"][number]>();
    const prevLastMessage = prevLastMessageRef.current;
    const prevFirstMessageRef = useRef<GetChatMessagesOutput["items"][number]>();
    const prevFirstMessage = prevFirstMessageRef.current;

    const markLastMessageAsRead = async (): Promise<void> => {
        // locate last non-mine message
        const lastNonMineMessage = messages?.findLast(m => m.sender?.id !== loggedInUser.id);
        if (lastNonMineMessage !== undefined) {
            await markLastReadChatMessage(lastNonMineMessage.id);
        }
    }
    const scrollToBottom = useCallback((): void => {
        if (endOfSimpleBarRef.current) {
            l.log("Scrolling to bottom...");
            setTimeout(() => {
                endOfSimpleBarRef.current?.scrollIntoView();
            }, 0);
        }
    }, []);
    const gracefullyScrollToBottom = useCallback((): void => {
        if (endOfSimpleBarRef.current) {
            l.log("Gracefully scrolling to bottom...");
            setTimeout(() => {
                endOfSimpleBarRef.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 0);
        }
    }, []);
    // const counter = useRef<number>(0); // Just for diagnostics
    const chatIdRef = useRef<string | undefined>();
    const oldChatId = chatIdRef.current;
    useEffect(() => {
//             counter.current = counter.current + 1; // Just for diagnostics
//             l.log(`${counter.current}:MESSAGES INCOMING
// prevMessages is ${prevMessages === null ? "null" : "NOT null"}
// messages is ${messages === null ? "null" : "NOT null"}
// chat is ${chat === null ? "null" : chat.id}
// oldChatId is ${oldChatId}
// `);
        if (
            (
                prevMessages === null &&
                messages !== null
            )
            ||
            (
                prevMessages !== null &&
                messages !== null &&
                openChat !== null &&
                oldChatId !== openChat.id
            )
        ) {
            chatIdRef.current = openChat.id;
            l.log("First messages loaded!");
            scrollToBottom();
            markLastMessageAsRead().catch(e => l.error(e));
        } else if (
            messages !== null
            && messages.length > 0
            && prevMessages !== null
        ) {
            if (
                messages.findIndex((m) => m.id === prevFirstMessage?.id) > 0 &&
                messages.length > prevMessages.length // not deleting messages, just adding new messages on top
            ) {
                // the previous first element is inside messages and some other messages are in front of it (it's not in position 0)
                l.log(`Loaded new page, scrolling to:`, prevFirstMessage?.content);
                //Scroll to first
                const target = document.getElementById(`message-id-${prevFirstMessage?.id}`);
                if (target) {
                    setTimeout(() => {
                        target.scrollIntoView({
                            block: "start",
                        });
                    }, 0);
                }
            } else if (
                prevLastMessage !== undefined
                && prevLastMessage.id !== messages[messages.length - 1]?.id
                && isUserAtBottomOfMessageEndRef
            ) {// not deleting messages, just adding new messages
                l.log("Was already at the bottom, keeping it that way");
                scrollToBottom();
                markLastMessageAsRead().catch(e => l.error(e));
            }
            // In any case, mark last emssage as read, since the chat is open and loading messages
            markLastMessageAsRead().catch(e => l.error(e));
        }
        prevMessagesRef.current = messages;
        prevFirstMessageRef.current = messages !== null ? messages[0] : undefined;
        prevLastMessageRef.current = messages !== null ? messages[messages.length - 1] : undefined;
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, prevMessages, scrollToBottom]);
    /**
     * Scroll to the bottom when the number of messages
     * received changes, which means a new message was
     * published
     *
     * NOT APPLICABLE ANYMORE IF YOU ARE PAGING UP
     */
    // useEffect(() => {
    //     scrollToBottom();
    // }, [showArchive, openChat, openChatArchive]);

    const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(e.target.value);
    };

    const toggleMenu = () => {
        setSidebar(!sidebar);
    };

    const onTextSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (delayedManualLoading) {
            return;
        }

        // const text = truncate(inputText, 74);
        const text = inputText.trim();
        text.length > 0 && sendMessage(text, loggedInUser.id, loggedInUser.displayName, loggedInUser.username);
        setInputText("");
    };

    const chatBodyClass = classNames({
        "nk-chat-body": true,
        "show-chat": mobileView,
        "profile-shown": sidebar, //&& window.innerWidth > 1550,
    });

    const [renameChatModal, setRenameChatModal] = useState<boolean>(false);
    const handleRenameChat = async (name: string): Promise<void> => {
        await editChat({
            id: openChat.id,
            name,
        });
    };
    const getTitle = () => {
        const foundUsers = (openChat.participants)
            .map((p) => p.user.displayName)
            .filter((u) => u !== loggedInUser.displayName)
            .sort((a, b) => (a > b ? 1 : -1));

        return openChat.name ?? (foundUsers.length > 0 ? [...foundUsers].join(", ") : t("nobody"));
    };
    const getParticipants = () => {
        const participants = openChat.participants;
        let foundUsers = "";
        if (openChat.name) {
            foundUsers = participants
                .map((p) => p.user.displayName)
                .map((p) => (p === loggedInUser.displayName ? t("myself") : p))
                .sort((a, b) => (a > b ? 1 : -1))
                .join(", ");
        }
        return foundUsers;
    };
    const isChatAGroup = (): boolean => openChat.name !== null;
    const areYouMod = () => isUserMod(openChat, loggedInUser);
    const canYouLeave = () => {
        const moderators = getModerators(openChat).filter((id) => id !== loggedInUser.id);
        if (moderators.length > 0) {
            return true;
        }
        return false;
    };
    const [confirmLeaveChatModal, setConfirmLeaveChatModal] = useState<boolean>(false);
    const toggleConfirmLeaveChatModal = () => {
        setConfirmLeaveChatModal(!confirmLeaveChatModal);
    };
    const [confirmDeleteChatModal, setConfirmDeleteChatModal] = useState<boolean>(false);
    const toggleConfirmDeleteChatModal = () => {
        setConfirmDeleteChatModal(!confirmDeleteChatModal);
    };
    const scrollToMessage = (id: string) => {
        const element = document.getElementById(`message-id-${id}`);
        if (element) {
            element.scrollIntoView(/* { behavior: "smooth" } */);
        }
    };
    const showLoader = () => (delayedLoading && messages === null);

    async function triggerAutoGenerate100(): Promise<void> {
        return triggerAutoGenerateN(100);
    }
    async function triggerAutoGenerateN(n: number, m?: number): Promise<any> {
        const max = m ?? n;
        l.log("Sending #" + (max - n + 1));
        await sendMessage(
            `Auto-generated test message #${`${max - n + 1}`.padStart(3, "0")}/${`${max}`.padStart(3, "0")}`,
            loggedInUser.id,
            loggedInUser.displayName,
            loggedInUser.username
        ).then(async () => {
            if (n - 1 > 0) {
                setTimeout(() => {
                    triggerAutoGenerateN(n - 1, max).then(() => {
                        scrollToBottom();
                    });
                }, 200);
            } else {
                scrollToBottom();
            }
        });
    }


    const [handleStartCall, handleStartCallLoading] = useHandler(async (chatId: string) => {
        const client = createApiClient({ tokenFromCookies: true });

        const res = unwrap(await client.calls.startCall({
            chatId,
            openToGuests: false,
            now: true,
            participants: [],
        }));

        window.open(`/call/${encodeURIComponent(res.shortCallId)}`, `_blank`);
    });

    const [handleSendMessage] = useHandler(async (event: React.FormEvent) => {
        await onTextSubmit(event);
        scrollToBottom();
    });

    const fileUploadRef = useRef<HTMLInputElement | null>(null);

    const handleFileBtnClick = useCallback(() => {
        fileUploadRef.current?.click();
    }, []);

    const [fileUploadModalData, setFileUploadModalData] = useState<{ inputElem: HTMLInputElement | null }>({ inputElem: null });

    const handleFileChange = useCallback(() => {
        if (fileUploadRef.current !== null) {
            setFileUploadModalData({ inputElem: fileUploadRef.current });
        }
    }, []);


    return <div className={chatBodyClass}>
        <div className="nk-chat-head">
            {isDevelopment() && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: Number.MAX_SAFE_INTEGER,
                        top: "4px",
                        left: "50%",
                        transform: "translate(-50%,0)",
                        border: "4px double #ff0000",
                        borderRadius: "5px",
                        padding: "3px",
                    }}
                >
                    <Button color="danger" size="xs" outline onClick={() => triggerAutoGenerate100()}>
                        Auto generate 100 msgs
                    </Button>
                    messages: {messages?.length ?? "NULL"}
                </div>
            )}
            <ul className="nk-chat-head-info">
                <li
                    className="nk-chat-body-close"
                    onClick={() => {
                        setMobileView(false);
                        handleChatQuit();
                    }}
                >
                    <a
                        href="/chat"
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                        className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                    >
                        <Icon name="arrow-left"></Icon>
                    </a>
                </li>
                {!mobileView && (
                    <li onClick={() => {
                        handleChatQuit();
                    }}>
                        <a
                            href="/chat"
                            onClick={(ev) => {
                                ev.preventDefault();
                            }}
                            className="btn btn-icon btn-trigger ms-n1"
                        >
                            <Icon name="arrow-left"></Icon>
                        </a>
                    </li>
                )}
                <li className="nk-chat-head-user">
                    <div className="user-card">
                        <div className="user-info">
                            <div className="lead-text">
                                {isChatAGroup() && <GroupBadge leaveSpace="after" />}
                                {getTitle()}
                            </div>
                            <div className="sub-text">
                                <span>{getParticipants()}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <ul className="nk-chat-head-tools">
                <li>
                    { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                    <a
                        href="#"
                        onClick={(ev) => {
                            handleStartCall(openChat.id);
                            ev.preventDefault();
                        }}
                        //className="btn btn-icon text-primary unclickable-text unselectable-text" // TODO put it back when feature becomes available
                        //className="btn btn-icon btn-trigger text-primary"
                        className={handleStartCallLoading ? `btn btn-icon text-primary unclickable-text unselectable-text` : `btn btn-icon btn-trigger text-primary`}
                        aria-disabled={!openChat}
                    >
                        <Icon name="call-fill"></Icon>
                    </a>
                </li>
                <li>
                    <UncontrolledDropdown>
                        <DropdownToggle
                            tag="a"
                            className="dropdown-toggle btn btn-icon btn-trigger text-primary"
                        >
                            <Icon name="setting-fill"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end className="dropdown-menu">
                            <ul className="link-list-opt no-bdr">
                                <li>
                                    {isChatAGroup() && (
                                        <DropdownItem
                                            tag="a"
                                            className={`dropdown-item ${!areYouMod() ? "unclickable-text unselectable-text" : ""
                                                }`}
                                            href="#dropdown"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                setRenameChatModal(true);
                                            }}
                                        >
                                            <FaIcon icon="keyboard" />
                                            <span>{t("chat.body.rename")}</span>
                                        </DropdownItem>
                                    )}
                                </li>
                                <li>
                                    <DropdownItem
                                        tag="a"
                                        className={`dropdown-item ${!areYouMod() ? "unclickable-text unselectable-text" : ""
                                            }`}
                                        href="#dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            areYouMod() && setConfirmDeleteChatModal(true);
                                        }}
                                    >
                                        <FaIcon icon="box-archive" ></FaIcon>
                                        <span>{t("chat.body.archive")}</span>
                                    </DropdownItem>
                                </li>
                                {isChatAGroup() && (
                                    <li>
                                        <DropdownItem
                                            tag="a"
                                            className={`dropdown-item ${!canYouLeave() ? "unclickable-text unselectable-text" : ""
                                                }`}
                                            href="#dropdown"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                canYouLeave() && setConfirmLeaveChatModal(true);
                                            }}
                                        >
                                            <Icon name="signout"></Icon>
                                            <span>{t("chat.body.leave")}</span>
                                        </DropdownItem>
                                    </li>
                                )}
                            </ul>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </li>
                <li className="me-n1 me-md-n2">
                    <a
                        href="#alert"
                        onClick={(e) => {
                            e.preventDefault();
                            openChat && (openChat.name || openChat.participants.length > 1) && toggleMenu();
                        }}
                        className={`btn btn-icon text-primary chat-profile-toggle${openChat && (openChat.name || openChat.participants.length > 1)
                                ? " btn-trigger"
                                : " unclickable-text"
                            }`}
                    >
                        <FaIcon icon="users" />
                    </a>
                </li>
            </ul>
        </div>
        <SimpleBar
            className="nk-chat-panel"
            scrollableNodeProps={{ ref: messagesEndRef }}
            onScrollCapture={updateIsUserAtBottomOfMessageEndRef}
        >
            {
                showLoader() ?
                <div className="loader">
                    <FaIcon icon="spinner" size="2x" pulse />
                </div>
                :
                <>
                    <div className="chat-body-upper-buttons">
                        {canLoadMorePages !== "cannotLoad" && (
                            <Button
                                color="primary"
                                outline
                                size="xs"
                                type="button"
                                onClick={() => {
                                    loadMoreMessages();
                                    messages !== null && scrollToMessage(messages[0]?.id ?? "");
                                }}
                                disabled={canLoadMorePages === "notSure"}
                            >
                                <Trans i18nKey={`loadMoreMessages`} />
                            </Button>
                        )}
                    </div>
                    {(messages ?? []).map((message, i) => {
                        if (message.sender?.id === loggedInUser.id) {
                            const hideButton = DateTime.fromMillis(message.creationTime).diffNow().as("minutes") < -60;
                            return (
                                <MeChat
                                    key={message.id}
                                    msg={message}
                                    loggedUser={loggedInUser}
                                    hideDeleteButton={hideButton}
                                    onRemoveMessage={(id) => {
                                        handleDeleteMessage(id);
                                    }}
                                />
                            );
                        }

                        return (
                            <YouChat
                                key={message.id}
                                msg={message}
                                sender={getYouChatSender(message.sender)}
                            />
                        );
                    })}
                    <span id={`end-of-simple-bar`} ref={endOfSimpleBarRef} />
                </>
            }
        </SimpleBar>
        <div
            className={classNames({
                "mini-lower-drawer": true,
                "open": showMiniDrawer /* && isUserDescendingSimpleBar */,
            })}
        >
            <a
                href="#go-down"                            
                onClick={e => {
                    e.preventDefault();
                    gracefullyScrollToBottom();
                }}
            >
                <FaIcon icon="chevron-down" sizeDL="md"/>
            </a>
        </div>
        <div className="nk-chat-editor">
            <div className="nk-chat-editor-form">
                <div className="form-control-wrap">
                    <textarea
                        className="form-control no-resize"
                        rows={2}
                        id="default-textarea"
                        onChange={(e) => onInputChange(e)}
                        value={inputText}
                        placeholder={t("chat.body.writeMessage").toString()}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                asyncVoid(handleSendMessage)(e);
                            }
                        }}
                    ></textarea>
                </div>
            </div>
            <ul className="nk-chat-editor-tools g-2">
                <li>
                    <Button
                        color="primary"
                        onClick={(e) => asyncVoid(handleSendMessage)(e)}
                        className="btn-round btn-icon"
                        disabled={delayedManualLoading}
                    >
                        {delayedManualLoading ? <FaIcon icon="spinner" sizeDL="xs" pulse /> : <Icon name="send-alt"></Icon>}
                    </Button>
                </li>
                <li>
                    <input type="file" ref={fileUploadRef} onChange={handleFileChange} style={{ display: `none` }} multiple={true} />
                    <Button
                        color="primary"
                        onClick={handleFileBtnClick}
                        className="btn-round btn-icon"
                        disabled={delayedManualLoading}
                    >
                        <FaIcon icon="file" sizeDL="xs" />
                    </Button>
                </li>
            </ul>
        </div>

        <ChatSideBar sidebar={sidebar} openChat={openChat} />
        
        {
            /* window.innerWidth < 1550 && */ sidebar && (
                <div onClick={() => toggleMenu()} className="nk-chat-profile-overlay"></div>
            )
        }
        <RenameChatModal
            isOpen={renameChatModal}
            onConfirm={handleRenameChat}
            onToggle={() => setRenameChatModal(!renameChatModal)}
            originalChat={openChat}
        />
        <ConfirmModal
            mode="chat-leave"
            isOpen={confirmLeaveChatModal}
            onToggle={toggleConfirmLeaveChatModal}
            onCallback={() => leaveChat(openChat.id)}
        />
        <ConfirmModal
            mode="chat-delete"
            isOpen={confirmDeleteChatModal}
            onToggle={toggleConfirmDeleteChatModal}
            onCallback={() => deleteChat(openChat.id)}
        />
        <FileUploadModal
            data={fileUploadModalData}
            onClose={() => {
                setFileUploadModalData({ inputElem: null });
                
                if (fileUploadRef.current !== null) {
                    fileUploadRef.current.value = ``;
                }
            }}
        />
    </div>;
};



type FileUploadModalProps = {
    data: { inputElem: HTMLInputElement | null },
    onClose: () => void,
};

function FileUploadModal(props: FileUploadModalProps): React.ReactElement {
    const { openChatId } = useContext(GetChatMessagesContext);

    const openChatIdRef = useRef(openChatId);

    const isOpen = props.data.inputElem !== null;
    
    const onCloseRef = useRef(props.onClose);
    onCloseRef.current = props.onClose;

    const [filesToUpload, setFilesToUpload] = useState<({
        idx: string,
        name: string,
        sizeInBytes: number,
        uploadedBytes: number,
        state: `pending` | `inProgress` | `done`,
    } | {
        idx: string,
        name: string,
        sizeInBytes: number,
        uploadedBytes: number,
        state: `error`,
        error: string,
    })[]>([]);

    const [progressValue, setProgressValue] = useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(`0.00 MiB/s`);
    const [eta, setEta] = useState(`N/A`);

    const currentUploadIdentifierRef = useRef(``);

    useEffect(() => {
        const myUploadIdentifier = randomBytesHex(8);
        currentUploadIdentifierRef.current = myUploadIdentifier;
        
        const elem = props.data.inputElem;

        if (elem === null || elem.files === null) {
            return;
        }

        const client = createApiClient({ tokenFromCookies: true });

        const globalFiles: {
            idx: string,
            file: File,
            uploadId: string | null,
        }[] = Array.from(elem.files).map(e => ({ idx: randomBytesHex(8), file: e, uploadId: null }));

        setFilesToUpload(globalFiles.map(e => ({
            idx: e.idx,
            name: e.file.name,
            sizeInBytes: e.file.size,
            uploadedBytes: 0,
            state: `pending`,
        })));

        setProgressValue(0);
        setUploadSpeed(`0.00 MiB/s`);
        setEta(`N/A`);

        asyncVoid(async () => {
            try {
                await sleep(2000);

                const { homeDir } = unwrap(await client.auth.myself());

                const homeDirId = homeDir?.id ?? unreachable();

                const { dirChildren } = unwrap(await client.fs.fsListDir({
                    directoryId: homeDirId,
                    orderBy: { field: `name`, order: `asc` },
                }));

                const talkDirId = dirChildren.find(e => e.name === `$TALK`)?.id ?? unreachable();

                const totalBytesToUpload = scope(() => {
                    let result = 0;

                    for (const elem of globalFiles) {
                        result += elem.file.size;
                    }

                    return result;
                });

                let totalBytesUploaded = 0;
                let startTime = performance.now();

                for (const fileToUpload of globalFiles) {
                    if (currentUploadIdentifierRef.current !== myUploadIdentifier) {
                        return;
                    }

                    const file = fileToUpload.file;

                    setFilesToUpload((filesToUpload) => {
                        return filesToUpload.map(e => e.idx === fileToUpload.idx ? { ...e, state: `inProgress` } : e);
                    });

                    const randomPrefix = randomBytesHex(6);
                
                    const { id: fileId, fileUploadId } = unwrap(await client.fs.fsCreateFile({
                        name: `$talk$-${randomPrefix}-${file.name}`,
                        parentDirectory: talkDirId,
                        linkedChat: openChatIdRef.current,
                        startUpload: {
                            totalSizeInBytes: file.size,
                        },
                    }));

                    assert( fileUploadId !== null );

                    fileToUpload.uploadId = fileUploadId;
            
                    let lastChunkId = 0;
                    const CHUNK_SIZE = 1048576; // 1 MiB
                    let writtenBytes = 0;
            
                    while (true) {
                        const blobEnd = (lastChunkId * CHUNK_SIZE) + CHUNK_SIZE;
                        const blob = file.slice(lastChunkId * CHUNK_SIZE, blobEnd > file.size ? file.size : blobEnd);
                        const u8arr = new Uint8Array(await blob.arrayBuffer());
            
                        const result = unwrap(await client.fs.fsUploadFileChunk({
                            fileUploadId,
                            chunkId: lastChunkId + 1,
                            operation: `sendChunk`,
                            chunkBase64: await u8arrToBase64(u8arr),
                        }));

                        if (currentUploadIdentifierRef.current !== myUploadIdentifier) {
                            return;
                        }

                        writtenBytes += u8arr.byteLength;
                        totalBytesUploaded += u8arr.byteLength;

                        setProgressValue(Math.floor(totalBytesUploaded / totalBytesToUpload * 100));
                        setUploadSpeed(`${((totalBytesUploaded / 1024 / 1024) / ((performance.now() - startTime) / 1000)).toFixed(2)} MiB/s`);
            
                        const bytesPerMs = totalBytesUploaded / (performance.now() - startTime);
                        const expectedEtaInMs = (totalBytesToUpload - totalBytesUploaded) / bytesPerMs;
                        setEta(formatDuration(expectedEtaInMs));

                        // eslint-disable-next-line no-loop-func
                        setFilesToUpload((filesToUpload) => {
                            return filesToUpload.map(e => e.idx === fileToUpload.idx ? { ...e, uploadedBytes: writtenBytes } : e);
                        });

                        if (result.fileUploadStatus !== `readyForMoreChunks`) {
                            break;
                        }
            
                        lastChunkId += 1;
                    }

                    unwrap(await client.chat.publishMessage({
                        chatId: openChatIdRef.current,
                        content: {
                            file: fileId,
                        },
                    }));

                    fileToUpload.uploadId = null;

                    setFilesToUpload((filesToUpload) => {
                        return filesToUpload.map(e => e.idx === fileToUpload.idx ? { ...e, state: `done` } : e);
                    });
                }

                if (currentUploadIdentifierRef.current !== myUploadIdentifier) {
                    return;
                }

                onCloseRef.current();
            }
            catch (error) {
                l.error(`FILE UPLOAD ERROR:`, error);
                // onCloseRef.current();
                debugger;
            }
        })();

        return asyncVoid(async () => {
            for (const elem of globalFiles) {
                if (elem.uploadId !== null) {
                    const uploadId = elem.uploadId;
                    elem.uploadId = null;

                    try {
                        await client.fs.fsUploadFileChunk({
                            fileUploadId: uploadId,
                            operation: `cancel`,
                        });
                    }
                    catch {
                        // do nothing
                    }
                }
            }
        });
    }, [props.data.inputElem]);

    return <Modal isOpen={isOpen} className="modal-md" keyboard={false} backdrop="static">
        <ModalHeader><Trans i18nKey="uploadModal.title" values={{count: filesToUpload.length}} /></ModalHeader>
        <ModalBody>
            <Row className="gy-3 py-3">
                <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                    <p><strong><Trans i18nKey="uploadModal.currentSpeed" />:</strong> {uploadSpeed} (<strong><Trans i18nKey="uploadModal.eta" />:</strong> {eta})</p>
                </Col>
            </Row>
            <Row className="gy-3 py-3">
                <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                    <progress value={progressValue} max={100} style={{ width: 0, minWidth: `100%` }} />
                </Col>
            </Row>
            <hr />
            <div style={{ overflowY: `auto`, overflowX: `hidden`, maxHeight: `400px` }}>
                {
                    filesToUpload.map(fileToUpload => <Row key={fileToUpload.idx} className="gy-3 py-3">
                        <Col xs="12" sm={{ offset: 1, size: 10 }} md={{ offset: 2, size: 8 }}>
                            <span><Trans i18nKey="uploadModal.fileName" />: <strong>{fileToUpload.name}</strong></span>
                            <span> (<Trans i18nKey="uploadModal.fileSize" />: {formatSize(fileToUpload.sizeInBytes)})</span>
                            <span> {
                                fileToUpload.state === `done` ? <progress value={100} max={100} style={{ width: 0, minWidth: `100%` }} /> :
                                fileToUpload.state === `pending` ? <progress style={{ width: 0, minWidth: `100%` }} /> :
                                <progress value={Math.floor((fileToUpload.uploadedBytes / fileToUpload.sizeInBytes) * 100)} max={100} style={{ width: 0, minWidth: `100%` }} />
                            }</span>
                        </Col>
                    </Row>)
                }
            </div>
            <hr />
            <Row className="gy-3 py-3">
                <Col size="12">
                    <ul className="align-center justify-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                            <Button
                                color="danger"
                                size="lg"
                                onClick={() => { onCloseRef.current(); }}
                            >
                                <Trans i18nKey="uploadModal.cancelButton" />
                            </Button>
                        </li>
                    </ul>
                </Col>
            </Row>
        </ModalBody>
    </Modal>;
}





type ChatArchiveBodyProps = GenericChatBodyProps & {
    openChat: ChatArchiveItem;
}
const ChatArchiveBody: React.FC<ChatArchiveBodyProps> = ({
    openChat,
    onChatQuit,
    mobileView,
    setMobileView,
}) => {
    const { t } = useTranslation();
    const handleChatQuit = () => {
        onChatQuit();
    };
    const [sidebar, setSidebar] = useState(false);
    // const [chatOptions, setChatOptions] = useState(false);

    const { deleteChat: deleteArchivedChat } = useContext(GetChatArchivesContext);
    const {
        messages: archivedMessages,
        canLoadMorePages: canLoadMoreArchivedPages,
        loadMoreMessages: loadMoreArchivedMessages,
        loading,
    } = useContext(GetChatArchivedMessagesContext);
    const delayedLoading = useDelayedLoading(loading, 0, 0);
    const loggedInUser = useContext(UserContext).loggedInUser[0];

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const endOfSimpleBarRef = useRef<HTMLSpanElement>(null);

    const [oldScrollTop, setOldScrollTop] = useState(0)

    const [/* isUserDescendingSimpleBar */, setIsUserDescendingSimpleBar] = useState<boolean>(false);
    const [isUserAtBottomOfMessageEndRef, setIsUserAtBottomOfMessageEndRef] = useState<boolean>(true);
    const updateIsUserAtBottomOfMessageEndRef = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 1 < e.target.clientHeight;
        if (bottom) {
            setIsUserAtBottomOfMessageEndRef(true);
        } else {
            setIsUserAtBottomOfMessageEndRef(false);
            setIsUserDescendingSimpleBar(e.target.scrollTop - oldScrollTop > 0);
        }
        setOldScrollTop(e.target.scrollTop);
    }

    const showMiniDrawer = useMemo(() => {
        return isUserAtBottomOfMessageEndRef/* || isUserDescendingSimpleBar */;
    }, [isUserAtBottomOfMessageEndRef]);

    const prevArchivedMessagesRef = useRef<GetChatArchiveMessagesOutput["items"] | null>(null);
    const prevArchivedMessages = prevArchivedMessagesRef.current;

    const prevLastArchivedMessageRef = useRef<GetChatArchiveMessagesOutput["items"][number]>();
    // const prevLastArchivedMessage = prevLastArchivedMessageRef.current;
    const prevFirstArchivedMessageRef = useRef<GetChatArchiveMessagesOutput["items"][number]>();
    const prevFirstArchivedMessage = prevFirstArchivedMessageRef.current;

    const scrollToBottom = useCallback((): void => {
        if (endOfSimpleBarRef.current) {
            l.log("Scrolling to bottom...");
            setTimeout(() => {
                endOfSimpleBarRef.current?.scrollIntoView();
            }, 0);
        }
    }, []);
    const gracefullyScrollToBottom = useCallback((): void => {
        if (endOfSimpleBarRef.current) {
            l.log("Gracefully scrolling to bottom...");
            setTimeout(() => {
                endOfSimpleBarRef.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 0);
        }
    }, []);
    // const counter = useRef<number>(0); // Just for diagnostics
    const chatIdRef = useRef<string | undefined>();
    const oldChatId = chatIdRef.current;
    useEffect(() => {
        if (
            (
                prevArchivedMessages === null &&
                archivedMessages !== null
            )
            ||
            (
                prevArchivedMessages !== null &&
                archivedMessages !== null &&
                openChat !== null &&
                oldChatId !== openChat.id
            )
        ) {
            chatIdRef.current = openChat.id;
            l.log("First archived messages loaded!");
            scrollToBottom();
        } else if (archivedMessages !== null && archivedMessages.length > 0)
            if (archivedMessages.findIndex((m) => m.id === prevFirstArchivedMessage?.id) > 0) {
                // the previous first element is inside messages and some other messages are in front of it (it's not in position 0)
                l.log("Loaded new page in archive");
                //Scroll to first
                const target = document.getElementById(`message-id-${prevFirstArchivedMessage?.id}`);
                if (target) {
                    setTimeout(() => {
                        target.scrollIntoView();
                    }, 0);
                }
            }
        prevArchivedMessagesRef.current = archivedMessages;
        prevFirstArchivedMessageRef.current = archivedMessages !== null ? archivedMessages[0] : undefined;
        prevLastArchivedMessageRef.current = archivedMessages !== null ? archivedMessages[archivedMessages.length - 1] : undefined;
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [archivedMessages, prevArchivedMessages, scrollToBottom]);
    const toggleMenu = () => {
        setSidebar(!sidebar);
    };

    const chatBodyClass = classNames({
        "nk-chat-body": true,
        "show-chat": mobileView,
        "profile-shown": sidebar, //&& window.innerWidth > 1550,
    });

    const getTitle = () => {
        const foundUsers = (openChat.participants)
            .map((p) => p.user.displayName)
            .filter((u) => u !== loggedInUser.displayName)
            .sort((a, b) => (a > b ? 1 : -1));

        return openChat.name ?? (foundUsers.length > 0 ? [...foundUsers].join(", ") : t("nobody"));
    };
    const getArchivedChatTime = () => {
        return (
            <div className="time">
                {/* <span>(</span> */}
                <Trans i18nKey="chatPartials.chatItem.archivedWhen" /> 
                {formatDate(openChat.creationTime)}
                {/* <span>)</span> */}
            </div>
        );
    };
    const getParticipants = () => {
        const participants = openChat.participants;
        let foundUsers = "";
        if (openChat.name) {
            foundUsers = participants
                .map((p) => p.user.displayName)
                .map((p) => (p === loggedInUser.displayName ? t("myself") : p))
                .sort((a, b) => (a > b ? 1 : -1))
                .join(", ");
        }
        return foundUsers;
    };
    const isChatAGroup = (): boolean => openChat.name !== null;
    const [confirmDeleteArchivedChatModal, setConfirmDeleteArchivedChatModal] = useState<boolean>(false);
    const toggleConfirmDeleteArchivedChatModal = () => {
        setConfirmDeleteArchivedChatModal(!confirmDeleteArchivedChatModal);
    };
    const showLoader = () => (delayedLoading && archivedMessages === null);

    return (
        <React.Fragment>
            {/* {Object.keys(Uchat).length > 0 && ( */}
            <div className={chatBodyClass}>
                <div className="nk-chat-head">
                    {isDevelopment() && (
                        <div
                            style={{
                                position: "absolute",
                                zIndex: Number.MAX_SAFE_INTEGER,
                                top: "4px",
                                left: "50%",
                                transform: "translate(-50%,0)",
                                border: "4px double #ff0000",
                                borderRadius: "5px",
                                padding: "3px",
                            }}
                        >
                            messages: {archivedMessages?.length ?? "NULL"}
                        </div>
                    )}
                    <ul className="nk-chat-head-info">
                        <li
                            className="nk-chat-body-close"
                            onClick={() => {
                                setMobileView(false);
                                handleChatQuit();
                            }}
                        >
                            <a
                                href="/chat"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                                className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                            >
                                <Icon name="arrow-left"></Icon>
                            </a>
                        </li>
                        {!mobileView && (
                            <li onClick={() => {
                                handleChatQuit();
                            }}>
                                <a
                                    href="/chat"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                    }}
                                    className="btn btn-icon btn-trigger ms-n1"
                                >
                                    <Icon name="arrow-left"></Icon>
                                </a>
                            </li>
                        )}
                        <li className="nk-chat-head-user">
                            <div className="user-card">
                                <div className="user-info">
                                    <div className="lead-text">
                                        {isChatAGroup() && <GroupBadge leaveSpace="after" />}
                                        {getTitle()}
                                        {getArchivedChatTime()}
                                    </div>
                                    <div className="sub-text">
                                        <span>{getParticipants()}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="nk-chat-head-tools">
                        <li>
                            <a
                                href={"/call/" + openChat.id}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                }}
                                className="btn btn-icon text-primary unclickable-text unselectable-text" // TODO put it back when feature becomes available
                                // className="btn btn-icon btn-trigger text-primary"
                                aria-disabled={true}
                            >
                                <Icon name="call-fill"></Icon>
                            </a>
                        </li>
                        <li>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    tag="a"
                                    className="dropdown-toggle btn btn-icon btn-trigger text-primary"
                                >
                                    <Icon name="setting-fill"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu">
                                    <ul className="link-list-opt no-bdr">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                className={`dropdown-item`}
                                                href="#dropdown"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setConfirmDeleteArchivedChatModal(true);
                                                }}
                                            >
                                                <Icon name="trash"></Icon>
                                                <span>{t("chat.body.remove")}</span>
                                            </DropdownItem>
                                        </li>
                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </li>
                    </ul>
                </div>
                <SimpleBar
                    className="nk-chat-panel"
                    scrollableNodeProps={{ ref: messagesEndRef }}
                    onScrollCapture={updateIsUserAtBottomOfMessageEndRef}
                >
                    <div className="chat-body-upper-buttons">
                        {canLoadMoreArchivedPages && (
                            <Button
                                color="primary"
                                outline
                                size="xs"
                                type="button"
                                // disabled={disableModal}
                                onClick={() => {
                                    loadMoreArchivedMessages();
                                }}
                            >
                                <Trans i18nKey={`loadMoreMessages`} />
                            </Button>
                        )}
                    </div>
                    {
                        showLoader() ?
                        <div className="loader">
                            <FaIcon icon="spinner" size="2x" pulse />
                        </div>
                        :
                        (archivedMessages ?? []).map((message) => {
                            if (message.sender?.id === loggedInUser.id) {
                                return (
                                    <MeChat
                                        key={"arch"+ message.id}
                                        msg={message}
                                        loggedUser={loggedInUser}
                                        onRemoveMessage={null}
                                        hideDeleteButton
                                    />
                                );
                            }

                            return (
                                <YouChat
                                    key={"arch"+ message.id}
                                    msg={message}
                                    sender={getYouChatSender(message.sender)}
                                />
                            );
                        })
                    }
                    <span id={`end-of-simple-bar`} ref={endOfSimpleBarRef} />
                </SimpleBar>
                <div
                    className={classNames({
                        "mini-lower-drawer": true,
                        "open": !isUserAtBottomOfMessageEndRef /* && isUserDescendingSimpleBar */,
                    })}
                >
                    <a
                        href="#go-down"                            
                        onClick={e => {
                            e.preventDefault();
                            gracefullyScrollToBottom();
                        }}
                    >
                        <FaIcon icon="chevron-down" sizeDL="md"/>
                    </a>
                </div>
                <div className="nk-chat-editor">
                    <div className="nk-chat-editor-form">
                        <div className="form-control-wrap">
                            <textarea
                                className="form-control form-control-simple no-resize"
                                rows={1}
                                id="default-textarea"
                                value=""
                                disabled
                            ></textarea>
                        </div>
                    </div>
                    <ul className="nk-chat-editor-tools g-2">
                        <li>
                            <Button
                                color="primary"
                                className="btn-round btn-icon"
                                disabled
                            >
                                <Icon name="send-alt"></Icon>
                            </Button>
                        </li>
                    </ul>
                </div>

                <ChatSideBar sidebar={sidebar} openChat={openChat} />
                
                {
                    /* window.innerWidth < 1550 && */ sidebar && (
                        <div onClick={() => toggleMenu()} className="nk-chat-profile-overlay"></div>
                    )
                }
                <ConfirmModal
                    mode="archived-chat-delete"
                    isOpen={confirmDeleteArchivedChatModal}
                    onToggle={toggleConfirmDeleteArchivedChatModal}
                    onCallback={() => deleteArchivedChat(openChat.id)}
                />
            </div>
        </React.Fragment>
    );
};

const Container = (props: ChatBodyProps | ChatArchiveBodyProps) => {
    const { openChat } = props;
    if (isChatItem(openChat)) {
        return <GetChatMessagesProvider openChat={openChat}>
            <ChatBody {...props} openChat={openChat} />
        </GetChatMessagesProvider>
    } else {
        return <GetChatArchivedMessagesProvider openChatArchive={openChat}>
            <ChatArchiveBody {...props} openChat={openChat} />
        </GetChatArchivedMessagesProvider>
    }
};
// props.id ? (
// ) : (
//     <Component {...props} />
// );

export default Container;

function getYouChatSender(
    sender: { id: string; displayName: string; username: string } | null
): { id: string; displayName: string; username: string; avatarBg: string } | null {
    if (sender === null) {
        return null;
    }

    return {
        id: sender.id,
        displayName: sender.displayName,
        username: sender.username,
        avatarBg: themeFromUsername(sender.username),
    };
}
