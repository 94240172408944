import { useTranslation } from "react-i18next";
import { UserData } from "../../utils/GeneralTypes";
import Icon from "../icon/Icon";
import { CSSProperties } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

type AvailableFunctions = {
    [key in "edit" | "delete"]: (id: string) => void;
};
interface DataTableContextMenuProps {
    row: UserData;
    coords: { x: number; y: number };
    functions: AvailableFunctions;
}
const DataTableContextMenu: React.FC<DataTableContextMenuProps> = ({
    row: { id /* , displayName */ },
    coords,
    functions,
}) => {
    const { t } = useTranslation();
    const style: CSSProperties = {
        position: "fixed",
        top: `${coords.y}px`,
        left: `${coords.x}px`,
        zIndex: 10000,
    };
    return (
        <UncontrolledDropdown isOpen={true}>
            <DropdownToggle
                style={style}
                tag="a"
                onClick={(e) => {
                    e.preventDefault();
                }}
            ></DropdownToggle>
            <DropdownMenu className="link-list-opt no-bdr">
                {/* {displayName && (
                    <>
                        <li className="my-md-1">
                            <center>
                                <strong>{displayName}:</strong>
                            </center>
                        </li>
                        <li className="divider"></li>
                    </>
                )} */}
                <li
                    onClick={() => {
                        functions["edit"].call(this, id);
                    }}
                >
                    <a
                        href="#edit"
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <Icon name="edit"></Icon>
                        <span>{t("usersManagement.contextualActions.edit")}</span>
                    </a>
                </li>
                <li
                    onClick={() => {
                        functions["delete"](id);
                    }}
                >
                    <a
                        href="#delete"
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <Icon name="trash"></Icon>
                        <span>{t("usersManagement.contextualActions.delete")}</span>
                    </a>
                </li>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default DataTableContextMenu;
