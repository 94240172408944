
import { ApiClient } from "@trantor/vdesk-api-client/dist";
import { getCookie } from "typescript-cookie";
import { z } from "zod";
import { isDevelopment } from "./Log";



export function createApiClient(opts: { tokenFromCookies?: boolean } = {}): ApiClient {
    const tokenFromCookies = opts.tokenFromCookies ?? false;

    const protocol = z.enum([`http`, `https`]).parse(
        isDevelopment() ? `http` : window.location.protocol.replace(`:`, ``)
    );

    const hostname = isDevelopment() ? `localhost` : window.location.hostname;

    const port = (() => {
        if (isDevelopment()) {
            return 8080;
        }

        const locationPort = window.location.port;

        if (locationPort === ``) {
            return protocol === `http` ? 80 : 443;
        }

        return parseInt(locationPort, 10);
    })();

    const token = (() => {
        try {
            if (tokenFromCookies !== true) {
                return null;
            }
    
            return z.object({
                token: z.string().min(1),
            }).parse(JSON.parse(getCookie(`sessionData`) ?? `{}`)).token;
        }
        catch {
            return null;
        }
    })();

    const client = new ApiClient({ protocol, hostname, port });

    client.setToken(token);

    return client;
}
