import { EditGroupParams } from "@trantor/vdesk-api-client/dist/clients/groups";
import { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { Button } from "../../../../components/Component";
import Icon from "../../../../components/icon/Icon";
import { GroupsContext } from "../../../../contexts/get-groups/GroupsContext";
import { GroupData } from "../../../../contexts/get-groups/GroupsContextTypes";
import l from "../../../../utils/Log";
import { ComputersSelector, GroupsSelector, UsersSelector } from "../MultiSelectDropdown";
import { GroupsService } from "../../../../services";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

export type EditGroupFormInputs = {
    mode: string;
    reValidateMode: string;

    id: string;
    name: string;
    memberUsersIds: string[];
    memberGroupsIds: string[];
    memberComputersIds: string[];
    memberOfIds: string[];
};

interface EditGroupModalProps {
    isOpen: boolean;
    onToggle: () => void;
    groupData: GroupData | null;
}

const getIds = (data: { id: string }[] | undefined): string[] => (data ?? []).map((item) => item.id);

const toFormState = (data: GroupData | undefined): EditGroupFormInputs => ({
    mode: "onSubmit",
    reValidateMode: "onChange",
    
    id: data?.id ?? "",
    name: data?.name ?? "",
    memberUsersIds: getIds(data?.memberUsers),
    memberGroupsIds: getIds(data?.memberGroups),
    memberComputersIds: getIds(data?.memberComputers),
    memberOfIds: data?.memberOf.map((item) => item.groupId) ?? [],
});

const EditGroupModal: React.FC<EditGroupModalProps> = ({ isOpen, onToggle, groupData: incomingData }) => {
    const prevGroupData = useRef<GroupData | null>(null);
    const groupData = useMemo(() => {
        if (incomingData !== null) {
            prevGroupData.current = incomingData;
            return incomingData;
        }
        return prevGroupData.current;
    }, [incomingData]);
    
    const { t } = useTranslation();

    const updateGroup = GroupsService.editGroup.bind(GroupsService);

    // Edit group form
    const editGroupForm = useForm<EditGroupFormInputs>({
        defaultValues: toFormState(groupData ?? undefined),
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });


    
    useEffect(() => {
        if (groupData === undefined || groupData?.id === editGroupForm.getValues("id")) {
            return;
        }

        editGroupForm.reset(toFormState(groupData ?? undefined));
    }, [editGroupForm, groupData]);

    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    // submit function to update a new item
    const onEditSubmit: SubmitHandler<EditGroupFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();
        try {
            const editedGroup: EditGroupParams = {
                id: submitData.id,
                name: submitData.name !== "" ? submitData.name : undefined,
                memberUsers: submitData.memberUsersIds,
                memberGroups: submitData.memberGroupsIds,
                memberComputers: submitData.memberComputersIds,
                memberOf: submitData.memberOfIds,
            }

            await updateGroup(editedGroup);

            onToggle();
        } catch (err) {
            l.error("failed to update group", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };

    const [error, setError] = useState<string | null>(null);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            setDisableModal(false);
            onToggle();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    setError(null);
                    onToggle();
                }}
                className="close"
            >
                <Icon name="cross-sm" />
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="groupsManagement.editModal.title" />
                </h5>
                <div className="mt-4">
                    <Form
                        className="row gy-4"
                        onSubmit={editGroupForm.handleSubmit(onEditSubmit, (p, e) => l.error("-- ERR:", p, e))}
                    >
                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={groupData?.name}
                                    placeholder={t("groupsManagement.attributes.namePlaceholder").toString()}
                                    {...editGroupForm.register("name")}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberUsers" />
                                </label>
                                <UsersSelector
                                    selectedData={getIds(groupData?.memberUsers)}
                                    onChange={(selected) => {
                                        editGroupForm.setValue("memberUsersIds", getIds(selected), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberGroups" />
                                </label>
                                <GroupsSelector
                                    selectedData={getIds(groupData?.memberGroups)}
                                    filter={(item) => item.id !== groupData?.id}
                                    onChange={(selected) => {
                                        editGroupForm.setValue("memberGroupsIds", getIds(selected), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberComputers" />
                                </label>
                                <ComputersSelector
                                    selectedData={getIds(groupData?.memberComputers)}
                                    onChange={(selected) => {
                                        editGroupForm.setValue("memberComputersIds", getIds(selected), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberOf" />
                                </label>
                                <GroupsSelector
                                    selectedData={groupData?.memberOf.map((item) => item.groupId) ?? []}
                                    filter={(item) => item.id !== groupData?.id}
                                    onChange={(selected) => {
                                        editGroupForm.setValue("memberOfIds", getIds(selected), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={disableModal}>
                                            <Trans i18nKey="groupsManagement.editModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    onToggle();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="groupsManagement.editModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default EditGroupModal;
