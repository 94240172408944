import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import { UserContextProvider } from "./UserContext";
import { SettingsSubsectionMenuLinks, SettingsSubsectionRoutes } from "./SettingsSubsection";
import FaIcon from "../../components/icon/FaIcon";
import { Button } from "../../components/Component";
import classNames from "classnames";

const SettingsLayout = () => {
    const [sm, setSm] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);

    // function to change the design view under 990 px
    const viewChange = () => {
        if (window.innerWidth < 990) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
            setSm(false);
        }
    };

    useEffect(() => {
        viewChange();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        const headerElements = document.getElementsByClassName("nk-header");
        if (headerElements?.[0] !== undefined) {
            headerElements[0].addEventListener("click", function () {
                setSm(false);
            });
        }
        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
        };
    }, []);

    return <Content>
        <Card className="card-bordered">
            <div className="card-aside-wrap">
                <div
                    className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                        sm ? "content-active" : ""
                    }`}
                >
                    <div className="card-inner-group">
                        <div className="card-inner p-0">
                            <ul className="link-list-menu">
                                {isMobileView
                                ? <Button
                                    className={classNames({
                                        "settings-submenu-button-close": true,
                                        "btn-icon": true,
                                        "open": sm,
                                    })}
                                    onClick={() => setSm(s => !s)}
                                    color="lighter"
                                >
                                    {!sm ? <FaIcon icon="chart-bar" flip="horizontal"/> : <FaIcon icon="chevron-left"/>}
                                </Button>
                                : <></>}
                                <SettingsSubsectionMenuLinks setSm={setSm} />
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card-inner card-inner-lg">
                    <UserContextProvider>
                        <SettingsSubsectionRoutes sm={sm} setSm={setSm} />
                    </UserContextProvider>
                </div>
            </div>
        </Card>
    </Content>;
}

export default SettingsLayout;
