import { z } from "zod";
import { EnumOpSchema, NumberOpSchema, StringOpSchema } from "../filters.js";
export const AccessRulesConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`name`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
]);
export const AccessRulesFiltersSchema = z.array(AccessRulesConditionSchema.or(z.array(AccessRulesConditionSchema)));
export const AccessRulesOrderBySchema = z.object({
    field: z.enum([`name`, `creationTime`]),
    order: z.enum([`asc`, `desc`]),
});
export const GetAccessRulesParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: AccessRulesOrderBySchema.optional().default({ field: `name`, order: `asc` }),
    filters: AccessRulesFiltersSchema.optional().default([]),
});
export const AccessRuleItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    name: z.string().min(1),
    users: z.object({
        id: z.string().uuid(),
        username: z.string().min(1),
        displayName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    userGroups: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    computers: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    computerGroups: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
});
export const GetAccessRulesOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: AccessRuleItemSchema.array(),
});
