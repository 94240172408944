import { z } from "zod";
import { UserRoleSchema } from "../common.js";
import { EnumOpSchema, NumberOpSchema, StringOpSchema } from "../filters.js";
export const UsersConditionSchema = z.union([
    z.object({
        field: z.literal("id"),
        op: EnumOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("username"),
        op: StringOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("creationTime"),
        op: NumberOpSchema,
        value: z.number().int()
    }),
    z.object({
        field: z.literal("role"),
        op: EnumOpSchema,
        value: UserRoleSchema
    }),
    z.object({
        field: z.literal("enabled"),
        op: EnumOpSchema,
        value: z.boolean()
    }),
    z.object({
        field: z.literal("lastLogin"),
        op: NumberOpSchema,
        value: z.number().int()
    }),
    z.object({
        field: z.literal("lastLogin"),
        op: z.literal("isNull"),
        value: z.boolean()
    }),
    z.object({
        field: z.literal("otpFirstLogin"),
        op: EnumOpSchema,
        value: z.boolean()
    }),
    z.object({
        field: z.literal("otpFirstLogin"),
        op: z.literal("isNull"),
        value: z.boolean()
    }),
    z.object({
        field: z.literal("otpKey"),
        op: EnumOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("otpKey"),
        op: z.literal("isNull"),
        value: z.boolean()
    }),
    z.object({
        field: z.literal("displayName"),
        op: StringOpSchema,
        value: z.string()
    }),
]);
export const UsersFiltersSchema = z.array(UsersConditionSchema.or(z.array(UsersConditionSchema)));
export const UsersOrderBySchema = z.object({
    field: z.enum(["username", "creationTime", "lastLogin", "displayName"]),
    order: z.enum(["asc", "desc"])
});
export const GetUsersParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: UsersOrderBySchema.optional().default({ field: "username", order: "asc" }),
    filters: UsersFiltersSchema.optional().default([])
});
export const UserItemSchema = z.object({
    id: z.string().uuid(),
    username: z.string().min(1),
    displayName: z.string().min(1),
    creationTime: z.number().int(),
    role: UserRoleSchema,
    lastLogin: z.number().int().nullable(),
    enabled: z.boolean(),
    otp: z.enum([`enabledAndConfigured`, `enabledButNotYetConfigured`, `disabled`]),
    ldapInfo: z.object({
        ldapId: z.string().min(1),
        ldapDn: z.string().min(1),
    }).nullable(),
    memberOf: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    homeDir: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        path: z.object({ id: z.string().uuid(), name: z.string().min(1) }).array(),
    }).nullable(),
    storageQuotaInBytes: z.object({
        available: z.number().int().min(0),
        used: z.number().int().min(0),
    }),
});
export const GetUsersOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: UserItemSchema.array(),
});
