class P {
    private static paths: { [key: string]: string } = {
        success: "/auth-success",
        reset: "/auth-reset",
        register: "/auth-register",
        login: "/auth-login",
        "no-auth-terms": "/auth/terms-policy",
        "no-auth-faq": "/auth/faq",
        terms: "/pages/terms-policy",
        faq: "/pages/faq",
        "404c": "/errors/404-classic",
        "504c": "/errors/504-classic",
        "404m": "/errors/404-modern",
        "504m": "/errors/504-modern",

        "user-list-regular": "/user-list-regular",
        "user-list-compact": "/user-list-compact",
        "user-details-regular": "/user-details-regular/:id",
        "user-contact-card": "/user-contact-card",

        file: "/file/*",
        "file/files": "/file/files/*",
        "file/shared": "/file/shared/*",
        "file/starred": "/file/starred/*",
        "file/recovery": "/file/recovery/*",
        "file/settings": "/file/settings/*",
        "file/pricing": "/file/pricing/*",
        "file/folder": "/file/folder/:id",

        chat: "/chat",
        archive: "/chat/archive",

        webAccess: "/webaccess",

        settingsRoot: "/settings/*",
        settingsRootPartial: "/settings/",
        settingsUsers: "users",
        settingsGroups: "groups",
        settingsComputers: "computers",
        settingsAccessRules: "access-rules",
        settingsLdap: "ldap",
        settingsNotification: "notification",
        settingsActivity: "activity",
        settingsSetting: "setting",
        settingsStatistics: "statistics",
        settingsLogs: "logs",
        settingsStorage: "storage",
        settingsServers: "servers",
        settingsLicense: "license",
        settingsSandBox: "sandbox",
    };

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor() { }

    public addPath(name: string, path: string): void {
        P.paths[name] = path;
    }

    public getPath(n: keyof typeof P["paths"], includeRoot: boolean = true): string {
        let path = P.paths[n];
        if (!path) return "/";
        if (includeRoot) {
            return `${process.env.PUBLIC_URL}${path}`;
        } else {
            return path;
        }
    }
}
const p = new P();
export default p;
