import { parseError } from "../libs/errors.js";
import { BaseClient } from "./base.js";
import { LoginOutputSchema, } from "@trantor/vdesk-api-schemas/dist/auth/login.js";
import { LogoutOutputSchema } from "@trantor/vdesk-api-schemas/dist/auth/logout.js";
import { ChangePasswordOutputSchema } from "@trantor/vdesk-api-schemas/dist/auth/changePassword.js";
import { MyselfOutputSchema } from "@trantor/vdesk-api-schemas/dist/users/myself.js";
export class AuthClient extends BaseClient {
    token;
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
        this.token = token;
    }
    async login(params) {
        if (this.token.get() !== null) {
            this.token.set(null);
        }
        const res = await super.call("login", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res),
            };
        }
        const data = LoginOutputSchema.parse(res.body);
        this.token.set(data.token);
        return {
            data
        };
    }
    async logout() {
        if (this.token.get() === null) {
            return {
                data: LogoutOutputSchema.parse({})
            };
        }
        const res = await super.call("logout");
        if (res.status !== "OK") {
            return {
                error: parseError(res),
            };
        }
        this.token.set(null);
        return {
            data: LogoutOutputSchema.parse({})
        };
    }
    async changePassword(params) {
        const res = await super.call("changePassword", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: ChangePasswordOutputSchema.parse(res.body)
        };
    }
    async myself() {
        const res = await super.call("myself", {});
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: MyselfOutputSchema.parse(res.body)
        };
    }
}
