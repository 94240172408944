import React, { useCallback, useContext, useMemo, useRef, useState } from "react";

import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent, RSelect } from "../../components/Component";
import { ComputersContext, ComputersContextProvider } from "../../contexts/get-computers/ComputersContext";
import { ComputerData, ComputersContextType } from "../../contexts/get-computers/ComputersContextTypes";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import l, { isDevelopment } from "../../utils/Log";
import { formatDate, hideMostOfString } from "../../utils/Utils";
import ConfirmationModal from "./modals/ConfirmationModal";
import { ApiTable, ApiTableActionGroupElem, ApiTableApiService, ApiTableColMapper, ApiTableContextMenuElem, ApiTableSearchMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import { t } from "i18next";
import StoragesService from "../../services/StorageService";
import CreateStorageModal from "./modals/storages/CreateStorageModal";
import classNames from "classnames";
import EditStorageModal from "./modals/storages/EditStorageModal";
import { useHandler } from "../../utils/handler";

const StorageSettingsPage = () => <StorageTable />;

export default StorageSettingsPage;


const getStorages = StoragesService.getStorages.bind(StoragesService);

type StorageItem = ItemOfApi<typeof getStorages>;

type StoragesOrderedCol = OrderedColOfApi<typeof getStorages>;

type StoragesFilterCond = FilterCondOfApi<typeof getStorages>;

function StorageTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<StorageItem, StoragesOrderedCol> = useMemo(() => {
        return [
            {
                colNameTranslationKey: `storagesManagement.attributes.id`,
                hideInTable: true,
                render: ({ item }) => <span>{item.id}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.online`,
                render: ({ item }) => <ul className="list-status">
                                        <li>
                                            <Icon
                                                className={classNames({
                                                    "text-success": item.online,
                                                    "text-danger": !item.online,
                                                    "unselectable-text": true,
                                                })}
                                                name={`${item.online ? "check-circle" : "cross-circle"}`}
                                            ></Icon>{" "}
                                            <span>
                                                <Trans
                                                    i18nKey={`storagesManagement.${item.online ? "online" : "offline"
                                                        }`}
                                                />
                                            </span>
                                        </li>
                                    </ul>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.name`,
                canOrder: `name`,
                render: ({ item }) => <span className="tb-lead">{item.name}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.endpoint`,
                hideInTable: true,
                render: ({ item }) => <span>{item.endpoint}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.accessKeyId`,
                hideInTable: true,
                render: ({ item }) => <span>{item.accessKeyId}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.secretAccessKey`,
                hideInTable: true,
                render: ({ item }) => <span>{hideMostOfString(item.secretAccessKey)}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.bucket`,
                hideInTable: true,
                render: ({ item }) => <span>{item.bucket}</span>,
            },
            {
                colNameTranslationKey: `storagesManagement.attributes.region`,
                render: ({ item }) => <span>{item.region}</span>,
            },
        ];
    }, []);

    const searchMapper: ApiTableSearchMapper<StoragesFilterCond> = useCallback((search, currentFilters) => {
        if (search.length === 0) {
            return currentFilters;
        }

        return [
            ...currentFilters,
            [
                {
                    field: `name`,
                    op: `inci`,
                    value: search,
                },
            ]
        ];
    }, []);

    const groupActions = useMemo<ApiTableActionGroupElem[]>(() => {
        return [
            {
                icon: { fa: `trash` },
                actionNameTrans: `bulk.storage.delete`,
                confirmDescriptionTrans: `storagesManagement.bulkConfirmationModal.description`,
                action: async (ids) => {
                    for (const id of ids) {
                        try {
                            await StoragesService.deleteStorage({id});
                        }
                        catch (error) {
                            l.warn(`CANNOT BULK DELETE STORAGES:`, error);
                        }
                    }
                },
            },
        ];
    }, []);

    const service: ApiTableApiService<StorageItem, StoragesOrderedCol, StoragesFilterCond> = {
        get: (params) => StoragesService.getStorages(params),
    };

    const [editStorageData, setEditStorageData] = useState<StorageItem | null>(null);

    const contextMenuComponent = useMemo<ApiTableContextMenuElem<StorageItem>[]>(() => {
        return [
            {
                actionNameTrans: () => `storagesManagement.contextualActions.edit`,
                icon: () => ({ ni: `edit` }),
                action: async (item) => {
                    setEditStorageData(item);
                },
            },
            {
                actionNameTrans: () => `storagesManagement.contextualActions.delete`,
                icon: () => ({ fa: `trash` }),
                confirmDescriptionTrans: () => `storagesManagement.deleteModal.description`,
                action: async (item) => {
                    await StoragesService.deleteStorage({id: item.id});
                },
            },
        ];
    }, []);

    const [isNewStorageModalOpen, setIsNewStorageModalOpen] = useState(false);

    const [handleCreateHomeDir, createHomeDirLoading] = useHandler(async () => {
        await StoragesService.createHomeDir({
            users: "all",
            autoConfigureDefaultHomeDirParent: true,
        })
    }, (err) => {
        setCreateHomeDirError(`${err}`);
    });
    const [createHomeDirError, setCreateHomeDirError] = useState<string | null>(null)
    const disableCreateHomeDir = useMemo(() => createHomeDirLoading || createHomeDirError !== null, [createHomeDirLoading, createHomeDirError]);
    const { t } = useTranslation();
    return <Content>
        <BlockHead size="sm">
            <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                        <Trans i18nKey="storagesManagement.title" />
                    </BlockTitle>
                    <BlockDes className="text-soft">
                        <Trans i18nKey="storagesManagement.description" />
                    </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                    <Button
                        color="primary"
                        onClick={() => {
                            handleCreateHomeDir();
                        }}
                        disabled={disableCreateHomeDir}
                    >
                        <Trans i18nKey="storagesManagement.createHomeDir" />
                    </Button>
                    &nbsp;
                    <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => {
                            setIsNewStorageModalOpen(true);
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </BlockHeadContent>
            </BlockBetween>
        </BlockHead>

        <Block>
            <ApiTable<StorageItem, StoragesOrderedCol, StoragesFilterCond, ComputersTableFiltersState>
                service={service}
                colMapper={colMapper}
                defaultOrderByField="creationTime"
                defaultOrderByOrder="desc"
                searchMapper={searchMapper}
                cache={1}
                contextMenuComponents={contextMenuComponent}
                groupActions={groupActions}
            />
        </Block>

        <CreateStorageModal
            isOpen={isNewStorageModalOpen}
            onClose={() => {
                setIsNewStorageModalOpen(false);
            }}
        />
        <EditStorageModal
            isOpen={editStorageData !== null}
            data={editStorageData}
            onToggle={() => {
                setEditStorageData(null);
            }}
        />
        <ConfirmationModal
            isOpen={createHomeDirError !== null}
            title={t("storagesManagement.createHomeDirErrorModal.title")}
            description={t(`errorCode.${createHomeDirError}`).toString()}
            primaryBtnText={t("storagesManagement.createHomeDirErrorModal.primaryBtnText")}
            primaryActionCallback={() => {
                setCreateHomeDirError(null);
            }}
            primaryButtonColor="danger"
        />
    </Content>
}

type ComputersTableFiltersState = {
    name: string,
};