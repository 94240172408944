import { GetMyCallInvitesOutput, GetMyCallInvitesParams } from "@trantor/vdesk-api-schemas/dist/calls/getMyCallInvites";
import MainService from "./MainService";
import { StartCallOutput, StartCallParams } from "@trantor/vdesk-api-schemas/dist/calls/startCall";
import { GetCallHistoryOutput, GetCallHistoryParams } from "@trantor/vdesk-api-schemas/dist/calls/getCallHistory";
import { DismissCallInviteOutput, DismissCallInviteParams } from "@trantor/vdesk-api-schemas/dist/calls/dismissCallInvite";
import { CallInviteUsersOutput, CallInviteUsersParams } from "@trantor/vdesk-api-schemas/dist/calls/callInviteUsers";
import { CallLiveActionOutput, CallLiveActionParams } from "@trantor/vdesk-api-schemas/dist/calls/callLiveAction";
import { CallKeepaliveOutput, CallKeepaliveParams } from "@trantor/vdesk-api-schemas/dist/calls/callKeepalive";
import { CallGenModeratorCodeOutput, CallGenModeratorCodeParams } from "@trantor/vdesk-api-schemas/dist/calls/callGenModeratorCode";
import { JoinCallOutput, JoinCallParams } from "@trantor/vdesk-api-schemas/dist/calls/joinCall";

class CS {
    private client = MainService.client.calls;

    async startCall(params: StartCallParams): Promise<StartCallOutput> {
        return MainService.call(() => this.client.startCall(params));
    }
    async getMyCallInvites(params: GetMyCallInvitesParams): Promise<GetMyCallInvitesOutput> {
        return MainService.call(() => this.client.getMyCallInvites(params));
    }
    async dismissCallInvite(params: DismissCallInviteParams): Promise<DismissCallInviteOutput> {
        return MainService.call(() => this.client.dismissCallInvite(params));
    }
    async callInviteUsers(params: CallInviteUsersParams): Promise<CallInviteUsersOutput> {
        return MainService.call(() => this.client.callInviteUsers(params));
    }
    async callLiveAction(params: CallLiveActionParams): Promise<CallLiveActionOutput> {
        return MainService.call(() => this.client.callLiveAction(params));
    }
    async callKeepalive(params: CallKeepaliveParams): Promise<CallKeepaliveOutput> {
        return MainService.call(() => this.client.callKeepalive(params));
    }
    async callGenModeratorCode(params: CallGenModeratorCodeParams): Promise<CallGenModeratorCodeOutput> {
        return MainService.call(() => this.client.callGenModeratorCode(params));
    }
    async joinCall(params: JoinCallParams): Promise<JoinCallOutput> {
        return MainService.call(() => this.client.joinCall(params));
    }
    async getCallHistory(params: GetCallHistoryParams): Promise<GetCallHistoryOutput> {
        return MainService.call(() => this.client.getCallHistory(params));
    }
}
const ChatsService = new CS();
export default ChatsService;
