/**
 * Simple class to store the current value of an authentication token
 */
export class Token {
    _token;
    constructor() {
        this._token = null;
    }
    get() {
        return this._token;
    }
    set(token) {
        this._token = token;
    }
}
