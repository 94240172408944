import React, { useState, createContext, ChangeEvent } from "react";
import { folderList } from "./Data";
import { IFileManagerContext, FileData, FileType } from "./FileManagerTypes";

export const FileManagerContext = createContext<IFileManagerContext>({} as IFileManagerContext);

export const FileManagerContextProvider = (props: any) => {
    const [data, setData] = useState<FileData[]>(folderList as FileData[]);
    const [plan, setPlan] = useState("Starter");

    // Creates a new folder
    const createFolder = (filename: string, svg: JSX.Element) => {
        // let newFolder: FileData = {
        //     id: data.length + 1 + "",
        //     meta: {
        //         type: FileType.folder,
        //         name: filename,
        //         svg: svg,
        //         time: currentTime(),
        //         date: getDateStructured(new Date()),
        //         size: 0,
        //         starred: false,
        //         checked: false,
        //     },
        //     subFolder: [],
        // };
        // let defaultData = data;
        // let folderId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
        // let findFolder = defaultData.findIndex((item) => item.id === folderId);
        // if (folderId !== "" && defaultData[findFolder] !== undefined) {
        //     defaultData[findFolder].subFolder?.push({ fileId: data.length + 1 + "" });
        //     defaultData = [newFolder, ...defaultData];
        //     setData(defaultData);
        // } else {
        //     setData([newFolder, ...defaultData]);
        // }
    };

    // Creates a file
    const createFile = (files: FileData[]) => {
        // let defaultData = data;
        // let folderId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
        // let foundFolder = defaultData.findIndex((item) => item.id === folderId);
        // if (folderId !== "" && defaultData[foundFolder] !== undefined) {
        //     let fileArray: IFilePointer[] = [];
        //     files.forEach((item) => {
        //         fileArray.push({ fileId: item.id });
        //     });
        //     defaultData[foundFolder].subFolder = [
        //         ...(defaultData[foundFolder].subFolder as Array<IFilePointer>),
        //         ...fileArray,
        //     ];
        //     defaultData = [...defaultData, ...files];
        //     setData([...defaultData]);
        // } else {
        //     defaultData = [...defaultData, ...files];
        //     setData([...defaultData]);
        // }
    };

    // Download a folder/file
    const downloadFile = (file: FileData) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:" + file.meta.ext + ";charset=utf-8," + encodeURIComponent(file.meta.name);
        downloadLink.download = file.meta.name;
        downloadLink.click();
    };

    // Downloads all the selected file
    const selectorDownloadFile = () => {
        let defaultData = data;
        let checkedItems = defaultData.filter((item) => item.meta.checked === true);
        checkedItems.forEach((file) => {
            downloadFile(file);
        });
    };

    // Copy a folder/file
    const copyToFolder = (file: FileData, folder: string) => {
        // let defaultData = data;
        // if (folder !== "") {
        //     let findNewFolderIndex = defaultData.findIndex((item) => item.id === folder);
        //     defaultData[findNewFolderIndex].subFolder?.push({ fileId: file.id });
        //     setData([...defaultData]);
        // }
    };

    // Selector copy for lists
    const selectorCopyToFolder = (folder: string) => {
        let defaultData = data;
        let checkedItems = defaultData.filter((item) => item.meta.checked === true);
        checkedItems.forEach((file) => {
            copyToFolder(file, folder);
        });
    };

    // Move a folder/filer
    const moveFolder = (currentFolder: string, file: FileData, folder: string) => {
        // let defaultData = data;
        // if (folder !== "") {
        //     if (currentFolder === null) {
        //         copyToFolder(file, folder);
        //     } else {
        //         let findNewFolderIndex = defaultData.findIndex((item) => item.id === folder);
        //         let findPrevFolderIndex = defaultData.findIndex((item) => item.id === currentFolder);
        //         defaultData[findNewFolderIndex].subFolder?.push({ fileId: file.id });
        //         defaultData[findPrevFolderIndex].subFolder = defaultData[findPrevFolderIndex].subFolder?.filter(
        //             (item) => item.fileId !== file.id
        //         );
        //         setData([...defaultData]);
        //     }
        // }
    };

    // Shares a file/folder
    const shareFiles = (id: string) => {
        // let defaultData = data;
        // let found = defaultData.findIndex((item) => item.id === id);
        // defaultData[found].shared = { sharedTime: currentTime(), sharedDate: "Today" };
        // defaultData[found].meta.members = [{ user: "Illiash Hossain", theme: UserTheme.purple }];
        // setData([...defaultData]);
    };

    // Stars a folder/file
    const onStarClick = (id: string) => {
        // let defaultData = data;
        // let found = defaultData.findIndex((item) => item.id === id);
        // if (defaultData[found].meta.starred) {
        //     defaultData[found].meta.starred = false;
        // } else {
        //     defaultData[found].meta.starred = true;
        // }
        // setData([...defaultData]);
    };

    // Checks a file/folder
    const onFileCheck = (ev: ChangeEvent<HTMLInputElement>, id: string) => {
        // let defaultData = data;
        // let found = defaultData.findIndex((item) => item.id === id);
        // if (ev.target.checked) {
        //     defaultData[found].meta.checked = true;
        //     setData([...defaultData]);
        // } else {
        //     defaultData[found].meta.checked = false;
        //     setData([...defaultData]);
        // }
    };

    // Deletes a file/folder
    const deleteFolder = (id: string) => {
        // let defaultData = data;
        // let found = defaultData.findIndex((item) => item.id === id);
        // defaultData[found]["recovery"] = { deletedAt: "Today", deletedOn: currentTime() };
        // setData([...defaultData]);
    };

    // Deletes all the selected file
    const selectorDeleteFolder = () => {
        let defaultData = data;
        let checkedItems = defaultData.filter((item) => item.meta.checked === true);
        checkedItems.forEach((file) => {
            deleteFolder(file.id);
        });
    };

    // Restores a file
    const restoreFolder = (id: string) => {
        // let defaultData = data;
        // let found = defaultData.findIndex((item) => item.id === id);
        // delete defaultData[found]["recovery"];
        // setData([...defaultData]);
    };

    // Removes shares
    const removeShare = (id: string, type: FileType) => {
        // let defaultData = data;
        // let found = defaultData.findIndex((item) => item.id === id);
        // if (type === FileType.in) {
        //   defaultData[found].meta.members = [];
        //   setData([...defaultData]);
        // } else if (type === FileType.out) {
        //   defaultData[found].meta.shared = false;
        //   delete defaultData[found].shared;
        //   setData([...defaultData]);
        // } else {
        //   defaultData[found].meta.link = false;
        //   delete defaultData[found].shared;
        //   setData([...defaultData]);
        // }
    };

    // Permanently deletes folders/files
    const permanentDelete = (id: string) => {
        let defaultData = data;
        defaultData = defaultData.filter((item) => item.id !== id);
        setData([...defaultData]);
    };

    // Returns the total size for a folder;
    const getTotalSize = (folder: FileData) => {
        let folderChildren = folder.subFolder;
        let size = 0;
        if (folderChildren?.length === 0) {
            return size;
        } else {
            folderChildren?.forEach((el) => {
                let file = getFiles(el.fileId);
                if (file === undefined) {
                    size = size + 0;
                } else {
                    size = size + file.meta.size;
                }
            });
            return size;
        }
    };

    const getFiles = (id: string) => {
        return data.find((item) => item.id === id);
    };

    return (
        <FileManagerContext.Provider
            value={{
                contextData: [data, setData],
                planData: [plan, setPlan],
                createFolder: createFolder,
                createFile: createFile,
                onStarClick: onStarClick,
                deleteFolder: deleteFolder,
                selectorDeleteFolder: selectorDeleteFolder,
                restoreFolder: restoreFolder,
                removeShare: removeShare,
                shareFiles: shareFiles,
                onFileCheck: onFileCheck,
                downloadFile: downloadFile,
                selectorDownloadFile: selectorDownloadFile,
                copyToFolder: copyToFolder,
                selectorCopyToFolder: selectorCopyToFolder,
                moveFolder: moveFolder,
                permanentDelete: permanentDelete,
                getTotalSize: getTotalSize,
            }}
        >
            {props.children}
        </FileManagerContext.Provider>
    );
};
