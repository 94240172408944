import { BaseClient } from "./base.js";
import { CreateChatOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/createChat.js";
import { DeleteChatOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/deleteChat.js";
import { EditChatOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/editChat.js";
import { GetChatsOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/getChats.js";
import { LeaveChatOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/leaveChat.js";
import { PublishChatMessageOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/messages/publishChatMessage.js";
import { GetChatMessagesOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/messages/getChatMessages.js";
import { DeleteChatMessageOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/messages/deleteChatMessage.js";
import { parseError } from "../libs/errors.js";
import { SearchChatsOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/searchChats.js";
export class ChatClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async create(params) {
        const res = await this.call("createChat", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: CreateChatOutputSchema.parse(res.body)
        };
    }
    async get(params) {
        const res = await this.call("getChats", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetChatsOutputSchema.parse(res.body)
        };
    }
    async edit(params) {
        const res = await this.call("editChat", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: EditChatOutputSchema.parse(res.body)
        };
    }
    async delete(params) {
        const res = await this.call("deleteChat", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteChatOutputSchema.parse(res.body)
        };
    }
    async leave(params) {
        const res = await this.call("leaveChat", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: LeaveChatOutputSchema.parse(res.body)
        };
    }
    async publishMessage(params) {
        const res = await this.call("publishChatMessage", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: PublishChatMessageOutputSchema.parse(res.body)
        };
    }
    async getMessages(params) {
        const res = await this.call("getChatMessages", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetChatMessagesOutputSchema.parse(res.body)
        };
    }
    async deleteMessage(params) {
        const res = await this.call("deleteChatMessage", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteChatMessageOutputSchema.parse(res.body),
        };
    }
    async markLastReadMessage(params) {
        const res = await this.call("markLastReadChatMessage", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: undefined,
        };
    }
    async search(params) {
        const res = await this.call(`searchChats`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: SearchChatsOutputSchema.parse(res.body),
        };
    }
}
