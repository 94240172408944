import { NavigateFunction, Location } from "react-router";
import { GlobalErrorType } from "./GeneralTypes";

// custom hooks object to allow navigation and customHooks usability outside React components
type ExportedHooks = {
  navigate: NavigateFunction | null;
  location: Location | null;
  triggerGlobalError: ((er: GlobalErrorType) => void) | null;
  cleanGlobalError: (() => void) | null;
  [key: string]: any;
};

/**
 * Use with caution: high risk of naming mismanagement. This is an antipattern to be used only when necessary!
 */
const exportedHooks: ExportedHooks = {
  navigate: null,
  location: null,
  triggerGlobalError: null,
  cleanGlobalError: null,
};
export default exportedHooks;
