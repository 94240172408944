import { z } from "zod";
import { EmptyOutputSchema } from "../../common.js";
export const PublishChatMessageParamsSchema = z.object({
    chatId: z.string().uuid(),
    content: z.union([
        z.object({
            text: z.string().min(1),
            file: z.null().optional().default(null),
        }),
        z.object({
            text: z.null().optional().default(null),
            file: z.string().uuid(),
        }),
    ]),
});
export const PublishChatMessageOutputSchema = EmptyOutputSchema;
