import { Trans, useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Icon from "../../../../components/icon/Icon";
import { CSSProperties, ChangeEvent, useCallback, KeyboardEvent, useEffect, useState } from "react";
import { Block, Button } from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

interface RenameChatModalProps {
    isOpen: boolean;
    onToggle: () => void;
    originalChat: ChatItem | null;
    onConfirm?: (name: string) => Promise<void>;
    onCallback?: (name: string) => void;
    isWarning?: boolean;
}
const RenameChatModal: React.FC<RenameChatModalProps> = ({
    isOpen,
    onToggle,
    originalChat,
    onConfirm,
    onCallback,
    isWarning,
}) => {
    const { t } = useTranslation();
    const [nameText, setNameText] = useState<string>("");

    const resetModal = useCallback(() => {
        setNameText(originalChat?.name ?? "");
        setDisableModal(false);
        setError(null);
    }, [originalChat?.name]);
    useEffect(() => {
        resetModal();
    }, [isOpen, resetModal]);
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleConfirm();
        }
        if (event.key === "Esc") {
            handleToggle();
        }
    };
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNameText(e.target.value);
    };
    const handleToggle = () => {
        resetModal();
        onToggle();
    };
    const handleConfirm = () => {
        onConfirm &&
            onConfirm(nameText)
                .then(() => {
                    handleToggle();
                })
                .catch((err) => {
                    setError(`${err}`);
                    setDisableModal(true);
                });
        onCallback && onCallback(nameText);
    };
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleToggle();
                }}
                className="modal-dialog-centered"
                size="xs"
                scrollable={true}
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="newChatName.title" />
                        </h5>
                        <div className="mt-4">
                            {/* <Head title={t("newChatName.settingsUsers")}></Head> */}
                            <Content className="compact">
                                <Block>
                                    <Row className="gy-3 py-3">
                                        <Col sm={12}>
                                            <div className="form-group">
                                                <label className="form-label">
                                                    <Trans i18nKey="newChatName.nameFieldName" />
                                                </label>
                                                <input
                                                    disabled={disableModal}
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue={nameText}
                                                    onChange={handleNameChange}
                                                    onKeyUp={handleKeyUp}
                                                    placeholder={t("newChatName.nameFieldNamePlaceholder").toString()}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Block>
                            </Content>
                            <Row>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 pt-2">
                                        <li>
                                            <Button
                                                color="primary"
                                                size="md"
                                                type="button"
                                                disabled={
                                                    originalChat?.name === nameText || nameText === "" || disableModal
                                                }
                                                onClick={() => {
                                                    handleConfirm();
                                                }}
                                            >
                                                <Trans i18nKey="newChatName.renameChat" />
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (!disableModal) {
                                                        handleToggle();
                                                    }
                                                }}
                                                className="link link-light"
                                            >
                                                <Trans i18nKey="newChatName.cancel" />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default RenameChatModal;
