import React, { useState, useEffect, useContext, useRef } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    Col,
    PaginationComponent,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    Button,
    RSelect,
    UserAvatar,
    ConfirmModal,
} from "../../components/Component";
import { BulkAction, bulkActionOptions, findUpper, formatDate } from "../../utils/Utils";
import { UserData, UserRole } from "../../utils/GeneralTypes";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import l, { isDevelopment } from "../../utils/Log";
import { GetUsersContext } from "../../contexts/get-users/GetUsersContext";
import UserDetailsModal from "./modals/UserDetailsModal";
import UserNewModal from "./modals/UserNewModal";
import UserEditModal from "./modals/UserEditModal";
import DataTableContextMenu from "../../components/contextMenus/DataTableContextMenu";
import { UsersFilters, UsersOrderBy } from "@trantor/vdesk-api-schemas/dist/users/getUsers";
import ConfirmationModal from "./modals/ConfirmationModal";
import classNames from "classnames";
import FaIcon from "../../components/icon/FaIcon";

const UserRoleFilterOptions: { value: UserRole, label: string }[] = [
    { value: "regular", label: "Regular" },
    { value: "admin", label: "Admin" },
];

type UserFilters = {
    role: UserRole | null;
    enabled: boolean | null;
}

const INITIAL_USER_FILTERS: UserFilters = {
    role: null,
    enabled: null,
};

const INITIAL_USER_ORDER_BY: UsersOrderBy = {
    field: "creationTime",
    order: "asc",
}
const UserListCompact: React.FC = () => {
    const { t: origT } = useTranslation();
    const t = (code: string): string => {
        return origT(code).toString();
    };

    const {
        state: { users, page, totalItems, itemsPerPage },
        deleteUser,
        updateUser,
        changePage,
        changeFilters,
        changeOrderBy,
        changeItemsPerPage,
    } = useContext(GetUsersContext);

    const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());
    const [tableSm, updateTableSm] = useState(false);

    /**
     * Filtering
     */
    const nameSearchRef = useRef<HTMLInputElement>(null);
    const [userFilters, setUserFilters] = useState<UserFilters>(INITIAL_USER_FILTERS);
    const [userOrderBy, setUserOrderBy] = useState<UsersOrderBy>(INITIAL_USER_ORDER_BY);
    const compareUserFilters = (a: UserFilters, b: UserFilters): boolean => {
        if (a.role !== b.role || a.enabled !== b.enabled) {
            return false
        }
        return true;
    }

    const [modals, setModals] = useState({
        edit: false,
        add: false,
        delete: false,
        details: false,
        bulkConfirmation: false,
    });
    const [bulkAction, setBulkAction] = useState<BulkAction | undefined>(undefined);

    // function to change the selected property of an item
    const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
        setSelectedUsers((prev) => {
            const newSet = new Set(prev);

            if (e.currentTarget.checked) {
                newSet.add(id);
            } else {
                newSet.delete(id);
            }

            return newSet;
        });
    };

    const [deleteUserId, setDeleteUserId] = useState<string>("");
    const handleDeleteUser = async (id: string): Promise<void> => {
        try {
            await deleteUser({ id });

            l.info("user deleted", { id });
        } catch (err) {
            l.error("failed to delete user", { id, err });
        }
    };
    const prepareDeleteUserModal = (id: string): void => {
        setDeleteUserId(id);
        setModals({
            ...modals,
            delete: true,
        });
    };

    // function which fires on applying selected bulk action
    const runBulkAction = (): void => {
        const action = (() => {
            switch (bulkAction) {
                case "suspend":
                    return (userId: string) => updateUser({ id: userId, enabled: false });

                case "delete":
                    return (userId: string) => deleteUser({ id: userId });

                default:
                    return () => Promise.resolve();
            }
        })();

        Promise.allSettled(Array.from(selectedUsers).map(action)).then((results) => {
            console.table(results);
            setBulkAction(undefined);
        });
    };

    const triggerOrderBy = (whichOrderBy: UsersOrderBy["field"]): void => {
        let newOB = {...userOrderBy};
        if (newOB.field === whichOrderBy) {
            if (userOrderBy.order === "asc") {
                newOB.order = "desc";
            } else {
                newOB.order = "asc";
            }
        } else {
            newOB = {
                field: whichOrderBy,
                order: "asc",
            }
        }
        setUserOrderBy(newOB);
    }
    useEffect(() => {
        changeOrderBy(userOrderBy);
    }, [changeOrderBy, userOrderBy, userOrderBy.field, userOrderBy.order]);

    const renderOrderingIcon = (whichOrderBy: UsersOrderBy["field"]): JSX.Element => {
        if (userOrderBy.field === whichOrderBy) {
            if (userOrderBy.order === "asc") {
                return <FaIcon icon="chevron-up" sizeDL="xs" className="ms-1 ordering-icon"/>
            } else {
                return <FaIcon icon="chevron-down" sizeDL="xs" className="ms-1 ordering-icon"/>
            }
        } else {
            return <></>;
        }
    } 

    const triggerFiltering = (): void => {
        const filters: UsersFilters = [];
        /**
         * Filter by username or display name
         */
        const name = nameSearchRef.current?.value
        if (name !== undefined && name.length > 0) {
            filters.push(
                // Array of filters, so "username" and "display" name filters are OR'ed
                [
                    {
                        field: "username",
                        op: "inci",
                        value: name,
                    },
                    {
                        field: "displayName",
                        op: "inci",
                        value: name,
                    },
                ]
            );
        }

        /**
         * Filter by user role
         */
        if (userFilters.role !== null) {
            filters.push({
                field: "role",
                op: "eq",
                value: userFilters.role,
            });
        }

        /**
         * Filter by user enabled or disabled
         */
        if (userFilters.enabled !== null) {
            filters.push({
                field: "enabled",
                op: "eq",
                value: userFilters.enabled,
            })
        }

        changeFilters(filters);
    }

    const resetUserFilters = (): void => {
        setUserFilters(INITIAL_USER_FILTERS);
        triggerFiltering();
    }

    const clearNameSearch = (): void => {
        nameSearchRef.current!.value = "";
        triggerFiltering();
    }

    const toggleSelectAllRows = (selectAll: boolean): void => {
        setSelectedUsers(
            new Set(
                selectAll ? users.map((user) => user.id) : [],
            ),
        );

    };

    const editUserData = useRef<UserData | null>(null);

    // function that loads the want to editted data
    const onEditClick = (id: string): void => {
        const found = users.find((user) => user.id === id);
        if (found != null) {
            editUserData.current = {...found};
            setModals(() => ({ ...modals, edit: true }));
        }
    };
    const [userDetails, setUserDetails] = useState<UserData | null>(null);

    const onDetailsClick = (ud: UserData) => {
        setUserDetails(() => ud);
        setModals(() => ({ ...modals, details: !modals.details }));
    };

    const [contextMenuCoords, setContextMenuCoords] = useState<{ x: number; y: number; id: string } | null>(null);
    const closeAllContextMenus = () => {
        setContextMenuCoords(null);
    };
    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string): void => {
        e.preventDefault();
        setContextMenuCoords({ x: e.clientX, y: e.clientY, id });
    };

    const setBulkConfirmationModalVisibility = (isOpen: boolean) => {
        setModals((prev) => ({
            ...prev,
            bulkConfirmation: isOpen,
        }))
    }

    useEffect(() => {
        // Closes the context menu whenever you click or scroll
        window.addEventListener("click", closeAllContextMenus);
        window.addEventListener("drag", closeAllContextMenus);
        window.addEventListener("resize", closeAllContextMenus);
        window.addEventListener("scroll", closeAllContextMenus);
        return () => {
            window.removeEventListener("click", closeAllContextMenus);
            window.removeEventListener("drag", closeAllContextMenus);
            window.removeEventListener("resize", closeAllContextMenus);
            window.removeEventListener("scroll", closeAllContextMenus);
        };
    }, []);

    const bulkActions = bulkActionOptions.map((o) => ({
        value: o.value,
        label: t(o.label),
    }));

    return (
        <>
            <Head title={t("pageTitles.settingsUsers")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                <Trans i18nKey="usersManagement.title" />
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <Trans
                                    i18nKey="usersManagement.usersFound"
                                    shouldUnescape
                                    values={{count: totalItems}}
                                />
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button
                                color="primary"
                                className="btn-icon"
                                onClick={() => setModals({ ...modals, add: true })}
                            >
                                <Icon name="plus"></Icon>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner position-relative card-tools-toggle">
                            <div className="form-group">
                                <div className="form-control-wrap nk-chat-aside-search p-0 w-50">
                                    <div className="form-icon form-icon-left">
                                        <Icon name="search"></Icon>
                                    </div>
                                    <input
                                        ref={nameSearchRef}
                                        type="text"
                                        className="border-transparent form-focus-none form-control form-round"
                                        placeholder={t("usersManagement.searchPlaceholder")}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                triggerFiltering();
                                            } else if (e.key === "Escape") {
                                                clearNameSearch()
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="card-title-group">
                                <div className="card-tools">
                                    <div className="form-inline flex-nowrap gx-3">
                                        <div className="form-wrap">
                                            <RSelect
                                                options={bulkActions}
                                                placeholder={t("usersManagement.bulkActionsPlaceholder")}
                                                value={bulkAction}
                                                onChange={(e: { value: BulkAction }) => {
                                                    setBulkAction(e.value);
                                                    setBulkConfirmationModalVisibility(true);
                                                }}
                                                isDisabled={selectedUsers.size === 0}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <div className="toggle-wrap">
                                                <Button
                                                    className={`btn-icon toggle ${tableSm ? "active" : ""}`}
                                                    onClick={() => updateTableSm(true)}
                                                    color="primary"
                                                >
                                                    <Icon name="menu-right"></Icon>
                                                </Button>
                                                <div className={`toggle-content ${tableSm ? "content-active" : ""}`}>
                                                    <ul className="btn-toolbar gx-1">
                                                        <li className="toggle-close">
                                                            <Button
                                                                className="btn-icon btn-trigger toggle"
                                                                onClick={() => updateTableSm(false)}
                                                            >
                                                                <Icon name="arrow-left"></Icon>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <div className={classNames({
                                                                        dot: true,
                                                                        "dot-primary": !compareUserFilters(userFilters, INITIAL_USER_FILTERS)
                                                                    })} />
                                                                    <Icon name="filter-alt"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    end
                                                                    className="filter-wg dropdown-menu-md"
                                                                    style={{ overflow: "visible" }}
                                                                >
                                                                    <div className="dropdown-head">
                                                                        <span className="sub-title dropdown-title">
                                                                            <Trans i18nKey="filterUsers" />
                                                                        </span>
                                                                        <div className="dropdown">
                                                                            <DropdownItem
                                                                                href="#more"
                                                                                onClick={(ev) => {
                                                                                    ev.preventDefault();
                                                                                }}
                                                                                className="btn btn-sm btn-icon"
                                                                            >
                                                                                <Icon name="cross"></Icon>
                                                                            </DropdownItem>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dropdown-body dropdown-body-rg g-3">
                                                                        <Col size={12}>
                                                                            <div className="form-group">
                                                                                <label className="overline-title overline-title-alt">
                                                                                    <Trans i18nKey="usersManagement.role" />
                                                                                </label>
                                                                                <RSelect
                                                                                    options={UserRoleFilterOptions}
                                                                                    placeholder={t("usersManagement.rolePlaceholder")}
                                                                                    defaultValue={
                                                                                        userFilters.role
                                                                                            ? UserRoleFilterOptions.find((o) => o.value === userFilters.role)
                                                                                            : null
                                                                                    }
                                                                                    onChange={(e: { value: UserRole }) => {
                                                                                        setUserFilters((prev) => ({
                                                                                            ...prev,
                                                                                            role: e.value,
                                                                                        }));
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col size={12}>
                                                                            <div className="custom-control custom-control-sm custom-checkbox">
                                                                                <input
                                                                                    id="isEnabled"
                                                                                    type="checkbox"
                                                                                    checked={userFilters.enabled !== null ? userFilters.enabled : false}
                                                                                    className="custom-control-input"
                                                                                    onChange={(e) => {
                                                                                        setUserFilters((prev) => ({
                                                                                            ...prev,
                                                                                            enabled: e.target.checked,
                                                                                        }));
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="isEnabled"
                                                                                >
                                                                                    <Trans i18nKey="usersManagement.enabled" />
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                    <div className="dropdown-foot between">
                                                                        <a
                                                                            href="#reset"
                                                                            onClick={(ev) => {
                                                                                ev.preventDefault();
                                                                                resetUserFilters();
                                                                            }}
                                                                            className="clickable"
                                                                        >
                                                                            {t("usersManagement.resetFilter")}
                                                                        </a>
                                                                        <Button color="secondary" onClick={triggerFiltering}>
                                                                            <Trans i18nKey="usersManagement.filter" />
                                                                        </Button>
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    tag="a"
                                                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                                                >
                                                                    <Icon name="list-thumb"></Icon>
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="dropdown-menu-xs">
                                                                    <ul className="link-check">
                                                                        <li>
                                                                            <span>{t("usersManagement.itemsPerPage")}</span>
                                                                        </li>
                                                                        {(isDevelopment()
                                                                            ? [1, 10, 15, 25, 50, 75, 100]
                                                                            : [10, 15, 25, 50, 75, 100]
                                                                        ).map((pageSize) => (
                                                                            <li
                                                                                key={pageSize}
                                                                                className={
                                                                                    itemsPerPage === pageSize
                                                                                        ? "active"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <DropdownItem
                                                                                    tag="a"
                                                                                    href="#dropdownitem"
                                                                                    onClick={(ev) => {
                                                                                        ev.preventDefault();
                                                                                        changeItemsPerPage(pageSize);
                                                                                    }}
                                                                                >
                                                                                    {pageSize}
                                                                                </DropdownItem>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <DataTableBody compact>
                            <DataTableHead>
                                <DataTableRow size="xxs" className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => toggleSelectAllRows(e.target.checked)}
                                            id="uid"
                                        />
                                        <label className="custom-control-label" htmlFor="uid"></label>
                                    </div>
                                </DataTableRow>
                                <DataTableRow>
                                    <a
                                        className="sub-text"
                                        href="#displayName"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            triggerOrderBy("displayName");
                                        }}
                                    >
                                        <Trans i18nKey="usersManagement.displayName" />{renderOrderingIcon("displayName")}
                                    </a>
                                </DataTableRow>
                                <DataTableRow>
                                    <a
                                        className="sub-text"
                                        href="#username"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            triggerOrderBy("username");
                                        }}
                                    >
                                        <Trans i18nKey="usersManagement.username" />{renderOrderingIcon("username")}
                                    </a>
                                </DataTableRow>
                                <DataTableRow size="sm">
                                    <span className="sub-text">
                                        <Trans i18nKey="usersManagement.role" />
                                    </span>
                                </DataTableRow>
                                <DataTableRow size="lg">
                                    <a
                                        className="sub-text"
                                        href="#lastLogin"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            triggerOrderBy("lastLogin");
                                        }}
                                    >
                                        <Trans i18nKey="usersManagement.lastLogin" />{renderOrderingIcon("lastLogin")}
                                    </a>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <a
                                        className="sub-text"
                                        href="#creationTime"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            triggerOrderBy("creationTime");
                                        }}
                                    >
                                        <Trans i18nKey="usersManagement.creationTime" />{renderOrderingIcon("creationTime")}
                                    </a>
                                </DataTableRow>
                                <DataTableRow size="xs">
                                    <span className="sub-text">
                                        <Trans i18nKey="usersManagement.enabledLabel" />
                                    </span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-tools text-end">{""}
                                    {/* <UncontrolledDropdown>
                                            <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                                            <Icon name="plus"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end className="dropdown-menu-xs">
                                            <ul className="link-tidy sm no-bdr">
                                                <li>
                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="bl" />
                                                    <label className="custom-control-label" htmlFor="bl">
                                                    Balance
                                                    </label>
                                                </div>
                                                </li>
                                                <li>
                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="ph" />
                                                    <label className="custom-control-label" htmlFor="ph">
                                                    Phone
                                                    </label>
                                                </div>
                                                </li>
                                                <li>
                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="vri" />
                                                    <label className="custom-control-label" htmlFor="vri">
                                                    Verified
                                                    </label>
                                                </div>
                                                </li>
                                                <li>
                                                <div className="custom-control custom-control-sm custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="st" />
                                                    <label className="custom-control-label" htmlFor="st">
                                                    Status
                                                    </label>
                                                </div>
                                                </li>
                                            </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown> */}
                                </DataTableRow>
                            </DataTableHead>
                            {/*Head*/}
                            {users.map((user) => {
                                const lastLogin = DateTime.fromMillis(user.lastLogin ?? NaN).isValid
                                    ? formatDate(user.lastLogin)
                                    : t("usersManagement.invalidLastLogin");
                                const creationTime = DateTime.fromMillis(user.creationTime ?? NaN).isValid
                                    ? formatDate(user.creationTime ?? null)
                                    : t("usersManagement.invalidLastLogin");
                                return (
                                    <DataTableItem key={user.id} onContextMenu={(ev) => handleContextMenu(ev, user.id)}>
                                        <DataTableRow size="xxs" className="nk-tb-col-check">
                                            {contextMenuCoords && contextMenuCoords.id === user.id && (
                                                <DataTableContextMenu
                                                    key={`context-menu-${user.username}`}
                                                    coords={contextMenuCoords}
                                                    row={user}
                                                    functions={{
                                                        edit: onEditClick,
                                                        delete: prepareDeleteUserModal,
                                                    }}
                                                />
                                            )}
                                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    defaultChecked={selectedUsers.has(user.id)}
                                                    id={user.id + "uid1"}
                                                    key={Math.random()}
                                                    onChange={(e) => onSelectChange(e, user.id)}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={user.id + "uid1"}
                                                ></label>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow size="xxs">
                                            <div
                                                className="user-card clickable-text unselectable-text"
                                                onClick={() => onDetailsClick(user)}
                                            >
                                                <UserAvatar
                                                    theme={`pink`}
                                                    className="xs"
                                                    text={findUpper(user.displayName)}
                                                ></UserAvatar>
                                                <div className="user-info">
                                                    <span className="tb-lead">{user.displayName}</span>
                                                </div>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow size="xl">
                                            <div
                                                className="user-card clickable-text unselectable-text"
                                                onClick={() => onDetailsClick(user)}
                                            >
                                                <div className="user-info">
                                                    <span className="tb-lead">{user.username}</span>
                                                </div>
                                            </div>
                                        </DataTableRow>
                                        <DataTableRow size="sm">
                                            <span className="unselectable-text">
                                                <Trans i18nKey={`roleLabels.${user.role}`} />
                                            </span>
                                        </DataTableRow>
                                        <DataTableRow size="md">
                                            <span className="unselectable-text">{lastLogin}</span>
                                        </DataTableRow>
                                        <DataTableRow size="md">
                                            <span className="unselectable-text">{creationTime}</span>
                                        </DataTableRow>
                                        <DataTableRow size="lg">
                                            <ul className="list-status unselectable-text">
                                                <li>
                                                    <Icon
                                                        className={`text-${user.enabled ? "success" : "danger"}`}
                                                        name={`${user.enabled ? "check-circle" : "alarm-alt"}`}
                                                    ></Icon>{" "}
                                                    <span>
                                                        <Trans
                                                            i18nKey={`usersManagement.${user.enabled ? "enabled" : "disabled"
                                                                }`}
                                                        />
                                                    </span>
                                                </li>
                                            </ul>
                                        </DataTableRow>
                                        {/* <DataTableRow>
                        <span
                          className={`tb-status text-${item.status === "Active" ? "success" : item.status === "Pending" ? "warning" : "danger"
                            }`}
                        >
                          {item.status}
                        </span>
                      </DataTableRow> */}
                                        <DataTableRow className="nk-tb-col-tools d-none d-xl-table-cell">
                                            <ul className="nk-tb-actions gx-1">
                                                {/* {(cookies.sessionData as SessionData).loggedUser.id !== item.id && (<li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-trigger btn-icon"
                              id={"edit" + item.id}
                              icon="edit-alt-fill"
                              direction="top"
                              text="Edit"
                            />
                          </li>)}
                          {(cookies.sessionData as SessionData).loggedUser.id !== item.id && item.status !== "Suspend" && (
                            <React.Fragment>
                              <li className="nk-tb-action-hidden" onClick={() => suspendUser(item.id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"suspend" + item.id}
                                  icon="user-cross-fill"
                                  direction="top"
                                  text={t(`userAction.suspend`).toString()}
                                />
                              </li>
                            </React.Fragment>
                          )} */}
                                                <li>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            tag="a"
                                                            className="dropdown-toggle btn btn-icon btn-trigger"
                                                        >
                                                            <Icon name="more-h"></Icon>
                                                        </DropdownToggle>
                                                        <DropdownMenu end>
                                                            <ul className="link-list-opt no-bdr">
                                                                <li onClick={() => onEditClick(user.id)}>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#edit"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        <Icon name="edit"></Icon>
                                                                        <span>
                                                                            {t(
                                                                                "usersManagement.contextualActions.edit"
                                                                            )}
                                                                        </span>
                                                                    </DropdownItem>
                                                                </li>
                                                                <li onClick={() => prepareDeleteUserModal(user.id)}>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#delete"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}
                                                                    >
                                                                        <Icon name="trash"></Icon>
                                                                        <span>
                                                                            {t(
                                                                                "usersManagement.contextualActions.delete"
                                                                            )}
                                                                        </span>
                                                                    </DropdownItem>
                                                                </li>
                                                            </ul>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </DataTableRow>
                                    </DataTableItem>
                                );
                            })}
                        </DataTableBody>
                        {(totalItems > itemsPerPage || (totalItems === itemsPerPage && page > 1)) && (
                            <div className="card-inner">
                                <PaginationComponent
                                    itemPerPage={itemsPerPage}
                                    totalItems={totalItems}
                                    paginate={(page: number) => changePage(page)}
                                    currentPage={page}
                                />
                            </div>
                        )}
                        {totalItems === 0 && (
                            <div className="card-inner">
                                <div className="text-center">
                                    <span className="text-silent">
                                        <Trans i18nKey="usersManagement.noDataFound" />
                                    </span>
                                </div>
                            </div>
                        )}
                    </DataTable>
                </Block>
                <UserNewModal isOpen={modals.add} onToggle={() => setModals({ ...modals, add: !modals.add })} />
                <UserEditModal
                    isOpen={modals.edit}
                    data={modals.edit ? editUserData.current : null}
                    onToggle={() => {
                        // setEditUserData(undefined);
                        setModals({ ...modals, edit: !modals.edit });
                    }}
                />
                <UserDetailsModal
                    isOpen={modals.details}
                    onToggle={() => setModals({ ...modals, details: !modals.details })}
                    userData={userDetails}
                />
                <ConfirmModal
                    isOpen={modals.delete}
                    mode="user-delete"
                    onToggle={() => setModals({ ...modals, delete: !modals.delete })}
                    onConfirm={()=>handleDeleteUser(deleteUserId)}
                />
                {/* Bulk confirmation modal */}
                <ConfirmationModal
                    isOpen={modals.bulkConfirmation}
                    title={t("usersManagement.bulkConfirmationModal.title")}
                    description={t("usersManagement.bulkConfirmationModal.description")}
                    primaryBtnText={t("usersManagement.bulkConfirmationModal.confirm")}
                    secondaryBtnText={t("usersManagement.bulkConfirmationModal.cancel")}
                    primaryActionCallback={() => {
                        setBulkConfirmationModalVisibility(false);
                        runBulkAction();
                    }}
                    secondaryActionCallback={() => {
                        setBulkConfirmationModalVisibility(false)
                        setBulkAction(undefined);
                    }}
                />
            </Content>
        </>
    );
};
export default UserListCompact;
