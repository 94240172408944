import React from "react";
import classnames from "classnames";

interface ColProps {
    sm?: number;
    offsetSm?: number;
    md?: number;
    offsetMd?: number;
    lg?: number;
    offsetLg?: number;
    xl?: number;
    offsetXl?: number;
    xxl?: number;
    offsetXxl?: number;
    size?: number;
    offset?: number;
    className?: string;
    [key: string]: any;
}
export const Col: React.FC<ColProps> = ({ sm,  offsetSm, md, offsetMd, lg, offsetLg, xl, offsetXl, xxl, offsetXxl, size, offset, className, ...props }) => {
    var classNames = classnames({
        [`col-sm-${sm}`]: sm,
        [`offset-sm-${offsetSm}`]: offsetSm,
        [`col-md-${md}`]: md,
        [`offset-md-${offsetMd}`]: offsetMd,
        [`col-xl-${xl}`]: xl,
        [`offset-xl-${offsetXl}`]: offsetXl,
        [`col-lg-${lg}`]: lg,
        [`offset-lg-${offsetLg}`]: offsetLg,
        [`col-xxl-${xxl}`]: xxl,
        [`offset-xxl-${offsetXxl}`]: offsetXxl,
        [`col-${size}`]: size,
        [`offset-${size}`]: offset,
        [`${className}`]: className,
    });
    return <div className={classNames}>{props.children}</div>;
};
interface RowProps {
    className?: string;
    [key: string]: any;
}
export const Row: React.FC<RowProps> = ({ className, ...props }) => {
    const rowClass = classnames({
        row: true,
        [`${className}`]: className,
    });
    return <div className={rowClass}>{props.children}</div>;
};
