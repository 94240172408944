import { useCallback, useContext, useMemo, useRef, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent, RSelect } from "../../components/Component";
import { GroupsContext, GroupsContextProvider } from "../../contexts/get-groups/GroupsContext";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import l, { isDevelopment } from "../../utils/Log";
import { formatDate } from "../../utils/Utils";
import GroupDetailsModal, { ModalState as GroupModalState } from "./modals/groups/GroupDetailsModal";
import  GroupNewModal, {} from "./modals/groups/CreateGroupModal";
import { GroupData, GroupsContextType } from "../../contexts/get-groups/GroupsContextTypes";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ConfirmationModal from "./modals/ConfirmationModal";
import GroupEditModal from "./modals/groups/EditGroupModal";
import _ from "lodash";
import { GroupsFilters } from "@trantor/vdesk-api-schemas/dist/groups/getGroups.js";
import { ComputersContextProvider } from "../../contexts/get-computers/ComputersContext";
import { UserContextProvider } from "./UserContext";
import { DefaultSpinner } from "../../components/default-spinner";
import { GroupsService } from "../../services";
import { ApiTable, ApiTableActionGroupElem, ApiTableApiFilters, ApiTableApiService, ApiTableColMapper, ApiTableContextMenuElem, ApiTableFiltersMapper, ApiTableSearchMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import classNames from "classnames";

const GroupsSettingsPage = () => <GroupsTable />;

export default GroupsSettingsPage;


const getGroups = GroupsService.getGroups.bind(GroupsService);

type GroupsItem = ItemOfApi<typeof getGroups>;

type GroupsOrderedCol = OrderedColOfApi<typeof getGroups>;

type GroupsFilterCond = FilterCondOfApi<typeof getGroups>;

function GroupsTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<GroupsItem, GroupsOrderedCol> = useMemo(() => {
        return [
            {
                colNameTranslationKey: `groupsManagement.attributes.id`,
                hideInTable: true,
                render: ({ item }) => <span>{item.id}</span>,
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.name`,
                canOrder: `name`,
                render: ({ item }) => <span className="tb-lead">{item.name}</span>,
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.ldap`,
                render: ({ item }) => {
                    if (item.ldapInfo !== null) {
                        return <span>{item.ldapInfo.ldapDn}</span>
                    }
                    return <Trans i18nKey="groupsManagement.noLdapGroup" />
                }
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.memberUsers`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.memberUsers.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.memberUsers.map(g => <li key={`User name: ${g.displayName}`}>
                                "{g.displayName}"
                                &nbsp;
                                <span>{g.ldapInfo!== null ? `(${g.ldapInfo.ldapDn})` : <Trans i18nKey="groupsManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="groupsManagement.noMemberUsers" /></span>
                }
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.memberGroups`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.memberGroups.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.memberGroups.map(g => <li key={`Group name: ${g.name}`}>
                                "{g.name}"
                                &nbsp;
                                <span>{g.ldapInfo!== null ? `(${g.ldapInfo.ldapDn})` : <Trans i18nKey="groupsManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="groupsManagement.noMemberGroups" /></span>
                }
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.memberComputers`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.memberComputers.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.memberComputers.map(g => <li key={`Computer name: ${g.name}`}>
                                "{g.name}"
                                &nbsp;
                                <span>{g.ldapInfo!== null ? `(${g.ldapInfo.ldapDn})` : <Trans i18nKey="groupsManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="groupsManagement.noMemberComputers" /></span>
                }
            },
            {
                colNameTranslationKey: `groupsManagement.attributes.memberOf`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.memberOf.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.memberOf.map(g => <li key={`GroupOf name: ${g.groupName}`}>
                                "{g.groupName}"
                                &nbsp;
                                <span>{g.ldapInfo!== null ? `(${g.ldapInfo.ldapDn})` : <Trans i18nKey="groupsManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="groupsManagement.noMemberOf" /></span>
                }
            },
        ];
    }, []);

    const searchMapper: ApiTableSearchMapper<GroupsFilterCond> = useCallback((search, currentFilters) => {
        if (search.length === 0) {
            return currentFilters;
        }

        return [
            ...currentFilters,
            [
                {
                    field: `name`,
                    op: `inci`,
                    value: search,
                },
            ]
        ];
    }, []);

    const groupActions = useMemo<ApiTableActionGroupElem[]>(() => {
        return [
            {
                icon: { fa: `trash` },
                actionNameTrans: `groupsManagement.groupActions.delete`,
                confirmDescriptionTrans: `groupsManagement.groupActions.delete.confirm`,
                action: async (ids) => {
                    for (const id of ids) {
                        try {
                            await GroupsService.deleteGroup({id});
                        }
                        catch (error) {
                            l.warn(`CANNOT BULK DELETE GROUPS:`, error);
                        }
                    }
                },
            },
        ];
    }, []);

    const service: ApiTableApiService<GroupsItem, GroupsOrderedCol, GroupsFilterCond> = {
        get: (params) => GroupsService.getGroups(params),
    };

    const [editGroupData, setEditGroupData] = useState<GroupsItem | null>(null);

    const contextMenuComponent = useMemo<ApiTableContextMenuElem<GroupsItem>[]>(() => {
        return [
            {
                actionNameTrans: () => `groupsManagement.contextMenu.edit.actionName`,
                icon: () => ({ ni: `edit` }),
                action: async (item) => {
                    setEditGroupData(item);
                },
            },
            {
                actionNameTrans: () => `groupsManagement.contextMenu.delete.actionName`,
                icon: () => ({ fa: `trash` }),
                confirmDescriptionTrans: () => `groupsManagement.contextMenu.delete.confirm`,
                action: async (item) => {
                    await GroupsService.deleteGroup({id: item.id});
                },
            },
        ];
    }, []);

    const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);

    return <Content>
        <BlockHead size="sm">
            <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                        <Trans i18nKey="groupsManagement.title" />
                    </BlockTitle>
                    <BlockDes className="text-soft">
                        <Trans i18nKey="groupsManagement.description" />
                    </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                    <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => {
                            setIsNewGroupModalOpen(true);
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </BlockHeadContent>
            </BlockBetween>
        </BlockHead>

        <Block>
            <ApiTable<GroupsItem, GroupsOrderedCol, GroupsFilterCond, GroupsTableFiltersState>
                service={service}
                colMapper={colMapper}
                defaultOrderByField={`name`}
                searchMapper={searchMapper}
                cache={1}
                contextMenuComponents={contextMenuComponent}
                groupActions={groupActions}
            />
        </Block>
        
        <GroupNewModal
            isOpen={isNewGroupModalOpen}
            onClose={() => {
                setIsNewGroupModalOpen(false);
            }}
        />

        <GroupEditModal
            isOpen={editGroupData !== null}
            groupData={editGroupData}
            onToggle={() => {
                setEditGroupData(null);
            }}
        />
    </Content>
}

type GroupsTableFiltersState = {
    name: string,
};