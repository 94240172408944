import MainService from "./MainService";

import {
    CreateComputerParams,
    CreateComputerOutput,

    DeleteComputerParams,

    EditComputerParams,

    GetComputersParams,
    GetComputersOutput,
} from "@trantor/vdesk-api-client/dist/clients/computers";

class CS {
    private client = MainService.client.computers;

    async getComputers(params: GetComputersParams): Promise<GetComputersOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async createComputer(params: CreateComputerParams): Promise<CreateComputerOutput> {
        return MainService.call(() => this.client.create(params));
    }

    async editComputer(params: EditComputerParams): Promise<void> {
        return MainService.call(() => this.client.edit(params));
    }

    async deleteComputer(params: DeleteComputerParams): Promise<void> {
        return MainService.call(() => this.client.delete(params));
    }
}
const ComputersService = new CS();
export default ComputersService;
