import React from "react";
// import ErrorImage from "../../images/gfx/error-404.svg";
import PageContainer from "../../layout/page-container/PageContainer";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../components/Component";
import { Trans } from "react-i18next";
import FaIcon from "../../components/icon/FaIcon";

const Error404ModernPage: React.FC = () => {
  return (
    <PageContainer>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <FaIcon icon="file-circle-question" size="xl" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">
              <Trans i18nKey="generalErrorPageIs404.title" />
            </h3>
            <p className="nk-error-text">
              <Trans i18nKey="generalErrorPageIs404.message" />
            </p>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button color="primary" size="lg" className="mt-2">
                <Trans i18nKey="generalErrorPageIs404.goHome" />
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </PageContainer>
  );
};
export default Error404ModernPage;
