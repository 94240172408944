import { z } from "zod";
import { HostnameSchema, PortNumberSchema } from "../common.js";
import { ConnectionProtocolSchema } from "./common.js";
export const CreateComputerParamsSchema = z.object({
    name: z.string().min(1),
    connectionParams: z.object({
        proto: ConnectionProtocolSchema,
        hostname: HostnameSchema,
        port: PortNumberSchema,
    }),
    macAddress: z.string().min(1).nullable().optional().default(null),
    wakeOnLanTimeInSeconds: z.number().int().min(1).nullable().optional().default(null),
    memberOf: z.string().uuid().array().optional().default([]),
});
export const CreateComputerOutputSchema = z.object({
    id: z.string().uuid(),
});
