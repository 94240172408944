import { z } from "zod";
import { EmptyOutputSchema, HostnameSchema, PortNumberSchema } from "../common.js";
import { ConnectionProtocolSchema } from "./common.js";
export const EditComputerParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    connectionParams: z.object({
        proto: ConnectionProtocolSchema,
        hostname: HostnameSchema,
        port: PortNumberSchema,
    }).optional(),
    macAddress: z.string().min(1).nullable().optional(),
    wakeOnLanTimeInSeconds: z.number().int().min(1).nullable().optional(),
    memberOf: z.string().uuid().array().optional(),
});
export const EditComputerOutputSchema = EmptyOutputSchema;
