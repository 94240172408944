import { BaseClient } from "./base.js";
import { PingOutputSchema } from "@trantor/vdesk-api-schemas/dist/ping.js";
import { ListFunctionsOutputSchema } from "@trantor/vdesk-api-schemas/dist/listFunctions.js";
import { parseError } from "../libs/errors.js";
import { GetSettingsOutputSchema } from "@trantor/vdesk-api-schemas/dist/getSettings.js";
export class Miscellaneous extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async ping() {
        const res = await super.call("ping");
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: PingOutputSchema.parse(res.body)
        };
    }
    async listFunctions() {
        const res = await super.call("listFunctions");
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: ListFunctionsOutputSchema.parse(res.body)
        };
    }
    async getSettings() {
        const res = await super.call(`getSettings`);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetSettingsOutputSchema.parse(res.body),
        };
    }
    async changeSettings(params) {
        const res = await super.call(`changeSettings`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: undefined,
        };
    }
}
