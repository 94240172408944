import { SearchChatsOutput, SearchChatsParams } from "@trantor/vdesk-api-schemas/dist/chats/searchChats";
import MainService from "./MainService";

import {
    CreateChatParams,
    CreateChatOutput,
    DeleteChatParams,
    DeleteChatOutput,
    EditChatParams,
    EditChatOutput,
    GetChatMessagesParams,
    GetChatMessagesOutput,
    GetChatsParams,
    GetChatsOutput,
    LeaveChatParams,
    LeaveChatOutput,
    PublishChatMessageParams,
    PublishChatMessageOutput,
    DeleteChatMessageParams,
    MarkLastReadChatMessageParams
} from "@trantor/vdesk-api-client/dist/clients/chat";

class CS {
    private client = MainService.client.chat;

    async getChats(params: GetChatsParams): Promise<GetChatsOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async createChat(params: CreateChatParams): Promise<CreateChatOutput> {
        return MainService.call(() => this.client.create(params));
    }

    async deleteChat(params: DeleteChatParams): Promise<DeleteChatOutput> {
        return MainService.call(() => this.client.delete(params));
    }

    async editChat(params: EditChatParams): Promise<EditChatOutput> {
        return MainService.call(() => this.client.edit(params));
    }

    async getMessages(params: GetChatMessagesParams): Promise<GetChatMessagesOutput> {
        return MainService.call(() => this.client.getMessages(params));
    }

    async publishMessage(params: PublishChatMessageParams): Promise<PublishChatMessageOutput> {
        return MainService.call(() => this.client.publishMessage(params));
    }

    async deleteMessage(params: DeleteChatMessageParams): Promise<void> {
        return MainService.call(() => this.client.deleteMessage(params));
    }

    async markLastReadChatMessage(params: MarkLastReadChatMessageParams): Promise<void> {
        return MainService.call(() => this.client.markLastReadMessage(params));
    }

    async leaveChat(params: LeaveChatParams): Promise<LeaveChatOutput> {
        return MainService.call(() => this.client.leave(params));
    }

    async search(params: SearchChatsParams): Promise<SearchChatsOutput> {
        return MainService.call(() => this.client.search(params));
    }
}
const ChatsService = new CS();
export default ChatsService;
