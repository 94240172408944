import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { RSelect } from "../../../../components/Component";
import Icon from "../../../../components/icon/Icon";
import { ComputersContext } from "../../../../contexts/get-computers/ComputersContext";
import { ConnectionProtocol } from "@trantor/vdesk-api-schemas/dist/computers/common.js";
import l from "../../../../utils/Log";
import { ComputersService } from "../../../../services";
import StoragesService from "../../../../services/StorageService";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};
interface CreateStorageModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateStorageModal: React.FC<CreateStorageModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const createStorage = StoragesService.createStorage.bind(StoragesService);
    
    type CreateComputerFormInputs = {
        name: string;
        endpoint: string;
        accessKeyId: string;
        secretAccessKey: string;
        bucket: string;
        region: string;
    };

    const createGroupForm = useForm<CreateComputerFormInputs>({
        defaultValues: {
            name: "",
            endpoint: "",
            accessKeyId: "",
            secretAccessKey: "",
            bucket: "",
            region: "",
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    });

    const [formData, setFormData] = useState<{
        name: string;
        endpoint: string;
        accessKeyId: string;
        secretAccessKey: string;
        bucket: string;
        region: string;
    }>({
        name: "",
        endpoint: "",
        accessKeyId: "",
        secretAccessKey: "",
        bucket: "",
        region: "",
    });

    const resetForm = useCallback((): void => {
        setFormData({
            name: "",
            endpoint: "",
            accessKeyId: "",
            secretAccessKey: "",
            bucket: "",
            region: "",
        });
        createGroupForm.setValue("name", "");
    }, [createGroupForm]);

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen, resetForm]);

    const onFormNewSubmit: SubmitHandler<CreateComputerFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            console.log("submitData", submitData);

            const { id } = await createStorage({
                name: submitData.name,
                endpoint: submitData.endpoint,
                accessKeyId: submitData.accessKeyId,
                secretAccessKey: submitData.secretAccessKey,
                bucket: submitData.bucket,
                region: submitData.region,
            });

            l.info("computer created", { id });
            onClose();
        } catch (err) {
            l.error("failed to create computer", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            onClose();
            resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    onClose();
                    resetForm();
                    resetForm();
                }}
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="storagesManagement.formModal.title" />
                </h5>
                <div className="mt-4">
                    <Form className="row gy-4" onSubmit={createGroupForm.handleSubmit(onFormNewSubmit)}>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.name}
                                    placeholder={t("storagesManagement.attributes.namePlaceholder").toString()}
                                    {...createGroupForm.register("name", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.endpoint" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.endpoint}
                                    placeholder={t("storagesManagement.attributes.endpointPlaceholder").toString()}
                                    {...createGroupForm.register("endpoint", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col><Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.accessKeyId" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.accessKeyId}
                                    placeholder={t("storagesManagement.attributes.accessKeyIdPlaceholder").toString()}
                                    {...createGroupForm.register("accessKeyId", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col><Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.secretAccessKey" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="password"
                                    defaultValue={formData.secretAccessKey}
                                    placeholder={t("storagesManagement.attributes.secretAccessKeyPlaceholder").toString()}
                                    {...createGroupForm.register("secretAccessKey", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col><Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.bucket" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.bucket}
                                    placeholder={t("storagesManagement.attributes.bucketPlaceholder").toString()}
                                    {...createGroupForm.register("bucket", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col><Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.region" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.region}
                                    placeholder={t("storagesManagement.attributes.regionPlaceholder").toString()}
                                    {...createGroupForm.register("region", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>

                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button disabled={disableModal} color="primary" size="md" type="submit">
                                            <Trans i18nKey="storagesManagement.formModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    resetForm();
                                                    onClose();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="storagesManagement.formModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default CreateStorageModal;
