import React, { useCallback, useMemo, useState } from "react";

import { Trans } from "react-i18next";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import l from "../../utils/Log";
import { formatDate } from "../../utils/Utils";
import CreateComputerModal from "./modals/computers/CreateComputerModal";
import EditComputerModal from "./modals/computers/EditComputerModal";
import { ApiTable, ApiTableActionGroupElem, ApiTableApiService, ApiTableColMapper, ApiTableContextMenuElem, ApiTableSearchMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import { ComputersService } from "../../services";
import { t } from "i18next";
import { RoundedBadge } from "../../components/chat/Badge";
import classNames from "classnames";

const ComputersSettingsPage = () => <ComputersTable />;

export default ComputersSettingsPage;


const getComputers = ComputersService.getComputers.bind(ComputersService);

type ComputersItem = ItemOfApi<typeof getComputers>;

type ComputersOrderedCol = OrderedColOfApi<typeof getComputers>;

type ComputersFilterCond = FilterCondOfApi<typeof getComputers>;

function ComputersTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<ComputersItem, ComputersOrderedCol> = useMemo(() => {
        return [
            {
                colNameTranslationKey: `computersManagement.attributes.id`,
                hideInTable: true,
                render: ({ item }) => <span>{item.id}</span>,
            },
            {
                colNameTranslationKey: `computersManagement.attributes.name`,
                canOrder: `name`,
                render: ({ item }) => <span className="tb-lead">{item.name}</span>,
            },
            {
                colNameTranslationKey: `computersManagement.attributes.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                // TODO: translate
                colNameTranslationKey: `Online`,
                render: ({ item }) => <span>
                    <Icon
                        className={classNames({
                            "text-success": item.online,
                            "text-danger": !item.online,
                            "unselectable-text": true,
                        })}
                        name={`${item.online ? "check-circle" : "cross-circle"}`}
                    ></Icon>
                </span>,
            },
            {
                colNameTranslationKey: `computersManagement.attributes.ldap`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.ldapInfo !== null) {
                        return <span>{item.ldapInfo.ldapDn}</span>
                    }
                    return <span><Trans i18nKey="computersManagement.noLdapGroup" /></span>
                }
            },
            {
                colNameTranslationKey: `${t("computersManagement.attributes.connectionProtocol")}://${t("computersManagement.attributes.connectionHostname")}:${t("computersManagement.attributes.connectionPort")}`,
                hideInTable: true,
                render: ({ item }) => {
                    const connectionString = item.connectionParams.proto + "://" + item.connectionParams.hostname + ":" + item.connectionParams.port
                    return <span>
                        {connectionString}
                    </span>;
                }
            },
            {
                colNameTranslationKey: `computersManagement.attributes.wolMacAddress`,
                hideInTable: true,
                render: ({ item }) => item.macAddress !== null ? <span>{item.macAddress}</span> : <span><Trans i18nKey="computersManagement.notAvailable" /></span>,
            },
            {
                colNameTranslationKey: `computersManagement.attributes.wolTimer`,
                hideInTable: true,
                render: ({ item }) => item.wakeOnLanTimeInSeconds !== null ? <span>{item.wakeOnLanTimeInSeconds}</span> : <span><Trans i18nKey="computersManagement.notAvailable" /></span>,
            },
            {
                colNameTranslationKey: `computersManagement.attributes.memberOf`,
                render: ({ item }) => {
                    if (item.memberOf.length > 0) {
                        // render groups and relative infos
                        return <>{item.memberOf.map((g, i) => <RoundedBadge key={`GroupOf name: ${g.groupName}`} leaveSpace={i > 0 ? "before" : undefined}>
                            "{g.groupName}"
                            &nbsp;
                            <span>{g.ldapInfo !== null ? `(${g.ldapInfo.ldapDn})` : <Trans i18nKey="computersManagement.noLdapGroup" />}</span></RoundedBadge>)}</>
                    }
                    return <span><Trans i18nKey="computersManagement.noMemberOf" /></span>
                }
            },
        ];
    }, []);

    const searchMapper: ApiTableSearchMapper<ComputersFilterCond> = useCallback((search, currentFilters) => {
        if (search.length === 0) {
            return currentFilters;
        }

        return [
            ...currentFilters,
            [
                {
                    field: `name`,
                    op: `inci`,
                    value: search,
                },
            ]
        ];
    }, []);

    const groupActions = useMemo<ApiTableActionGroupElem[]>(() => {
        return [
            {
                icon: { fa: `trash` },
                actionNameTrans: `bulk.computer.delete`,
                confirmDescriptionTrans: `computersManagement.bulkConfirmationModal.description`,
                action: async (ids) => {
                    for (const id of ids) {
                        try {
                            await ComputersService.deleteComputer({ id });
                        }
                        catch (error) {
                            l.warn(`CANNOT BULK DELETE COMPUTERS:`, error);
                        }
                    }
                },
            },
        ];
    }, []);

    const service: ApiTableApiService<ComputersItem, ComputersOrderedCol, ComputersFilterCond> = {
        get: (params) => ComputersService.getComputers(params),
    };

    const [editComputerData, setEditComputerData] = useState<ComputersItem | null>(null);

    const contextMenuComponent = useMemo<ApiTableContextMenuElem<ComputersItem>[]>(() => {
        return [
            {
                actionNameTrans: () => `computersManagement.contextualActions.edit`,
                icon: () => ({ ni: `edit` }),
                action: async (item) => {
                    setEditComputerData(item);
                },
            },
            {
                actionNameTrans: () => `computersManagement.contextualActions.delete`,
                icon: () => ({ fa: `trash` }),
                confirmDescriptionTrans: () => `computersManagement.deleteModal.description`,
                action: async (item) => {
                    await ComputersService.deleteComputer({ id: item.id });
                },
            },
        ];
    }, []);

    const [isNewComputerModalOpen, setIsNewComputerModalOpen] = useState(false);

    return <Content>
        <BlockHead size="sm">
            <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                        <Trans i18nKey="computersManagement.title" />
                    </BlockTitle>
                    <BlockDes className="text-soft">
                        <Trans i18nKey="computersManagement.description" />
                    </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                    <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => {
                            setIsNewComputerModalOpen(true);
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </BlockHeadContent>
            </BlockBetween>
        </BlockHead>

        <Block>
            <ApiTable<ComputersItem, ComputersOrderedCol, ComputersFilterCond, ComputersTableFiltersState>
                service={service}
                colMapper={colMapper}
                defaultOrderByField={`name`}
                searchMapper={searchMapper}
                cache={1}
                contextMenuComponents={contextMenuComponent}
                groupActions={groupActions}
            />
        </Block>

        <CreateComputerModal
            isOpen={isNewComputerModalOpen}
            onClose={() => {
                setIsNewComputerModalOpen(false);
            }}
        />

        <EditComputerModal
            isOpen={editComputerData !== null}
            computerData={editComputerData}
            onToggle={() => {
                setEditComputerData(null);
            }}
        />
    </Content>
}

type ComputersTableFiltersState = {
    name: string,
};