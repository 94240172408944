import { z } from "zod";
import { JSONObjectSchema } from "./json.js";
import { isHostname } from "./utils/validators.js";
export const PortNumberSchema = z.number().int().min(1).max(65535);
export const HostnameSchema = z.string().refine(isHostname, { message: "must be a hostname" });
export const UserRoleSchema = z.enum(["admin", "regular"]);
export const ApiStatusSchema = z.enum(["OK", "ERROR", "PENDING"]);
export const ApiResponseSchema = z.discriminatedUnion("status", [
    z.object({
        ovmp: z.literal("1.0"),
        requestId: z.string().uuid(),
        status: z.literal("OK"),
        body: JSONObjectSchema
    }),
    z.object({
        ovmp: z.literal("1.0"),
        requestId: z.string().uuid(),
        status: z.literal("PENDING"),
        body: z.object({
            jobId: z.string().uuid()
        })
    }),
    z.object({
        ovmp: z.literal("1.0"),
        requestId: z.string().uuid(),
        status: z.literal("ERROR"),
        body: JSONObjectSchema.and(z.object({ errorCode: z.string().min(1) }))
    })
]);
export const EmptyOutputSchema = z.record(z.string(), z.never());
