import { z } from "zod";
export const FsListDirParamsSchema = z.object({
    directoryId: z.string().uuid().nullable(),
    orderBy: z.object({
        field: z.enum([`creationTime`, `name`]),
        order: z.enum([`asc`, `desc`]),
    }),
});
export const FsListDirOutputSchema = z.object({
    id: z.string().uuid().nullable(),
    name: z.string(),
    creationTime: z.number().int(),
    parentDirectory: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
    }).nullable(),
    storage: z.object({
        id: z.string().uuid(),
        name: z.string(), // NOTE: empty in case root dir
    }),
    path: z.object({ id: z.string().uuid(), name: z.string().min(1) }).array(),
    perms: z.object({
        type: z.enum([`read-only`, `read-write`]),
        targetUser: z.object({ id: z.string().uuid(), username: z.string().min(1), displayName: z.string().min(1) }).nullable(),
        targetGroup: z.object({ id: z.string().uuid(), name: z.string().min(1) }).nullable(),
    }).array(),
    userOwner: z.object({
        id: z.string().uuid(),
        username: z.string().min(1),
        displayName: z.string().min(1),
    }),
    sizeInBytes: z.number().int().min(0),
    dirChildren: z.object({
        type: z.literal(`directory`),
        id: z.string().uuid(),
        name: z.string().min(1),
        creationTime: z.number().int(),
        perms: z.object({
            type: z.enum([`read-only`, `read-write`]),
            targetUser: z.object({ id: z.string().uuid(), username: z.string().min(1), displayName: z.string().min(1) }).nullable(),
            targetGroup: z.object({ id: z.string().uuid(), name: z.string().min(1) }).nullable(),
        }).array(),
        userOwner: z.object({
            id: z.string().uuid(),
            username: z.string().min(1),
            displayName: z.string().min(1),
        }),
        sizeInBytes: z.number().int().min(0),
    }).array(),
    fileChildren: z.object({
        type: z.literal(`file`),
        id: z.string().uuid(),
        name: z.string().min(1),
        creationTime: z.number().int(),
        perms: z.object({
            type: z.enum([`read-only`, `read-write`]),
            targetUser: z.object({ id: z.string().uuid(), username: z.string().min(1), displayName: z.string().min(1) }).nullable(),
            targetGroup: z.object({ id: z.string().uuid(), name: z.string().min(1) }).nullable(),
        }).array(),
        sizeInBytes: z.number().int().min(0),
        checksum: z.string().min(1),
        initialUpload: z.boolean(),
        uploadInProgress: z.boolean(),
        linkedChat: z.union([
            z.object({
                type: z.literal(`p2p`),
                id: z.string().uuid(),
                name: z.null(),
                peers: z.object({
                    id: z.string().uuid(),
                    username: z.string().min(1),
                    displayName: z.string().min(1),
                }).array().length(2),
            }),
            z.object({
                type: z.literal(`group`),
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        ]).nullable(),
        userOwner: z.object({
            id: z.string().uuid(),
            username: z.string().min(1),
            displayName: z.string().min(1),
        }),
    }).array(),
});
