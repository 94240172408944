import MainService from "./MainService";

import {
    CreateAccessRuleParams,
    CreateAccessRuleOutput,

    DeleteAccessRuleParams,

    EditAccessRuleParams,

    GetAccessRulesParams,
    GetAccessRulesOutput,
} from "@trantor/vdesk-api-client/dist/clients/accessRules";

class ARS {
    private client = MainService.client.accessRules;

    async getAccessRules(params: GetAccessRulesParams): Promise<GetAccessRulesOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async createAccessRule(params: CreateAccessRuleParams): Promise<CreateAccessRuleOutput> {
        return MainService.call(() => this.client.create(params));
    }

    async editAccessRule(params: EditAccessRuleParams): Promise<void> {
        return MainService.call(() => this.client.edit(params));
    }

    async deleteAccessRule(params: DeleteAccessRuleParams): Promise<void> {
        return MainService.call(() => this.client.delete(params));
    }
}
const AccessRulesService = new ARS();
export default AccessRulesService;
