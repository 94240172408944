
import React, { useCallback, useEffect, useRef } from "react";



export function useOnEnter(elemRef: React.MutableRefObject<HTMLElement | null>, cb: () => void): void {
    const cbRef = useRef(cb);
    cbRef.current = cb;

    const handler = useCallback((event: KeyboardEvent): boolean => {
        if (event.key === `Enter`) {
            try { cbRef.current(); } catch { /* do nothing */ }
            return false;
        }

        return true;
    }, []);

    useEffect(() => {
        const elem = elemRef.current;

        if (elem === null) {
            return;
        }

        elem.addEventListener(`keydown`, handler);

        return () => {
            elem.removeEventListener(`keydown`, handler);
        };
    }, [elemRef, handler]);
}
