import { z } from "zod";
export const GetLicenseInfoParamsSchema = z.record(z.string(), z.never());
export const LicenseBodySchema = z.object({
    id: z.string().uuid(),
    installationId: z.string().uuid(),
    model: z.enum([`userBased`]),
    limit: z.number().int().min(1),
    expiration: z.number().int().nullable(), // `null` if it never expires, otherwise UNIX millisecond timestamp
});
export const GetLicenseInfoOutputSchema = z.object({
    licenseInfo: z.object({
        productKey: z.string().min(1),
        licenseBody: LicenseBodySchema,
        isExpired: z.boolean(),
        isInstallationIdValid: z.boolean(),
    }).nullable(),
});
