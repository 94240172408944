import { z } from "zod";
import { HostnameSchema, PortNumberSchema } from "./common.js";
export const GetSettingsParamsSchema = z.record(z.string(), z.never());
export const GetSettingsOutputSchema = z.object({
    installationId: z.string().uuid(),
    defaultUsersHomeDirParent: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        path: z.object({ id: z.string().uuid(), name: z.string().min(1) }).array(),
    }).nullable(),
    ldapSettings: z.object({
        sessionParams: z.object({
            proto: z.enum([`ldap`, `ldaps`]),
            hostname: HostnameSchema,
            port: PortNumberSchema,
            creds: z.object({
                dn: z.string().min(1),
            }),
        }),
        userParams: z.object({
            base: z.string().min(1),
            filter: z.string().min(1),
            filterForDisabled: z.string().min(1).nullable(),
            attrId: z.string().min(1),
            attrUsername: z.string().min(1),
            attrDisplayName: z.string().min(1),
            defaultStorageQuotaInBytes: z.number().int().min(0),
        }),
        groupParams: z.object({
            base: z.string().min(1),
            filter: z.string().min(1),
            attrId: z.string().min(1),
            attrName: z.string().min(1),
        }),
        computerParams: z.object({
            base: z.string().min(1),
            filter: z.string().min(1),
            attrId: z.string().min(1),
            attrName: z.string().min(1),
            attrHostname: z.string().min(1),
            attrMacAddress: z.string().min(1).nullable(),
        }).nullable(),
        syncIntervalSecs: z.number().int().min(5),
    }).nullable(),
    ruleAdminGroups: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    ruleOtpGroups: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
});
