import { z } from "zod";
export const GetStatsParamsSchema = z.record(z.string(), z.never());
export const GetStatsOutputSchema = z.object({
    totalNumberOfRegisteredUsers: z.number().int().min(1),
    numberOfEnabledUsers: z.number().int().min(1),
    numberOfLdapUsers: z.number().int().min(0),
    averageSessionDurationInMs: z.object({
        lastWeek: z.number().int().min(0),
        lastMonth: z.number().int().min(0),
        lastThreeMonths: z.number().int().min(0),
        lastSixMonths: z.number().int().min(0),
    }),
    numberOfOnlineUsers: z.object({
        rightNow: z.number().int().min(1),
        lastWeek: z.number().int().min(0),
        lastMonth: z.number().int().min(0),
    }),
    numberOfChatRooms: z.object({
        p2p: z.number().int().min(0),
        group: z.number().int().min(0),
        p2pArchived: z.number().int().min(0),
        groupArchived: z.number().int().min(0),
    }),
    numberOfCalls: z.object({
        notYetStarted: z.number().int().min(0),
        alreadyStarted: z.number().int().min(0),
        finishedOrCanceled: z.number().int().min(0),
    }),
});
