import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Icon from "../../../../components/icon/Icon";
import { AccessRulesContext } from "../../../../contexts/get-access-rules/AccessRulesContext";
import l from "../../../../utils/Log";
import { ComputersSelector, GroupsSelector, UsersSelector } from "../MultiSelectDropdown";
import { AccessRulesService } from "../../../../services";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};
interface CreateAccessRuleModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateAccessRuleModal: React.FC<CreateAccessRuleModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const createAccessRule = AccessRulesService.createAccessRule.bind(AccessRulesService);
    
    type CreateAccessRuleFormInputs = {
        name: string;
        users: string[];
        userGroups: string[];
        computers: string[];
        computerGroups: string[];
    };

    const createAccessRuleForm = useForm<CreateAccessRuleFormInputs>({
        defaultValues: {
            name: "",
            users: [],
            userGroups: [],
            computers: [],
            computerGroups: [],
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    });

    const [formData, setFormData] = useState<{
        name: string;
        users: string[];
        userGroups: string[];
        computers: string[];
        computerGroups: string[];
    }>({
        name: "",
        users: [],
        userGroups: [],
        computers: [],
        computerGroups: [],
    });

    const resetForm = useCallback((): void => {
        setFormData({
            name: "",
            users: [],
            userGroups: [],
            computers: [],
            computerGroups: [],
        });
        createAccessRuleForm.setValue("name", "");
        createAccessRuleForm.setValue("users", []);
        createAccessRuleForm.setValue("userGroups", []);
        createAccessRuleForm.setValue("computers", []);
        createAccessRuleForm.setValue("computerGroups", []);
    }, [createAccessRuleForm]);

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen, resetForm]);

    const onFormNewSubmit: SubmitHandler<CreateAccessRuleFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            console.log("submitData", submitData);

            const { id } = await createAccessRule({
                name: submitData.name,
                users: submitData.users,
                userGroups: submitData.userGroups,
                computers: submitData.computers,
                computerGroups: submitData.computerGroups,
            });

            l.info("access rule created", { id });
            onClose();
        } catch (err) {
            l.error("failed to create access rule", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            onClose();
            resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    onClose();
                    resetForm();
                    resetForm();
                }}
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="accessRulesManagement.formModal.title" />
                </h5>
                <div className="mt-4">
                    <Form className="row gy-4" onSubmit={createAccessRuleForm.handleSubmit(onFormNewSubmit)}>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.name}
                                    placeholder={t("accessRulesManagement.attributes.namePlaceholder").toString()}
                                    {...createAccessRuleForm.register("name", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.users" />
                                </label>
                                <UsersSelector
                                    selectedData={formData.users}
                                    onChange={(selected) => {
                                        createAccessRuleForm.setValue("users", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.userGroups" />
                                </label>
                                <GroupsSelector
                                    selectedData={formData.userGroups}
                                    onChange={(selected) => {
                                        createAccessRuleForm.setValue("userGroups", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.computers" />
                                </label>
                                <ComputersSelector
                                    selectedData={formData.computers}
                                    onChange={(selected) => {
                                        createAccessRuleForm.setValue("computers", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="accessRulesManagement.attributes.computerGroups" />
                                </label>
                                <GroupsSelector
                                    selectedData={formData.computerGroups}
                                    onChange={(selected) => {
                                        createAccessRuleForm.setValue("computerGroups", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button disabled={disableModal} color="primary" size="md" type="submit">
                                            <Trans i18nKey="accessRulesManagement.formModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    resetForm();
                                                    onClose();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="accessRulesManagement.formModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default CreateAccessRuleModal;
