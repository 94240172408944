import { BaseClient } from "./base.js";
import { GetChatArchivesOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives.js";
import { DeleteChatArchiveOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/archives/deleteChatArchive.js";
import { GetChatArchiveMessagesOutputSchema } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchiveMessages.js";
import { parseError } from "../libs/errors.js";
export class ChatArchiveClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async get(params) {
        const res = await this.call("getChatArchives", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetChatArchivesOutputSchema.parse(res.body)
        };
    }
    async delete(params) {
        const res = await this.call("deleteChatArchive", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteChatArchiveOutputSchema.parse(res.body)
        };
    }
    async getMessages(params) {
        const res = await this.call("getChatArchiveMessages", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetChatArchiveMessagesOutputSchema.parse(res.body)
        };
    }
}
