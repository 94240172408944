import React from "react";
import classNames from "classnames";
import ChatDropdown from "./dropdown/chat/Chat";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import User from "./dropdown/user/User";
import MobileMenu from "../menu/MobileMenu";
import menu from "../menu/MenuData";
import LanguageHead from "./dropdown/language/Language";
import { Link } from "react-router-dom";
import { isDevelopment } from "../../utils/Log";

export const renderHeaderTools = (additionalClasses?: string) => {
    const headerToolsClasses = classNames({
        "nk-header-tools": true,
        [`${additionalClasses}`]: additionalClasses !== undefined,
    })
    return <div className={headerToolsClasses}>
        <ul className="nk-quick-nav">
            <li className="chats-dropdown">
                <ChatDropdown />
            </li>
            <li className="language-dropdown me-n1">
                <LanguageHead />
            </li>
            <li className="user-dropdown">
                <User />
            </li>
        </ul>
    </div>;
}

interface HeaderProps {
    fixed: boolean;
    theme: string;
    visibility: boolean;
    toggleSidebar: () => void;
    mobileView: boolean;
    className?: string;
}

const Header: React.FC<HeaderProps> = ({
    fixed,
    theme,
    visibility,
    toggleSidebar,
    mobileView,
    className,
    ...props
}) => {
    const headerClass = classNames({
        "nk-header is-regular": true,
        "nk-header-fixed": fixed,
        [`is-light`]: theme === "white",
        [`is-${theme}`]: theme !== "white" && theme !== "light",
        [`${className}`]: className,
    });

    return (
        <div className={headerClass}>
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger me-sm-2 d-lg-none">
                        <Toggle
                            className={`nk-nav-toggle nk-quick-nav-icon ${visibility ? `unclickable-text` : ``}`}
                            icon="menu"
                            click={toggleSidebar}
                        />
                    </div>
                    <div className="nk-header-brand">
                        <Logo withName />

                        {isDevelopment()
                        ? <></>
                        : <div className="brand-logo">
                            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                                {/* <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                                <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" /> */}
                                <img className="logo-light logo-img logo-img-lg" src="/vendor.png" alt="logo" />
                                <img className="logo-dark logo-img logo-img-lg" src="/vendor.png" alt="logo-dark" />
                            </Link>
                        </div>}
                    </div>
                    <div
                        className={`nk-header-menu ms-auto${mobileView ? " mobile-menu" : ""}${
                            visibility ? " nk-header-active" : ""
                        }`}
                    >
                        <div className="nk-header-mobile">
                            <div className="nk-header-brand mobile">
                                <Logo />
                                <div className="brand-logo">
                                    <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                                        {/* <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                                        <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" /> */}
                                        <img className="logo-light logo-img logo-img-lg" src="/vendor.png" alt="logo" />
                                        <img className="logo-dark logo-img logo-img-lg" src="/vendor.png" alt="logo-dark" />
                                    </Link>
                                </div>
                            </div>
                            {visibility && (
                                <div className="nk-menu-trigger ms-3 p-1">
                                    <Toggle
                                        className="nk-nav-toggle nk-quick-nav-icon"
                                        icon="arrow-left"
                                        click={toggleSidebar}
                                    />
                                </div>
                            )}
                        </div>
                        {mobileView ? (
                            <MobileMenu data={menu} sidebarToggle={toggleSidebar} mobileView={mobileView} />
                        ) : (
                            <Menu />
                        )}
                    </div>
                    {visibility && <div className="nk-header-overlay" onClick={toggleSidebar}></div>}
                    {renderHeaderTools("d-none d-sm-flex")}
                </div>
            </div>
        </div>
    );
};
export default Header;
