import { GetStatsOutput } from "@trantor/vdesk-api-schemas/dist/getStats";
import MainService from "./MainService";

class StS {
    private client = MainService.client.stats;

    async getAudit(): Promise<GetStatsOutput> {
        return MainService.call(() => this.client.getStats({}));
    }
}
const AuditService = new StS();
export default AuditService;
