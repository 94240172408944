import classNames from "classnames";
import React, { CSSProperties } from "react";

const Icon: React.FC<{
    className?: string;
    name?: string;
    id?: string;
    style?: CSSProperties;
    // children?: ReactNode;
}> = ({ name, id, className, style, ...props }) => {
    const iconClass = classNames({
        [`${className}`]: className,
        icon: true,
        ni: true,
        [`ni-${name}`]: true,
    });
    return <em className={iconClass} id={id} style={style} {...props}></em>;
};
export default Icon;
