import { z } from "zod";
export const EditStorageParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    endpoint: z.string().min(1).optional(),
    accessKeyId: z.string().min(1).optional(),
    secretAccessKey: z.string().min(1).optional(),
    bucket: z.string().min(1).optional(),
    region: z.string().min(1).optional(),
});
export const EditStorageOutputSchema = z.record(z.string(), z.never());
