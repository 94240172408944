import { ChangeSettingsParams } from "@trantor/vdesk-api-schemas/dist/changeSettings";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, RSelect } from "../../components/Component";
import { LdapProtocol, SettingsData } from "../../contexts/get-settings/SettingsContextTypes";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import l from "../../utils/Log";
import ConfirmationModal from "./modals/ConfirmationModal";
import { GroupsSelector } from "./modals/MultiSelectDropdown";
import { DefaultSpinner } from "../../components/default-spinner";
import { useCronState } from "../../utils/CronJob";
import { MiscService } from "../../services";
import { asyncVoid } from "../../utils/handler";
import { GetSettingsOutput } from "@trantor/vdesk-api-schemas/dist/getSettings";

type LdapSettings = NonNullable<ChangeSettingsParams["ldapSettings"]>
type RuleAdminGroupsSettings = NonNullable<ChangeSettingsParams["ruleAdminGroups"]>
type RuleOtpGroupsSettings = NonNullable<ChangeSettingsParams["ruleOtpGroups"]>

export type EditFormInputs = {
    mode: string;
    reValidateMode: string;

    params: {
        ldap: LdapSettings;
        ruleAdminGroups: RuleAdminGroupsSettings;
        ruleOtpGroups: RuleOtpGroupsSettings;
    };
};

const getSettings = MiscService.getSettings.bind(MiscService);
const changeSettings = MiscService.changeSettings.bind(MiscService);

const toFormState = (data?: SettingsData): EditFormInputs => {
    const ldap: LdapSettings | null = data?.ldapSettings ?? {
        sessionParams: {
            proto: "ldap",
            hostname: "",
            port: 389,
            creds: {
                dn: "",
                password: "",
            },
        },
        userParams: {
            attrId: "",
            attrUsername: "",
            attrDisplayName: "",
            filter: "",
            filterForDisabled: "",
            base: "",
            defaultStorageQuotaInBytes: 0,
        },
        computerParams: {
            attrId: "",
            attrName: "",
            attrHostname: "",
            attrMacAddress: "",
            filter: "",
            base: "",
        },
        groupParams: {
            attrId: "",
            attrName: "",
            filter: "",
            base: "",
        },
        syncIntervalSecs: 60,
    };
    const ruleAdminGroups: RuleAdminGroupsSettings = data?.ruleAdminGroups?.map((g) => g.groupId) ?? [];
    const ruleOtpGroups: RuleOtpGroupsSettings = data?.ruleOtpGroups?.map((g) => g.groupId) ?? [];

    return {
        mode: "onSubmit",
        reValidateMode: "onChange",

        params: {
            ldap,
            ruleAdminGroups,
            ruleOtpGroups,
        },
    }
};

const Component = () => {
    const { t: origT } = useTranslation();
    const t = (code: string): string => {
        return origT(code).toString();
    };

    const [ldapSettingsEnabled, setLdapSettingsEnabled] = useState(false);

    const [settings, setSettings] = useState<GetSettingsOutput>();
    useEffect(() => {
        asyncVoid(async () => {
            const data = await getSettings();
            setSettings(data);
            setLdapSettingsEnabled(data.ldapSettings !== null)
        })();
    }, [])

    const form = useForm<EditFormInputs>({
        defaultValues: toFormState(settings),
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });
    const [modals, setModals] = useState<{ success: boolean; error: boolean; }>({
        success: false,
        error: false,
    });

    // submit function to update a new item
    const onEditSubmit: SubmitHandler<EditFormInputs> = async ({ params }): Promise<void> => {
        /**
         * LDAP update settings
         */
        const ldapSettings: ChangeSettingsParams["ldapSettings"] = ldapSettingsEnabled ? { ...params.ldap } : null;
        if (ldapSettings !== null) {
            // Adjust password, which shouldn't be provided if not updated
            ldapSettings.sessionParams.creds.password = params.ldap.sessionParams.creds.password === "" ? undefined : params.ldap.sessionParams.creds.password;
        }

        /**
         * ruleAdminGroups update settings
         */
        const ruleAdminGroups: ChangeSettingsParams["ruleAdminGroups"] = params.ruleAdminGroups;

        /**
         * ruleOtpGroups update settings
         */
        const ruleOtpGroups: ChangeSettingsParams["ruleOtpGroups"] = params.ruleOtpGroups;

        changeSettings({
            ldapSettings,
            ruleAdminGroups,
            ruleOtpGroups,
        }).then(() => {
            l.info("successfully updated settings", { params });
            setModals((prev) => ({ ...prev, success: true }));
        }).catch((err) => {
            l.error("failed to update settings", { params, err });
            setModals((prev) => ({ ...prev, error: true }));
        });
    };

    const toggleLdapSettings = () => setLdapSettingsEnabled((prev) => !prev);

    return (
        <>
            <Head title={t("pageTitles.settingsLdap")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                {t("settingsManagement.formModal.title")}
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {settings === undefined
                        ? <DefaultSpinner />
                        : <FormProvider {...form}>
                            <Form onSubmit={form.handleSubmit(onEditSubmit)}>
                                <Row>
                                    <FormGroup>
                                        <FormGroup switch>
                                            <Input
                                                type="switch"
                                                role="switch"
                                                checked={ldapSettingsEnabled}
                                                onChange={toggleLdapSettings}
                                            />
                                            <Label check onClick={toggleLdapSettings}>
                                                {t("settingsManagement.formModal.ldapSettingsToggleLabel")}
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                    <LdapSettingsSection hide={!ldapSettingsEnabled} />
                                </Row>
                                <Row>
                                    <RuleAdminGroupsSettingsSection />
                                </Row>
                                <Row>
                                    <RuleOtpGroupsSettingsSection />
                                </Row>
                                <Row className="gy-3 py-3">
                                    <Col size={12}>
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    <Trans i18nKey="settingsManagement.formModal.confirmBtnText" />
                                                </Button>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Form>
                        </FormProvider>}
                </Block>
            </Content>

            <ConfirmationModal
                isOpen={modals.success}
                title={t("settingsManagement.formModal.successfulUpdateModal.title")}
                description={t("settingsManagement.formModal.successfulUpdateModal.description")}
                primaryBtnText={t("settingsManagement.formModal.successfulUpdateModal.dismissBtnLabel")}
                primaryActionCallback={() => setModals((prev) => ({ ...prev, success: false }))}
            />

            <ConfirmationModal
                isOpen={modals.error}
                title={t("settingsManagement.formModal.failedUpdateModal.title")}
                description={t("settingsManagement.formModal.failedUpdateModal.description")}
                primaryBtnText={t("settingsManagement.formModal.failedUpdateModal.dismissBtnLabel")}
                primaryActionCallback={() => setModals((prev) => ({ ...prev, error: false }))}
            />
        </>
    );
};

const Hide = ({ when, children }: { when?: boolean; children: JSX.Element }) => {
    return <div style={when ? { display: "none" } : {}}>{children}</div>;
}

const LdapSettingsSection = ({ hide }: { hide?: boolean }) => {
    const { register, getValues, setValue } = useFormContext<EditFormInputs>();

    const ldapSettings = getValues().params.ldap

    const ProtocolOptions = [
        { value: "ldap", label: "LDAP" },
        { value: "ldaps", label: "LDAPS" },
    ];

    return (
        <Hide when={hide}>
            <>
                <FormGroup>
                    <legend>
                        <Trans i18nKey="settingsManagement.attributes.ldap.sessionParams.title" />
                    </legend>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.sessionParams.proto" />
                                </label>
                                <RSelect
                                    options={ProtocolOptions}
                                    defaultValue={ProtocolOptions.find((o) => o.value === ldapSettings.sessionParams.proto)}
                                    onChange={(e: { value: string }) =>
                                        setValue("params.ldap.sessionParams.proto", e.value as LdapProtocol)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.sessionParams.hostname" />
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register("params.ldap.sessionParams.hostname")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.sessionParams.port" />
                                </label>
                                <input
                                    className="form-control"
                                    type="number"
                                    min={1}
                                    max={65535}
                                    step={1}
                                    {...register("params.ldap.sessionParams.port", { valueAsNumber: true })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <div className="form-group">
                                    <label className="form-label">
                                        <Trans i18nKey="settingsManagement.attributes.ldap.sessionParams.creds.dn" />
                                    </label>
                                    <input
                                        className="form-control"
                                        {...register("params.ldap.sessionParams.creds.dn")}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={7}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.sessionParams.creds.password" />
                                </label>
                                <input
                                    className="form-control"
                                    type="password"
                                    {...register("params.ldap.sessionParams.creds.password")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.syncIntervalSecs" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.syncIntervalSecs", { valueAsNumber: true })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <legend>
                        <Trans i18nKey="settingsManagement.attributes.ldap.userParams.title" />
                    </legend>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.attrId" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.attrId")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.attrUsername" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.attrUsername")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.attrDisplayName" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.attrDisplayName")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.filter" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.filter")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.filterForDisabled" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.filterForDisabled")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.base" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.base")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.userParams.defaultStorageQuotaInBytes" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.userParams.defaultStorageQuotaInBytes", { valueAsNumber: true })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <legend>
                        <Trans i18nKey="settingsManagement.attributes.ldap.groupParams.title" />
                    </legend>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.groupParams.attrId" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.groupParams.attrId")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.groupParams.attrName" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.groupParams.attrName")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.groupParams.filter" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.groupParams.filter")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.groupParams.base" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.groupParams.base")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <legend>
                        <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.title" />
                    </legend>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.attrId" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.computerParams.attrId")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.attrName" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.computerParams.attrName")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.attrHostname" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.computerParams.attrHostname")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.attrMacAddress" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.computerParams.attrMacAddress")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.filter" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.computerParams.filter")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className="form-label">
                                    <Trans i18nKey="settingsManagement.attributes.ldap.computerParams.base" />
                                </label>
                                <input
                                    className="form-control"
                                    {...register("params.ldap.computerParams.base")}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>
            </>
        </Hide>
    )
}

const RuleAdminGroupsSettingsSection = () => {
    const { getValues, setValue } = useFormContext<EditFormInputs>();

    const ruleAdminGroupsSettings = getValues().params.ruleAdminGroups;

    return (
        <FormGroup>
            <legend>
                <Trans i18nKey="settingsManagement.formModal.ruleAdminGroupLabel" />
            </legend>
            <GroupsSelector
                selectedData={ruleAdminGroupsSettings}
                onChange={(selected) => {
                    setValue("params.ruleAdminGroups", selected.map((item) => item.id), {
                        shouldValidate: true,
                    });
                }}
            />
        </FormGroup>
    );
}

const RuleOtpGroupsSettingsSection = () => {
    const { getValues, setValue } = useFormContext<EditFormInputs>();

    const ruleOtpGroupsSettings = getValues().params.ruleOtpGroups;

    return (
        <FormGroup>
            <legend>
                <Trans i18nKey="settingsManagement.formModal.ruleOtpGroupLabel" />
            </legend>
            <GroupsSelector
                selectedData={ruleOtpGroupsSettings}
                onChange={(selected) => {
                    setValue("params.ruleOtpGroups", selected.map((item) => item.id), {
                        shouldValidate: true,
                    });
                }}
            />
        </FormGroup>
    );
}

const SettingsPage = () => <Component />;

export default SettingsPage;
