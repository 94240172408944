import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Icon from "../../../../components/icon/Icon";
import { SubmitHandler, useForm } from "react-hook-form";
import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import l from "../../../../utils/Log";
import { GroupsContext } from "../../../../contexts/get-groups/GroupsContext";
import { ComputersSelector, GroupsSelector, UsersSelector } from "../MultiSelectDropdown";
import { GroupsService } from "../../../../services";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};
interface CreateGroupModalProps {
    isOpen: boolean;
    onClose: () => void;
}
const CreateGroupModal: React.FC<CreateGroupModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const createGroup = GroupsService.createGroup.bind(GroupsService);
    
    type CreateGroupFormInputs = {
        name: string;
        memberUsers: string[];
        memberGroups: string[];
        memberComputers: string[];
        memberOf: string[];
    };

    const createGroupForm = useForm<CreateGroupFormInputs>({
        defaultValues: {
            name: "",
            memberUsers: [],
            memberGroups: [],
            memberComputers: [],
            memberOf: [],
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    });

    const [formData, setFormData] = useState<{
        name: string;
        memberUsers: string[];
        memberGroups: string[];
        memberComputers: string[];
        memberOf: string[];
    }>({
        name: "",
        memberUsers: [],
        memberGroups: [],
        memberComputers: [],
        memberOf: [],
    });

    const resetForm = useCallback((): void => {
        setFormData({
            name: "",
            memberUsers: [],
            memberGroups: [],
            memberComputers: [],
            memberOf: [],
        });
        createGroupForm.setValue("name", "");
    }, [createGroupForm]);

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen, resetForm]);

    const onFormNewSubmit: SubmitHandler<CreateGroupFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            const { id } = await createGroup({
                name: submitData.name,
                memberUsers: submitData.memberUsers,
                memberGroups: submitData.memberGroups,
                memberComputers: submitData.memberComputers,
                memberOf: submitData.memberOf,
            });

            l.info("group created", { id });
            onClose();
        } catch (err) {
            l.error("failed to create group", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            onClose();
            resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    onClose();
                    resetForm();
                    resetForm();
                }}
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="groupsManagement.formModal.title" />
                </h5>
                <div className="mt-4">
                    <Form className="row gy-4" onSubmit={createGroupForm.handleSubmit(onFormNewSubmit)}>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.name}
                                    placeholder={t("groupsManagement.attributes.namePlaceholder").toString()}
                                    {...createGroupForm.register("name", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberUsers" />
                                </label>
                                <UsersSelector
                                    selectedData={formData.memberUsers}
                                    onChange={(selected) => {
                                        createGroupForm.setValue("memberUsers", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberGroups" />
                                </label>
                                <GroupsSelector
                                    selectedData={formData.memberGroups}
                                    onChange={(selected) => {
                                        createGroupForm.setValue("memberGroups", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberComputers" />
                                </label>
                                <ComputersSelector
                                    selectedData={formData.memberComputers}
                                    onChange={(selected) => {
                                        createGroupForm.setValue("memberComputers", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="groupsManagement.attributes.memberOf" />
                                </label>
                                <GroupsSelector
                                    selectedData={formData.memberOf}
                                    onChange={(selected) => {
                                        createGroupForm.setValue("memberOf", selected.map((item) => item.id), {
                                            shouldValidate: true,
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button disabled={disableModal} color="primary" size="md" type="submit">
                                            <Trans i18nKey="groupsManagement.formModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    resetForm();
                                                    onClose();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="groupsManagement.formModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default CreateGroupModal;
