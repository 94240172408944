import { z } from "zod";
export const FsUploadFileChunkParamsSchema = z.union([
    z.object({
        fileUploadId: z.string().uuid(),
        operation: z.literal(`cancel`),
        chunkBase64: z.null().optional().default(null),
        chunkId: z.null().optional().default(null),
    }),
    z.object({
        fileUploadId: z.string().uuid(),
        operation: z.literal(`sendChunk`),
        chunkBase64: z.string(),
        chunkId: z.number().int().min(1),
    }),
]);
export const FsUploadFileChunkOutputSchema = z.object({
    fileUploadStatus: z.enum([`readyForMoreChunks`, `canceled`, `finished`]),
});
