import MainService from "./MainService";

import {
    CreateUserParams,
    CreateUserOutput,
    GetUsersParams,
    GetUsersOutput,
    EditUserParams,
    EditUserOutput,
    DeleteUserOutput,
} from "@trantor/vdesk-api-client/dist/clients/user";

class US {
    private client = MainService.client.users;

    async getUsers(params: GetUsersParams): Promise<GetUsersOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async createUser(params: CreateUserParams): Promise<CreateUserOutput> {
        return MainService.call(() => this.client.create(params));
    }

    async editUser(params: EditUserParams): Promise<EditUserOutput> {
        return MainService.call(() => this.client.edit(params));
    }

    async deleteUser(id: string): Promise<DeleteUserOutput> {
        return MainService.call(() => this.client.delete({ id }));
    }
}
const UsersService = new US();
export default UsersService;
