import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { EditComputerOutputSchema } from "@trantor/vdesk-api-schemas/dist/computers/editComputer.js";
import { GetComputersOutputSchema } from "@trantor/vdesk-api-schemas/dist/computers/getComputers.js";
import { CreateComputerOutputSchema } from "@trantor/vdesk-api-schemas/dist/computers/createComputer.js";
import { DeleteComputerOutputSchema } from "@trantor/vdesk-api-schemas/dist/computers/deleteComputer.js";
import { ConnectComputerOutputSchema } from "@trantor/vdesk-api-schemas/dist/computers/connectComputer.js";
import { GetConnectionInfoOutputSchema } from "@trantor/vdesk-api-schemas/dist/computers/getConnectionInfo.js";
export class ComputerClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async create(params) {
        const res = await this.call("createComputer", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: CreateComputerOutputSchema.parse(res.body)
        };
    }
    async get(params) {
        const res = await this.call("getComputers", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetComputersOutputSchema.parse(res.body)
        };
    }
    async edit(params) {
        const res = await this.call("editComputer", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: EditComputerOutputSchema.parse(res.body),
        };
    }
    async delete(params) {
        const res = await this.call("deleteComputer", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteComputerOutputSchema.parse(res.body)
        };
    }
    async connect(params) {
        const res = await this.call("connectComputer", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: ConnectComputerOutputSchema.parse(res.body)
        };
    }
    async getConnectionInfo(params) {
        const res = await this.call(`getConnectionInfo`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetConnectionInfoOutputSchema.parse(res.body),
        };
    }
}
