import React, { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    ConfirmModal,
    Icon,
} from "../../components/Component";
import { Trans, useTranslation } from "react-i18next";
import { useCronState } from "../../utils/CronJob";
import { ChangeLicenseParams, GetLicenseInfoOutput } from "@trantor/vdesk-api-client/dist/clients/license"
import { Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { LicenseService } from "../../services";
import classNames from "classnames";
import { DefaultSpinner } from "../../components/default-spinner";
import { formatDate } from "../../utils/Utils";
import { errorToastStyle } from "./modals/UserEditModal";
import l from "../../utils/Log";
import { SubmitHandler, useForm } from "react-hook-form";

const LicensePage: React.FC = () => {
    const { t: origT } = useTranslation();
    const t = (code: string): string => {
        return origT(code).toString();
    };
    
    const [ license ] = useCronState<GetLicenseInfoOutput | null>(1000, null, async () => {
        return await LicenseService.getLicenseInfo({});
    });

    const [showProductKeyModal, setShowProductKeyModal] = useState(false);
    const [editProductKeyModal, setEditProductKeyModal] = useState<string | null>("");
    return (
        <>
            <Head title={t("pageTitles.settingsLicense")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                <Trans i18nKey="settingsLicense.title" />
                            </BlockTitle>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <div style={{position: "relative", minHeight: "5rem"}}>
                    {license === null
                    ? <div className="absolute-overlay">
                        {/* <div className="text-center"> */}
                            <DefaultSpinner />
                            <Trans i18nKey="apiTable.loading" />
                        {/* </div> */}
                    </div>
                    : <>
                        <Row>
                            <Col>
                                <h4>
                                    <Trans i18nKey="settingsLicense.titleStatus" />
                                </h4>
                            </Col>
                        </Row>
                        {license.licenseInfo !== null
                        ? <>
                            <Row className="pt-3">
                                <Col>
                                    <Icon
                                        className={classNames({
                                            "text-success": !license.licenseInfo.isExpired,
                                            "text-danger": license.licenseInfo.isExpired,
                                            "unselectable-text": true,
                                        })}
                                        name={`${!license.licenseInfo.isExpired ? "check-circle" : "cross-circle"}`}
                                    ></Icon>
                                    &nbsp;
                                    {license.licenseInfo.isExpired
                                    ? <Trans i18nKey="settingsLicense.isExpired" />
                                    : <Trans i18nKey="settingsLicense.isNotExpired" />}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Icon
                                        className={classNames({
                                            "text-success": license.licenseInfo.isInstallationIdValid,
                                            "text-danger": !license.licenseInfo.isInstallationIdValid,
                                            "unselectable-text": true,
                                        })}
                                        name={`${license.licenseInfo.isInstallationIdValid ? "check-circle" : "cross-circle"}`}
                                    ></Icon>
                                    &nbsp;
                                    {license.licenseInfo.isInstallationIdValid
                                    ? <Trans i18nKey="settingsLicense.isInstallationIdValid" />
                                    : <Trans i18nKey="settingsLicense.isNotInstallationIdValid" />}
                                </Col>
                            </Row>
                        </>
                        : <Row className="pt-3">
                            <Col>
                                <Icon
                                    className={classNames({
                                        "text-danger": true,
                                        "unselectable-text": true,
                                    })}
                                    name="cross-circle"
                                ></Icon>
                                &nbsp;
                                <Trans i18nKey="settingsLicense.isMissing" />
                            </Col>
                        </Row>}
                        <Row className="pt-3">
                            <Col>
                                <Button onClick={() => {
                                    setShowProductKeyModal(true)
                                }}>
                                    <Trans i18nKey={license.licenseInfo === null ? "settingsLicense.addProductKey" : "settingsLicense.showProductKey"} />
                                </Button>
                            </Col>
                        </Row>
                        {license.licenseInfo !== null
                        ? <>
                            <Row className="pt-3">
                                <Col>
                                    <h4>
                                        <Trans i18nKey="settingsLicense.titleDetails" />
                                    </h4>
                                </Col>
                            </Row>
                            <Row className="pt-3">
                                <Col>
                                    <Trans i18nKey="settingsLicense.expiration" />
                                    :&nbsp;
                                    {formatDate(license.licenseInfo.licenseBody.expiration, {format: "dateOnly"})}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Trans i18nKey="settingsLicense.model" />
                                    :&nbsp;
                                    <Trans i18nKey={`settingsLicense.modelLabels.${license.licenseInfo.licenseBody.model}`}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Trans i18nKey="settingsLicense.limit" />
                                    :&nbsp;
                                    <Trans
                                        i18nKey="settingsLicense.user"
                                        values={{count: license.licenseInfo.licenseBody.limit}}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Trans i18nKey="settingsLicense.installationId" />
                                    :&nbsp;
                                    {license.licenseInfo.licenseBody.installationId}
                                </Col>
                            </Row>
                        </>
                        : <></>}
                    </>}
                </div>
                <ConfirmModal
                    isOpen={showProductKeyModal}
                    onToggle={() => {
                        setShowProductKeyModal(false);
                    }}
                    onCallback={() => {
                        setEditProductKeyModal(license?.licenseInfo?.productKey ?? null);
                    }}
                    mode={license?.licenseInfo === null ? "add-product-key" : "change-product-key"}
                    translationData={{productKey: license?.licenseInfo?.productKey}}
                />

                <EditProductKeyModal
                    isOpen={editProductKeyModal !== ""}
                    data={editProductKeyModal}
                    onToggle={() => {
                        setEditProductKeyModal("");
                    }}
                />
            </Content>
        </>
    );
};
export default LicensePage;

interface EditProductKeyModalProps {
    data: string | null;
    isOpen: boolean;
    onToggle: () => void;
}
const EditProductKeyModal:React.FC<EditProductKeyModalProps> = (props): React.ReactElement => {

    const { t } = useTranslation();

    // const [formData, setFormData] = useState<{
    //     id: string;
    //     displayName: string;
    //     username: string;
    //     role: UserRole;
    //     enabled: boolean;
    //     password?: string;
    // }>({
    //     id: "",
    //     displayName: "",
    //     username: "",
    //     role: "regular",
    //     enabled: true,
    //     password: undefined,
    // });
    const prevData = useRef<string | null>(null);
    const myData = useMemo(() => {
        if (props.data !== null) {
            prevData.current = props.data;
            return props.data;
        }
        return prevData.current;
    }, [props.data]);

    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    const [error, setError] = useState<string | null>(null);
    return <Modal
        isOpen={props.isOpen}
        toggle={() => {
            setDisableModal(false);
            props.onToggle();
        }}
        className="modal-dialog-centered"
        size="xl"
        fullscreen="xl"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    setError(null);
                    props.onToggle();
                }}
                className="close"
            >
                <Icon name="cross-sm" />
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="settingsLicense.editProductKey" />
                </h5>
                {myData !== null
                ? <EditProductKeyBody
                    productKey={myData}
                    disableModal={disableModal}
                    setDisableModal={setDisableModal}
                    error={error}
                    setError={setError}
                    onToggle={props.onToggle}
                />
                : <></>}
            </div>
        </ModalBody>
    </Modal>
}

export type EditProductKeyFormInputs = {
    mode: string;
    reValidateMode: string;
    productKey: string;
};
type BodyProps = {
    productKey: string;
    disableModal: boolean;
    setDisableModal: Dispatch<React.SetStateAction<boolean>>;
    error: string | null;
    setError: Dispatch<React.SetStateAction<string | null>>;
    onToggle: () => void;
};
const EditProductKeyBody: React.FC<BodyProps> = ({productKey, error, setError, disableModal, setDisableModal, onToggle}) => {
    const { t } = useTranslation();
    const changeLicense = LicenseService.changeLicense.bind(LicenseService);

    // Edit user form
    const editProductKeyForm = useForm<EditProductKeyFormInputs>({
        defaultValues: {
            mode: "onSubmit",
            reValidateMode: "onChange",
            productKey
        },
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });
    useEffect(() => {
        editProductKeyForm.setValue("productKey", productKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productKey, editProductKeyForm]);

    // submit function to update a new item
    const onEditSubmit: SubmitHandler<EditProductKeyFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();
        try {
            const editedData: ChangeLicenseParams = {
                productKey: submitData.productKey.length > 0 ? submitData.productKey : null,
            };
            await changeLicense(editedData);
            onToggle();
        } catch (err) {
            l.error("Failed to update product key", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };

    return <div className="mt-4">
        <Form
            className="row gy-4"
            onSubmit={editProductKeyForm.handleSubmit(onEditSubmit, (p, e) => l.error("-- ERR:", p, e))}
        >
            <Row className="gy-3 py-3">
                <Col size={12}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="settingsLicense.productKey" />
                        </label>
                        <textarea
                            disabled={disableModal}
                            className="form-control"
                            rows={10}
                            defaultValue={productKey}
                            placeholder={t("settingsLicense.productKeyPlaceholder").toString()}
                            {...editProductKeyForm.register("productKey")}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="gy-3 py-3">
                <Col size={12}>
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                            <Button
                                color={
                                    editProductKeyForm.getValues("productKey").length === 0
                                    ? "danger"
                                    : "primary"
                                }
                                size="md"
                                type="submit"
                                disabled={disableModal}
                            >
                                <Trans
                                    i18nKey={
                                        editProductKeyForm.getValues("productKey").length === 0
                                        ? "settingsLicense.deleteProductKeyConfirm"
                                        : "settingsLicense.editProductKeyConfirm"
                                    }
                                />
                            </Button>
                        </li>
                        <li>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!disableModal) {
                                        onToggle();
                                    }
                                }}
                                className="link link-light"
                            >
                                <Trans i18nKey="settingsLicense.cancel" />
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Form>
    </div>;
}