import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ApiTable, ApiTableApiService, ApiTableColMapper, ApiTableFiltersComponentProps, FilterCondOfApi, ItemOfApi, OrderedColOfApi, ApiTableFiltersMapper, ApiTableApiFilters } from "../../components/ApiTable";
import LogsService from "../../services/LogsService";
import { Trans } from "react-i18next";
import { formatDate, formatDuration } from "../../utils/Utils";
import Head from "../../layout/head/Head";
import { t } from "i18next";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Button, Col, Row } from "../../components/Component";
import Content from "../../layout/content/Content";
import { UsersService } from "../../services";
import { KindsSelector, UsersSelector } from "./modals/MultiSelectDropdown";
import { DateTime } from "luxon";
import { AuditLogBody } from "@trantor/vdesk-api-schemas/dist/audit";
import { getCurrentLang } from "../../utils/lang";

const getLogsApi = LogsService.getLogs.bind(LogsService);
type LogsItem = ItemOfApi<typeof getLogsApi>;
const getUsersApi = UsersService.getUsers.bind(UsersService);
type UserItem = ItemOfApi<typeof getUsersApi>;
type LogsOrderedCol = OrderedColOfApi<typeof getLogsApi>;
type LogsFilterCond = FilterCondOfApi<typeof getLogsApi>;
const service: ApiTableApiService<LogsItem, LogsOrderedCol, LogsFilterCond> = {
    get: (params) => LogsService.getLogs(params)
};

function LogsTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<LogsItem, LogsOrderedCol> = useMemo(() => {
        return [
            // {
            //     colNameTranslationKey: `logsManagement.id`,
            //     render: ({ item }) => <span>{item.id}</span>,
            // },
            {
                colNameTranslationKey: `logsManagement.colTitle.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `logsManagement.colTitle.displayName`,
                render: ({ item }) => <div className="user-info">
                                        <span className="tb-lead">{item.fromUser?.displayName}</span>
                                    </div>
            },
            {
                colNameTranslationKey: `logsManagement.colTitle.username`,
                render: ({ item }) => <span>{item.fromUser?.username}</span>,
            },
            {
                colNameTranslationKey: `logsManagement.colTitle.kind`,
                render: ({ item }) => <span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.kindLabel`}/>
                </span>,
            },
            {
                colNameTranslationKey: `logsManagement.colTitle.details`,
                render: ({ item }) => renderColMapperBody(item),
            },
        ];
    }, []);

    const filtersMapper: ApiTableFiltersMapper<LogsFilterCond, LogsTableFiltersState> = useCallback((state) => {
        const result: ApiTableApiFilters<LogsFilterCond> = [];
        const usersORs: ApiTableApiFilters<LogsFilterCond>[number] = [];
        const kindsORs: ApiTableApiFilters<LogsFilterCond>[number] = [];
        if (state.users !== undefined) {
            state.users.forEach((u) => {
                usersORs.push({
                    field: `fromUserId`,
                    op: `eq`,
                    value: u.id,
                });
            })
            result.push([...usersORs]);
        }
        if (state.start !== '') {
            result.push({
                field: `creationTime`,
                op: `gte`,
                value: DateTime.fromISO(state.start).toMillis(),
            });
        }
        if (state.end !== '') {
            result.push({
                field: `creationTime`,
                op: `lte`,
                value: DateTime.fromISO(state.end).toMillis(),
            });
        }
        if (state.kinds !== undefined) {
            state.kinds.forEach((k) => {
                kindsORs.push({
                    field: "kind",
                    op: "eq",
                    value: k
                });
            })
            result.push([...kindsORs]);
        }
        return result;
    }, []);

    const renderColMapperBody = (item: LogsItem): React.ReactElement => {
        const renderSimpleBody = (displayName: string | null | undefined, kind: string) => <>
            <span>{displayName}</span>
            <Trans i18nKey={`logsManagement.details.${kind}.detailedDescription.text_1`} />
        </>
        switch (item.body.kind) {
            case `info:auth.login`:
                return renderSimpleBody(item.fromUser?.displayName, item.body.kind);
            case `info:auth.logout`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    <span>{formatDuration(item.body.details.sessionDurationInMs)}</span>
                </>
            case `warn:auth.login.failed`:
                return <>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {item.body.details.username}
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_2`} />
                </>
            case `info:auth.changePassword`:
                return renderSimpleBody(item.fromUser?.displayName, item.body.kind);
            case `info:settings.changed`:
                return renderSimpleBody(item.fromUser?.displayName, item.body.kind);
            case `info:users.create`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {item.body.details.user.displayName}&nbsp;({item.body.details.user.username})
                </>
            case `info:users.edit`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    <span>{item.body.details.user.displayName}</span>
                </>
            case `info:users.delete`:
                return renderSimpleBody(item.fromUser?.displayName, item.body.kind);
            case `info:computers.connect`:
                return renderSimpleBody(item.fromUser?.displayName, item.body.kind);
            case `info:computers.create`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`"${item.body.details.computer.name}"`}
                    &nbsp;
                    {`(${item.body.details.computer.protocol}//${item.body.details.computer.hostname}:${item.body.details.computer.port})`}
                </>
            case `info:computers.edit`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`"${item.body.details.computer.name}"`}
                </>
            case `info:computers.delete`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`"${item.body.details.computer.name}"`}
                </>
            case `info:accessRules.create`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`"${item.body.details.accessRule.name}"`}
                </>
            case `info:accessRules.edit`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`"${item.body.details.accessRule.name}"`}
                </>
            case `info:accessRules.delete`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`"${item.body.details.accessRule.name}"`}
                </>
            case `info:calls.create`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`(${item.body.details.call.shortCallId})`}
                </>
            case `info:chats.create`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`(${item.body.details.chat.name ?? item.body.details.chat.participants[0]?.displayName})`}
                </>
            case `info:chats.edit`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`(${item.body.details.chat.name})`}
                </>
            case `info:chats.delete`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`(${item.body.details.chat.name})`}
                </>
            case `info:chats.expel`:
                return renderSimpleBody(item.fromUser?.displayName, item.body.kind);
            case `info:chats.leave`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`logsManagement.details.${item.body.kind}.detailedDescription.text_1`} />
                    {`(${item.body.details.chat.name})`}
                </>
        
            default:
                return <>{JSON.stringify(item.body)}</>;
        }
    }

    return <>
        <Head title={t("pageTitles.settingsSandBox")}></Head>
        <Content>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h3" page>
                            <Trans i18nKey="logsManagement.title"/>
                        </BlockTitle>
                        {/* <BlockDes className="text-soft">
                            <Trans i18nKey="usersManagement.usersFound" shouldUnescape values={{count: totalItems}} />
                        </BlockDes> */}
                    </BlockHeadContent>
                    {/* <BlockHeadContent>
                        <Button
                            color="primary"
                            className="btn-icon"
                            onClick={() => setModals({ ...modals, add: true })}
                        >
                            <Icon name="plus"></Icon>
                        </Button>
                    </BlockHeadContent> */}
                </BlockBetween>
            </BlockHead>

            <Block>

                <ApiTable<LogsItem, LogsOrderedCol, LogsFilterCond, LogsTableFiltersState>
                    service={service}
                    colMapper={colMapper}
                    defaultOrderByField="creationTime"
                    defaultOrderByOrder="desc"
                    cache={1}
                    filters={{
                        component: LogsTableFilters,
                        mapper: filtersMapper,
                    }}
                    // searchMapper={searchMapper}
                    // filtersComponent={UsersTableFilters}
                    //cacheFirstAndLast={true}
                    // menuComponent={UsersMenu}
                />
            </Block>
        </Content>
    </>;
}

type LogsTableFiltersState = {
    users?: UserItem[],
    start: string,
    end: string,
    kinds?: AuditLogBody["kind"][],
};
function LogsTableFilters(props: ApiTableFiltersComponentProps<LogsTableFiltersState>): React.ReactElement {
    const onChangeFiltersRef = useRef(props.onChangeFilters);

    const [users, setUsers] = useState(props.currentState?.users);
    const [start, setStart] = useState(props.currentState?.start ?? '');
    const [end, setEnd] = useState(props.currentState?.end ?? '');
    const [kinds, setKinds] = useState(props.currentState?.kinds ?? undefined)

    const state = useMemo<LogsTableFiltersState | null>(() => {
        if (users === undefined && start === '' && end === '' && kinds === undefined) {
            return null;
        }

        return {
            users,
            start,
            end,
            kinds,
        };
    }, [users, start, end, kinds]);

    useEffect(() => {
        onChangeFiltersRef.current(state);
    }, [state]);

    return <>
        <Col size={12}>
            <div className="form-group">
                <label
                    className="overline-title overline-title-alt"
                    style={{width: "100%"}}
                >
                    <Trans i18nKey="logsManagement.filters.users" />
                    {
                        users !== undefined
                        ?
                        <a
                            href="#reset-field"
                            onClick={(e) => {
                                e.preventDefault();
                                setUsers(undefined);
                            }}
                            style={{float: "right"}}
                        >
                            <Trans i18nKey="logsManagement.resetField" />
                        </a>
                        :
                        <></>
                    }
                </label>
                <UsersSelector
                    key={"users-->" + users?.map(u => u.username).join("-")}
                    selectedData={users?.map((user) => user.id)}
                    onChange={(uu) => {
                        setUsers(uu.length > 0 ? uu : undefined);
                    }}
                    // isDisabled={true}
                />
            </div>
        </Col>
        <Col size={12}>
            <div className="form-group">
                <label
                    className="overline-title overline-title-alt"
                    style={{width: "100%"}}
                >
                    <Trans i18nKey="logsManagement.filters.fromDate" />
                    {
                        start !== ""
                        ?
                        <a
                            href="#reset-field"
                            onClick={(e) => {
                                e.preventDefault();
                                setStart("");
                            }}
                            style={{float: "right"}}
                        >
                            <Trans i18nKey="logsManagement.resetField" />
                        </a>
                        :
                        <></>
                    }
                </label>
                <input
                    lang={getCurrentLang()}
                    type="datetime-local"
                    value={start ?? ''}
                    onChange={e => {
                        const val = e.target.value;
                        setStart(val);
                    }}
                    className="form-control"
                />
            </div>
        </Col>
        <Col size={12}>
            <div className="form-group">
                <label
                    className="overline-title overline-title-alt"
                    style={{width: "100%"}}
                >
                    <Trans i18nKey="logsManagement.filters.toDate" />
                    {
                        end !== ""
                        ?
                        <a
                            href="#reset-field"
                            onClick={(e) => {
                                e.preventDefault();
                                setEnd("");
                            }}
                            style={{float: "right"}}
                        >
                            <Trans i18nKey="logsManagement.resetField" />
                        </a>
                        :
                        <></>
                    }
                </label>
                <input
                    lang={getCurrentLang()}
                    type="datetime-local"
                    value={end ?? ''}
                    onChange={e => {
                        const val = e.target.value;
                        setEnd(val);
                    }}
                    className="form-control"
                />
            </div>
        </Col>
        <Col size={12}>
            <div className="form-group">
                <label
                    className="overline-title overline-title-alt"
                    style={{width: "100%"}}
                >
                    <Trans i18nKey="logsManagement.filters.kinds" />
                    {
                        kinds !== undefined
                        ?
                        <a
                            href="#reset-field"
                            onClick={(e) => {
                                e.preventDefault();
                                setKinds(undefined);
                            }}
                            style={{float: "right"}}
                        >
                            <Trans i18nKey="logsManagement.resetField" />
                        </a>
                        :
                        <></>
                    }
                </label>
                <KindsSelector
                    key={"kinds-->" + kinds?.map(k=>k.toString()).join("-")}
                    selectedData={kinds}
                    onChange={(kk) => {
                        setKinds(kk.length > 0 ? kk.map(k => k.id as AuditLogBody["kind"]) : undefined);
                    }}
                />
            </div>
        </Col>
    </>;
}


type LogsPageProps = {}
const LogsPage: FC<LogsPageProps> = () => <LogsTable />;

export default LogsPage;