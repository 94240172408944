import { z } from "zod";
export const FsDownloadFileParamsSchema = z.object({
    id: z.string().uuid(),
    checksum: z.string().min(1),
    startOffset: z.number().int().min(0).optional().default(0),
    sizeInBytes: z.number().int().min(1).max(1 * 1024 * 1024).nullable().optional().default(null),
});
export const FsDownloadFileOutputSchema = z.object({
    chunkBase64: z.string(),
});
