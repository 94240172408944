import MainService from "./MainService";

import {
    ChangeSettingsParams,
    GetSettingsOutput,
} from "@trantor/vdesk-api-client/dist/clients/misc";

class MS {
    private client = MainService.client.misc;

    async getSettings(): Promise<GetSettingsOutput> {
        return MainService.call(() => this.client.getSettings());
    }

    async changeSettings(params: ChangeSettingsParams): Promise<void> {
        return MainService.call(() => this.client.changeSettings(params));
    }
}
const MiscService = new MS();
export default MiscService;
