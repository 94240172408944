import React, { MouseEventHandler, ReactNode } from "react";
import Icon from "../icon/Icon";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const Block: React.FC<{
  className?: string;
  size?: string;
  children?: ReactNode;
}> = ({ className = null, size = null, ...props }) => {
  const blockClass = classNames({
    "nk-block": true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{props.children}</div>;
};
export const BlockContent: React.FC<{
  className?: string;
  size?: string;
  children?: ReactNode;
}> = ({ className = null, ...props }) => {
  const blockContentClass = classNames({
    "nk-block-content": true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{props.children}</div>;
};

export const BlockBetween: React.FC<{
  className?: string;
  size?: string;
  children?: ReactNode;
}> = ({ className = null, size = null, ...props }) => {
  return (
    <div className={`${size ? `nk-block-between-${size}` : "nk-block-between"} ${className ? className : ""}`}>
      {props.children}
    </div>
  );
};
export const BlockHead: React.FC<{
  className?: string;
  size?: string;
  wide?: string;
  children?: ReactNode;
}> = ({ className = null, size = null, wide = null, ...props }) => {
  const blockHeadClass = classNames({
    "nk-block-head": true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{props.children}</div>;
};
export const BlockHeadContent: React.FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className = null, ...props }) => {
  return <div className={`nk-block-head-content${className ? " " + className : ""}`}>{props.children}</div>;
};
interface BlockTitleProps {
  className?: string;
  page?: boolean;
  tag?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
}
export const BlockTitle: React.FC<BlockTitleProps> = ({ className, page, children, ...props }) => {
  const classes = `nk-block-title ${page ? "page-title" : "title"}${className ? " " + className : ""}`;
  return (
    <>{!props.tag ? <h3 className={classes}>{children}</h3> : <props.tag className={classes}>{children}</props.tag>}</>
  );
};
export const BlockDes: React.FC<{
  className?: string;
  page?: boolean;
  children?: ReactNode;
}> = ({ className, page, ...props }) => {
  const classes = `nk-block-des${className ? " " + className : ""}`;
  return <div className={classes}>{props.children}</div>;
};

export const BlockHeadSub: React.FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className, ...props }) => {
  return (
    <div className={`nk-block-head-sub ${className ? className : ""}`}>
      <span>{props.children}</span>
    </div>
  );
};

export const BlockImage: React.FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className, ...props }) => {
  return <div className={`nk-block-image ${className ? className : ""}`}>{props.children}</div>;
};

export const BackTo: React.FC<{
  className?: string;
  link?: string;
  icon?: string;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}> = ({ className, link, icon, onClick, ...props }) => {
  const classes = `back-to${className ? " " + className : ""}`;
  return (
    <div className="nk-block-head-sub" onClick={onClick}>
      <Link className={classes} to={process.env.PUBLIC_URL + link}>
        <Icon name={icon} />
        <span>{props.children}</span>
      </Link>
    </div>
  );
};
