import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { CreateStorageOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/createStorage.js";
import { DeleteStorageOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/deleteStorage.js";
import { EditStorageOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/editStorage.js";
import { FsChangeDirMetadataOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsChangeDirMetadata.js";
import { FsChangeFileMetadataOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsChangeFileMetadata.js";
import { FsCopyFileOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsCopyFile.js";
import { FsCreateDirOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsCreateDir.js";
import { FsCreateFileOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsCreateFile.js";
import { FsCreateHomeDirOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsCreateHomeDir.js";
import { FsDeleteDirOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsDeleteDir.js";
import { FsDeleteFileOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsDeleteFile.js";
import { FsDownloadFileOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsDownloadFile.js";
import { FsDownloadUrlOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsDownloadUrl.js";
import { FsFileInfoOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsFileInfo.js";
import { FsListDirOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsListDir.js";
import { FsMoveDirOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsMoveDir.js";
import { FsMoveFileOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsMoveFile.js";
import { FsStartUploadFileOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsStartUploadFile.js";
import { FsUploadFileChunkOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/fsUploadFileChunk.js";
import { GetStoragesOutputSchema } from "@trantor/vdesk-api-schemas/dist/fs/getStorages.js";
export class FsClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async createStorage(params) {
        const res = await this.call(`createStorage`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: CreateStorageOutputSchema.parse(res.body),
        };
    }
    async deleteStorage(params) {
        const res = await this.call(`deleteStorage`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: DeleteStorageOutputSchema.parse(res.body),
        };
    }
    async editStorage(params) {
        const res = await this.call(`editStorage`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: EditStorageOutputSchema.parse(res.body),
        };
    }
    async fsChangeDirMetadata(params) {
        const res = await this.call(`fsChangeDirMetadata`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsChangeDirMetadataOutputSchema.parse(res.body),
        };
    }
    async fsChangeFileMetadata(params) {
        const res = await this.call(`fsChangeFileMetadata`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsChangeFileMetadataOutputSchema.parse(res.body),
        };
    }
    async fsCopyFile(params) {
        const res = await this.call(`fsCopyFile`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsCopyFileOutputSchema.parse(res.body),
        };
    }
    async fsCreateDir(params) {
        const res = await this.call(`fsCreateDir`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsCreateDirOutputSchema.parse(res.body),
        };
    }
    async fsCreateFile(params) {
        const res = await this.call(`fsCreateFile`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsCreateFileOutputSchema.parse(res.body),
        };
    }
    async fsCreateHomeDir(params) {
        const res = await this.call(`fsCreateHomeDir`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsCreateHomeDirOutputSchema.parse(res.body),
        };
    }
    async fsDeleteDir(params) {
        const res = await this.call(`fsDeleteDir`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsDeleteDirOutputSchema.parse(res.body),
        };
    }
    async fsDeleteFile(params) {
        const res = await this.call(`fsDeleteFile`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsDeleteFileOutputSchema.parse(res.body),
        };
    }
    async fsDownloadFile(params) {
        const res = await this.call(`fsDownloadFile`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsDownloadFileOutputSchema.parse(res.body),
        };
    }
    async fsDownloadUrl(params) {
        const res = await this.call(`fsDownloadUrl`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsDownloadUrlOutputSchema.parse(res.body),
        };
    }
    async fsFileInfo(params) {
        const res = await this.call(`fsFileInfo`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsFileInfoOutputSchema.parse(res.body),
        };
    }
    async fsListDir(params) {
        const res = await this.call(`fsListDir`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsListDirOutputSchema.parse(res.body),
        };
    }
    async fsMoveDir(params) {
        const res = await this.call(`fsMoveDir`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsMoveDirOutputSchema.parse(res.body),
        };
    }
    async fsMoveFile(params) {
        const res = await this.call(`fsMoveFile`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsMoveFileOutputSchema.parse(res.body),
        };
    }
    async fsStartUploadFile(params) {
        const res = await this.call(`fsStartUploadFile`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsStartUploadFileOutputSchema.parse(res.body),
        };
    }
    async fsUploadFileChunk(params) {
        const res = await this.call(`fsUploadFileChunk`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: FsUploadFileChunkOutputSchema.parse(res.body),
        };
    }
    async getStorages(params) {
        const res = await this.call(`getStorages`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetStoragesOutputSchema.parse(res.body),
        };
    }
}
