import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { GetLogsOutputSchema } from "@trantor/vdesk-api-schemas/dist/getLogs.js";
export class LogsClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async getLogs(params) {
        const res = await this.call(`getLogs`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetLogsOutputSchema.parse(res.body),
        };
    }
}
