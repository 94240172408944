import React, { FC, useMemo } from "react"
import { ApiTable, ApiTableApiService, ApiTableColMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import LogsService from "../../services/LogsService";
import { Trans } from "react-i18next";
import { formatDate, formatDuration } from "../Utils";
import Head from "../../layout/head/Head";
import { t } from "i18next";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import Content from "../../layout/content/Content";

const getLogsApi = LogsService.getLogs.bind(LogsService);
type LogsItem = ItemOfApi<typeof getLogsApi>;
type LogsOrderedCol = OrderedColOfApi<typeof getLogsApi>;
type LogsFilterCond = FilterCondOfApi<typeof getLogsApi>;
const service: ApiTableApiService<LogsItem, LogsOrderedCol, LogsFilterCond> = {
    get: (params) => LogsService.getLogs(params)
};

function LogsTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<LogsItem, LogsOrderedCol> = useMemo(() => {
        return [
            // {
            //     colNameTranslationKey: `apiTable.logs.id`,
            //     render: ({ item }) => <span>{item.id}</span>,
            // },
            {
                colNameTranslationKey: `apiTable.logs.colTitle.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `apiTable.logs.colTitle.displayName`,
                render: ({ item }) => <div className="user-info">
                                        <span className="tb-lead">{item.fromUser?.displayName}</span>
                                    </div>
            },
            {
                colNameTranslationKey: `apiTable.logs.colTitle.username`,
                render: ({ item }) => <span>{item.fromUser?.username}</span>,
            },
            {
                colNameTranslationKey: `apiTable.logs.colTitle.kind`,
                render: ({ item }) => <span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.kindLabel`}/>
                </span>,
            },
            {
                colNameTranslationKey: `apiTable.logs.colTitle.details`,
                render: ({ item }) => renderColMapperBody(item),
            },
        ];
    }, []);

    const renderColMapperBody = (item: LogsItem): React.ReactElement => {
        switch (item.body.kind) {
            case `info:auth.login`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                </>
            case `info:auth.logout`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                    <span>{formatDuration(item.body.details.sessionDurationInMs)}</span>
                </>
            case `warn:auth.login.failed`:
                return <>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                    {item.body.details.username}
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_2`} />
                </>
            case `info:auth.changePassword`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                </>
            case `info:settings.changed`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                </>
            case `info:users.create`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                    {item.body.details.user.displayName}&nbsp;({item.body.details.user.username})
                </>
            case `info:users.edit`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                </>
            case `info:users.delete`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                </>
            case `info:computers.connect`:
                return <>
                    <span>{item.fromUser?.displayName}</span>
                    <Trans i18nKey={`apiTable.logs.details.${item.body.kind}.detailedDescription.text_1`} />
                </>
        
            default:
                return <></>;
        }
    }

    return <>
        <Head title={t("pageTitles.settingsSandBox")}></Head>
        <Content>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                        <BlockTitle tag="h3" page>
                            <Trans i18nKey="apiTable.logs.title"/>
                        </BlockTitle>
                        {/* <BlockDes className="text-soft">
                            <Trans i18nKey="usersManagement.usersFound" shouldUnescape values={{count: totalItems}} />
                        </BlockDes> */}
                    </BlockHeadContent>
                    {/* <BlockHeadContent>
                        <Button
                            color="primary"
                            className="btn-icon"
                            onClick={() => setModals({ ...modals, add: true })}
                        >
                            <Icon name="plus"></Icon>
                        </Button>
                    </BlockHeadContent> */}
                </BlockBetween>
            </BlockHead>

            <Block>

                <ApiTable<LogsItem, LogsOrderedCol, LogsFilterCond>
                    service={service}
                    colMapper={colMapper}
                    defaultOrderByField={`creationTime`}
                    defaultOrderByOrder="desc"
                    cache={1}

                    // searchMapper={searchMapper}
                    // filtersComponent={UsersTableFilters}
                    //cacheFirstAndLast={true}
                    // menuComponent={UsersMenu}
                />
                </Block>
            </Content>
    </>;
}

type SandBoxProps = {
    name: string
}
const Sandbox: FC<SandBoxProps> = () => <LogsTable />;

export default Sandbox;