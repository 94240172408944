import { z } from "zod";
import { EnumOpSchema, NumberOpSchema } from "../../filters.js";
export const ChatArchivesConditionSchema = z.union([
    z.object({
        field: z.literal("id"),
        op: EnumOpSchema,
        value: z.string().uuid()
    }),
    z.object({
        field: z.literal("chatId"),
        op: EnumOpSchema,
        value: z.string().uuid()
    }),
    z.object({
        field: z.literal("creationTime"),
        op: NumberOpSchema,
        value: z.number().int()
    }),
]);
export const ChatArchivesFiltersSchema = z.array(ChatArchivesConditionSchema.or(z.array(ChatArchivesConditionSchema)));
export const ChatArchivesOrderBySchema = z.object({
    field: z.enum(["name", "creationTime"]),
    order: z.enum(["asc", "desc"])
});
export const GetChatArchivesParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: ChatArchivesOrderBySchema.optional().default({
        field: "creationTime",
        order: "desc",
    }),
    filters: ChatArchivesFiltersSchema.optional().default([])
});
export const ChatArchiveItemParticipantSchema = z.object({
    user: z.object({
        id: z.string().uuid(),
        displayName: z.string().min(1),
        username: z.string().min(1),
    }),
    moderator: z.boolean(),
});
export const ChatArchiveItemSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).nullable(),
    chatId: z.string().uuid(),
    userId: z.string().uuid(),
    participants: ChatArchiveItemParticipantSchema.array(),
    creationTime: z.number().int(),
});
export const GetChatArchivesOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: ChatArchiveItemSchema.array(),
});
