import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { compareJSON, useApiGetter } from "../utils/CronJob";
import { JSONObject, JSONValue } from "@trantor/vdesk-api-schemas/dist/json";
import { scope } from "../utils/scope";
import { useHandler } from "../utils/handler";
import { Trans, useTranslation } from "react-i18next";
import FaIcon from "./icon/FaIcon";
import { Button, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent } from "./Component";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, UncontrolledDropdown } from "reactstrap";
import classNames from "classnames";
import l, { isDevelopment } from "../utils/Log";
import { t } from "i18next";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import ConfirmationModal from "../pages/settings/modals/ConfirmationModal";
import { DefaultSpinner } from "./default-spinner";



export type ItemOfApi<F extends ((...args: any[]) => Promise<{ items: unknown[] }>)> = Awaited<ReturnType<F>>[`items`][number];

export type OrderedColOfApi<F extends ((params: Record<string, any>) => Promise<any>)> = NonNullable<Parameters<F>[0][`orderBy`]>[`field`];

export type FiltersOfApi<F extends ((params: Record<string, any>) => Promise<any>)> = NonNullable<Parameters<F>[0][`filters`]>;

export type FilterCondOfApi<F extends ((params: Record<string, any>) => Promise<any>)> = FiltersOfApi<F> extends Array<(infer C) | (infer C)[]> ? C : never;



/**
 * A JSONObject that grants the `id` property existence
 * @date 5/4/2024 - 16:34:19
 *
 * @export
 * @typedef {ApiTableBaseItem}
 */
export type ApiTableBaseItem = JSONObject & { id: string };

export type ApiTableApiService<Item extends ApiTableBaseItem, OrderedCol extends string, FilterCond extends JSONValue> = {
    get: (params: {
        page?: number,
        itemsPerPage?: number,
        orderBy?: ApiTableApiOrderBy<OrderedCol>,
        filters?: ApiTableApiFilters<FilterCond>,
    }) => Promise<{
        page: number,
        itemsPerPage: number,
        totalItems: number,
        totalPages: number,
        items: Item[],
    }>,
};

export type ApiTableApiFilters<FilterCond extends JSONValue> = Array<FilterCond | FilterCond[]>;

export type ApiTableApiOrderBy<Col extends string> = {
    field: Col,
    order: `asc` | `desc`,
};

export type ApiTableApi<Item extends ApiTableBaseItem, OrderedCol extends string, FilterCond extends JSONValue> = (
    params: {
        page?: number,
        itemsPerPage?: number,
        orderBy?: ApiTableApiOrderBy<OrderedCol>,
        filters?: ApiTableApiFilters<FilterCond>,
    },
) => Promise<{
    page: number,
    itemsPerPage: number,
    totalItems: number,
    totalPages: number,
    items: Item[],
}>;

export type ApiTableSearchMapper<FilterCond extends JSONValue> = (search: string, currentFilters: ApiTableApiFilters<FilterCond>) => ApiTableApiFilters<FilterCond>;

export type ApiTableColMapper<Item extends ApiTableBaseItem, OrderedCol extends string> = Array<{
    colNameTranslationKey: string,
    canOrder?: OrderedCol,
    hideInTable?: boolean,
    render: (props: { item: Item, location: `table` | `detailsModal` }) => React.ReactElement,
}>;

export type ApiTableFiltersComponentProps<FilterCompState extends JSONObject> = {
    onChangeFilters: (newFilters: FilterCompState | null) => void,
    currentState: FilterCompState | null,
};




export type ApiTableContextMenuElem<Item extends ApiTableBaseItem> = {
    icon?: (item: Item) => {
        fa: IconName,
        ni?: undefined,
    } | {
        fa?: undefined,
        ni: string,
    },
    actionNameTrans: (item: Item) => string, // Note: Needs to be a function to handle different translations or situations programmatically
    confirmDescriptionTrans?: (item: Item) => string,
    action: `details` | ((item: Item) => Promise<void>),
}

/**
 * An element in the ActionsGroup list inside ApiTable
 * @date 5/4/2024 - 16:37:37
 *
 * @export
 * @typedef {ApiTableActionGroupElem}
 */
export type ApiTableActionGroupElem = {
    icon?: {
        fa: IconName,
        ni?: undefined,
    } | {
        fa?: undefined,
        ni: string,
    },
    actionNameTrans: string,
    confirmDescriptionTrans: string,
    action: (ids: string[]) => Promise<void>,
};

export type ApiTableFiltersMapper<FilterCond extends JSONValue, FilterCompState extends JSONObject> = (state: FilterCompState) => ApiTableApiFilters<FilterCond>;



/**
 * Description placeholder
 * @date 5/4/2024 - 16:47:27
 *
 * @export
 * @typedef {ApiTableProps}
 * @template {ApiTableBaseItem} Item
 * @template {string} OrderedCol
 * @template {JSONValue} FilterCond
 * @template {JSONObject} FilterCompState
 */
export type ApiTableProps<Item extends ApiTableBaseItem, OrderedCol extends string, FilterCond extends JSONValue, FilterCompState extends JSONObject> = {
    service: ApiTableApiService<Item, OrderedCol, FilterCond>,
    colMapper: ApiTableColMapper<Item, OrderedCol>,
    defaultOrderByField: OrderedCol,
    defaultOrderByOrder?: `asc` | `desc`,
    searchMapper?: ApiTableSearchMapper<FilterCond>,
    filters?: {
        component: (props: ApiTableFiltersComponentProps<FilterCompState>) => React.ReactElement,
        mapper: ApiTableFiltersMapper<FilterCond, FilterCompState>,
    },
    initialPage?: number,
    cache?: number,
    intervalMs?: number,
    delayLoading?: { afterMs: number, minDurationMs: number },
    searchPlaceholderTransKey?: string,
    detailsModalTitleTransKey?: string,
    contextMenuComponents?: ApiTableContextMenuElem<Item>[],
    groupActions?: ApiTableActionGroupElem[],
    additionalButtons?: JSX.Element[],
};



/**
 * Description placeholder
 * @date 5/4/2024 - 16:47:04
 *
 * @export
 * @template {ApiTableBaseItem} Item
 * @template {string} OrderedCol
 * @template {JSONValue} FilterCond
 * @template {JSONObject} [FilterCompState={}]
 * @param {ApiTableProps<Item, OrderedCol, FilterCond, FilterCompState>} props
 * @returns {React.ReactElement}
 */
export function ApiTable<Item extends ApiTableBaseItem, OrderedCol extends string, FilterCond extends JSONValue, FilterCompState extends JSONObject = {}>(props: Readonly<ApiTableProps<Item, OrderedCol, FilterCond, FilterCompState>>): React.ReactElement {
    const serviceGetRef = useRef(props.service.get);

    const groupActionsRef = useRef([...(props.groupActions?.map(e => ({ ...e, icon: e.icon === undefined ? undefined : { ...e.icon } })) ?? [])]);

    const contextMenuComponentsRef = useRef([...(props.contextMenuComponents?.map(e => ({ ...e })) ?? [])]);

    const filtersComponentRef = useRef(props.filters?.component);
    const filtersMapperRef = useRef(props.filters?.mapper);

    const colMapperRef = useRef(props.colMapper.map(e => ({ ...e })));

    const searchMapperRef = useRef(props.searchMapper);
    searchMapperRef.current = props.searchMapper;

    const initialPageRef = useRef(props.initialPage ?? 1);
    const cacheRef = useRef(props.cache ?? 0);
    const intervalMsRef = useRef(props.intervalMs ?? 1000);

    const delayLoadingRef = useRef(props.delayLoading === undefined ? undefined : {
        afterMs: props.delayLoading.afterMs,
        minDuration: props.delayLoading.minDurationMs,
    });

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [search, setSearch] = useState(``);

    const [orderByField, setOrderByField] = useState<OrderedCol>(props.defaultOrderByField);
    const [orderByOrder, setOrderByOrder] = useState<`asc` | `desc`>(props.defaultOrderByOrder ?? `asc`);

    const [currentFilterCompStateTemp, setCurrentFilterCompStateTemp] = useState<FilterCompState | null>(null);
    const [currentFilterCompState, setCurrentFilterCompState] = useState<FilterCompState | null>(null);

    const onChangeFilters = useCallback((newFilters: FilterCompState | null) => {
        setCurrentFilterCompStateTemp(newFilters);
    }, []);

    const prevFiltersRef = useRef<ApiTableApiFilters<FilterCond>>([]);

    const filters = useMemo<ApiTableApiFilters<FilterCond>>(() => {
        const newFilters = scope(() => {
            if (currentFilterCompState === null || filtersMapperRef.current === undefined) {
                return [];
            }
            return filtersMapperRef.current(currentFilterCompState);
        });

        if (compareJSON(newFilters, prevFiltersRef.current) !== true) {
            prevFiltersRef.current = newFilters;
            return newFilters;
        }

        return prevFiltersRef.current;
    }, [currentFilterCompState]);

    const [resetFiltersCounter, setResetFiltersCounter] = useState(0);

    const orderBy = useMemo<ApiTableApiOrderBy<OrderedCol>>(() => {
        return {
            field: orderByField,
            order: orderByOrder,
        };
    }, [orderByField, orderByOrder]);

    const { data, currentPage, loading, totalItems, requestPage, firstTime } = useApiGetter({
        itemsPerPage,
        cache: cacheRef.current,
        delayLoading: delayLoadingRef.current,
        filters,
        orderBy,
        initialPage: initialPageRef.current,
        intervalMs: intervalMsRef.current,
        search,
        fetcher: async (params: {
            page: number,
            filters?: ApiTableApiFilters<FilterCond>,
            orderBy?: ApiTableApiOrderBy<OrderedCol>,
            itemsPerPage: number,
            search: string,
        }): Promise<{
            items: Item[],
            page: number,
            totalPages: number,
            totalItems: number,
        }> => {
            const newFilters = scope(() => {
                if (searchMapperRef.current === undefined) {
                    return params.filters ?? [];
                }

                return searchMapperRef.current(params.search, params.filters ?? []);
            });
            const result = await serviceGetRef.current({
                page: params.page,
                itemsPerPage: params.itemsPerPage,
                orderBy: params.orderBy,
                filters: newFilters,
            })
            // result.items = result.items.map(item => {
            //     const parsed = z.object({
            //         ldapInfo: JSONObjectSchema.nullable(),
            //     }).safeParse(item);
            //     if (parsed.success) {
            //         return {
            //             ...item,
            //             ldapInfo: {
            //                 ldapId: "DEADBEEF",
            //                 ldapDn: "Fake-DN-please-ignore",
            //             }
            //         }
            //     }
            //     return item;
            // });

            return {
                page: result.page,
                totalItems: result.totalItems,
                totalPages: result.totalPages,
                items: result.items,
            };
        },
    });

    const [handleChangeOrder] = useHandler((field: OrderedCol, order: `asc` | `desc`) => {
        setOrderByField(field);
        setOrderByOrder(order);
    });

    const renderOrderingIcon = (colInfo: typeof colMapperRef.current[number]) => {
        const canBeOrdered = colInfo.canOrder !== undefined;
        const orderActive = (colInfo.canOrder !== undefined && orderByField === colInfo.canOrder) ? orderByOrder : null;
        if (orderActive === null) {
            if (canBeOrdered === true) {
                return <div style={{ float: "right", height: "1.2rem" }}><FaIcon icon="sort" /></div>;
            } else {
                return <></>;
            }
        } else if (orderActive === "asc") {
            //asc
            return <div style={{ float: "right", height: "1.2rem" }}><FaIcon icon="sort-down" /></div>;
        } else if (orderActive === "desc") {
            //desc
            return <div style={{ float: "right", height: "1.2rem" }}><FaIcon icon="sort-up" /></div>;
        }
        return <></>;
    }

    const [tableSm, setTableSm] = useState(false);

    const [selectedElems, setSelectedElems] = useState<string[]>([]);
    const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
        let newSelectedElems = [...selectedElems];
        if (e.currentTarget.checked) {
            newSelectedElems.push(id);
        } else {
            newSelectedElems = selectedElems.filter(eId => eId !== id)
        }
        setSelectedElems(newSelectedElems)
    };
    const toggleSelectAllElems = (selectAll: boolean): void => {
        let newSelectedElems;
        if (selectAll) {
            newSelectedElems = selectedElems.concat(data.map((item) => item.id));
        } else {
            newSelectedElems = selectedElems.filter(selElem => !data.map(d => d.id).includes(selElem))
        }
        setSelectedElems(newSelectedElems);
    };
    const [currentBulkAction, setCurrentBulkAction] = useState<ApiTableActionGroupElem | null>(null);
    const [currentContextMenuAction, setCurrentContextMenuAction] = useState<ApiTableContextMenuElem<Item> | null>(null);

    const FilterComp = filtersComponentRef.current;

    const [currentDetailsItem, setCurrentDetailsItem] = useState<Item | null>(null);

    const openDetails = useCallback((item: Item) => {
        setCurrentDetailsItem(item);
    }, []);

    const [contextMenuState, setContextMenuState] = useState<{
        coords: { x: number, y: number } | null,
        item: Item | null,
        open: boolean
    }>({
        open: false,
        item: null,
        coords: null,
    });

    useEffect(() => {
        // Closes the context menu whenever you click or scroll
        window.addEventListener("click", (e) => {
            if (e.target instanceof Element && !e.target.classList.contains("drop-down-menu")) setContextMenuState((s) => ({ ...s, open: false }));
        });
        window.addEventListener("drag", () => {
            setContextMenuState((s) => ({ ...s, open: false }));
        });
        window.addEventListener("resize", () => {
            setContextMenuState((s) => ({ ...s, open: false }));
        });
        window.addEventListener("scroll", () => {
            setContextMenuState((s) => ({ ...s, open: false }));
        });
        return () => {
            window.removeEventListener("click", () => {
                setContextMenuState((s) => ({ ...s, open: false }));
            });
            window.removeEventListener("drag", () => {
                setContextMenuState((s) => ({ ...s, open: false }));
            });
            window.removeEventListener("resize", () => {
                setContextMenuState((s) => ({ ...s, open: false }));
            });
            window.removeEventListener("scroll", () => {
                setContextMenuState((s) => ({ ...s, open: false }));
            });
        };
    }, []);

    const [filtersDropdownOpen, setFiltersDropdownOpen] = useState(false);

    const [error, setError] = useState<string | null>(null);
    const oldError = useRef<string | null>(null);
    const stickyError = useMemo(() => {
        if (error !== null) {
            oldError.current = error;
            return error;
        }
        return oldError.current;
    }, [error]);

    return <div style={{ position: "relative" }}>
        <DataTable>
            {
                loading
                    ? <div className="absolute-overlay">
                        {/* <div className="text-center"> */}
                        <DefaultSpinner />
                        <Trans i18nKey="apiTable.loading" />
                        {/* </div> */}
                    </div>
                    : undefined
            }
            <div className="card-inner position-relative card-tools-toggle">
                {
                    searchMapperRef.current === undefined
                        ? <></>
                        : <div className="form-group">
                            <div className="form-control-wrap nk-chat-aside-search p-0 w-100">
                                <div className="form-icon form-icon-left">
                                    <Icon name="search"></Icon>
                                </div>
                                <input
                                    type="text"
                                    className="border-transparent form-focus-none form-control form-round"
                                    placeholder={t(props.searchPlaceholderTransKey ?? `apiTable.defaultSearchPlaceholder`).toString()}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Escape") {
                                            e.currentTarget.value = "";
                                            setSearch("");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                }
                <div className="card-title-group">
                    {groupActionsRef.current.length === 0 ? <></> : <div className="card-tools">
                        <div className="form-inline flex-nowrap gx-3">
                            <div className="form-wrap">
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        tag="a"
                                        className={classNames({
                                            "btn btn-outline-primary": true,
                                            disabled: selectedElems.length === 0,
                                        })}
                                    >
                                        {t(`apiTable.bulkActions.placeholder`)}
                                    </DropdownToggle>
                                    <DropdownMenu
                                        className="filter-wg dropdown-menu-md"
                                        style={{ overflow: "visible" }}
                                    >
                                        <div className="dropdown-head p-3">
                                            <span className="sub-title dropdown-title">
                                                <Trans i18nKey="apiTable.bulkActions.title" />
                                            </span>
                                            <div className="dropdown">
                                                <DropdownItem
                                                    href="#more"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                    }}
                                                    className="btn btn-sm d-flex center"
                                                >
                                                    <Icon name="cross"></Icon>
                                                </DropdownItem>
                                            </div>
                                        </div>
                                        <div className="dropdown-body dropdown-body-rg p-0">
                                            {groupActionsRef.current.map((e) => <DropdownItem
                                                key={`bulk-action-${e.actionNameTrans}`}
                                                onClick={() => {
                                                    setCurrentBulkAction(e);
                                                }}
                                            >
                                                {
                                                    e.icon?.fa !== undefined
                                                        ? <FaIcon icon={e.icon.fa} />
                                                        : undefined
                                                }
                                                {
                                                    e.icon?.ni !== undefined
                                                        ? <Icon name={e.icon?.ni} />
                                                        : <></>
                                                }
                                                &nbsp;
                                                <Trans i18nKey={e.actionNameTrans} />
                                            </DropdownItem>)}
                                        </div>
                                        <div className="dropdown-foot center p-1">
                                            <Trans i18nKey="apiTable.bulkActions.selectedItems" values={{ count: selectedElems.length }} />
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                    </div>}
                    {loading
                        ? undefined
                        : <div className="div-tools">
                            <div className="form-inline flex-nowrap gx-3">
                                <div className="form-wrap">
                                    {totalItems}
                                    &nbsp;
                                    <Trans i18nKey="apiTable.foundElements" values={{ count: totalItems }} />
                                </div>
                            </div>
                        </div>}
                    <div className="card-tools me-n1">
                        <ul className="btn-toolbar gx-1">
                            <li>
                                <div className="toggle-wrap">
                                    <Button
                                        className={`btn-icon toggle ${tableSm ? "active" : ""}`}
                                        onClick={() => setTableSm(true)}
                                        color="primary"
                                    >
                                        <Icon name="menu-right"></Icon>
                                    </Button>
                                    <div className={`toggle-content ${tableSm ? "content-active" : ""}`}>
                                        <ul className="btn-toolbar gx-1">
                                            <li className="toggle-close">
                                                <Button
                                                    className="btn-icon btn-trigger toggle"
                                                    onClick={() => setTableSm(false)}
                                                >
                                                    <Icon name="arrow-left"></Icon>
                                                </Button>
                                            </li>
                                            {FilterComp === undefined
                                                ? <></>
                                                : <li>
                                                    <Dropdown
                                                        isOpen={filtersDropdownOpen}
                                                        toggle={() => {
                                                            setFiltersDropdownOpen(!filtersDropdownOpen);
                                                            setResetFiltersCounter(c => c + 1);
                                                        }}
                                                    >
                                                        <DropdownToggle
                                                            tag="a"
                                                            className="btn btn-trigger btn-icon dropdown-toggle"
                                                        >
                                                            <div className={classNames({
                                                                dot: true,
                                                                "dot-primary": filters.length > 0,
                                                            })} />
                                                            <Icon name="filter-alt"></Icon>
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            end
                                                            className="filter-wg dropdown-menu-lg"
                                                            style={{ overflow: "visible" }}
                                                        >
                                                            <div className="dropdown-head">
                                                                <span className="sub-title dropdown-title">
                                                                    <Trans i18nKey="apiTable.filters.title" />
                                                                </span>
                                                                <div className="dropdown">
                                                                    <DropdownItem
                                                                        href="#more"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                            setFiltersDropdownOpen(false);
                                                                        }}
                                                                        className="btn btn-sm btn-icon d-flex center"
                                                                    >
                                                                        <Icon name="cross"></Icon>
                                                                    </DropdownItem>
                                                                </div>
                                                            </div>
                                                            <div className="dropdown-body dropdown-body-rg g-3">
                                                                <FilterComp key={`count-${resetFiltersCounter}`} onChangeFilters={onChangeFilters} currentState={currentFilterCompState} />
                                                            </div>
                                                            <div className="dropdown-foot between">
                                                                <a
                                                                    href="#reset"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setCurrentFilterCompState(null);
                                                                        setFiltersDropdownOpen(false);
                                                                        setCurrentFilterCompStateTemp(null); // NOTE: should not be necessary
                                                                    }}
                                                                >
                                                                    {t("apiTable.filters.reset")}
                                                                </a>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setFiltersDropdownOpen(false);
                                                                        setCurrentFilterCompState(currentFilterCompStateTemp);
                                                                    }}
                                                                >
                                                                    <Trans i18nKey="apiTable.filters.apply" />
                                                                </Button>
                                                            </div>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>}
                                            <li>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        tag="a"
                                                        className="btn btn-trigger btn-icon dropdown-toggle"
                                                    >
                                                        <Icon name="list-thumb"></Icon>
                                                    </DropdownToggle>
                                                    <DropdownMenu end className="dropdown-menu-xs">
                                                        <ul className="link-check">
                                                            <li>
                                                                <span>{t("usersManagement.itemsPerPage")}</span>
                                                            </li>
                                                            {(isDevelopment()
                                                                ? [1, 10, 15, 25, 50, 75, 100]
                                                                : [10, 15, 25, 50, 75, 100]
                                                            ).map((pageSize) => (
                                                                <DropdownItem
                                                                    key={pageSize}
                                                                    tag="a"
                                                                    href="#dropdownitem"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        setItemsPerPage(pageSize);
                                                                    }}
                                                                    className={classNames({
                                                                        "active": itemsPerPage === pageSize,
                                                                    })}
                                                                >
                                                                    {pageSize}
                                                                </DropdownItem>
                                                            ))}
                                                        </ul>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <DataTableBody
                scrollHorizontally
                compact
            >
                <DataTableHead>
                    {groupActionsRef.current.length > 0
                        ? <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    onChange={(e) => toggleSelectAllElems(e.target.checked)}
                                    id={data.map(d => d.id).join('+')}
                                    key={data.map(d => d.id).join('+')}
                                    checked={data.map(d => d.id).every(d => selectedElems.includes(d)) && selectedElems.length > 0}
                                />
                                <label className="custom-control-label" htmlFor={data.map(d => d.id).join('+')}></label>
                            </div>
                        </DataTableRow>
                        : <></>}
                    {colMapperRef.current.filter(e => e.hideInTable !== true).map(colInfo => {
                        const handleChangeMyOrder = () => {
                            if (colInfo.canOrder === undefined) {
                                return;
                            }

                            const order = scope(() => {
                                if (orderByField !== colInfo.canOrder) {
                                    return `asc`;
                                }

                                return orderByOrder === `asc` ? `desc` : `asc`;
                            });

                            handleChangeOrder(colInfo.canOrder, order);
                        };
                        return <DataTableRow
                            key={colInfo.colNameTranslationKey}
                            onClick={handleChangeMyOrder}
                            className={colInfo.canOrder !== undefined ? `clickable-text` : ``}
                        >
                            <span className="sub-text">
                                <Trans i18nKey={colInfo.colNameTranslationKey} />
                                &nbsp;
                                {renderOrderingIcon(colInfo)}
                            </span>
                        </DataTableRow>;
                    })}
                    {/* {
            contextMenuComponentsRef.current.length > 0
              ? <DataTableRow className="nk-tb-col-tools text-end">
                {contextMenuState.open && contextMenuState.item !== null
                  ? <GenericContextMenu<Item>
                    key={`floating-context-menu-located-at:(${contextMenuState.coords?.x},${contextMenuState.coords?.y})`}
                    item={contextMenuState.item}
                    coords={contextMenuState.coords}
                    onToggle={() => {
                      setContextMenuState((s) => ({ ...s, open: false }));
                    }}
                    onOpenDetails={item => openDetails(item)}
                    menuComponents={contextMenuComponentsRef.current}
                    onChangeCurrentMenuAction={setCurrentContextMenuAction}
                  />
                  : undefined}
              </DataTableRow>
              : undefined} */}
                </DataTableHead>
                {firstTime
                    ? <DataTableItem>
                        <DataTableRow id="first-loading-curtesy-message">
                            {/* <div className="text-center"> */}
                            <Trans i18nKey="apiTable.firstLoading" />
                            {/* </div> */}
                        </DataTableRow>
                    </DataTableItem>
                    : data.map((item) => {
                        return <DataTableItem key={item.id}>
                            {groupActionsRef.current.length > 0
                                ? <DataTableRow className="nk-tb-col-check"><div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        // defaultChecked={selectedElems.includes(item.id)}
                                        checked={selectedElems.includes(item.id)}
                                        id={item.id + "-selected"}
                                        key={item.id + "-selected"}
                                        onChange={(e) => onSelectChange(e, item.id)}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={item.id + "-selected"}
                                    ></label>
                                </div></DataTableRow>
                                : undefined}
                            {colMapperRef.current.filter(e => e.hideInTable !== true).map((colInfo) => {
                                const Comp = colInfo.render;

                                return <DataTableRow
                                    key={colInfo.colNameTranslationKey}
                                    className="clickable-text"
                                    onClick={() => openDetails(item)}
                                    onContextMenu={e => {
                                        e.preventDefault();
                                        setContextMenuState({
                                            open: true,
                                            item,
                                            coords: {
                                                x: e.clientX,
                                                y: e.clientY
                                            },
                                        });
                                        // if (!contextMenuState.open){
                                        //     setContextMenuState({
                                        //         open: true,
                                        //         item,
                                        //         coords: {
                                        //             x: e.clientX,
                                        //             y: e.clientY
                                        //         },
                                        //     });
                                        // } else {
                                        //     setContextMenuState({
                                        //         open: false,
                                        //         item: null,
                                        //         coords: null,
                                        //     });
                                        // }
                                    }}
                                >
                                    <Comp item={item} location="table" />
                                </DataTableRow>;
                            })}
                            {
                                contextMenuComponentsRef.current.length > 0
                                    ? <DataTableRow className="nk-tb-col-tools d-none d-xl-table-cell">
                                        <ul className="nk-tb-actions gx-1">
                                            <li>
                                                <Button
                                                    color="transparent"
                                                    className="dropdown-toggle btn btn-icon btn-trigger drop-down-menu"
                                                    onClick={(e) => {
                                                        let pos = { x: 0, y: 0 };
                                                        if (e.target instanceof Element) {
                                                            const coords = e.target.getClientRects()[0];
                                                            pos.x = coords ? coords.right : e.clientX;
                                                            pos.y = coords ? coords.bottom : e.clientY;
                                                        }
                                                        setContextMenuState({
                                                            open: true,
                                                            item,
                                                            coords: {
                                                                x: pos.x,
                                                                y: pos.y
                                                            },
                                                        });
                                                    }
                                                    }
                                                    onContextMenu={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <Icon name="more-h" className="drop-down-menu" ></Icon>
                                                </Button>
                                                {/* <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="dropdown-toggle btn btn-icon btn-trigger"
                                onClick={() => setContextMenuState({
                                  open: true,
                                  item,
                                  coords: null
                                })}
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu
                                className="link-list-opt no-bdr"
                                end
                              >
                                {item !== undefined && false
                                  ? <ContextMenuRows<Item>
                                    item={item}
                                    onOpenDetails={item => openDetails(item)}
                                    menuComponents={contextMenuComponentsRef.current}
                                    onChangeCurrentMenuAction={setCurrentContextMenuAction}
                                  />
                                  : undefined}
                              </DropdownMenu>
                            </UncontrolledDropdown> */}
                                            </li>
                                        </ul>
                                    </DataTableRow>
                                    : undefined}
                        </DataTableItem>;
                    })}
            </DataTableBody>
            {totalItems > itemsPerPage && (
                <div className="card-inner">
                    <PaginationComponent
                        itemPerPage={itemsPerPage}
                        totalItems={totalItems}
                        paginate={(page: number) => requestPage(page)}
                        currentPage={currentPage}
                    />
                </div>
            )}
            {
                contextMenuComponentsRef.current.length > 0 && contextMenuState.open && contextMenuState.item !== null
                    ? <GenericContextMenu<Item>
                        key={`floating-context-menu-located-at:(${contextMenuState.coords?.x},${contextMenuState.coords?.y})`}
                        item={contextMenuState.item}
                        coords={contextMenuState.coords}
                        onToggle={() => {
                            setContextMenuState((s) => ({ ...s, open: false }));
                        }}
                        onOpenDetails={item => openDetails(item)}
                        menuComponents={contextMenuComponentsRef.current}
                        onChangeCurrentMenuAction={setCurrentContextMenuAction}
                    />
                    : undefined}
        </DataTable>

        <div className="mt-2 text-center">
            {data.length === 0 && filters.length === 0 && search.length === 0
                ? <Trans i18nKey="apiTable.noItemsFound" />
                : (data.length === 0 && (filters.length > 0 || search.length > 0))
                    ? <Trans i18nKey="apiTable.noItemsWithSetFilters" /> : null}
        </div>

        <BulkConfirmationModal
            groupAction={currentBulkAction}
            onClose={() => {
                setCurrentBulkAction(null);
            }}
            onRunAction={async (groupAction) => {
                await groupAction.action(selectedElems);
            }}
            onError={(e) => {
                setError(e);
            }}
        />

        <ContextMenuConfirmationModal
            contextAction={currentContextMenuAction}
            item={contextMenuState.item}
            onClose={() => {
                setCurrentContextMenuAction(null);
            }}
            onRunAction={async (action) => {
                l.info(`DEV contextMenuState !== null |${contextMenuState !== null}|`);
                l.info(`DEV action.action !== "details" |${action.action !== "details"}|`);
                if (contextMenuState.item !== null && action.action !== "details") {
                    await action.action(contextMenuState.item);
                }
            }}
            onError={(e) => {
                setError(e);
            }}
        />

        <ConfirmationModal
            isOpen={error !== null}
            description={`${t("apiTable.errorModal.description")}: ${t([`apiTable.errorModal.errorCode.${error}`, `errorCode.${error}`, `errorCode.generic`])}`}
            title={`${t("apiTable.errorModal.title")}`}
            primaryBtnText={`${t("apiTable.errorModal.primaryBtnText")}`}
            primaryActionCallback={() => {
                setError(null);
            }}
        />

        <DetailsModal<Item, OrderedCol>
            item={currentDetailsItem}
            colMapper={colMapperRef.current}
            onClose={() => {
                setCurrentDetailsItem(null);
            }}
            detailsModalTitleTransKey={props.detailsModalTitleTransKey}
        />
    </div >;
}



type ContextMenuConfirmationModalProps<Item extends ApiTableBaseItem> = {
    contextAction: ApiTableContextMenuElem<Item> | null,
    item: Item | null,
    onClose: () => void,
    onRunAction: (groupAction: ApiTableContextMenuElem<Item>) => Promise<void>,
    onError: (error: string) => void;
};

function ContextMenuConfirmationModal<Item extends ApiTableBaseItem>(props: ContextMenuConfirmationModalProps<Item>): React.ReactElement {
    const prev = useRef<ApiTableContextMenuElem<Item> | null>(null);
    const contextAction = useMemo(() => {
        if (props.contextAction !== null) {
            prev.current = props.contextAction;
            return props.contextAction;
        }

        return prev.current;
    }, [props.contextAction]);

    const prevDescriptionTransKey = useRef<string>("");
    const descriptionTransKey = useMemo(() => {
        if (props.contextAction?.confirmDescriptionTrans !== undefined && props.item !== null) {
            const key = props.contextAction.confirmDescriptionTrans(props.item);
            prevDescriptionTransKey.current = key;
            return key;
        }

        return prevDescriptionTransKey.current;
    }, [props.contextAction, props.item]);

    if (contextAction === null) {
        return <></>;
    }
    return <ConfirmationModal
        isOpen={props.contextAction !== null}
        title={t("apiTable.contextMenuConfirmationModal.title")}
        description={t(descriptionTransKey)}
        primaryBtnText={t("apiTable.contextMenuConfirmationModal.confirm")}
        secondaryBtnText={t("apiTable.contextMenuConfirmationModal.cancel").toString()}
        primaryActionCallback={() => {
            props.onClose();
            props.onRunAction(contextAction).catch((e) => {
                props.onError(e);
            });
        }}
        secondaryActionCallback={() => {
            props.onClose();
        }}
    />;
}



type GenericContextMenuProps<Item extends ApiTableBaseItem> = {
    item: Item,
    coords: {
        x: number;
        y: number;
    } | null,
    menuComponents: ApiTableContextMenuElem<Item>[],
    onToggle: () => void,
    onOpenDetails: (item: Item) => void,
    onChangeCurrentMenuAction: (data: ApiTableContextMenuElem<Item> | null) => void,
};
function GenericContextMenu<Item extends ApiTableBaseItem>(props: Readonly<GenericContextMenuProps<Item>>): React.ReactElement {
    if (props.coords === null) return <></>;
    const style: CSSProperties = {
        position: "fixed",
        top: `${props.coords.y}px`,
        left: `${props.coords.x}px`,
        zIndex: 10000,
    };

    return <UncontrolledDropdown isOpen={true}>
        <DropdownToggle
            style={style}
            tag="a"
            onClick={(e) => {
                e.preventDefault();
            }}
        ></DropdownToggle>
        <DropdownMenu className="link-list-opt no-bdr">
            <ContextMenuRows<Item>
                {...props}
            />
        </DropdownMenu>
    </UncontrolledDropdown>;
}



type ContextMenuRowsProps<Item extends ApiTableBaseItem> = {
    item: Item,
    menuComponents: ApiTableContextMenuElem<Item>[],
    onToggle?: () => void,
    onOpenDetails: (item: Item) => void,
    onChangeCurrentMenuAction: (data: ApiTableContextMenuElem<Item> | null) => void,
};

function ContextMenuRows<Item extends ApiTableBaseItem>(props: Readonly<ContextMenuRowsProps<Item>>): React.ReactElement {
    const selectedText: string | null = useMemo(() => {
        const selection = window.getSelection();
        if (typeof selection !== 'undefined' && selection !== null && selection.toString() !== "") {
            return selection.toString();
        }
        return null;
    }, [])
    const [handleClick] = useHandler(async (menuComponentElem: ApiTableContextMenuElem<Item>) => {
        if (menuComponentElem.action === `details`) {
            props.onOpenDetails(props.item);
            return;
        }

        await menuComponentElem.action(props.item);

        if (props.onToggle !== undefined) {
            props.onToggle();
        }
    });
    return <>
        {selectedText !== null
            ? <>
                <DropdownItem
                    key="copy"
                    className="clickable-text p-0"
                    onClick={async () => {
                        l.info("Copying", selectedText);
                        await navigator.clipboard.writeText(selectedText);
                        l.info("Done copying");
                    }}
                >
                    <a
                        href="#copy"
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <FaIcon icon="copy" />
                        <span><Trans i18nKey="apiTable.contextMenu.copy.label" /></span>
                    </a>
                </DropdownItem>
                <DropdownItem divider />
            </>
            : undefined}
        {props.menuComponents.map(elem => {
            const trans = elem.actionNameTrans(props.item);
            return <DropdownItem
                key={elem.actionNameTrans(props.item)}
                className="clickable-text p-0"
            >
                <a
                    href="#action"
                    onClick={(ev) => {
                        ev.preventDefault();
                        if (elem.confirmDescriptionTrans !== undefined) {
                            props.onChangeCurrentMenuAction(elem);
                        } else {
                            handleClick(elem);
                        }
                    }}
                >
                    {renderContextMenuIcon(props.item, elem)}
                    <span><Trans i18nKey={trans} /></span>
                </a>
            </DropdownItem>;
        })}
    </>

    function renderContextMenuIcon(item: Item, elem: ApiTableContextMenuElem<Item>): React.ReactElement | undefined {
        if (elem.icon !== undefined) {
            const { fa, ni } = elem.icon(item);
            if (fa !== undefined) {
                return <FaIcon icon={fa} />
            }
            if (ni !== undefined) {
                return <Icon name={ni} />
            }
        }
        return undefined;
    }
}



type BulkConfirmationModalProps = {
    groupAction: ApiTableActionGroupElem | null,
    onClose: () => void,
    onRunAction: (groupAction: ApiTableActionGroupElem) => Promise<void>,
    onError: (error: string) => void;
};

function BulkConfirmationModal(props: Readonly<BulkConfirmationModalProps>): React.ReactElement {
    const prevGroupAction = useRef<ApiTableActionGroupElem | null>(null);

    const groupAction = useMemo(() => {
        if (props.groupAction !== null) {
            prevGroupAction.current = props.groupAction;
            return props.groupAction;
        }

        return prevGroupAction.current;
    }, [props.groupAction]);

    return <>
        {
            (groupAction === null) ? <></> : <ConfirmationModal
                isOpen={props.groupAction !== null}
                title={t("apiTable.bulkConfirmationModal.title")}
                description={t([groupAction.confirmDescriptionTrans, "apiTable.bulkConfirmationModal.description"])}
                primaryBtnText={t("apiTable.bulkConfirmationModal.confirm")}
                secondaryBtnText={t("apiTable.bulkConfirmationModal.cancel").toString()}
                primaryActionCallback={() => {
                    props.onClose();
                    props.onRunAction(groupAction).catch((e) => {
                        props.onError(e);
                    });
                }}
                secondaryActionCallback={() => {
                    props.onClose();
                }}
            />
        }
    </>;
}



type DetailsModalProps<Item extends ApiTableBaseItem, OrderedCol extends string> = {
    colMapper: ApiTableColMapper<Item, OrderedCol>,
    item: Item | null,
    onClose: () => void,
    detailsModalTitleTransKey?: string,
};



function DetailsModal<Item extends ApiTableBaseItem, OrderedCol extends string>(props: DetailsModalProps<Item, OrderedCol>): React.ReactElement {
    const { t } = useTranslation();

    const prevItem = useRef<Item | null>(null);

    const item = useMemo(() => {
        if (props.item !== null) {
            prevItem.current = props.item;
            return props.item;
        }

        return prevItem.current;
    }, [props.item]);

    return item === null
        ? <></>
        : <Modal
            isOpen={props.item !== null}
            toggle={props.onClose}
            fullscreen="md"
            size="xl"
        >
            <ModalHeader toggle={props.onClose}>
                {t(props.detailsModalTitleTransKey ?? "apiTable.detailsModal.title")}
            </ModalHeader>

            <ModalBody className="gy-3 py-3" style={{ display: "flex", flexWrap: "wrap" }}>
                {props.colMapper.map((colInfo) => {
                    const Comp = colInfo.render;

                    return <div key={colInfo.colNameTranslationKey} className="col-sm-12 col-lg-6">
                        <h6 className="overline-title">
                            {t(colInfo.colNameTranslationKey)}
                        </h6>
                        <Comp item={item} location="detailsModal" />
                    </div>;
                })}
            </ModalBody>
        </Modal>;
};