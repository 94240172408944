import React, { useState, useContext, useEffect, useRef, useMemo, FC } from "react";
import Head from "../../../layout/head/Head";
import ContentAlt from "../../../layout/content/ContentAlt";
import ChatBody from "./ChatBody";
import { ConfirmModal, Icon } from "../../../components/Component";
import { ChatAsideBody } from "./ChatAsideBody";
import { Trans } from "react-i18next";
import { GetChatsContext } from "../../../contexts/get-chats/GetChatsContext";
import { GetChatArchivesContext } from "../../../contexts/get-chat-archives/GetChatArchivesContext";
import NewChatModal from "./modals/NewChatModal";
import { UserContext } from "../../settings/UserContext";
import { ModalControls, UserData, UserStatus } from "../../../utils/GeneralTypes";
import { getModerators, isUserMod } from "../../../utils/Chat";
import RenameChatModal from "./modals/RenameChatModal";
import { useNavigate, useParams } from "react-router";
import P from "../../../utils/Path";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { SearchChatsResult } from "@trantor/vdesk-api-schemas/dist/chats/searchChats";
import { useDelayedLoading, useHandler } from "../../../utils/handler";
import { ChatsService } from "../../../services";
import { debounce } from "lodash";
import { ChatArchiveItem } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";
import { useCronState } from "../../../utils/CronJob";
import NewCallModal from "./modals/NewCallModal";
import { unwrap } from "../../../services/MainService";
import { createApiClient } from "../../../utils/createApiClient";
import CallsHistoryModal from "./modals/CallsHistoryModal";
import ActiveCallsModal from "./modals/ActiveCallsModal";
import CallsService from "../../../services/CallsService";
import { GetMyCallInvitesOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/getMyCallInvites";
import l from "../../../utils/Log";
import { unreachable } from "../../../utils/unreachable";
import { composeCallUrl, navigateToCall } from "../../../utils/Call";

export interface ChatProps {
    showArchive: boolean;
}

const Chat: FC<ChatProps> = ({
    showArchive
}) => {
    const navigate = useNavigate();
    const loggedInUser = useContext(UserContext).loggedInUser[0];
    const [mobileView, setMobileView] = useState(false);
    const {
        createChat,
        deleteChat,
        leaveChat,
        editChat,
        store: [chatsState/* , chatsReducer */],
    } = useContext(GetChatsContext);
    const {
        deleteChat: deleteArchivedChat,
        store: [chatArchivesState/* , chatArchivesReducer */],
    } = useContext(GetChatArchivesContext);
    // const setSelectedChatArchiveId = (chatId: string | null): void => {
    //     chatArchivesReducer({
    //         type: "SET_SELECTED_CHAT",
    //         chatId,
    //     });
    // };
    // const setSelectedChatId = (chatId: string | null): void => {
    //     chatsReducer({
    //         type: "SET_SELECTED_CHAT",
    //         chatId,
    //     });
    // };
    const handleShowArchive = () => {
        navigate(P.getPath(`archive`), {replace: true});
    };
    const handleHideArchive = () => {
        navigate(P.getPath(`chat`), {replace: true});
    };
    const [filterText, setFilterText] = useState<string>("");
    const callSearch = async (): Promise<SearchChatsResult[] | null> => {
        if (filterText.length === 0) return null;
        const results = await ChatsService.search({ searchText: filterText });
        return results.items;
    };
    const [searchResults, triggerSearch,, searchLoadingManually] = useCronState(500, [], callSearch);
    const searchLoading = useDelayedLoading(searchLoadingManually, 200, 600);
    const callSearchRef = useRef<() => void>();
    useEffect(() => {
        callSearchRef.current = triggerSearch
    }, [triggerSearch]);
    const callSearchWithDebounce = useMemo(() => {
        const cb = () => callSearchRef.current?.();
        return debounce(cb, 400);
    }, []);
    const onInputChange = (s: string) => {
        setFilterText(s);
    };
    useEffect(() => {
        callSearchWithDebounce();
    }, [callSearchWithDebounce, filterText]);

    useEffect(() => {
        const { chats, selectedChatId } = chatsState;

        if (selectedChatId === null || chats === null) {
            return;
        }
        if (chats.find((c) => c.id === selectedChatId) === undefined) {
            /**
             * User is no longer part of the chat room
             * (either kicked or the chat room was deleted)
             */
            handleChatQuit();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatsState]);

    // const favInputSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    //   setFavFilterText(e.target.value);
    // };

    // const onFilterClick = (prop) => {
    //   setFilterTab(prop);
    // };

    // CHAT ROUTING START ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
    const { chatId, chatArchiveId } = useParams();
    useEffect(() => {
        if (chatId !== undefined) {
            if (chatsState.chats === null) {
                // NON HA ANCORA CARICATO LA PRIMA INFORNATA DI CHATS
                return;
            }
            const found = chatsState.chats.find((c) => c.id === chatId);
            if (found !== undefined) {
                setOpenChat(found);
                // setSelectedChatId(found.id);
                if (window.innerWidth < 860) {
                    setMobileView(true);
                }
            } else {
                navigate(P.getPath(`chat`), { replace: true });
            }
        } else {
            setOpenChat(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId, chatsState]);
    useEffect(() => {
        if (chatArchiveId) {
            if (chatArchivesState.chats === null) {
                // NON HA ANCORA CARICATO LA PRIMA INFORNATA DI CHAT ARCHIVES
                return;
            }
            const found = chatArchivesState.chats.find((c) => c.id === chatArchiveId);
            if (found) {
                setOpenChatArchive(found)
                // setSelectedChatArchiveId(found.id);
                if (window.innerWidth < 860) {
                    setMobileView(true);
                }
            } else {
                navigate(P.getPath(`archive`), { replace: true });
            }
        } else {
            setOpenChatArchive(null);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatArchiveId, chatArchivesState]);
    // CHAT ROUTING END   ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
    
    const chatItemClick = (item: ChatItem) => {
        navigate(`${P.getPath(`chat`)}/${item.id}`);
    };
  
    const chatArchiveItemClick = (item: ChatArchiveItem) => {
        navigate(`${P.getPath(`archive`)}/${item.id}`);
    };

    const resultItemClick = (result: SearchChatsResult): void => {
        if (!result.isArchive && result.chatId !== null) {
            navigate(`${P.getPath(`chat`)}/${result.chatId}`);
        } else if (result.isArchive && result.chatId !== null) {
            navigate(`${P.getPath(`archive`)}/${result.chatId}`);
        } else {
            setConfirmNewChatFromSearchResult(result);
        }
    }
    const [newChatOpen, setNewChatOpen] = useState<boolean>(false);
    const handleNewChat = async (users: UserData[], label?: string): Promise<{ id: string }> => {
        const selectedParticipants = users.map((u) => ({
            userId: u.id,
            userDisplayName: u.displayName,
            moderator: label === undefined,
            lastReadMessage: null,
            lastReadMessageCTime: null,
            numberOfUnreadMessages: 0,
        }));

        if (label === undefined) {
            return await createChat({
                type: `p2p`,
                otherUserId: selectedParticipants.at(0)?.userId ?? unreachable(),
            });
        }

        return await createChat({
            type: `group`,
            name: label,
            participants: selectedParticipants,
        });
    };
    const handleNewChatFromNewChatModal = async (users: UserData[], label?: string): Promise<{id: string}> => {
        return await handleNewChat(users, label).catch((err)=>{
            if (chatsState.chats !== null && err === "CREATE-CHAT:FOUND-DUPLICATE") {
                if (label === undefined) {
                    const foundP2PChat = chatsState.chats.find(c => c.name === null && users.every(u=> c.participants.some(p => u.id === p.user.id)));
                    if (foundP2PChat !== undefined) {
                        return {id: foundP2PChat.id};
                    }
                }
            }
            throw err;
        }).then((r) => {
                console.log("ATTEMPTING NORMAL REDIRECT", r);
                navigate(`${P.getPath(`chat`)}/${r.id}`, {replace: true});
            return r;
        });
    }
    const handleNewChatFromSearchResult = async (result: SearchChatsResult | null): Promise<void> => {
        if (result !== null && result.chatId === null) {
            await handleNewChat([{
                id: result.userId,
                avatarBg:"black",
                checked:false,
                displayName:"",
                enabled: true,
                lastLogin: 0,
                role: "regular",
                status: UserStatus.Active,
                username: "",
            }]).catch((err)=>{
                if (chatsState.chats !== null && err === "CREATE-CHAT:FOUND-DUPLICATE") {
                    const foundP2PChat = chatsState.chats.find(c => c.name === null && c.participants.some(p => p.user.id === result.userId));
                    if (foundP2PChat !== undefined) {
                        return {id: foundP2PChat.id};
                    }
                }
                return {id : ""};
            }).then((r) => {
                navigate(`${P.getPath(`chat`)}/${r.id}`);
            });
            
        }
        setFilterText("");
    }
    const [newCallOpen, setNewCallOpen] = useState<boolean>(false);
    const [callHistoryOpen, setCallHistoryOpen] = useState<boolean>(false);
    const [activeCallsOpen, setActiveCallsOpen] = useState<boolean>(false);
    const [callDisplayId, setCallDisplayId] = useState<string | null>(null);
    const [handleNewCallFromNewCallModal/* , handleNewCallFromNewCallModalLoading */] = useHandler(async (participants: UserData[], openToGuests: boolean, now: boolean) => {
        const client = createApiClient({ tokenFromCookies: true });
        const { id } = loggedInUser;
        const resStartCall = await client.calls.startCall({
            openToGuests,
            now,
            participants: [{
                id,
                moderator: true
            }].concat(participants.map((p, i) => ({
                id: p.id,
                moderator: false,
            }))),
        })
        const res = unwrap(resStartCall);
        if (now) {
            navigateToCall(res.shortCallId);
        } else {
            setCallDisplayId(res.shortCallId);
        }
    });
    const [openChat, setOpenChat] = useState<ChatItem | null>(null);
    const [openChatArchive, setOpenChatArchive] = useState<ChatArchiveItem | null>(null);
    
    const [confirmLeaveChatModal, setConfirmLeaveChatModal] = useState<boolean>(false);
    const toggleConfirmLeaveChatModal = () => {
        setConfirmLeaveChatModal(!confirmLeaveChatModal);
    };
    const [confirmDeleteChatModal, setConfirmDeleteChatModal] = useState<boolean>(false);
    const toggleConfirmDeleteChatModal = () => {
        setConfirmDeleteChatModal(!confirmDeleteChatModal);
    };
    const [confirmDeleteArchivedChatModal, setConfirmDeleteArchivedChatModal] = useState<boolean>(false);
    const toggleConfirmDeleteArchivedChatModal = () => {
        setConfirmDeleteArchivedChatModal(!confirmDeleteArchivedChatModal);
    };
    const [confirmNewChatFromSearchResult, setConfirmNewChatFromSearchResult] = useState<SearchChatsResult | null>(null);
    const toggleConfirmNewChatFromSearchResult = () => {
        if (confirmNewChatFromSearchResult !== null) {
            setConfirmNewChatFromSearchResult(null);
        }
    };
    const [renameChatModal, setRenameChatModal] = useState<boolean>(false);
    const toggleRenameChatModal = () => {
        setRenameChatModal(!renameChatModal);
    };
    const handleRenameChat = async (name: string): Promise<void> => {
        await editChat({
            id: selectedChat?.id ?? unreachable(),
            name,
        });
    };

    const [selectedChat, setSelectedChat] = useState<ChatItem | null>(null);
    const [selectedArchivedChat, setSelectedArchivedChat] = useState<ChatArchiveItem | null>(null);

    const areYouMod = () => isUserMod(selectedChat, loggedInUser);
    const canYouLeave = () => {
        if (showArchive) {
            return false;
        }
        const moderators = getModerators(selectedChat).filter((id) => id !== loggedInUser.id);
        if (moderators.length > 0) {
            return true;
        }
        return false;
    };
    /*
    <li>
                                            {!showArchive && openChat?.name && (
                                                <DropdownItem
                                                    tag="a"
                                                    className={`dropdown-item ${
                                                        !areYouMod() ? "unclickable-text unselectable-text" : ""
                                                    }`}
                                                    href="#dropdown"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setRenameChatModal(true);
                                                    }}
                                                >
                                                    <FaIcon icon="keyboard" />
                                                    <span>{t("chat.body.rename")}</span>
                                                </DropdownItem>
                                            )}
                                        </li>
    */
    const getModalControls = (): ModalControls => {
        const modalControls: ModalControls = {};
        if (!showArchive) {
            if (selectedChat !== null && selectedChat.name === null) {
                modalControls.leave = undefined;
            } else if (canYouLeave()) {
                modalControls.leave = toggleConfirmLeaveChatModal;
            } else {
                modalControls.leave = null;
            }
            if (areYouMod()) {
                modalControls.delete = toggleConfirmDeleteChatModal;
            } else {
                modalControls.delete = null;
            }
            if (selectedChat !== null && selectedChat.name !== null) {
                if (areYouMod()) {
                    modalControls.rename = toggleRenameChatModal;
                } else {
                    modalControls.rename = null;
                }
            }
        }
        if (showArchive) {
            modalControls.delete = toggleConfirmDeleteArchivedChatModal;
        }
        /** 
        {
            leave: isChatArchiveOpen
                ? 
                : undefined,
            delete:
                areYouMod() || isChatArchiveOpen
                    ? 
                    : null,
        }
        */
        return modalControls;
    };

    const renderPlaceholderText = (key: "chat" | "chatArchive", count: number) => {
        const iconKey = key === "chat" ? "chat" : "archived";
        return (
            <div className="nk-chat-body">
                <div className="nk-chat-blank">
                    <div className="nk-chat-blank-icon">
                        <Icon name={iconKey} className="icon-circle icon-circle-xxl bg-white"></Icon>
                    </div>
                    <div className="nk-chat-blank-btn">
                        {/* <Link to={P.getPath("chat", true)}>
                            <Button
                                color="primary"
                                // disabled={mainTab === ChatTab.Contact}
                                onClick={() => {
                                    // setMainTab(ChatTab.Contact);
                                    setNewChatOpen(true);
                                }}
                            >
                                {t("chat.start")}
                            </Button>
                        </Link> */}
                        <p>
                            <Trans i18nKey={`chat.placeholder.${key}`} values={{count}} />
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    const handleChatQuit = () => {
        if (!showArchive) {
            // setSelectedChat(null);
            navigate(P.getPath(`chat`), {replace: true});
        } else {
            // setSelectedArchivedChat(null);
            navigate(P.getPath(`archive`), {replace: true});
        }
    };
    const renderChatBody = () => {
        if (
            (!showArchive && openChat !== null)
        ) {
            return (
                <ChatBody
                    key={openChat.id}
                    openChat={openChat}
                    onChatQuit={handleChatQuit}
                    mobileView={mobileView}
                    setMobileView={setMobileView}
                />
            );
        } else if (
            (showArchive && openChatArchive !== null)
        ) {
            return (
                <ChatBody
                    key={openChatArchive.id}
                    openChat={openChatArchive}
                    onChatQuit={handleChatQuit}
                    mobileView={mobileView}
                    setMobileView={setMobileView}
                />
            );
        } else if (!showArchive) {
            return renderPlaceholderText("chat", chatsState.chats?.length ?? 0);
        } else {
            return renderPlaceholderText("chatArchive", chatArchivesState.chats?.length ?? 0);
        }
    };
    const isLoading = useMemo(()=>{
        if (showArchive) {
            return chatsState.chats === null;
        } else {
            return chatArchivesState.chats === null;
        }
    }, [chatsState, chatArchivesState, showArchive])
    const isStillLoading = useDelayedLoading(isLoading, 0, 0);

    const [calls] = useCronState(1000, [], async () => {
        const res = await CallsService.getMyCallInvites({});
        const parsed = GetMyCallInvitesOutputSchema.safeParse(res);
        if (parsed.success) {
            return parsed.data.calls
        } else {
            l.error("DESERIALIZATION-ERROR");
        }
        return [];
    });
    return (
        <>
            <Head title="Chat"></Head>
            <ContentAlt>
                <div className="nk-chat">
                    {
                        isStillLoading ?
                        <>
                            {/* <FaIcon icon="spinner" size="6x" pulse/> */}
                        </>
                        :
                        <>
                            <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
                            {/* {`Open:${openChat?.name ?? openChat?.id}\nSelected:${selectedChat?.name ?? selectedChat?.id}`} */}
                            <div className="nk-chat-aside-head">
                                <div className="nk-chat-aside-user">
                                    {/* <UncontrolledDropdown>
                                        <UserAvatar image={User}></UserAvatar>
                                        <DropdownToggle tag="a" className="dropdown-toggle dropdown-indicator">
                                            <div className="title">{mainTab}</div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <ul className="link-list-opt no-bdr">
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#contact"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            setMainTab(mainTab === "Chats" ? "Contact" : "Chats");
                                                        }}
                                                    >
                                                        <span>{mainTab === "Chats" ? "Contact" : "Chats"}</span>
                                                    </DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#contact"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            setMainTab(
                                                                mainTab === "Chats"
                                                                    ? "Channel"
                                                                    : mainTab === "Channel"
                                                                    ? "Contact"
                                                                    : "Channel"
                                                            );
                                                        }}
                                                    >
                                                        <span>
                                                            {mainTab === "Chats"
                                                                ? "Channel"
                                                                : mainTab === "Channel"
                                                                ? "Contact"
                                                                : "Channel"}
                                                        </span>
                                                    </DropdownItem>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}
                                </div>
                                {/* <ul className="nk-chat-aside-tools g-2">
                                    <li>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                tag="a"
                                                className="btn btn-round btn-icon btn-light dropdown-toggle unselectable-text unclickable-text"
                                            >
                                                <Icon name="setting-alt-fill"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu end>
                                                <ul className="link-list-opt no-bdr">
                                                    <li>
                                                        <DropdownItem
                                                            tag="a"
                                                            className="unselectable-text unclickable-text"
                                                            href="#dropdown"
                                                            onClick={(ev) => {
                                                                ev.preventDefault();
                                                            }}
                                                        >
                                                            <span>{t("chat.settings")}</span>
                                                        </DropdownItem>
                                                    </li>
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    <li>
                                        <Button
                                            color="light"
                                            className="btn-round"
                                            // className="btn-round btn-icon"
                                            onClick={() => setNewChatOpen(true)}
                                        >
                                            <Icon name="plus-circle"></Icon>
                                            &nbsp;
                                            <Trans i18nKey="chat.start" />
                                        </Button>
                                    </li>
                                </ul> */}
                            </div>
                            <ChatAsideBody
                                onSearchInputChange={onInputChange}
                                searchInputValue={filterText}
                                searchResults={searchResults}
                                searchLoading={searchLoading}
                                resultItemClick={resultItemClick}

                                filteredChatList={chatsState.chats ?? []} // TODO: mettere loader
                                openChat={openChat}
                                selectedChat={selectedChat}
                                chatItemClick={chatItemClick}
                                chatItemMenuClick={setSelectedChat}
                                chatItemContextMenuClick={setSelectedChat}

                                showArchive={showArchive}

                                filteredArchiveChatList={chatArchivesState.chats ?? []} // TODO: mettere loader
                                openChatArchive={openChatArchive}
                                selectedChatArchive={selectedArchivedChat}
                                chatArchiveItemClick={chatArchiveItemClick}
                                chatArchiveItemMenuClick={setSelectedArchivedChat}
                                chatArchiveItemContextMenuClick={setSelectedArchivedChat}

                                onShowArchive={handleShowArchive}
                                onHideArchive={handleHideArchive}
                                modalControls={getModalControls()}
                                onAddNewChat={() => setNewChatOpen(true)}

                                calls={calls}
                                onAddNewCall={() => setNewCallOpen(true)}
                                onCallsHistoryModalOpen={() => setCallHistoryOpen(true)}
                                onActiveCallsModalOpen={() => setActiveCallsOpen(true)}
                            />
                            </div>
                            {renderChatBody()}
                        </>
                    }
                </div>
            </ContentAlt>
            <NewChatModal
                isOpen={newChatOpen}
                onToggle={() => setNewChatOpen(!newChatOpen)}
                onConfirm={handleNewChatFromNewChatModal}
            />
            <NewCallModal
                isOpen={newCallOpen}
                onToggle={() => setNewCallOpen(!newCallOpen)}
                onConfirm={handleNewCallFromNewCallModal}
            />
            <CallsHistoryModal
                isOpen={callHistoryOpen}
                onToggle={() => setCallHistoryOpen(!callHistoryOpen)}
            />
            <ActiveCallsModal
                isOpen={activeCallsOpen}
                onToggle={() => setActiveCallsOpen(!activeCallsOpen)}
            />
            <RenameChatModal
                isOpen={renameChatModal}
                onToggle={() => toggleRenameChatModal()}
                onConfirm={handleRenameChat}
                originalChat={selectedChat ?? null}
            />
            <ConfirmModal
                mode="chat-leave"
                isOpen={confirmLeaveChatModal}
                onToggle={toggleConfirmLeaveChatModal}
                onCallback={() => {
                    selectedChat && leaveChat(selectedChat.id);
                    selectedChat && callSearchWithDebounce();
                }}
            />
            <ConfirmModal
                mode="chat-delete"
                isOpen={confirmDeleteChatModal}
                onToggle={toggleConfirmDeleteChatModal}
                onCallback={() => {
                    selectedChat && deleteChat(selectedChat.id);
                    selectedChat && callSearchWithDebounce();
                }}
                />
            <ConfirmModal
                mode="archived-chat-delete"
                isOpen={confirmDeleteArchivedChatModal}
                onToggle={toggleConfirmDeleteArchivedChatModal}
                onCallback={() => {
                    selectedArchivedChat && deleteArchivedChat(selectedArchivedChat.id);
                    selectedArchivedChat && callSearchWithDebounce();
                }}
            />
            <ConfirmModal
                mode="call-display-url"
                isOpen={callDisplayId!==null}
                onToggle={() => setCallDisplayId(null)}
                onCallback={() => {
                    callDisplayId !== null && navigateToCall(callDisplayId);
                }}
                translationData={{
                    link: callDisplayId !== null ? composeCallUrl(callDisplayId) : ""
                }}
            />
            <ConfirmModal
                mode="new-chat-with-participant"
                isOpen={confirmNewChatFromSearchResult !== null}
                onToggle={toggleConfirmNewChatFromSearchResult}
                onCallback={() => handleNewChatFromSearchResult(confirmNewChatFromSearchResult)}
                translationData={{
                    who: confirmNewChatFromSearchResult?.name
                }}
            />
        </>
    );
};

export default Chat;
