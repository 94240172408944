import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LangDropdown, UserAvatar } from "../../../../components/Component";
import { getLanguageFlag } from "../../../../utils/Languages";

const LanguageHead: React.FC = () => {
    const [lFlag, setLFlag] = useState("");
    const {
        i18n: { language },
    } = useTranslation();

    useEffect(() => {
        setLFlag(getLanguageFlag({ language, square: true }));
    }, [language]);
    return (
        <UncontrolledDropdown className="language-dropdown">
            <DropdownToggle
                tag="a"
                href="#toggle"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle nk-quick-nav-icon"
            >
                <UserAvatar className="border border-light xs">
                    <img src={lFlag} alt={`Flag for language ${language}`} />
                </UserAvatar>
            </DropdownToggle>
            <LangDropdown className="dropdown-menu-s1" />
        </UncontrolledDropdown>
    );
};

export default LanguageHead;
