import { z } from "zod";
export const AuditLogBodySchema = z.union([
    z.object({
        kind: z.literal(`info:auth.login`),
        details: z.record(z.string(), z.never()),
    }),
    z.object({
        kind: z.literal(`info:auth.logout`),
        details: z.object({
            sessionDurationInMs: z.number().int().min(0),
        }),
    }),
    z.object({
        kind: z.literal(`info:auth.changePassword`),
        details: z.record(z.string(), z.never()),
    }),
    z.object({
        kind: z.literal(`warn:auth.login.failed`),
        details: z.object({
            username: z.string().min(1),
        }),
    }),
    z.object({
        kind: z.literal(`info:settings.changed`),
        details: z.record(z.string(), z.never()),
    }),
    z.object({
        kind: z.literal(`info:users.create`),
        details: z.object({
            user: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:users.edit`),
        details: z.object({
            user: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:users.delete`),
        details: z.object({
            user: z.object({
                id: z.string().uuid(),
                username: z.string().min(1),
                displayName: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:accessRules.create`),
        details: z.object({
            accessRule: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:accessRules.edit`),
        details: z.object({
            accessRule: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:accessRules.delete`),
        details: z.object({
            accessRule: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:chats.create`),
        details: z.object({
            chat: z.object({
                id: z.string().uuid(),
                name: z.string().min(1).nullable(),
                participants: z.object({
                    id: z.string().uuid(),
                    username: z.string().min(1),
                    displayName: z.string().min(1),
                }).array(),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:chats.edit`),
        details: z.object({
            chat: z.object({
                id: z.string().uuid(),
                name: z.string().min(1).nullable(),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:chats.delete`),
        details: z.object({
            chat: z.object({
                id: z.string().uuid(),
                name: z.string().min(1).nullable(),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:chats.leave`),
        details: z.object({
            chat: z.object({
                id: z.string().uuid(),
                name: z.string().min(1).nullable(),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:chats.expel`),
        details: z.object({
            chat: z.object({
                id: z.string().uuid(),
                name: z.string().min(1).nullable(),
            }),
            users: z.object({
                id: z.string().uuid(),
            }).array(),
        }),
    }),
    z.object({
        kind: z.literal(`info:calls.create`),
        details: z.object({
            call: z.object({
                id: z.string().uuid(),
                shortCallId: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:computers.create`),
        details: z.object({
            computer: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
                protocol: z.enum([`vdesk`]),
                hostname: z.string().min(1),
                port: z.number().int().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:computers.edit`),
        details: z.object({
            computer: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:computers.delete`),
        details: z.object({
            computer: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        }),
    }),
    z.object({
        kind: z.literal(`info:computers.connect`),
        details: z.object({
            computer: z.object({
                id: z.string().uuid(),
                name: z.string().min(1),
                protocol: z.enum([`vdesk`]),
                hostname: z.string().min(1),
                port: z.number().int().min(1),
            }),
        }),
    }),
]);
