import { Navigate, Route, Routes } from "react-router";
import PrivateRoute from "../../route/PrivateRoute";
import { SessionData, sessionDataFromJSON } from "../../utils/GeneralTypes";
import UserProfileActivityPage from "./UserProfileActivity";
import P from "../../utils/Path";
import { getCookie } from "typescript-cookie";
import { canAccess } from "../../utils/Utils";
import { Link } from "react-router-dom";
import { Icon, LinkItem } from "../../components/Component";
import { Trans } from "react-i18next";
import SettingsUsersPage from "./SettingsUsersPage";
import GroupsSettingsPage from "./GroupsSettingsPage";
import ComputersSettingsPage from "./ComputersSettingsPage";
import FaIcon from "../../components/icon/FaIcon";
import AccessRulesSettingsPage from "./AccessRulesSettingsPage";
import SettingsPage from "./SettingsPage";
import classNames from "classnames";
import StatisticsPage from "./StatisticsPage";
import { isDevelopment } from "../../utils/Log";
import SandBox from "../../utils/sandboxes/SandBox";
import LogsPage from "./LogsPage";
import StorageSettingsPage from "./StorageSettingsPage";
import ServersPage from "./ServersPage";
import LicensePage from "./LicensePage";

export interface SettingsSubsectionRoutesProps {
    sm: boolean;
    setSm: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SettingsSubsectionRoutes: React.FC<SettingsSubsectionRoutesProps> = ({ sm, setSm }) => (
    <Routes>
        <Route
            path={P.getPath("settingsActivity", true)}
            element={<UserProfileActivityPage setSm={setSm} sm={sm} />}
        ></Route>
        <Route
            path={P.getPath("settingsUsers", true)}
            element={<PrivateRoute element={SettingsUsersPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsGroups", true)}
            element={<PrivateRoute element={GroupsSettingsPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsComputers", true)}
            element={<PrivateRoute element={ComputersSettingsPage} restrictAccess={"admin"} setSm={setSm} sm={sm}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsAccessRules", true)}
            element={<PrivateRoute element={AccessRulesSettingsPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsLdap", true)}
            element={<PrivateRoute element={SettingsPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsStatistics", true)}
            element={<PrivateRoute element={StatisticsPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsLogs", true)}
            element={<PrivateRoute element={LogsPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsStorage", true)}
            element={<PrivateRoute element={StorageSettingsPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsServers", true)}
            element={<PrivateRoute element={ServersPage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        <Route
            path={P.getPath("settingsLicense", true)}
            element={<PrivateRoute element={LicensePage} restrictAccess={"admin"}></PrivateRoute>}
        ></Route>
        {/* <Route
      path={P.getPath("settingsNotification", true)}
      element={<UserProfileNotificationPage setSm={setSm} sm={sm} />}
    ></Route> */}
        {/* <Route path={P.getPath("settingsSetting", true)} element={<UserProfileSettingPage setSm={setSm} sm={sm} />}></Route> */}
        
        {isDevelopment()
        ? <Route
            path={`${P.getPath("settingsSandBox", false)}`}
            element={<PrivateRoute element={SandBox} restrictAccess={"admin"}></PrivateRoute>}
        />
        : undefined}
        <Route
            path={`*`}
            element={
                <Navigate
                    replace
                    to={P.getPath("settingsActivity")}
                    state={{ fromRoot: false, fromPrivateRoute: false }}
                />
            }
        />
        {/* <Route path="*" element={<RedirectAs404 />}></Route> */}
    </Routes>
);

const isSubsectionMenuLinkActive = (section: string): boolean => {
    const currentPath = window.location.pathname;
    const sectionPath = `${P.getPath("settingsRootPartial")}${P.getPath(section, false)}`;
    return currentPath === sectionPath;
}
interface SettingsSubsectionsMenuLinksProps {
    setSm: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SettingsSubsectionMenuLinks: React.FC<SettingsSubsectionsMenuLinksProps> = ({ setSm }) => {
    const cookie = getCookie("sessionData") + "";
    const sessionData: SessionData = sessionDataFromJSON(cookie ? JSON.parse(cookie) : {});
    return (
        <>
            <li>
                <Link
                    onClick={() => setSm(false)}
                    to={P.getPath("settingsActivity", false)}
                    className={classNames({
                        "active": isSubsectionMenuLinkActive("settingsActivity")
                    })}
                >
                    <Icon name="activity" />
                    <span>
                        <Trans i18nKey="settings.activity" />
                    </span>
                </Link>
            </li>
            {canAccess(sessionData.loggedUser, "admin")
            ? <>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsUsers", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsUsers")
                        })}
                    >
                        <FaIcon icon="users" />
                        <span>
                            <Trans i18nKey="settings.usersManagement" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsGroups", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsGroups")
                        })}
                    >
                        <FaIcon icon="table" />
                        <span>
                            <Trans i18nKey="settings.groupsManagement" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsComputers", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsComputers")
                        })}
                    >
                        <FaIcon icon="display" />
                        <span>
                            <Trans i18nKey="settings.computersManagement" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsAccessRules", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsAccessRules")
                        })}
                    >
                        <FaIcon icon="circle-nodes" />
                        <span>
                            <Trans i18nKey="settings.accessRulesManagement" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsLdap", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsLdap")
                        })}
                    >
                        <Icon name="folder"></Icon>
                        <span>
                            <Trans i18nKey="settings.ldapManagement" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsStatistics", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsStatistics")
                        })}
                    >
                        <Icon name="bar-chart-alt"></Icon>
                        <span>
                            <Trans i18nKey="settings.settingsStatistics" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsLogs", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsLogs")
                        })}
                    >
                        <Icon name="book-read"></Icon>
                        <span>
                            <Trans i18nKey="settings.settingsLogs" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsStorage", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsStorage")
                        })}
                    >
                        <Icon name="hard-drive"></Icon>
                        <span>
                            <Trans i18nKey="settings.settingsStorage" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsServers", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsServers")
                        })}
                    >
                        <Icon name="server"></Icon>
                        <span>
                            <Trans i18nKey="settings.settingsServers" />
                        </span>
                    </Link>
                </li>
                <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsLicense", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsLicense")
                        })}
                    >
                        <FaIcon icon="key" />
                        <span>
                            <Trans i18nKey="settings.settingsLicense" />
                        </span>
                    </Link>
                </li>
                {isDevelopment()
                ? <li>
                    <Link
                        onClick={() => setSm(false)}
                        to={P.getPath("settingsSandBox", false)}
                        className={classNames({
                            "active": isSubsectionMenuLinkActive("settingsSandBox")
                        })}
                    >
                        <FaIcon icon="bug" />
                        <span>
                            <Trans i18nKey="settings.settingsSandBox" />
                        </span>
                    </Link>
                </li> : undefined}
            </>
            :<></>}
            {/*>
      <Link to={"setting"} className={window.location.pathname === "/settings/setting" ? "active" : ""}>
        <li onClick={() => setSm(false)}
      <Icon name="lock-alt-fill"></Icon>
      <span>
      <Trans i18nKey="settings.security" />
      </span>
      </Link>
    </li> */}
        </>
    );
};
interface SettingsSubsectionDropdownListPartialProps {
    toggle: () => void;
}
export const SettingsSubsectionDropdownListPartial: React.FC<SettingsSubsectionDropdownListPartialProps> = ({
    toggle,
}) => {
    const sessionDataCookie = getCookie("sessionData") + "";
    const sessionData: SessionData = JSON.parse(sessionDataCookie);
    return (
        <>
            <LinkItem link={"/settings/activity"} icon="activity-alt" onClick={toggle}>
                <Trans i18nKey="settings.activity" shouldUnescape />
            </LinkItem>
            {!!canAccess(sessionData.loggedUser, "admin") && (
                <LinkItem link="/settings/users" faIcon="users" onClick={toggle}>
                    <Trans i18nKey="settings.usersManagement" />
                </LinkItem>
            )}
            {canAccess(sessionData.loggedUser, "admin") && (
                <>
                    <LinkItem link="/settings/groups" faIcon="table" onClick={toggle}>
                        <Trans i18nKey="settings.groupsManagement" />
                    </LinkItem>
                    <LinkItem link="/settings/computers" faIcon="display" onClick={toggle}>
                        <Trans i18nKey="settings.computersManagement" />
                    </LinkItem>
                    <LinkItem link="/settings/access-rules" faIcon="circle-nodes" onClick={toggle}>
                        <Trans i18nKey="settings.accessRulesManagement" />
                    </LinkItem>
                    <LinkItem link="/settings/ldap" icon="folder" onClick={toggle}>
                        <Trans i18nKey="settings.ldapManagement" />
                    </LinkItem>
                    <LinkItem link="/settings/statistics" icon="bar-chart-alt" onClick={toggle}>
                        <Trans i18nKey="settings.settingsStatistics" />
                    </LinkItem>
                    <LinkItem link="/settings/logs" icon="book-read" onClick={toggle}>
                        <Trans i18nKey="settings.settingsLogs" />
                    </LinkItem>
                    <LinkItem link="/settings/storage" icon="hard-drive" onClick={toggle}>
                        <Trans i18nKey="settings.settingsStorage" />
                    </LinkItem>
                    <LinkItem link="/settings/servers" icon="server" onClick={toggle}>
                        <Trans i18nKey="settings.settingsServers" />
                    </LinkItem>
                    <LinkItem link="/settings/license" faIcon="key" onClick={toggle}>
                        <Trans i18nKey="settings.settingsLicense" />
                    </LinkItem>
                    {isDevelopment()
                    ? <LinkItem link="/settings/sandbox" faIcon="bug" onClick={toggle}>
                        <Trans i18nKey="settings.settingsSandBox" />
                    </LinkItem>
                    : undefined
                    }
                </>
            )}
        </>
    );
};
