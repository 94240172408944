import { z } from "zod";
export const FsMoveDirParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    destDir: z.string().uuid(),
    overwriteDestination: z.boolean().optional().default(false),
    // TODO: at the moment, you cannot move a directory under a parent with different storage
    /* destination: z.union([
        z.object({
            directoryId: z.string().uuid(),
            storageId: z.null().optional().default(null),
        }),
        z.object({
            directoryId: z.null().optional().default(null),
            storageId: z.string().uuid(),
        }),
    ]), */
});
export const FsMoveDirOutputSchema = z.record(z.string(), z.never());
