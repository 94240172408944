import { z } from "zod";
import { EmptyOutputSchema } from "../common.js";
export const EditAccessRuleParamsSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).optional(),
    users: z.string().uuid().array().optional(),
    userGroups: z.string().uuid().array().optional(),
    computers: z.string().uuid().array().optional(),
    computerGroups: z.string().uuid().array().optional(),
});
export const EditAccessRuleOutputSchema = EmptyOutputSchema;
