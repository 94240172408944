import { z } from "zod";
import { UserRoleSchema } from "../common.js";
export const CreateUserParamsSchema = z.object({
    username: z.string().min(1),
    displayName: z.string().min(1),
    password: z.string().min(1),
    role: UserRoleSchema,
    enabled: z.boolean(),
    otp: z.boolean().optional().default(false),
    memberOf: z.string().uuid().array().optional().default([]),
    homeDir: z.string().uuid().nullable().optional(),
    storageQuotaInBytes: z.number().int().min(0).optional().default(0),
});
export const CreateUserOutputSchema = z.object({
    id: z.string().uuid(),
    homeDir: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        path: z.object({ id: z.string().uuid(), name: z.string().min(1) }).array(),
    }).nullable(),
});
