import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { EditGroupOutputSchema } from "@trantor/vdesk-api-schemas/dist/groups/editGroup.js";
import { GetGroupsOutputSchema } from "@trantor/vdesk-api-schemas/dist/groups/getGroups.js";
import { CreateGroupOutputSchema } from "@trantor/vdesk-api-schemas/dist/groups/createGroup.js";
import { DeleteGroupOutputSchema } from "@trantor/vdesk-api-schemas/dist/groups/deleteGroup.js";
export class GroupClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async create(params) {
        const res = await this.call("createGroup", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: CreateGroupOutputSchema.parse(res.body)
        };
    }
    async get(params) {
        const res = await this.call("getGroups", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetGroupsOutputSchema.parse(res.body)
        };
    }
    async edit(params) {
        const res = await this.call("editGroup", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: EditGroupOutputSchema.parse(res.body),
        };
    }
    async delete(params) {
        const res = await this.call("deleteGroup", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteGroupOutputSchema.parse(res.body),
        };
    }
}
