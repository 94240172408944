import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown, Modal } from "reactstrap";
import { Icon, ConfirmModal } from "../../../../components/Component";
import { LinkList } from "../../../../components/links/Links";
import P from "../../../../utils/Path";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, removeCookie } from "typescript-cookie";
import { findUpper } from "../../../../utils/Utils";
import { SessionData } from "../../../../utils/GeneralTypes";
import { SettingsSubsectionDropdownListPartial } from "../../../../pages/settings/SettingsSubsection";
import AuthService from "../../../../services/AuthService";
import l from "../../../../utils/Log";
import { Trans } from "react-i18next";


interface UserHeaderProps {
    isMobile?: boolean;
    sidebarToggle?: () => void;
}

const User: React.FC<UserHeaderProps> = ({ isMobile, sidebarToggle }) => {
    const [open, setOpen] = useState(false);
    const toggle = (overload?: boolean) => {
        if (overload !== undefined) {
            setOpen(overload);
        } else {
            setOpen(!open);
        }
    };
    const cookie = getCookie("sessionData");
    const sessionData: SessionData = cookie ? JSON.parse(cookie) : {};
    const navigate = useNavigate();

    // Logs out user
    const handleSignout = () => {
        AuthService.logout()
            .then(() => {
                toggle(false);
                navigate("/auth-login", { state: { fromSignout: true } });
            })
            .catch((e) => {
                l.error(`Unable to signout\n${JSON.stringify(e)}`);
                removeCookie("sessionData");
                navigate("/");
            });
    };
    const [confirmLogoutModal, setConfirmLogoutModal] = useState<boolean>(false);
    const toggleConfirmLogoutModal = () => {
        setConfirmLogoutModal(!confirmLogoutModal);
    };

    const renderBody = () => {
        return <>
            <div className="dropdown-inner user-card-wrap bg-lighter">
                <div className="user-card sm">
                    {/* <div className="user-avatar">
                        <span>{findUpper(sessionData.loggedUser.displayName)}</span>
                    </div> */}
                    <UserAvatar
                        theme={sessionData.loggedUser.avatarBg}
                        className="md"
                        text={findUpper(sessionData.loggedUser.displayName)}
                    ></UserAvatar>
                    <div className="user-info">
                        <span className="lead-text">{sessionData.loggedUser.displayName}</span>
                        <span className="sub-text">{sessionData.loggedUser.username}</span>
                    </div>
                    {/* <div className="user-action" onClick={() => setOpen(false)}>
                        <Link to={P.getPath("user-profile-setting", true)} className="btn btn-icon me-n2">
                            <Icon name="setting"></Icon>
                        </Link>
                    </div> */}
                </div>
            </div>
            <div className="dropdown-inner">
                <LinkList>
                    <SettingsSubsectionDropdownListPartial toggle={() => {
                        isMobile === true && sidebarToggle !== undefined && sidebarToggle();
                        setOpen(false);
                    }} />
                    {/* <LinkItem link={`/pages/faq`} onClick={() => toggle()} compact={true}>
                        <Trans i18nKey="userDropdown.faq" />
                    </LinkItem>
                    <LinkItem link={`/pages/terms-policy`} onClick={() => toggle()} compact={true}>
                        <Trans i18nKey="userDropdown.terms" />
                    </LinkItem> */}
                </LinkList>
            </div>
            <div className="dropdown-inner">
                <LinkList>
                    <a
                        href="#signout"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleConfirmLogoutModal();
                        }}
                    >
                        <Icon name="signout"></Icon>
                        <Trans i18nKey="userDropdown.quit" />
                    </a>
                </LinkList>
            </div>
        </>;
    }

    return (
        <>
            <Dropdown
                isOpen={open}
                className="user-dropdown"
                toggle={() => {
                    toggle();
                }}
            >
                <DropdownToggle
                    tag="a"
                    href="#toggle"
                    className="dropdown-toggle"
                    onClick={(ev) => {
                        ev.preventDefault();
                    }}
                >
                    <div className="user-toggle">
                        {/* <UserAvatar icon="user-alt" className="sm" /> */}
                        <UserAvatar
                            theme={sessionData.loggedUser.avatarBg}
                            className="sm"
                            // text={findUpper(sessionData.loggedUser.displayName)}
                            icon="user-alt"
                        ></UserAvatar>
                    </div>
                </DropdownToggle>
                {isMobile !== true ?
                    <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
                        {renderBody()}
                    </DropdownMenu>
                    :
                    <Modal
                        isOpen={open}
                        size="sm"
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        {renderBody()}
                    </Modal>
                }
            </Dropdown>
            <ConfirmModal
                mode="app-logout"
                isOpen={confirmLogoutModal}
                onToggle={toggleConfirmLogoutModal}
                onCallback={handleSignout}
            />
        </>
    );
};

export default User;
