import { z } from "zod";
import { EnumOpSchema, StringOpSchema } from "../filters.js";
export const ChatsConditionSchema = z.union([
    z.object({
        field: z.literal("id"),
        op: EnumOpSchema,
        value: z.string().uuid()
    }),
    z.object({
        field: z.literal("name"),
        op: StringOpSchema,
        value: z.string()
    }),
    z.object({
        field: z.literal("name"),
        op: z.literal("isNull"),
        value: z.boolean()
    }),
]);
export const ChatsFiltersSchema = z.array(ChatsConditionSchema.or(z.array(ChatsConditionSchema)));
export const ChatsOrderBySchema = z.object({
    field: z.enum(["name", "creationTime", "lastSentMessage"]),
    order: z.enum(["asc", "desc"])
});
export const GetChatsParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: ChatsOrderBySchema.optional().default({ field: "name", order: "asc" }),
    filters: ChatsFiltersSchema.optional().default([])
});
export const ChatItemParticipantSchema = z.object({
    user: z.object({
        id: z.string().uuid(),
        displayName: z.string().min(1),
        username: z.string().min(1),
    }),
    moderator: z.boolean(),
    lastReadMessage: z.string().uuid().nullable(),
    lastReadMessageCTime: z.number().int().nullable(),
    numberOfUnreadMessages: z.number().int().min(0),
});
export const ChatItemSchema = z.object({
    id: z.string().uuid(),
    name: z.string().min(1).nullable(),
    participants: ChatItemParticipantSchema.array(),
    creationTime: z.number().int(),
    lastSentMessageTime: z.number().int().nullable(),
});
export const GetChatsOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: ChatItemSchema.array(),
});
