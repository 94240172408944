import React from "react";
import { DropdownItem, DropdownMenu } from "reactstrap";
import classNames from "classnames";
import { getLanguageFlag } from "../../utils/Languages";
import { useTranslation } from "react-i18next";
import { Settings } from "luxon";
import { useLocation, useNavigate } from "react-router";
interface LangDropProps {
    className?: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
}
const LangDropdown: React.FC<LangDropProps> = ({ className, size }) => {
    const langDropClass = classNames({
        [`${className}`]: className,
        [`dropdown-menu-${size}`]: size,
    });
    const { i18n, t } = useTranslation();
    const supportedLanguages = Object.keys(i18n.services.resourceStore.data);
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <DropdownMenu end className={langDropClass}>
            <ul className="language-list">
                {supportedLanguages.map((l) => (
                    <li key={`lang-iterated-${l}`}>
                        <DropdownItem
                            tag="a"
                            href="#change-lang"
                            className="language-item"
                            onClick={(ev) => {
                                ev.preventDefault();
                                i18n.changeLanguage(l);
                                Settings.defaultLocale = l;
                                navigate(location.pathname, { replace: true, state: location.state });
                            }}
                        >
                            <img
                                src={getLanguageFlag({ language: l })}
                                alt={`Flag for language ${l}`}
                                className="language-flag"
                            />
                            <span className="language-name">{t(`lang.${l}`)}</span>
                        </DropdownItem>
                    </li>
                ))}
            </ul>
        </DropdownMenu>
    );
};

export default LangDropdown;
