import { useCallback, useMemo, useState } from "react";

import { Trans } from "react-i18next";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import l from "../../utils/Log";
import { formatDate } from "../../utils/Utils";
import CreateAccessRuleModal from "./modals/accessRules/CreateAccessRuleModal";
import { AccessRulesService } from "../../services";
import { ApiTable, ApiTableActionGroupElem, ApiTableApiService, ApiTableColMapper, ApiTableContextMenuElem, ApiTableSearchMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import EditAccessRuleModal from "./modals/accessRules/EditAccessRuleModal";

const AccessRulesSettingsPage = () => <AccessRulesTable />;

export default AccessRulesSettingsPage;

const getAccessRules = AccessRulesService.getAccessRules.bind(AccessRulesService);

type AccessRulesItem = ItemOfApi<typeof getAccessRules>;

type AccessRulesOrderedCol = OrderedColOfApi<typeof getAccessRules>;

type AccessRulesFilterCond = FilterCondOfApi<typeof getAccessRules>;

function AccessRulesTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<AccessRulesItem, AccessRulesOrderedCol> = useMemo(() => {
        return [
            {
                colNameTranslationKey: `accessRulesManagement.attributes.id`,
                hideInTable: true,
                render: ({ item }) => <span>{item.id}</span>,
            },
            {
                colNameTranslationKey: `accessRulesManagement.attributes.name`,
                canOrder: `name`,
                render: ({ item }) => <span className="tb-lead">{item.name}</span>,
            },
            {
                colNameTranslationKey: `accessRulesManagement.attributes.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `accessRulesManagement.attributes.users`,
                render: ({ item }) => {
                    if (item.users.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.users.map(i => <li key={`User name: ${i.displayName}`}>
                                "{i.displayName}"
                                &nbsp;
                                <span>{i.ldapInfo!== null ? `(${i.ldapInfo.ldapDn})` : <Trans i18nKey="accessRulesManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="accessRulesManagement.noUsers" /></span>
                }
            },
            {
                colNameTranslationKey: `accessRulesManagement.attributes.userGroups`,
                render: ({ item }) => {
                    if (item.userGroups.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.userGroups.map(i => <li key={`User group name: ${i.groupName}`}>
                                "{i.groupName}"
                                &nbsp;
                                <span>{i.ldapInfo!== null ? `(${i.ldapInfo.ldapDn})` : <Trans i18nKey="accessRulesManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="accessRulesManagement.noUserGroups" /></span>
                }
            },
            {
                colNameTranslationKey: `accessRulesManagement.attributes.computers`,
                render: ({ item }) => {
                    if (item.computers.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.computers.map(i => <li key={`Computer name: ${i.name}`}>
                                "{i.name}"
                                &nbsp;
                                <span>{i.ldapInfo!== null ? `(${i.ldapInfo.ldapDn})` : <Trans i18nKey="accessRulesManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="accessRulesManagement.noComputers" /></span>
                }
            },
            {
                colNameTranslationKey: `accessRulesManagement.attributes.computerGroups`,
                render: ({ item }) => {
                    if (item.computerGroups.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.computerGroups.map(i => <li key={`Computer group name: ${i.groupName}`}>
                                "{i.groupName}"
                                &nbsp;
                                <span>{i.ldapInfo!== null ? `(${i.ldapInfo.ldapDn})` : <Trans i18nKey="accessRulesManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="accessRulesManagement.noComputerGroups" /></span>
                }
            },
        ];
    }, []);

    const searchMapper: ApiTableSearchMapper<AccessRulesFilterCond> = useCallback((search, currentFilters) => {
        if (search.length === 0) {
            return currentFilters;
        }

        return [
            ...currentFilters,
            [
                {
                    field: `name`,
                    op: `inci`,
                    value: search,
                },
            ]
        ];
    }, []);

    const groupActions = useMemo<ApiTableActionGroupElem[]>(() => {
        return [
            {
                icon: { fa: `trash` },
                actionNameTrans: `bulk.delete`,
                confirmDescriptionTrans: `accessRulesManagement.bulkConfirmationModal.description`,
                action: async (ids) => {
                    for (const id of ids) {
                        try {
                            await AccessRulesService.deleteAccessRule({id});
                        }
                        catch (error) {
                            l.warn(`CANNOT BULK DELETE GROUPS:`, error);
                        }
                    }
                },
            },
        ];
    }, []);

    const service: ApiTableApiService<AccessRulesItem, AccessRulesOrderedCol, AccessRulesFilterCond> = {
        get: (params) => AccessRulesService.getAccessRules(params),
    };

    // const editComputerData = useRef<AccessRulesItem>();

    const contextMenuComponent = useMemo<ApiTableContextMenuElem<AccessRulesItem>[]>(() => {
        return [
            {
                actionNameTrans: () => `accessRulesManagement.contextualActions.edit`,
                icon: () => ({ ni: `edit` }),
                action: async (item) => {
                    setEditAccessRulesData(item);
                },
            },
            {
                actionNameTrans: () => `accessRulesManagement.contextualActions.delete`,
                icon: () => ({ fa: `trash` }),
                confirmDescriptionTrans: () => `accessRulesManagement.deleteModal.description`,
                action: async (item) => {
                    await AccessRulesService.deleteAccessRule({id: item.id});
                },
            },
        ];
    }, []);
    const [editAccessRulesData, setEditAccessRulesData] = useState<AccessRulesItem | null>(null)
    const [isNewAccessRuleModalOpen, setIsNewAccessRuleModalOpen] = useState(false);
    // const [isEditAccessRuleModalOpen, setIsEditAccessRuleModalOpen] = useState(false);

    return <Content>
        <BlockHead size="sm">
            <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                        <Trans i18nKey="accessRulesManagement.title" />
                    </BlockTitle>
                    <BlockDes className="text-soft">
                        <Trans i18nKey="accessRulesManagement.description" />
                    </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                    <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => {
                            setIsNewAccessRuleModalOpen(true);
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </BlockHeadContent>
            </BlockBetween>
        </BlockHead>

        <Block>
            <ApiTable<AccessRulesItem, AccessRulesOrderedCol, AccessRulesFilterCond, ComputersTableFiltersState>
                service={service}
                colMapper={colMapper}
                defaultOrderByField={`name`}
                searchMapper={searchMapper}
                cache={1}
                contextMenuComponents={contextMenuComponent}
                groupActions={groupActions}
            />
        </Block>

        <CreateAccessRuleModal
            isOpen={isNewAccessRuleModalOpen}
            onClose={() => {
                setIsNewAccessRuleModalOpen(false);
            }}
        />
        <EditAccessRuleModal
            isOpen={editAccessRulesData !== null}
            accessRules={editAccessRulesData}
            onClose={() => {
                setEditAccessRulesData(null);
            }}
        />
    </Content>
}

type ComputersTableFiltersState = {
    name: string,
};