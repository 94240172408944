import { BaseClient } from "./base.js";
import { GetNotificationsOutputSchema } from "@trantor/vdesk-api-schemas/dist/notifications/getNotifications.js";
import { parseError } from "../libs/errors.js";
export class NotificationClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async get(params) {
        const res = await this.call(`getNotifications`, params);
        if (res.status !== "OK") {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetNotificationsOutputSchema.parse(res.body),
        };
    }
    async markAsRead(params) {
        const res = await this.call(`markNotificationsAsRead`, params);
        if (res.status !== "OK") {
            return {
                error: parseError(res),
            };
        }
        return {
            data: undefined,
        };
    }
}
