export class FailedRequestError extends Error {
    requestId;
    status;
    errorCode;
    body;
    constructor({ requestId, status, errorCode, body }) {
        super("'ERROR' status received");
        this.requestId = requestId;
        this.status = status;
        this.errorCode = errorCode;
        this.body = body;
    }
}
export class PendingRequestError extends Error {
    requestId;
    status;
    jobId;
    constructor({ requestId, status, jobId }) {
        super("'PENDING' status received");
        this.requestId = requestId;
        this.status = status;
        this.jobId = jobId;
    }
}
export class UnknownError extends Error {
    requestId;
    status;
    body;
    constructor({ requestId, status, body }) {
        super("unknown error received");
        this.requestId = requestId;
        this.status = status;
        this.body = body;
    }
}
export function parseError(resp) {
    if (resp.status === "ERROR") {
        const { errorCode, ...body } = resp.body;
        return new FailedRequestError({
            requestId: resp.requestId,
            status: resp.status,
            errorCode,
            body,
        });
    }
    if (resp.status === "PENDING") {
        return new PendingRequestError({
            requestId: resp.requestId,
            status: resp.status,
            jobId: resp.body.jobId,
        });
    }
    return new UnknownError({
        requestId: resp.requestId,
        status: resp.status,
        body: resp.body,
    });
}
