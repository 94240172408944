import { Trans, useTranslation } from "react-i18next";
import { Row, Col, Form, Modal, ModalBody, Toast, ToastBody, ToastHeader } from "reactstrap";
import { UserRole } from "../../../utils/GeneralTypes";
import Icon from "../../../components/icon/Icon";
import { SubmitHandler, useForm } from "react-hook-form";
import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import { GetUsersContext } from "../../../contexts/get-users/GetUsersContext";
import l from "../../../utils/Log";
import { Button, RSelect } from "../../../components/Component";
import { UsersService } from "../../../services";

const quotaUnits = [
    {
        value: "b",
        label: "B",
        key: "b",
    },
    {
        value: "kb",
        label: "KiB",
        key: "kb",
    },
    {
        value: "mb",
        label: "MiB",
        key: "mb",
    },
    {
        value: "gb",
        label: "GiB",
        key: "gb",
    },
] as const;
type QuotaUnit = typeof quotaUnits[number]["value"];

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};
interface UserNewModalProps {
    isOpen: boolean;
    onToggle: () => void;
}
const UserNewModal: React.FC<UserNewModalProps> = ({ isOpen, onToggle }) => {
    const { t } = useTranslation();
    const createUser = UsersService.createUser.bind(UsersService);
    type CreateUserFormInputs = {
        mode: string;
        reValidateMode: string;
        displayName: string;
        username: string;
        role: UserRole;
        enabled: boolean;
        otp: boolean;
        storageQuota: number;
        quotaUnit: QuotaUnit;
        password: string;
    };

    // Create user form
    const createUserForm = useForm<CreateUserFormInputs>({
        defaultValues: {
            mode: "onSubmit",
            reValidateMode: "onChange",
            displayName: "",
            username: "",
            role: "regular",
            enabled: true,
            otp: false,
        },
        resetOptions: {
            keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });

    const [formData, setFormData] = useState<{
        displayName: string;
        username: string;
        role: UserRole;
        enabled: boolean;
        otp: boolean;
        storageQuota: number;
        quotaUnit: QuotaUnit;
        password: string;
    }>({
        displayName: "",
        username: "",
        role: "regular",
        enabled: true,
        otp: false,
        storageQuota: 0,
        quotaUnit: "gb",
        password: "",
    });
    // function to reset the form
    const resetForm = useCallback((): void => {
        setFormData({
            displayName: "",
            username: "",
            role: "regular",
            enabled: true,
            otp: false,
            quotaUnit: "gb",
            storageQuota: 0,
            password: "",
        });
        createUserForm.setValue("displayName", "");
        createUserForm.setValue("enabled", true);
        createUserForm.setValue("otp", false);
        createUserForm.setValue("password", "");
        createUserForm.setValue("role", "regular");
        createUserForm.setValue("username", "");
        createUserForm.setValue("quotaUnit", "gb");
        createUserForm.setValue("storageQuota", 0);
    }, [createUserForm]);

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen, resetForm]);

    // submit function to add a new item
    const onFormNewSubmit: SubmitHandler<CreateUserFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            const { id } = await createUser({
                username: submitData.username,
                password: submitData.password,
                displayName: submitData.displayName,
                role: submitData.role,
                enabled: submitData.enabled,
                otp: submitData.otp,
                storageQuotaInBytes: submitData.storageQuota,
            });

            l.info("user created", { id });
            onToggle();
        } catch (err) {
            l.error("failed to create user", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    onToggle();
                    resetForm();
                }}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setDisableModal(false);
                            onToggle();
                            resetForm();
                            resetForm();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="usersManagement.addUser" />
                        </h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={createUserForm.handleSubmit(onFormNewSubmit)}>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label className="form-label">
                                            <Trans i18nKey="usersManagement.username" />
                                        </label>
                                        <input
                                            disabled={disableModal}
                                            className="form-control"
                                            type="text"
                                            defaultValue={formData.username}
                                            placeholder={t("usersManagement.usernamePlaceholder").toString()}
                                            {...createUserForm.register("username", {
                                                required: "This field is required",
                                            })}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label className="form-label">
                                            <Trans i18nKey="usersManagement.displayName" />
                                        </label>
                                        <input
                                            disabled={disableModal}
                                            className="form-control"
                                            type="text"
                                            defaultValue={formData.displayName}
                                            placeholder={t("usersManagement.displayNamePlaceholder").toString()}
                                            {...createUserForm.register("displayName")}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label className="form-label">
                                            <Trans i18nKey="usersManagement.password" />
                                        </label>
                                        <input
                                            disabled={disableModal}
                                            className="form-control"
                                            type="password"
                                            defaultValue={formData.password}
                                            placeholder={t("usersManagement.passwordPlaceholder").toString()}
                                            {...createUserForm.register("password", {
                                                required: "This field is required",
                                            })}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group">
                                        <label className="form-label">
                                            <Trans i18nKey="usersManagement.storageQuotaInBytes" />
                                        </label>
                                        <div className="d-flex">
                                            <input
                                                disabled={disableModal}
                                                className="form-control"
                                                type="text"
                                                placeholder={t("usersManagement.storageQuotaInBytes").toString()}
                                                {...createUserForm.register("storageQuota", {valueAsNumber: true})}
                                            />
                                            {/* <RSelect
                                                isDisable={disableModal}
                                                options={quotaUnits}
                                                className="w-100px"
                                                placeholder="UNIT"
                                                // value={quotaUnit}
                                                // value={editUserForm.getValues("quotaUnit")}
                                                onChange={(e: { value: QuotaUnit }) => {
                                                    editUserForm.setValue("quotaUnit", e.value);
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-group">
                                        <label className="form-label">
                                            <Trans i18nKey="usersManagement.role" />
                                        </label>
                                        <div className="form-control-wrap">
                                            <RSelect
                                                isDisabled={disableModal}
                                                options={[
                                                    { value: "regular", label: t(`roleLabels.regular`) },
                                                    { value: "admin", label: t(`roleLabels.admin`) },
                                                ]}
                                                defaultValue={{
                                                    value: formData.role,
                                                    label: t(`roleLabels.${formData.role}`),
                                                }}
                                                onChange={(e: { value: UserRole }) => {
                                                    createUserForm.setValue("role", e.value, {
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                // {...register("role", { required: true })}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col size={6}>
                                    <div className="custom-control custom-control-sm custom-checkbox">
                                        <input
                                            disabled={disableModal}
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="enabledNew"
                                            {...createUserForm.register("enabled")}
                                        />
                                        <label className="custom-control-label" htmlFor="enabledNew">
                                            <Trans i18nKey="usersManagement.enabled" />
                                        </label>
                                    </div>
                                </Col>
                                <Col size={6}>
                                    <div className="custom-control custom-control-sm custom-checkbox">
                                        <input
                                            disabled={disableModal}
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="otpNew"
                                            {...createUserForm.register("otp")}
                                        />
                                        <label className="custom-control-label" htmlFor="otpNew">
                                            <Trans i18nKey="usersManagement.newUserModal.otpLabel" />
                                        </label>
                                    </div>
                                </Col>
                                <Row className="gy-3 py-3">
                                    <Col size={12}>
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button disabled={disableModal} color="primary" size="md" type="submit">
                                                    <Trans i18nKey="usersManagement.addUser" />
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        if (!disableModal) {
                                                            resetForm();
                                                            onToggle();
                                                        }
                                                    }}
                                                    className="link link-light"
                                                >
                                                    <Trans i18nKey="usersManagement.cancel" />
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UserNewModal;
