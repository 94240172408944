import { Trans, useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { Ban, PersonCheck } from "react-bootstrap-icons";
import React, { CSSProperties, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Icon,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { formatDate } from "../../../../utils/Utils";
import { UserContext } from "../../../settings/UserContext";
import FaIcon from "../../../../components/icon/FaIcon";
import { DateTime } from "luxon";
import { useCronState } from "../../../../utils/CronJob";

import { GetMyCallInvitesOutput, GetMyCallInvitesOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/getMyCallInvites";

import CallsService from "../../../../services/CallsService";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

interface ActiveCallsModalProps {
    isOpen: boolean;
    onToggle: () => void;
}
const ActiveCallsModal: React.FC<ActiveCallsModalProps> = ({ isOpen, onToggle }) => {
    const { t } = useTranslation();
    // TODO: Remove this API and use Calls Hystory
    // const { state: { users }, params, changeItemsPerPage } = useContext(GetUsersContext);
    const loggedUser = useContext(UserContext).loggedInUser[0];
    const [data, setData] = useState<GetMyCallInvitesOutput["calls"]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const debouncingTimeout = useRef<NodeJS.Timeout>();

    const [calls, triggerCalls,, manualLoading] = useCronState(1000, [], async () => {
        const res = await CallsService.getMyCallInvites({});
        const parsed = GetMyCallInvitesOutputSchema.safeParse(res);
        if (parsed.success) {
            return parsed.data.calls
        } else {
            setError("DESERIALIZATION-ERROR");
        }
        return [];
    });
    
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    const resetModal = () => {
        setSearchText("");
        setDisableModal(false);
        setError(null);
    };
    const handleToggle = () => {
        resetModal();
        onToggle();
    };
    const joinCall = (call: GetMyCallInvitesOutput["calls"][number]): void => {
        window.open(`${window.location.protocol}//${window.location.host}/call/${encodeURIComponent(call.shortCallId)}`, `_blank`)
    }

    const deriveConnectedChatName = useCallback((call: GetMyCallInvitesOutput["calls"][number]): string => {
        const {displayName: myName} = loggedUser;
        const hasLinkedChat = call.linkedChatRoom !== undefined;
        if (hasLinkedChat) {
            if (call.linkedChatRoom?.type === "group") {
                return call.linkedChatRoom.name;
            } else if (call.linkedChatRoom?.type === "p2p") {
                if (!myName.toLocaleLowerCase().localeCompare(call.startedBy.displayName.toLocaleLowerCase())) {
                    return call.linkedChatRoom.otherUser.displayName;
                } else {
                    return call.startedBy.displayName;
                }
            }
        }
        return "";
    }, [loggedUser]);

    const drawDismissed = (call: GetMyCallInvitesOutput["calls"][number]): React.ReactNode => {
        const {dismissed, joined, } = call;
        const mySize: string = "1rem";
        const Wrapper: React.FC<{children: ReactNode, trans: "missed"|"accepted"|"rejected"|"pending"|"participating"|"unknown"}> =({children, trans}) => {
            return <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", gap: ".5rem"}}>
                {children}
                <span>
                    <Trans i18nKey={"activeCalls.dismissedLabel." + trans} />
                </span>
            </div>
        }
        if (joined) {
            return <Wrapper trans="participating">
                <PersonCheck size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}} />
            </Wrapper>;
        } else {
            if (dismissed === false) {
                return <Wrapper trans="pending">
                    <Icon name="call-alt"></Icon>
                    {/* <TelephoneMinus size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/> */}
                </Wrapper>
            } else if (dismissed === "rejected") {
                return <Wrapper trans="rejected">
                    <Ban size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/>
                </Wrapper>
            } else if (dismissed === "accepted") {
                return <Wrapper trans="pending">
                    <Icon name="call-alt"></Icon>
                </Wrapper>
            }

            return <Wrapper trans="unknown">
                <Ban size={mySize} style={{minWidth:"1rem", maxWidth:"1rem !important"}}/>
            </Wrapper>
        }

        /**
         * se joined true è 'partecipating' se false e dismissed è false allora è 'pending' 
         * dismissed rejected è rejected
         * dismissed accepted vuol dire che poi sei uscito, scrivi 'pending'
         * pending che icona: phone con ondine
         * 
         * se started !== null allora puoi joinare, button enabled, altrimenti (===null)
         * join è enabled se startedBy sei te o se sei moderator per quella call, altrimenti disabled
         * 
         * come moderator mettere placeholder const e TODO
         * 
         * 
         * join label variabile: started===null deve essere START, sennò JOIN
         */
    }
    const areYouModInCall = (_call: GetMyCallInvitesOutput["calls"][number]): boolean => {
        return true;
    }
    const didYouStartCall = (call: GetMyCallInvitesOutput["calls"][number]): boolean => {
        return call.startedBy.username === loggedUser.username;
    }
    const isCallJoinable = (call: GetMyCallInvitesOutput["calls"][number]): boolean => {
        // if (call.joined) return false;
        return areYouModInCall(call) || didYouStartCall(call);
    }

    useEffect(() => {
        if (debouncingTimeout.current !== undefined) {
            clearTimeout(debouncingTimeout.current);
            debouncingTimeout.current = undefined;
        }
        debouncingTimeout.current = setTimeout(() => {
            setData(calls.filter((i) => i.shortCallId.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || deriveConnectedChatName(i).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        }, 500);
    }, [loggedUser.id, searchText, calls, triggerCalls, manualLoading, deriveConnectedChatName]);

    const renderContent = (): React.ReactNode => {
        return <Content className="compact">
            <Row>
                <Col md={12} className="pb-3">
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="activeCalls.search" />
                        </label>
                        <input
                            disabled={disableModal}
                            className="form-control"
                            type="text"
                            placeholder={t("activeCalls.searchPlaceholder").toString()}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
            <DataTable className="overflow-x-auto">
                <DataTableBody compact>
                    <DataTableHead>
                        {/* <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                    type="checkbox"
                                    disabled={disableModal}
                                    className="custom-control-input"
                                    onChange={(e) => onSelectAllChange(e)}
                                    checked={selectAllValue()}
                                    id="uid"
                                />
                                <label className="custom-control-label" htmlFor="uid"></label>
                            </div>
                        </DataTableRow> */}
                        <DataTableRow>
                            <Trans i18nKey="activeCalls.dismissed" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="activeCalls.startedAt" />
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">
                                <Trans i18nKey="activeCalls.shortCallName" />
                            </span>
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="activeCalls.connectedChat" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="activeCalls.startedBy" />
                        </DataTableRow>
                        <DataTableRow>
                            <Trans i18nKey="activeCalls.joinCall" />
                        </DataTableRow>
                        {/* <DataTableRow>
                                <span className="sub-text">
                                    <Trans i18nKey="activeCalls.username" />
                                </span>
                            </DataTableRow> */}
                    </DataTableHead>
                    {/*Head*/}
                    {data.map((call) => {
                        const startedAt = call.startedAt === null ? `` : DateTime.fromMillis(call.startedAt ?? NaN).isValid
                            ? formatDate(call.startedAt)
                            : t("activeCalls.invalidStartedAt");
                        return (
                            <DataTableItem key={call.shortCallId + call.callId}>
                                <DataTableRow>
                                    {drawDismissed(call)}
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{startedAt}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{call.shortCallId}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    {/* <div
                                        className={
                                            "user-card unselectable-text"
                                        }
                                        >
                                        <div className="user-info">
                                        <span className="tb-lead">{deriveNameFromCall(call)}</span>
                                        </div>
                                    </div> */}
                                    <span className="unselectable-text">{deriveConnectedChatName(call)}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span className="unselectable-text">{call.startedBy.displayName}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <Button
                                        onClick={() => {
                                            joinCall(call);
                                            setTimeout(() => {
                                                onToggle();
                                            }, 500);
                                        }}
                                        // disabled={call.dismissed !== false}
                                        disabled={!isCallJoinable(call)}
                                    >
                                        {call.startedAt !== null ? <Trans i18nKey="activeCalls.joinCallButton"/> : <Trans i18nKey="activeCalls.startCallButton" />}
                                    </Button>
                                </DataTableRow>
                                {/* <DataTableRow>
                                        <div
                                            className={
                                                "user-card unselectable-text" +
                                                (disableModal ? "" : " clickable-text")
                                            }
                                            onClick={(e) => onSelectChange(item)}
                                        >
                                            <div className="user-info">
                                                <span className="tb-lead">{item.username}</span>
                                            </div>
                                        </div>
                                    </DataTableRow> */}
                            </DataTableItem>
                        );
                    })}
                    {manualLoading && <FaIcon icon="spinner" spin />}
                </DataTableBody>
                {!manualLoading && data.length === 0 && (
                    <div className="card-inner">
                        <div className="text-center">
                            <span className="text-silent">
                                <Trans i18nKey="activeCalls.noDataFound" />
                            </span>
                        </div>
                    </div>
                )}
            </DataTable>
        </Content>
    }
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleToggle();
                }}
                className="modal-dialog-centered"
                scrollable={true}
                fullscreen="xl"
                size="xl"
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="activeCalls.title" />
                        </h5>

                        <div className="mt-4">
                            {renderContent()}
                            
                            {/* <Row className="gy-3 pt-2">
                                <Col size={12}>
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button
                                                color="primary"
                                                type="button"
                                                disabled={disableModal}
                                                onClick={() => {
                                                    // handleConfirm();
                                                }}
                                            >
                                                <Trans i18nKey="activeCalls.createChat" />
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (!disableModal) {
                                                        handleToggle();
                                                    }
                                                }}
                                                className="link link-light"
                                            >
                                                <Trans i18nKey="activeCalls.cancel" />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row> */}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ActiveCallsModal;
