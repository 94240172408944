import React, { useMemo, useCallback, useRef, useState, useEffect } from "react";
import { GetUsersContextProvider } from "../../contexts/get-users/GetUsersContext";
import { UsersService } from "../../services";
import { ApiTable, ApiTableActionGroupElem, ApiTableApiFilters, ApiTableApiService, ApiTableColMapper, ApiTableContextMenuElem, ApiTableFiltersComponentProps, ApiTableFiltersMapper, ApiTableSearchMapper, FilterCondOfApi, ItemOfApi, OrderedColOfApi } from "../../components/ApiTable";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Icon, UserAvatar } from "../../components/Component";
import { findUpper, formatDate, formatSize, formatSizes, themeFromUsername } from "../../utils/Utils";
import { Trans } from "react-i18next";
import l from "../../utils/Log";
import { Col } from "reactstrap";
import Content from "../../layout/content/Content";
import UserNewModal from "./modals/UserNewModal";
import classNames from "classnames";
import UserEditModal from "./modals/UserEditModal";
import { flushSync } from "react-dom";

const Component = () => {
    return <>
            <UsersTable />
            {/* <UserListCompact /> */}
        </>;
};

const SettingsUsersPage = () => (
    <GetUsersContextProvider>
        <Component />
    </GetUsersContextProvider>
);

export default SettingsUsersPage;


/**
 * USERS TABLE AREA BEGIN
 */


const getUsers = UsersService.getUsers.bind(UsersService);

type UsersItem = ItemOfApi<typeof getUsers>;

type UsersOrderedCol = OrderedColOfApi<typeof getUsers>;

type UsersFilterCond = FilterCondOfApi<typeof getUsers>;

function UsersTable(): React.ReactElement {
    const colMapper: ApiTableColMapper<UsersItem, UsersOrderedCol> = useMemo(() => {
        return [
            {
                colNameTranslationKey: `usersManagement.id`,
                hideInTable: true,
                render: ({ item }) => <span>{item.id}</span>,
            },
            {
                colNameTranslationKey: `usersManagement.displayName`,
                canOrder: `displayName`,
                render: ({ item, location }) => {
                    if (location === `table`) {
                        return <div
                            className="user-card"
                        >
                            <UserAvatar
                                theme={themeFromUsername(item.username)}
                                size="xs"
                                className="unselectable-text"
                                text={findUpper(item.displayName)}
                            ></UserAvatar>
                            <div className="user-info">
                                <span className="tb-lead">{item.displayName}</span>
                            </div>
                        </div>;
                    }

                    return <span className="tb-lead">{item.displayName}</span>;
                },
            },
            {
                colNameTranslationKey: `usersManagement.username`,
                canOrder: `username`,
                render: ({ item }) => <span className="tb-lead">{item.username}</span>,
            },
            {
                colNameTranslationKey: `usersManagement.role`,
                render: ({ item }) => <span><Trans i18nKey={`roleLabels.${item.role}`} /></span>,
            },
            {
                colNameTranslationKey: `usersManagement.lastLogin`,
                canOrder: `lastLogin`,
                render: ({ item }) => <span>{formatDate(item.lastLogin)}</span>,
            },
            {
                colNameTranslationKey: `usersManagement.storageQuotaInBytes`,
                hideInTable: true,
                render: ({ item }) => <span>{formatSizes(item.storageQuotaInBytes.used, item.storageQuotaInBytes.available)}</span>,
            },
            {
                colNameTranslationKey: `usersManagement.creationTime`,
                canOrder: `creationTime`,
                render: ({ item }) => <span>{formatDate(item.creationTime)}</span>,
            },
            {
                colNameTranslationKey: `usersManagement.enabledLabel`,
                render: ({ item }) => <ul className="list-status">
                                        <li>
                                            <Icon
                                                className={classNames({
                                                    "text-success": item.enabled,
                                                    "text-danger": !item.enabled,
                                                    "unselectable-text": true,
                                                })}
                                                name={`${item.enabled ? "check-circle" : "cross-circle"}`}
                                            ></Icon>{" "}
                                            <span>
                                                <Trans
                                                    i18nKey={`usersManagement.${item.enabled ? "enabled" : "disabled"
                                                        }`}
                                                />
                                            </span>
                                        </li>
                                    </ul>,
            },
            {
                colNameTranslationKey: `usersManagement.otpLabel`,
                render: ({ item }) => <ul className="list-status">
                                        <li>
                                            <Icon
                                                className={classNames({
                                                    "text-success": item.otp !== `disabled`,
                                                    "text-danger": item.otp === `disabled`,
                                                    "unselectable-text": true,
                                                })}
                                                name={`${item.otp !== `disabled` ? "check-circle" : "cross-circle"}`}
                                            ></Icon>{" "}
                                            <span>
                                                <Trans
                                                    i18nKey={`usersManagement.${item.otp !== `disabled` ? "otpEnabled" : "otpDisabled"
                                                        }`}
                                                />
                                            </span>
                                        </li>
                                    </ul>,
            },
            {
                colNameTranslationKey: `usersManagement.ldap`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.ldapInfo !== null) {
                        return <span>{item.ldapInfo.ldapDn}</span>
                    }
                    return <Trans i18nKey="usersManagement.noLdapGroup" />
                }
            },
            {
                colNameTranslationKey: `usersManagement.memberOf`,
                hideInTable: true,
                render: ({ item }) => {
                    if (item.memberOf.length > 0) {
                        // render groups and relative infos
                        return (<ul>
                            {item.memberOf.map(g => <li key={g.groupId}>
                                "{g.groupName}"
                                &nbsp;
                                <span>{g.ldapInfo!== null ? `(${g.ldapInfo.ldapDn})` : <Trans i18nKey="usersManagement.noLdapGroup" />}</span></li>)}
                        </ul>)
                    }
                    return <span><Trans i18nKey="usersManagement.noMemberOf" /></span>
                }
            },
        ];
    }, []);

    const searchMapper: ApiTableSearchMapper<UsersFilterCond> = useCallback((search, currentFilters) => {
        if (search.length === 0) {
            return currentFilters;
        }

        return [
            ...currentFilters,
            [
                {
                    field: `username`,
                    op: `inci`,
                    value: search,
                },
                {
                    field: `displayName`,
                    op: `inci`,
                    value: search,
                },
            ]
        ];
    }, []);

    const groupActions = useMemo<ApiTableActionGroupElem[]>(() => {
        return [
            {
                icon: { fa: `trash` },
                actionNameTrans: `usersManagement.groupActions.delete`,
                confirmDescriptionTrans: `usersManagement.groupActions.delete.confirm`,
                action: async (ids) => {
                    for (const id of ids) {
                        try {
                            await UsersService.deleteUser(id);
                        }
                        catch (error) {
                            l.warn(`CANNOT BULK DELETE USER:`, error);
                        }
                    }
                },
            },
        ];
    }, []);

    const service: ApiTableApiService<UsersItem, UsersOrderedCol, UsersFilterCond> = {
        get: (params) => UsersService.getUsers(params),
    };

    const filtersMapper: ApiTableFiltersMapper<UsersFilterCond, UsersTableFiltersState> = useCallback((state) => {
        const result: ApiTableApiFilters<UsersFilterCond> = [];

        if (state.username.length > 0) {
            result.push({
                field: `username`,
                op: `inci`,
                value: state.username,
            });
        }

        if (state.displayName.length > 0) {
            result.push({
                field: `displayName`,
                op: `inci`,
                value: state.displayName,
            });
        }

        return result;
    }, []);

    const [editUserData, setEditUserData] = useState<UsersItem | null>(null);

    const contextMenuComponent = useMemo<ApiTableContextMenuElem<UsersItem>[]>(() => {
        function deriveDisableEnable(e: boolean): string {
            return e ? `disable` :`enable`;
        }
        return [
            {
                actionNameTrans: () => `usersManagement.contextMenu.edit.actionName`,
                icon: () => ({ ni: `edit` }),
                action: async (item) => {
                    setEditUserData({...item});
                },
            },
            {
                actionNameTrans: () => `usersManagement.contextMenu.delete.actionName`,
                icon: () => ({ fa: `trash` }),
                confirmDescriptionTrans: () => `usersManagement.contextMenu.delete.confirm`,
                action: async (item) => {
                    await UsersService.deleteUser(item.id);
                },
            },
            {
                actionNameTrans: (item) => `usersManagement.contextMenu.${deriveDisableEnable(item.enabled)}.actionName`,
                icon: (item) => ({ fa: item.enabled ? `ban` : `circle-check` }),
                confirmDescriptionTrans: (item) => `usersManagement.contextMenu.${deriveDisableEnable(item.enabled)}.actionName`,
                action: async (item) => {
                    await UsersService.editUser({
                        id: item.id,
                        enabled: !item.enabled,
                    });
                },
            },
        ];
    }, []);

    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);

    return <Content>
        <BlockHead size="sm">
            <BlockBetween>
                <BlockHeadContent>
                    <BlockTitle tag="h3" page>
                        <Trans i18nKey="usersManagement.title" />
                    </BlockTitle>
                    <BlockDes className="text-soft">
                        <Trans i18nKey="usersManagement.description" />
                    </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                    <Button
                        color="primary"
                        className="btn-icon"
                        onClick={() => {
                            setIsNewUserModalOpen(true);
                        }}
                    >
                        <Icon name="plus"></Icon>
                    </Button>
                </BlockHeadContent>
            </BlockBetween>
        </BlockHead>

        <Block>
            <ApiTable<UsersItem, UsersOrderedCol, UsersFilterCond, UsersTableFiltersState>
                service={service}
                colMapper={colMapper}
                defaultOrderByField={`displayName`}
                searchMapper={searchMapper}
                cache={1}
                filters={{
                    component: UsersTableFilters,
                    mapper: filtersMapper,
                }}
                //cacheFirstAndLast={true}
                contextMenuComponents={contextMenuComponent}
                groupActions={groupActions}
                detailsModalTitleTransKey="usersManagement.detailsModal.title"
            />
        </Block>
        <UserNewModal
            isOpen={isNewUserModalOpen}
            onToggle={() => {
                setIsNewUserModalOpen(!isNewUserModalOpen);
            }}
        />
        <UserEditModal
            isOpen={editUserData !== null}
            data={editUserData}
            onToggle={() => {
                setEditUserData(null);
            }}
        />
    </Content>
}



type UsersTableFiltersState = {
    username: string,
    displayName: string,
};

function UsersTableFilters(props: ApiTableFiltersComponentProps<UsersTableFiltersState>): React.ReactElement {
    const onChangeFiltersRef = useRef(props.onChangeFilters);

    const [searchUsername, setSearchUsername] = useState(props.currentState?.username ?? ``);
    const [searchDisplayName, setSearchDisplayName] = useState(props.currentState?.displayName ?? ``);

    const state = useMemo<UsersTableFiltersState | null>(() => {
        if (searchUsername === `` && searchDisplayName === ``) {
            return null;
        }

        return {
            username: searchUsername,
            displayName: searchDisplayName,
        };
    }, [searchUsername, searchDisplayName]);

    useEffect(() => {
        onChangeFiltersRef.current(state);
    }, [state]);

    return <>
        <Col size={12}>
            <div className="form-group">
                <label className="overline-title overline-title-alt">
                    <Trans i18nKey="usersManagement.filters.username" />
                </label>
                <input
                    type="text"
                    value={searchUsername}
                    onChange={e => setSearchUsername(e.currentTarget.value)}
                    className="form-control"
                />
            </div>
        </Col>

        <Col size={12}>
            <div className="form-group">
                <label className="overline-title overline-title-alt">
                    <Trans i18nKey="usersManagement.filters.displayName" />
                </label>
                <input
                    type="text"
                    value={searchDisplayName}
                    onChange={e => setSearchDisplayName(e.currentTarget.value)}
                    className="form-control"
                />
            </div>
        </Col>
    </>;
}
