import { z } from "zod";
import { EnumOpSchema, NumberOpSchema, StringOpSchema } from "../filters.js";
export const ServersConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`name`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`online`),
        op: EnumOpSchema,
        value: z.boolean(),
    }),
    z.object({
        field: z.literal(`maintenance`),
        op: EnumOpSchema,
        value: z.boolean(),
    }),
    z.object({
        field: z.literal(`agentHostname`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`agentVersion`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`wireguardEndpoint`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`wireguardPubKey`),
        op: StringOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
]);
export const ServersFiltersSchema = z.array(ServersConditionSchema.or(z.array(ServersConditionSchema)));
export const ServersOrderBySchema = z.object({
    field: z.enum([`name`, `creationTime`, `online`, `maintenance`]),
    order: z.enum([`asc`, `desc`]),
});
export const GetServersParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: ServersOrderBySchema.optional().default({ field: `name`, order: `asc` }),
    filters: ServersFiltersSchema.optional().default([]),
});
export const ServerItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    name: z.string().min(1),
    online: z.boolean(),
    maintenance: z.boolean(),
    agentHostname: z.string().min(1).nullable(),
    agentVersion: z.string().min(1).nullable(),
    wireguardEndpoint: z.string().min(1).nullable(),
    wireguardPubKey: z.string().min(1).nullable(),
});
export const GetServersOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: ServerItemSchema.array(),
});
