import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { JoinDomainOutputSchema } from "@trantor/vdesk-api-schemas/dist/servers/joinDomain.js";
import { GetServersOutputSchema } from "@trantor/vdesk-api-schemas/dist/servers/getServers.js";
import { DeleteServerOutputSchema } from "@trantor/vdesk-api-schemas/dist/servers/deleteServer.js";
export class ServersClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async joinDomain(params) {
        const res = await this.call("joinDomain", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: JoinDomainOutputSchema.parse(res.body)
        };
    }
    async getServers(params) {
        const res = await this.call("getServers", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetServersOutputSchema.parse(res.body)
        };
    }
    async delete(params) {
        const res = await this.call("deleteServer", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteServerOutputSchema.parse(res.body)
        };
    }
}
