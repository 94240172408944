import { BaseClient } from "./base.js";
import { EditUserOutputSchema } from "@trantor/vdesk-api-schemas/dist/users/editUser.js";
import { GetUsersOutputSchema } from "@trantor/vdesk-api-schemas/dist/users/getUsers.js";
import { CreateUserOutputSchema } from "@trantor/vdesk-api-schemas/dist/users/createUser.js";
import { DeleteUserOutputSchema } from "@trantor/vdesk-api-schemas/dist/users/deleteUser.js";
import { parseError } from "../libs/errors.js";
export class UserClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async create(params) {
        const res = await this.call("createUser", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: CreateUserOutputSchema.parse(res.body)
        };
    }
    async get(params) {
        const res = await this.call("getUsers", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetUsersOutputSchema.parse(res.body)
        };
    }
    async edit(params) {
        const res = await this.call("editUser", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: EditUserOutputSchema.parse(res.body)
        };
    }
    async delete(params) {
        const res = await this.call("deleteUser", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteUserOutputSchema.parse(res.body)
        };
    }
}
