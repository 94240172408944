import { Trans } from "react-i18next";
// import Icon from "../icon/Icon";
import { CSSProperties } from "react";
import FaIcon from "../icon/FaIcon";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export type ChatSideBarParticipantsContextMenuAvailableFunctions = {
    [key in "delete" | "makeMod" | "unmakeMod"]?: (() => void) | null;
};
interface ChatSideBarParticipantsContextMenuProps {
    coords: { x: number; y: number };
    functions: ChatSideBarParticipantsContextMenuAvailableFunctions;
}
const ChatSideBarParticipantsContextMenu: React.FC<ChatSideBarParticipantsContextMenuProps> = ({
    coords,
    functions,
}) => {
    const style: CSSProperties = {
        position: "fixed",
        top: `${coords.y}px`,
        left: `${coords.x}px`,
        zIndex: 10000,
    };
    return (
        <UncontrolledDropdown isOpen={true}>
            <DropdownToggle
                style={style}
                tag="a"
                onClick={(e) => {
                    e.preventDefault();
                }}
            ></DropdownToggle>
            <DropdownMenu className="link-list-opt no-bdr">
                {(functions.delete === null || typeof functions.delete === "function") && (
                    <li>
                        <a
                            className={`dropdown-item ${!functions.delete ? "unclickable-text unselectable-text" : ""}`}
                            href="#delete"
                            onClick={(ev) => {
                                ev.preventDefault();
                                functions.delete && functions.delete();
                            }}
                        >
                            {/* <Icon name="trash"></Icon> */}
                            <FaIcon icon="user-minus" />
                            <Trans i18nKey="chat.sidebar.remove" />
                        </a>
                    </li>
                )}
                {(functions.makeMod === null || typeof functions.makeMod === "function") && (
                    <li>
                        <a
                            className={`dropdown-item ${
                                !functions.makeMod ? "unclickable-text unselectable-text" : ""
                            }`}
                            href="#makeMod"
                            onClick={(ev) => {
                                ev.preventDefault();
                                functions.makeMod && functions.makeMod();
                            }}
                        >
                            {/* <Icon name="trash"></Icon> */}
                            <FaIcon icon="user-shield" />
                            <Trans i18nKey="chat.sidebar.makeMod" />
                        </a>
                    </li>
                )}
                {(functions.unmakeMod === null || typeof functions.unmakeMod === "function") && (
                    <li>
                        <a
                            className={`dropdown-item ${
                                !functions.unmakeMod ? "unclickable-text unselectable-text" : ""
                            }`}
                            href="#unmakeMod"
                            onClick={(ev) => {
                                ev.preventDefault();
                                functions.unmakeMod && functions.unmakeMod();
                            }}
                        >
                            {/* <Icon name="trash"></Icon> */}
                            <FaIcon icon="user" />
                            <Trans i18nKey="chat.sidebar.unmakeMod" />
                        </a>
                    </li>
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ChatSideBarParticipantsContextMenu;
