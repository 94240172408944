import { z } from "zod";
export const GetMyCallInvitesParamsSchema = z.record(z.string(), z.never());
export const GetMyCallInvitesOutputSchema = z.object({
    calls: z.object({
        callId: z.string().uuid(),
        shortCallId: z.string().min(1),
        startedBy: z.object({
            id: z.string().uuid(),
            username: z.string().min(1),
            displayName: z.string().min(1),
        }),
        moderator: z.boolean(),
        dismissed: z.union([z.literal(false), z.literal(`accepted`), z.literal(`rejected`)]),
        joined: z.boolean(),
        createdAt: z.number().int(),
        startedAt: z.number().int().nullable(),
        linkedChatRoom: z.union([
            z.object({
                type: z.literal(`p2p`),
                id: z.string().uuid(),
                name: z.null(),
                otherUser: z.object({
                    id: z.string().uuid(),
                    username: z.string().min(1),
                    displayName: z.string().min(1),
                }),
            }),
            z.object({
                type: z.literal(`group`),
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        ]).nullable(),
    }).array(),
});
