import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { StartCallOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/startCall.js";
import { GetMyCallInvitesOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/getMyCallInvites.js";
import { DismissCallInviteOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/dismissCallInvite.js";
import { CallInviteUsersOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/callInviteUsers.js";
import { CallLiveActionOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/callLiveAction.js";
import { CallKeepaliveOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/callKeepalive.js";
import { CallGenModeratorCodeOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/callGenModeratorCode.js";
import { JoinCallOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/joinCall.js";
import { GetCallHistoryOutputSchema } from "@trantor/vdesk-api-schemas/dist/calls/getCallHistory.js";
export class CallsClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async startCall(params) {
        const res = await this.call(`startCall`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: StartCallOutputSchema.parse(res.body),
        };
    }
    async getMyCallInvites(params) {
        const res = await this.call(`getMyCallInvites`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetMyCallInvitesOutputSchema.parse(res.body),
        };
    }
    async dismissCallInvite(params) {
        const res = await this.call(`dismissCallInvite`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: DismissCallInviteOutputSchema.parse(res.body),
        };
    }
    async callInviteUsers(params) {
        const res = await this.call(`callInviteUsers`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: CallInviteUsersOutputSchema.parse(res.body),
        };
    }
    async callLiveAction(params) {
        const res = await this.call(`callLiveAction`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: CallLiveActionOutputSchema.parse(res.body),
        };
    }
    async callKeepalive(params) {
        const res = await this.call(`callKeepalive`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: CallKeepaliveOutputSchema.parse(res.body),
        };
    }
    async callGenModeratorCode(params) {
        const res = await this.call(`callGenModeratorCode`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: CallGenModeratorCodeOutputSchema.parse(res.body),
        };
    }
    async joinCall(params) {
        const res = await this.call(`joinCall`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: JoinCallOutputSchema.parse(res.body),
        };
    }
    async getCallHistory(params) {
        const res = await this.call(`getCallHistory`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetCallHistoryOutputSchema.parse(res.body),
        };
    }
}
