import { z } from "zod";
export const LoginParamsSchema = z.object({
    username: z.string().min(1),
    password: z.string().min(1),
    otp: z.string().min(1).optional().transform(e => e ?? null)
});
export const LoginOutputSchema = z.object({
    token: z.string().uuid(),
    lastLogin: z.number().int().nullable(),
    lastFailedLogins: z.number().int().min(0),
    displayName: z.string().min(1),
    username: z.string().min(1),
    userId: z.string().uuid(),
    role: z.enum([`admin`, `regular`]),
});
