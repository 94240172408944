import MainService from "./MainService";
import { GetLogsParams, GetLogsOutput } from "@trantor/vdesk-api-schemas/dist/getLogs";

class LS {
    private client = MainService.client.logs;

    async getLogs(params: GetLogsParams): Promise<GetLogsOutput> {
        return MainService.call(() => this.client.getLogs(params));
    }
}
const LogsService = new LS();
export default LogsService;
