import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import useSWR from "swr";
import { ChatsService } from "../../services";
import { GetChatsContextType } from "./GetChatsContextTypes";
import { UserData } from "../../utils/GeneralTypes";
import chatsReducer, { INITIAL_STATE } from "./reducer";
import { UserContext } from "../../pages/settings/UserContext";
import { CreateChatParams, EditChatOutput, EditChatParams } from "@trantor/vdesk-api-client/dist/clients/chat";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { useNavigate, useParams } from "react-router";
import P from "../../utils/Path";

export const GetChatsContext = createContext({} as GetChatsContextType);

const UPDATE_INTERVAL_MILLIS = 5000;

interface GetChatsContextProviderProps {
    children?: React.ReactNode;
}

const chatsFetcher = async (loggedInUser: UserData): Promise<ChatItem[]> => {
    const chats = await ChatsService.getChats({
        orderBy: { field: `lastSentMessage`, order: `desc` },
        page: 1,
        itemsPerPage: 500,
    });

    return chats.items;
};

export const GetChatsContextProvider: React.FC<GetChatsContextProviderProps> = (props) => {
    const navigate = useNavigate();
    const { chatId } = useParams();

    const [state, reducer] = useReducer(chatsReducer, INITIAL_STATE);

    const loggedInUser = useContext(UserContext).loggedInUser[0];

    const { data: chats, mutate } = useSWR("getChats", () => chatsFetcher(loggedInUser), {
        refreshInterval: UPDATE_INTERVAL_MILLIS,
    });

    const refetchChats = useCallback(mutate, [mutate]);

    useEffect(() => {
        if (chats !== undefined) {
            reducer({
                type: "UPDATE_CHATS",
                chats,
            });
        }
    }, [chats]);

    async function createChat(data: CreateChatParams): Promise<{ id: string }> {
        return await ChatsService.createChat(data).then(async (response) => {
            await refetchChats();
            reducer({ type: "SET_SELECTED_CHAT", chatId: response.id });
            return response;
        });
    }

    async function deleteChat(id: string): Promise<void> {
        await ChatsService.deleteChat({ id }).then(() => {
            if (chatId !== undefined && chatId === id) {
                navigate(P.getPath(`chat`), {replace: true});
            }
        });
    }

    async function leaveChat(id: string): Promise<void> {
        await ChatsService.leaveChat({ id }).then(() => {
            if (chatId !== undefined && chatId === id) {
                navigate(P.getPath(`chat`), {replace: true});
            }
        });
    }

    function editChat(newChat: EditChatParams): Promise<EditChatOutput> {
        return ChatsService.editChat({ ...newChat });
    }

    return (
        <GetChatsContext.Provider
            value={{
                store: [state, reducer],
                createChat,
                deleteChat,
                leaveChat,
                editChat,
            }}
        >
            {props.children}
        </GetChatsContext.Provider>
    );
};
