import { useTranslation } from "react-i18next";
import { ModalControls } from "../../utils/GeneralTypes";
import Icon from "../icon/Icon";
import { CSSProperties } from "react";
import FaIcon from "../icon/FaIcon";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

// type AvailableFunctions = {
//     [key in "edit" | "delete"]: (id: string) => void;
// };
interface ChatItemContextMenuProps {
    coords: { x: number; y: number };
    modalControls: ModalControls;
    isArchived: boolean;
}
const ChatItemContextMenu: React.FC<ChatItemContextMenuProps> = ({ coords, modalControls, isArchived }) => {
    const { t } = useTranslation();
    const style: CSSProperties = {
        position: "fixed",
        top: `${coords.y}px`,
        left: `${coords.x}px`,
        zIndex: 10000,
    };
    return (
        <UncontrolledDropdown isOpen={true}>
            <DropdownToggle
                style={style}
                tag="a"
                onClick={(e) => {
                    e.preventDefault();
                }}
            ></DropdownToggle>
            <DropdownMenu>
                {(modalControls.rename === null || typeof modalControls.rename === "function") && (
                    <li>
                        <DropdownItem
                            tag="a"
                            className={`dropdown-item ${
                                !modalControls.rename ? "unclickable-text unselectable-text" : ""
                            }`}
                            href="#dropdown"
                            onClick={(ev) => {
                                ev.preventDefault();
                                modalControls.rename && modalControls.rename();
                            }}
                        >
                            <FaIcon icon="keyboard" />
                            <span>{t("chat.body.rename")}</span>
                        </DropdownItem>
                    </li>
                )}
                {(modalControls.delete === null || typeof modalControls.delete === "function") && (
                    <li>
                        <DropdownItem
                            tag="a"
                            className={`dropdown-item ${
                                !modalControls.delete ? "unclickable-text unselectable-text" : ""
                            }`}
                            href="#dropdown"
                            onClick={(ev) => {
                                ev.preventDefault();
                                modalControls.delete && modalControls.delete();
                            }}
                        >
                            {isArchived ? <Icon name="trash"></Icon> : <FaIcon icon="box-archive" />}
                            {isArchived ? <span>{t("chat.body.remove")}</span> : <span>{t("chat.body.archive")}</span>}
                        </DropdownItem>
                    </li>
                )}
                {(modalControls.leave === null || typeof modalControls.leave === "function") && (
                    <li>
                        <DropdownItem
                            tag="a"
                            className={`dropdown-item ${
                                !modalControls.leave ? "unclickable-text unselectable-text" : ""
                            }`}
                            href="#dropdown"
                            onClick={(ev) => {
                                ev.preventDefault();
                                modalControls.leave && modalControls.leave();
                            }}
                        >
                            <Icon name="signout"></Icon>
                            <span>{t("chat.body.leave")}</span>
                        </DropdownItem>
                    </li>
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ChatItemContextMenu;
