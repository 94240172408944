import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { Button, Icon } from "../../../components/Component";
import { Input } from "reactstrap";
import { CallListItem, ChatListItem } from "./ChatPartials";
import { Trans, useTranslation } from "react-i18next";
import { ModalControls } from "../../../utils/GeneralTypes";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { SearchChatsResult } from "@trantor/vdesk-api-schemas/dist/chats/searchChats";
import FaIcon from "../../../components/icon/FaIcon";
import { formatDate } from "../../../utils/Utils";
import classNames from "classnames";
import { ChatArchiveItem } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";
import { GetMyCallInvitesOutput } from "@trantor/vdesk-api-schemas/dist/calls/getMyCallInvites";
import { navigateToCall } from "../../../utils/Call";

interface ChatAsideBodyProps {
    onSearchInputChange: (e: string) => void;
    searchInputValue: string;
    searchResults: SearchChatsResult[] | null;
    searchLoading: boolean;
    resultItemClick: (result: SearchChatsResult) => void;

    filteredChatList: Array<ChatItem>;
    openChat: ChatItem | null;
    selectedChat: ChatItem | null;
    chatItemClick: (item: ChatItem) => void;
    chatItemMenuClick: (item: ChatItem) => void;
    chatItemContextMenuClick: (item: ChatItem) => void;
    
    filteredArchiveChatList: Array<ChatArchiveItem>;
    openChatArchive: ChatArchiveItem | null;
    selectedChatArchive: ChatArchiveItem | null;
    chatArchiveItemClick: (item: ChatArchiveItem) => void;
    chatArchiveItemMenuClick: (item: ChatArchiveItem) => void;
    chatArchiveItemContextMenuClick: (item: ChatArchiveItem) => void;

    showArchive: boolean;
    onShowArchive: () => void;
    onHideArchive: () => void;
    modalControls: ModalControls;
    onAddNewChat: () => void;

    calls: GetMyCallInvitesOutput["calls"];
    onAddNewCall: () => void;
    onCallsHistoryModalOpen: () => void;
    onActiveCallsModalOpen: () => void;
}
export const ChatAsideBody: React.FC<ChatAsideBodyProps> = ({
    onSearchInputChange,
    searchInputValue,
    searchResults,
    searchLoading,
    resultItemClick,
    openChat,
    openChatArchive,
    selectedChat,
    selectedChatArchive,
    chatItemClick,
    chatArchiveItemClick,
    chatItemMenuClick,
    chatArchiveItemMenuClick,
    chatItemContextMenuClick,
    chatArchiveItemContextMenuClick,
    filteredChatList,
    filteredArchiveChatList,
    showArchive,
    onShowArchive,
    onHideArchive,
    modalControls,
    onAddNewChat,
    calls: incomingCalls,
    onAddNewCall,
    onCallsHistoryModalOpen,
    onActiveCallsModalOpen
}) => {
    const { t } = useTranslation();
    const [ calls, setCalls ] = useState<GetMyCallInvitesOutput["calls"]>([]);
    useEffect(() => {
        //setCalls(incomingCalls.filter(i => i.dismissed !== false && i.dismissed !== "accepted"));
        setCalls(incomingCalls.filter(e => e.dismissed !== `rejected` && e.startedAt !== null));
    }, [incomingCalls])
    // const defaultChat = filteredChatList.filter((item) => item.group !== true);
    const renderChatList = () => {
        const listIsEmpty = !showArchive ? !filteredChatList.length : !filteredArchiveChatList.length;
        if (listIsEmpty) {
            if (!showArchive) {
                return (
                    <>
                        <ul className="chat-list">
                            <li className="chat-item">
                                <a
                                    className={"chat-link"}
                                    href="#edit"
                                    color="transparent"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        onAddNewChat();
                                    }}
                                >
                                    <div className="user-avatar chat-media">
                                        <Icon name="plus" className="icon-circle bg-light"></Icon>
                                    </div>
                                    <div className="chat-info">
                                        <Trans className="sub-text" i18nKey="chat.start" />
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <p className="empty-message">
                            <Trans i18nKey="chat.aside.empty" />
                        </p>
                    </>
                );
            } else {
                return (
                    <p className="empty-message">
                        <Trans i18nKey="chat.aside.emptyArchive" />
                    </p>
                );
            }
        } else {
            return (
                <ul className="chat-list">
                    {!showArchive && (
                        <>
                            <li className="chat-item">
                                <a
                                    className={"chat-link"}
                                    href="#edit"
                                    color="transparent"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        onAddNewChat();
                                    }}
                                >
                                    <div className="user-avatar chat-media">
                                        <Icon name="plus" className="icon-circle bg-light"></Icon>
                                    </div>
                                    <div className="chat-info">
                                        <Trans className="sub-text" i18nKey="chat.start" />
                                    </div>
                                </a>
                            </li>
                            {filteredChatList.map((item, idx) => (
                                <ChatListItem
                                    key={item.id}
                                    item={item}
                                    openItem={openChat}
                                    selectedItem={selectedChat}
                                    chatItemClick={() => chatItemClick(item)}
                                    chatItemMenuClick={() => chatItemMenuClick(item)}
                                    chatItemContextMenuClick={() => chatItemContextMenuClick(item)}
                                    modalControls={modalControls}
                                />
                            ))}
                        </>
                    )}
                    {showArchive &&
                        filteredArchiveChatList.map((item, idx) => (
                            <ChatListItem
                                key={item.id}
                                item={item}
                                isArchived
                                openItem={openChatArchive}
                                selectedItem={selectedChatArchive}
                                chatItemClick={() => chatArchiveItemClick(item)}
                                chatItemMenuClick={() => chatArchiveItemMenuClick(item)}
                                chatItemContextMenuClick={() => chatArchiveItemContextMenuClick(item)}
                                modalControls={modalControls}
                            />
                        ))}
                </ul>
            );
        }
    };
    const renderCallList = () => {
        if (calls && calls.length === 0) {
            return <>
                <ul className="chat-list">
                    <li className="chat-item ">
                        <a
                            className={"chat-link"}
                            href="#new-call"
                            color="transparent"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onAddNewCall();
                            }}
                        >
                            <div className="user-avatar chat-media">
                                <Icon name="call" className="icon-circle bg-light"></Icon>
                            </div>
                            <div className="chat-info">
                                <Trans className="sub-text" i18nKey="call.start" />
                            </div>
                        </a>
                    </li>
                </ul>
                <p className="empty-message">
                    <Trans i18nKey="callListItem.empty" />
                </p>
            </>
        } else {
            return (
                <ul className="chat-list">
                    <li className="chat-item ">
                        <a
                            className={"chat-link"}
                            href="#new-call"
                            color="transparent"
                            onClick={(ev) => {
                                ev.preventDefault();
                                onAddNewCall();
                            }}
                        >
                            <div className="user-avatar chat-media">
                                <Icon name="call" className="icon-circle bg-light"></Icon>
                            </div>
                            <div className="chat-info">
                                <Trans className="sub-text" i18nKey="call.start" />
                            </div>
                        </a>
                    </li>
                    {calls && calls.map((item, idx) => (
                        <CallListItem
                            key={item.shortCallId}
                            item={item}
                            callItemClick={() => navigateToCall(item.shortCallId)}
                        />
                    ))}
                </ul>
            );
        }
    };

    return (
        <SimpleBar className="nk-chat-aside-body">
            <ul className="chat-list mx-3">
                <li className="chat-item ">
                    <a
                        className={"chat-link"}
                        href="#history"
                        color="transparent"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onCallsHistoryModalOpen();
                        }}
                    >
                        <div className="user-avatar chat-media">
                            <Icon name="history" className="icon-circle bg-light"></Icon>
                        </div>
                        <div className="chat-info">
                            <Trans className="sub-text" i18nKey="callsHistory.open" />
                        </div>
                    </a>
                </li>
                <li className="chat-item ">
                    <a
                        className={"chat-link"}
                        href="#history"
                        color="transparent"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onActiveCallsModalOpen();
                        }}
                    >
                        <div className="user-avatar chat-media">
                            <Icon name="call-alt" className="icon-circle bg-light"></Icon>
                        </div>
                        <div className="chat-info">
                            <Trans className="sub-text" i18nKey="activeCalls.open" />
                        </div>
                    </a>
                </li>
            </ul>
            <div className="nk-chat-aside-search">
                <div className="form-group">
                    <div className="form-control-wrap">
                        <div className="form-icon form-icon-left">
                            <Icon name="search"></Icon>
                        </div>
                        <Input
                            type="text"
                            className="form-round"
                            id="default-03"
                            placeholder={t("contact.searchPlaceholder") as string}
                            onChange={(e) => onSearchInputChange(e.target.value)}
                            value={searchInputValue}
                        />
                    </div>
                </div>
            </div>
            {
            searchResults === null && !searchLoading
            ?
                <>
                    <div className="tabs">
                        <div className={`title overline-title-alt tab${!showArchive ? ` active` : ``}`} onClick={onHideArchive}>
                            <a
                                href="#active-calls"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <Trans i18nKey="activeCalls.title" />
                                {` (${calls?.length ?? 0})`}
                            </a>
                        </div>
                    </div>
                    <div className="nk-chat-list">
                        {renderCallList()}
                    </div>
                    <div className="tabs">
                        <div className={`title overline-title-alt tab${!showArchive ? ` active` : ``}`} onClick={onHideArchive}>
                            <a
                                href="#chats"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <Trans i18nKey="chat.aside.chatsButton" />
                                {filteredChatList && ` (${filteredChatList.length})`}
                            </a>
                        </div>
                        <div className="sep" />
                        <div className={`title overline-title-alt tab${showArchive ? ` active` : ``}`} onClick={onShowArchive}>
                            <a
                                href="#archive"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <Trans i18nKey="chat.aside.archiveButton" />
                                {filteredArchiveChatList && ` (${filteredArchiveChatList.length})`}
                            </a>
                        </div>
                    </div>
                    <div className="nk-chat-list">
                        {/* <h6 className="title overline-title-alt">
                            {!showArchive ? t("chat.aside.chatsTitle") : t("chat.aside.archiveTitle")}
                        </h6> */}
                        {renderChatList()}
                    </div>
            </>
            :
            <>
                <div className="tabs gx-2">
                    <div className={`title overline-title-alt tab no-pointer active`} >
                        <Trans i18nKey="chat.aside.results" values={{count: searchResults?.length ?? 0}}/>
                        <Button type="button" className="close" outline onClick={()=>onSearchInputChange("")}>
                            <FaIcon icon="xmark" sizeDL="xs" />
                        </Button>
                    </div>
                </div>
                <div className="nk-chat-list">
                    {searchLoading ?
                        <div className="empty-message">
                            <FaIcon icon="spinner" pulse size="3x"/>
                            <Trans i18nKey="chat.aside.loading" />
                        </div>
                        :
                        searchResults !== null && searchResults.length > 0 ?
                            <ul className="chat-list">
                                {searchResults.map((r, i) => <SearchResult key={`search-result-${i}`} openItemId={showArchive?openChatArchive?.id:openChat?.id} searchResult={r} resultItemClick={resultItemClick} />)}
                            </ul>
                            :
                            <div className="empty-message">
                                <Trans i18nKey="chat.aside.noSearchResults" />
                            </div>
                    }
                </div>
            </>
        }
        </SimpleBar>
    );
};


type SearchResultProps = {
    openItemId: string | undefined,
    searchResult: SearchChatsResult,
    resultItemClick: (result: SearchChatsResult) => void,
};
export const SearchResult: React.FC<SearchResultProps> = ({openItemId, searchResult, resultItemClick}) => {
    function renderResultIcon(): React.ReactNode {
        if (searchResult.isGroupChat) return <FaIcon icon="users"/>;
        if (searchResult.chatId !== null) return <FaIcon icon="user" />;
        return <FaIcon icon="user" />;
    }
    function isResultOpen(): boolean {
        return openItemId === searchResult.chatId;  
    }

    return (<li className={classNames({
            "chat-item": true,
            "is-open": isResultOpen()
        })}
        >
        <a
            className={"chat-link"}
            href="#edit"
            color="transparent"
            onClick={(ev) => {
                ev.preventDefault();
                resultItemClick(searchResult);
            }}
        >
            <div className="user-avatar chat-media">
                {renderResultIcon()}
                {searchResult.isArchive && <FaIcon icon="box-archive" className="bottom-right" />}
            </div>
            <div className="chat-info">
                {searchResult.name}
                {searchResult.isArchive && <div className="sub-text"><Trans i18nKey="chat.aside.archivedChat" />{formatDate(searchResult.creationTime)}</div>}
            </div>
        </a>
    </li>);
}