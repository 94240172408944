import { createContext, useEffect, useRef, useState } from "react";
import { ChatsArchiveService } from "../../services";
import { GetChatArchivedMessagesContextType } from "./GetChatArchivedMessagesContextTypes";
import { GetChatArchiveMessagesOutput } from "@trantor/vdesk-api-client/src/clients/chatArchive";
import { isDevelopment } from "../../utils/Log";
import { ChatArchiveItem } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";
import { useCronState } from "../../utils/CronJob";

export const GetChatArchivedMessagesContext = createContext({} as GetChatArchivedMessagesContextType);

const UPDATE_INTERVAL_MILLIS = 250;

interface GetChatArchivedMessagesContextProviderProps {
    openChatArchive: ChatArchiveItem;
    children?: React.ReactNode;
}

export const GetChatArchivedMessagesProvider: React.FC<GetChatArchivedMessagesContextProviderProps> = ({
    openChatArchive,
    children,
}) => {
    const [page, setPage] = useState<number>(1);
    const [displayedData, setDisplayedData] = useState<GetChatArchiveMessagesOutput["items"] | null>(null);
    const prevOpenChatArchiveRef =  useRef<string | null>(null);
    const [canLoadMorePages, setCanLoadMorePages] = useState<boolean>(false);
    const messagesFetcher = async (chatArchiveId: string, page: number): Promise<GetChatArchiveMessagesOutput["items"]> => {
        let output: GetChatArchiveMessagesOutput["items"] = [];
        let lastResp: GetChatArchiveMessagesOutput = {
            page: 1,
            itemsPerPage: isDevelopment() ? 10 : 100,
            totalItems: 0,
            totalPages: 1,
            items: [],
        };

        for (let i = 1; i <= (page > lastResp.totalPages ? lastResp.totalPages : page); i++) {
            lastResp = await ChatsArchiveService.getArchivedMessages({
                filters: [{ field: "chatArchiveId", op: "eq", value: chatArchiveId }],
                orderBy: { field: "creationTime", order: "desc" },
                itemsPerPage: lastResp.itemsPerPage,
                page: i,
            });

            output.push(...lastResp.items);
        }
        setCanLoadMorePages(displayedData !== null && lastResp.totalPages >= page);
        setItemsPerPageOfLastCall(lastResp.itemsPerPage);
        return output.reverse();
    };
    const [itemsPerPageOfLastCall, setItemsPerPageOfLastCall] = useState<number>(isDevelopment() ? 10 : 100);
    const [retrievedData,, loading] = useCronState(UPDATE_INTERVAL_MILLIS, null, () => messagesFetcher(openChatArchive.id, page));
    useEffect(() => {
        if (openChatArchive !== null && openChatArchive.id !== prevOpenChatArchiveRef.current) {
            setPage(2);
            setCanLoadMorePages(false);
            setDisplayedData(null);
        }
    }, [openChatArchive]);
    useEffect(() => {
        if (retrievedData !== null) setDisplayedData(retrievedData.slice(-itemsPerPageOfLastCall * (page - 1)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retrievedData]);
    function loadMoreMessages() {
        if (retrievedData) setDisplayedData(retrievedData);
        setPage(page + 1);
    }

    return (
        <GetChatArchivedMessagesContext.Provider
            value={{
                messages: displayedData,
                canLoadMorePages,
                loadMoreMessages,
                loading,
            }}
        >
            {children}
        </GetChatArchivedMessagesContext.Provider>
    );
};
