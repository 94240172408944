
import React, { useContext, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon, ConfirmModal } from "../../../components/Component";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { findUpper, themeFromUsername } from "../../../utils/Utils";
import { Trans, useTranslation } from "react-i18next";
import { GetUsersContext } from "../../../contexts/get-users/GetUsersContext";
import { UserContext } from "../../settings/UserContext";
import { EditChatParams } from "@trantor/vdesk-api-client/dist/clients/chat";
import { GetChatsContext } from "../../../contexts/get-chats/GetChatsContext";
import AddChatParticipantsModal from "./modals/AddChatParticipantsModal";
import { isChatItem, isUserMod } from "../../../utils/Chat";
import ChatSideBarParticipantsContextMenu, {
    ChatSideBarParticipantsContextMenuAvailableFunctions,
} from "../../../components/contextMenus/ChatSideBarParticipantsContextMenu";
import { GroupBadge } from "../../../components/chat/Badge";
import FaIcon from "../../../components/icon/FaIcon";
import { ChatItem, ChatItemParticipant, ChatItemParticipantSchema } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { ChatArchiveItem, ChatArchiveItemParticipant } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";



interface ChatSideBarProps {
    sidebar: boolean;
    openChat: ChatItem | ChatArchiveItem;
}
const ChatSideBar: React.FC<ChatSideBarProps> = ({ sidebar, openChat }) => {
    const { t } = useTranslation();
    const { state: { users } } = useContext(GetUsersContext);
    const { editChat } = useContext(GetChatsContext);
    const loggedInUser = useContext(UserContext).loggedInUser[0];

    const getP2POtherUser = (): ChatItemParticipant | ChatArchiveItemParticipant | null => {
        if (openChat?.participants.length === 2) {
            return openChat.participants.find((p) => p.user.id !== loggedInUser.id) ?? null;
        }
        return null;
    };
    const [addParticipantsModal, setAddParticipantsModal] = useState<boolean>(false);

    const handleAddParticipants = async (originalChat: ChatItem | null, newUsers: NonNullable<EditChatParams["participants"]>): Promise<void> => {
        if (originalChat === null) {
            return;
        }

        await editChat({
            id: originalChat.id,
            participants: [
                ...originalChat.participants.map(e => ({
                    userId: e.user.id,
                    moderator: e.moderator,
                })),
                ...newUsers,
            ],
        });
    };
    const areYouMod = () => isUserMod(openChat, loggedInUser);
    const canSomeoneBeDemoted = (): boolean => {
        const mods =
            openChat.participants.filter((p) =>
                isUserMod(
                    openChat,
                    users.find((u) => u.id === p.user.id)
                )
            ) || [];
        return mods.length > 1 && openChat !== null && openChat.name !== null;
    };
    const handleAlterMod = async (originalChat: ChatItem | null, participant: ChatItemParticipant, moderator: boolean): Promise<void> => {
        if (originalChat === null) {
            return;
        }

        await editChat({
            id: originalChat.id,
            participants: [
                ...originalChat.participants.filter(e => e.user.id !== participant.user.id).map(e => ({
                    userId: e.user.id,
                    moderator: e.moderator,
                })),
                {
                    userId: participant.user.id,
                    moderator,
                },
            ],
        });
    };
    const handleParticipantDeletion = async (originalChat: ChatItem | null, participant: ChatItemParticipant | null): Promise<void> => {
        if (originalChat === null || participant === null) {
            return;
        }

        await editChat({
            id: originalChat.id,
            participants: originalChat.participants.filter(e => e.user.id !== participant.user.id).map(e => ({
                userId: e.user.id,
                moderator: e.moderator,
            })),
        });
    };
    const [confirmRemoveParticipantModal, setConfirmRemoveParticipantModal] = useState<boolean>(false);
    const [selectedParticipant, setSelectedParticipant] = useState<ChatItemParticipant | null>(null);
    const toggleConfirmRemoveParticipantModal = () => {
        setConfirmRemoveParticipantModal(!confirmRemoveParticipantModal);
    };
    const openConfirmRemoveParticipantModal = (participant: ChatItemParticipant) => {
        setConfirmRemoveParticipantModal((old) => {
            setSelectedParticipant(participant);
            return true;
        });
    };

    const isChatItemParticipant = (participant : ChatItemParticipant | ChatArchiveItemParticipant | null): participant is ChatItemParticipant => {
        return ChatItemParticipantSchema.safeParse(participant).success;
    }

    const generateContextMenuFunctions = (
        participant: ChatItemParticipant
    ): ChatSideBarParticipantsContextMenuAvailableFunctions => {
        const funcs: ChatSideBarParticipantsContextMenuAvailableFunctions = {};
        if (areYouMod() && isChatItem(openChat)) {
            if (participant.user.id !== loggedInUser.id && openChat && openChat.participants.length > 2) {
                funcs.delete = () => openConfirmRemoveParticipantModal(participant);
            } else {
                funcs.delete = null;
            }
            // if (chat !== null && chat.name !== null){
                if (participant.moderator) {
                    if (canSomeoneBeDemoted()) {
                        funcs.unmakeMod = () => {
                            handleAlterMod(openChat, participant, !participant.moderator).catch(()=>null);
                        };
                    } else {
                        funcs.unmakeMod = null;
                    }
                } else {
                    funcs.makeMod = () => {
                        handleAlterMod(openChat, participant, !participant.moderator).catch(()=>null);
                    };
                }
            // }
        } else {
            funcs.delete = null;
            if (participant.moderator) {
                funcs.unmakeMod = null;
            } else {
                funcs.makeMod = null;
            }
        }
        return funcs;
    };

    const [contextMenuCoords, setContextMenuCoords] = useState<{
        x: number;
        y: number;
        participant: ChatItemParticipant;
    } | null>(null);
    const closeAllContextMenus = () => {
        setContextMenuCoords(null);
    };
    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, participant: ChatItemParticipant): void => {
        e.preventDefault();
        setContextMenuCoords({ x: e.clientX, y: e.clientY, participant });
    };
    useEffect(() => {
        // Closes the context menu whenever you click or scroll
        window.addEventListener("click", closeAllContextMenus);
        window.addEventListener("drag", closeAllContextMenus);
        window.addEventListener("resize", closeAllContextMenus);
        window.addEventListener("scroll", closeAllContextMenus);
        return () => {
            window.removeEventListener("click", closeAllContextMenus);
            window.removeEventListener("drag", closeAllContextMenus);
            window.removeEventListener("resize", closeAllContextMenus);
            window.removeEventListener("scroll", closeAllContextMenus);
        };
    }, []);
    const isChatAGroup = (): boolean => openChat !== null && typeof openChat.name === "string" && openChat.name !== null;

    return (
        <SimpleBar className={`nk-chat-profile ${sidebar ? "visible" : ""}`}>
            <div className="user-card user-card-s2 my-4">
                <div className="user-info">
                    <h5>{isChatAGroup() ? openChat?.name : getP2POtherUser()?.user.displayName}</h5>
                    {isChatAGroup() && <GroupBadge />}
                    {/* <span className="sub-text">
                        {t("active", { active: chat.active === true ? t("now") : `${chat.active} ${t("ago")}` })}
                    </span> */}
                </div>
                {/* <UncontrolledDropdown className="user-card-menu">
                    <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
                        <Icon name="more-h"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end>
                        <LinkList opt className="no-bdr">
                            <LinkItem link={"/user-details-regular/1"} icon="eye">
                                {t("chat.sidebar.viewProfile")}
                            </LinkItem>
                            <LinkItem link={""} tag="a" icon="na">
                                {t("chat.sidebar.lockOut")}
                            </LinkItem>
                        </LinkList>
                    </DropdownMenu>
                </UncontrolledDropdown> */}
            </div>
            <div className="chat-profile">
                {/* MEMBERS MENU (VISIBLE WHEN CHAT IS A GROUP) */}
                <div className="chat-profile-group">
                    <div className="chat-profile-head">
                        <h6 className="title overline-title">{t("chat.sidebar.members")}</h6>
                    </div>
                    <div className={`chat-profile-body collapse show`} id="chat-photos">
                        <div className="chat-profile-body-inner">
                            <ul className="chat-members">
                                {
                                    openChat.name !== null ? <li>
                                        <a
                                            className={
                                                "chat-members-link add-opt" +
                                                (isChatAGroup() ||
                                                    areYouMod() ||
                                                    (openChat?.participants && openChat?.participants.length > 1)
                                                    ? ""
                                                    : " unclickable-text unselectable-text")
                                            }
                                            href="#edit"
                                            color="transparent"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                (isChatAGroup() || (openChat?.participants && openChat?.participants.length > 1)) &&
                                                    areYouMod() &&
                                                    setAddParticipantsModal(true);
                                            }}
                                        >
                                            <Icon name="plus" className="icon-circle bg-light"></Icon>
                                            <span className="sub-text">{t("chat.sidebar.addPerson")}</span>
                                        </a>
                                    </li> : <></>
                                }

                                <li>
                                    {openChat.participants
                                        .sort((a, b) => (a.moderator && !b.moderator ? -1 : 1))
                                        .map((participant, idx) => {
                                            return (
                                                <div
                                                    className="user-card"
                                                    key={idx}
                                                    onContextMenu={(ev) => isChatItemParticipant(participant) && handleContextMenu(ev, participant)}
                                                >
                                                    {contextMenuCoords && isChatItemParticipant(participant) &&
                                                        contextMenuCoords.participant.user.id === participant.user.id && (
                                                            <ChatSideBarParticipantsContextMenu
                                                                coords={contextMenuCoords}
                                                                functions={generateContextMenuFunctions(participant)}
                                                                key={`context-menu-${participant.user.id}`}
                                                            />
                                                        )}
                                                    <a
                                                        href="#card"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            // TODO: Add navigation to User details page
                                                        }}
                                                    >
                                                        <UserAvatar
                                                            size="sm"
                                                            theme={themeFromUsername(participant.user.username)}
                                                            // image={user.image}
                                                            text={findUpper(participant.user.displayName)}
                                                        ></UserAvatar>
                                                        <div className="user-role">
                                                            {participant.moderator && (
                                                                <FaIcon
                                                                    className={`status dot dot-md`}
                                                                    icon="user-shield"
                                                                    size="xl"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="user-name">{participant.user.displayName}</div>
                                                    </a>
                                                    <div className="user-actions">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                tag="a"
                                                                className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
                                                            >
                                                                <Icon name="more-h"></Icon>
                                                            </DropdownToggle>
                                                            <DropdownMenu end>
                                                                <ul className="link-list-opt no-bdr">
                                                                    <li>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            className={`${!areYouMod() ||
                                                                                    openChat.participants.length <= 2 ||
                                                                                    participant.user.id === loggedInUser.id
                                                                                    ? "unclickable-text "
                                                                                    : ""
                                                                                }`}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                areYouMod() &&
                                                                                    openChat.participants.length > 2 &&
                                                                                    participant.user.id !== loggedInUser.id &&
                                                                                    isChatItemParticipant(participant) &&
                                                                                    openConfirmRemoveParticipantModal(
                                                                                        participant
                                                                                    );
                                                                            }}
                                                                            href="#delete"
                                                                        >
                                                                            <FaIcon icon="user-minus" />
                                                                            <Trans i18nKey="chat.sidebar.remove" />
                                                                        </DropdownItem>
                                                                    </li>
                                                                    <li>
                                                                        <DropdownItem
                                                                            tag="a"
                                                                            className={`${!areYouMod() ||
                                                                                    (participant.moderator &&
                                                                                        !canSomeoneBeDemoted())
                                                                                    ? "unclickable-text "
                                                                                    : ""
                                                                                }`}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                areYouMod() &&
                                                                                isChatItem(openChat) &&
                                                                                isChatItemParticipant(participant) &&
                                                                                handleAlterMod(
                                                                                    openChat,
                                                                                    participant,
                                                                                    !participant.moderator
                                                                                ).catch(()=>null);
                                                                            }}
                                                                            href={
                                                                                participant.moderator
                                                                                    ? "#unmake-mod"
                                                                                    : "#make-mod"
                                                                            }
                                                                        >
                                                                            {participant.moderator ? (
                                                                                <>
                                                                                    <FaIcon icon="user" />
                                                                                    <Trans i18nKey="chat.sidebar.unmakeMod" />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <FaIcon icon="user-shield" />
                                                                                    <Trans i18nKey="chat.sidebar.makeMod" />
                                                                                </>
                                                                            )}
                                                                        </DropdownItem>
                                                                    </li>
                                                                </ul>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* OPTIONS MENU */}
                {/* {chat?.name && (
                    <div className="chat-profile-group">
                        <a
                            href="#options"
                            onClick={(ev) => {
                                ev.preventDefault();
                                setOptionState(!optionState);
                            }}
                            className={`chat-profile-head`}
                            id="chat-options"
                        >
                            <h6 className="title overline-title">{t("chat.sidebar.options")}</h6>
                            <span className="indicator-icon">
                                <Icon name={`chevron-${optionState ? "down" : "up"}`}></Icon>
                            </span>
                        </a>
                        <div className={`chat-profile-body collapse ${optionState ? "show" : ""}`} id="chat-options">
                            <div className="chat-profile-body-inner">
                                <ul className="chat-profile-options">
                                    <li>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                tag="a"
                                                href="#"
                                                color="transparent"
                                                className="chat-option-link"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                }}
                                            >
                                                <Icon className="icon icon-circle bg-light ni ni-edit-alt"></Icon>
                                                <span className="lead-text">{t("chat.sidebar.grpName")}</span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <ul className="link-tidy no-bdr p-3">
                                                    <input
                                                        className="form-control mb-2"
                                                        type="text"
                                                        placeholder={(chat?.name
                                                            ? t("chat.sidebar.grpName")
                                                            : t("chat.sidebar.nick")
                                                        ).toString()}
                                                        onChange={
                                                            (e) =>
                                                                null /* changeNickname(chat?.id + "", e.target.value) */}
                {/* />
                                                </ul>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    {/* User color selection */}
                {/* <li>
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            tag="a"
                                            color="transparent"
                                            className="chat-option-link"
                                            href="#dropdown"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                            }}
                                        >
                                            <Icon
                                                name="circle-fill"
                                                // className={`icon-circle bg-light text-${chat.chatTheme}`}
                                                className={`icon-circle bg-light`}
                                            ></Icon>
                                            <span className="lead-text">{t("chat.sidebar.changeColor")}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <ul className="link-list-plain li-col3x text-center">
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        className="bg-blue"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "blue");
                                                        }}
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "purple");
                                                        }}
                                                        className="bg-purple"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "warning");
                                                        }}
                                                        className="bg-warning"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "success");
                                                        }}
                                                        className="bg-success"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "danger");
                                                        }}
                                                        className="bg-danger"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "info");
                                                        }}
                                                        className="bg-info"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "pink");
                                                        }}
                                                        className="bg-pink"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "orange");
                                                        }}
                                                        className="bg-orange"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                                <li>
                                                    <DropdownItem
                                                        tag="a"
                                                        href="#dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            changeTheme(chat?.id + "", "secondary");
                                                        }}
                                                        className="bg-secondary"
                                                        style={{ height: "25px" }}
                                                    ></DropdownItem>
                                                </li>
                                            </ul>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li> */}
                {/* </ul>
                            </div>
                        </div>
                    </div>
                )} */}
                {/* SETTINGS MENU */}
                {/* <div className="chat-profile-group">
                    <a
                        href="#settings"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setSettingState(!settingState);
                        }}
                        className="chat-profile-head"
                    >
                        <h6 className="title overline-title">{t("chat.sidebar.settings")}</h6>
                        <span className="indicator-icon">
                            <Icon name={`chevron-${settingState ? "down" : "up"}`}></Icon>
                        </span>
                    </a>
                    <div className={`chat-profile-body collapse ${settingState ? "" : "show"}`} id="chat-settings">
                        <div className="chat-profile-body-inner">
                            <ul className="chat-profile-settings">
                                <li>
                                    <div className="custom-control custom-control-sm custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                                        <label className="custom-control-label" htmlFor="customSwitch2">
                                            {t("chat.sidebar.notifications")}
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <a
                                        className="chat-option-link"
                                        href="#ignore"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <Icon className="icon icon-circle bg-light ni ni-bell-off-fill"></Icon>
                                        <div>
                                            <span className="lead-text">{t("chat.sidebar.ignoreMessages")}</span>
                                            <span className="sub-text">{t("chat.sidebar.noNotification")}</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="chat-option-link"
                                        href="#feedback"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <Icon className="icon icon-circle bg-light ni ni-alert-fill"></Icon>
                                        <div>
                                            <span className="lead-text">{t("chat.sidebar.reportIssue")}</span>
                                            <span className="sub-text">{t("chat.sidebar.reportIssue2")}</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* .chat-profile-group */}
                {/* <div className="chat-profile-group">
                    <a
                        href="#shared"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setPhotoState(!photoState);
                        }}
                        className="chat-profile-head"
                    >
                        <h6 className="title overline-title">{t("chat.sidebar.sharedPics")}</h6>
                        <span className="indicator-icon">
                            <Icon name={`chevron-${photoState ? "down" : "up"}`}></Icon>
                        </span>
                    </a>
                    <div className={`chat-profile-body collapse ${photoState ? "" : "show"}`} id="chat-photos">
                        <div className="chat-profile-body-inner">
                            <ul className="chat-profile-media">
                                <li>
                                    <a
                                        href="#images"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <img src={SlideA} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#images"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <img src={SlideB} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#images"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <img src={SlideC} alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* .chat-profile-group */}
            </div>
            {
                isChatItem(openChat) &&
                <AddChatParticipantsModal
                isOpen={addParticipantsModal}
                onToggle={() => setAddParticipantsModal(!addParticipantsModal)}
                onConfirm={handleAddParticipants}
                originalChat={openChat}
                />
            }
            <ConfirmModal
                mode="participant-delete"
                isOpen={confirmRemoveParticipantModal}
                onToggle={toggleConfirmRemoveParticipantModal}
                onConfirm={async () => {
                    if (isChatItem(openChat)) await handleParticipantDeletion(openChat, selectedParticipant)
                }}
            />
        </SimpleBar>
    );
};
export default ChatSideBar;
