import React, { ReactNode } from "react";
import classNames from "classnames";
import { ButtonColor, Sizes } from "../../utils/GeneralTypes";

interface ButtonProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    className?: string;
    color?: ButtonColor;
    size?: Sizes;
    outline?: boolean;
    buttonType?: "button" | "submit" | "reset" | undefined;
    disabled?: boolean;
    children?: ReactNode;
}
/**
 *
 * @param param0
 * @returns
 */
const Button: React.FC<ButtonProps> = ({
    color = "primary",
    size,
    className,
    outline,
    buttonType,
    disabled,
    ...props
}) => {
    const buttonClass = classNames({
        [`${className}`]: className,
        btn: true,
        [`btn-${color}`]: !outline,
        [`btn-outline-${color}`]: outline,
        [`btn-${size}`]: size,
        disabled: disabled,
    });
    return (
        <button type={buttonType} className={buttonClass} {...props}>
            {props.children}
        </button>
    );
};
export default Button;
