import { GetServersOutput, GetServersParams } from "@trantor/vdesk-api-schemas/dist/servers/getServers";
import MainService from "./MainService";

import {
    JoinDomainParams,
    JoinDomainOutput,
} from "@trantor/vdesk-api-client/dist/clients/servers";

class StS {
    private client = MainService.client.servers;

    async joinDomain(params: JoinDomainParams): Promise<JoinDomainOutput> {
        return MainService.call(() => this.client.joinDomain(params));
    }

    async getServers(params: GetServersParams): Promise<GetServersOutput> {
        return MainService.call(() => this.client.getServers(params));
    }
}
const StoragesService = new StS();
export default StoragesService;
