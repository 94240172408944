import EnglishFlag from "../images/flags/uk.png";
import EnglishFlagSq from "../images/flags/uk-sq.png";
import ItalianFlag from "../images/flags/italy.png";
import ItalianFlagSq from "../images/flags/italy-sq.png";

interface GetFlagProps {
    language: string;
    square?: boolean;
}
/**
 * It returns the active language flag url, square-shaped if requested
 * @param language Select a specific language to retrieve for
 * @param square Select the square flag
 * @returns The url for the requested flag
 */
export const getLanguageFlag = ({ language, square = false }: GetFlagProps) => {
    if (square)
        switch (language) {
            case "it":
                return ItalianFlagSq;
            case "en":
                return EnglishFlagSq;
            default:
                return ItalianFlagSq;
        }
    else
        switch (language) {
            case "it":
                return ItalianFlag;
            case "en":
                return EnglishFlag;
            default:
                return ItalianFlag;
        }
};
