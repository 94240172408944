import { ChatArchiveItem } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";

type SetChatsAction = { type: "SET_CHATS", chats: ChatArchiveItem[] };
type UpdateChatsAction = { type: "UPDATE_CHATS", chats: ChatArchiveItem[] };
type SetSelectedChatAction = { type: "SET_SELECTED_CHAT", chatId: string | null };

export type ChatArchivesAction = SetChatsAction | UpdateChatsAction | SetSelectedChatAction;

export type ChatArchivesState = {
    chats: ChatArchiveItem[] | null,
    selectedChatId: string | null;
}

export const INITIAL_STATE: Readonly<ChatArchivesState> = {
    chats: null,
    selectedChatId: null,
};

export default function reducer(state: ChatArchivesState, action: ChatArchivesAction): ChatArchivesState {
    switch (action.type) {
        case "SET_CHATS":
            return { chats: action.chats, selectedChatId: null };
        case "UPDATE_CHATS":
            return {
                ...state,
                chats: action.chats,
            };
        case "SET_SELECTED_CHAT":
            return { ...state, selectedChatId: action.chatId };
    }
}