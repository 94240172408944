import MainService from "./MainService";

import {
    CreateGroupParams,
    CreateGroupOutput,

    DeleteGroupParams,

    EditGroupParams,

    GetGroupsParams,
    GetGroupsOutput,
} from "@trantor/vdesk-api-client/dist/clients/groups";

class GS {
    private client = MainService.client.groups;

    async getGroups(params: GetGroupsParams): Promise<GetGroupsOutput> {
        return MainService.call(() => this.client.get(params));
    }

    async createGroup(params: CreateGroupParams): Promise<CreateGroupOutput> {
        return MainService.call(() => this.client.create(params));
    }

    async editGroup(params: EditGroupParams): Promise<void> {
        return MainService.call(() => this.client.edit(params));
    }

    async deleteGroup(params: DeleteGroupParams): Promise<void> {
        return MainService.call(() => this.client.delete(params));
    }
}
const GroupsService = new GS();
export default GroupsService;
