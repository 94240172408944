import { Trans, useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { UserData } from "../../../../utils/GeneralTypes";
import Icon from "../../../../components/icon/Icon";
import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { GetUsersContext } from "../../../../contexts/get-users/GetUsersContext";
import {
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    UserAvatar,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { findUpper } from "../../../../utils/Utils";
import { UserContext } from "../../../settings/UserContext";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

interface NewChatModalProps {
    isOpen: boolean;
    onToggle: () => void;
    onConfirm: (users: UserData[], open: boolean, now: boolean) => Promise<void>;
}
const NewCallModal: React.FC<NewChatModalProps> = ({ isOpen, onToggle, onConfirm }) => {
    const { t } = useTranslation();
    const { state: { users }, params, changeItemsPerPage } = useContext(GetUsersContext);
    const loggedUser = useContext(UserContext).loggedInUser[0];
    useEffect(() => {
        const oldItemsPerPage = params.itemsPerPage;
        changeItemsPerPage(500);
        return () => {
            if (oldItemsPerPage !== undefined) changeItemsPerPage(oldItemsPerPage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (isOpen) {
            setSelectedUsers([]);
        }
    }, [isOpen]);
    const [data, setData] = useState<UserData[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const debouncingTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (debouncingTimeout.current !== undefined) {
            clearTimeout(debouncingTimeout.current);
            debouncingTimeout.current = undefined;
        }
        debouncingTimeout.current = setTimeout(() => {
            setData(
                users.filter(
                    (u) =>
                        u.id !== loggedUser.id &&
                        u.enabled &&
                        (u.username.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
                            u.displayName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
                )
            );
        }, 500);
    }, [loggedUser.id, searchText, users]);
    const [openCall, setOpenCall] = useState<boolean>(true);
    const [quickStartCall, setQuickStartCall] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    const labelInputRef = useRef<HTMLInputElement>(null);
    const onSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (selectedUsers.length < data.length) {
            setSelectedUsers([...data]);
            setTimeout(() => {
                labelInputRef.current?.focus();
            }, 0);
        }
        if (selectedUsers.length > 0) {
            setSelectedUsers([]);
        }
    };
    const selectAllValue = () => data.length > 0 && selectedUsers.length === data.length;
    const onSelectChange = (user: UserData, e?: React.ChangeEvent<HTMLInputElement>): void => {
        if (disableModal) {
            return;
        }
        const foundInData = data.find((u) => user.id === u.id);
        const foundInSelected = selectedUsers.find((u) => user.id === u.id);
        if (foundInData && !foundInSelected) {
            let newSelectedUsers = [...selectedUsers];
            newSelectedUsers.push(foundInData);
            setSelectedUsers(newSelectedUsers);
        }
        if (foundInSelected) {
            const newSelectedUsers = [...selectedUsers];
            const i = newSelectedUsers.findIndex((u) => u.id === user.id);
            newSelectedUsers.splice(i, 1);
            setSelectedUsers(newSelectedUsers);
        }
    };
    const isUserSelected = (user: UserData) => {
        return selectedUsers.some((u) => u.id === user.id);
    };
    const [selectedUsers, setSelectedUsers] = useState<UserData[]>([]);
    const resetModal = () => {
        setSearchText("");
        setOpenCall(true);
        setQuickStartCall(true)
        setSelectedUsers([]);
        setDisableModal(false);
        setError(null);
    };
    const handleToggle = () => {
        resetModal();
        onToggle();
    };
    const handleConfirm = () => {
        onConfirm(selectedUsers, openCall, quickStartCall) // TODO: Doublecheck with Luca if `randomUUID()` is appropriate
            .then(() => {
                handleToggle();
            })
            .catch((err) => {
                setError(`${err}`);
                setDisableModal(true);
            });
    };

    const renderContent = (): React.ReactNode => {
        return <Content className="compact">
            <Row>
                <Col md={12} className="pb-3">
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="newCall.search" />
                        </label>
                        <input
                            disabled={disableModal}
                            className="form-control"
                            type="text"
                            placeholder={t("newCall.searchPlaceholder").toString()}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
            <DataTable className="card-stretch">
                <DataTableBody compact>
                    <DataTableHead>
                        <DataTableRow className="nk-tb-col-check">
                            <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                    type="checkbox"
                                    disabled={disableModal}
                                    className="custom-control-input"
                                    onChange={(e) => onSelectAllChange(e)}
                                    checked={selectAllValue()}
                                    id="uid"
                                />
                                <label className="custom-control-label" htmlFor="uid"></label>
                            </div>
                        </DataTableRow>
                        <DataTableRow>
                            <span className="sub-text">
                                <Trans i18nKey="usersManagement.displayName" />
                            </span>
                        </DataTableRow>
                        {/* <DataTableRow>
                                <span className="sub-text">
                                    <Trans i18nKey="usersManagement.username" />
                                </span>
                            </DataTableRow> */}
                    </DataTableHead>
                    {/*Head*/}
                    {data.map((item) => {
                        return (
                            <DataTableItem key={item.id}>
                                <DataTableRow className="nk-tb-col-check">
                                    <div className="custom-control custom-control-sm custom-checkbox notext">
                                        <input
                                            type="checkbox"
                                            disabled={disableModal}
                                            className="custom-control-input"
                                            checked={isUserSelected(item)}
                                            id={item.id + "uid1"}
                                            key={Math.random()}
                                            onChange={(e) => e.preventDefault()}
                                            onClick={() => onSelectChange(item)}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor={item.id + "uid1"}
                                        ></label>
                                    </div>
                                </DataTableRow>
                                <DataTableRow>
                                    <div
                                        className={
                                            "user-card unselectable-text" +
                                            (disableModal ? "" : " clickable-text")
                                        }
                                        onClick={(e) => onSelectChange(item)}
                                    >
                                        <UserAvatar
                                            theme={item.avatarBg}
                                            className="xs"
                                            text={findUpper(item.displayName)}
                                        ></UserAvatar>
                                        <div className="user-info">
                                            <span className="tb-lead">{item.displayName}</span>
                                        </div>
                                    </div>
                                </DataTableRow>
                                {/* <DataTableRow>
                                        <div
                                            className={
                                                "user-card unselectable-text" +
                                                (disableModal ? "" : " clickable-text")
                                            }
                                            onClick={(e) => onSelectChange(item)}
                                        >
                                            <div className="user-info">
                                                <span className="tb-lead">{item.username}</span>
                                            </div>
                                        </div>
                                    </DataTableRow> */}
                            </DataTableItem>
                        );
                    })}
                </DataTableBody>
                {data.length === 0 && (
                    <div className="card-inner">
                        <div className="text-center">
                            <span className="text-silent">
                                <Trans i18nKey="usersManagement.noDataFound" />
                            </span>
                        </div>
                    </div>
                )}
            </DataTable>

            <Row>
                <Col sm={12} className="pt-3">
                    {/* <h5 className="title"> */}
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="newCall.participants" />
                        </label>
                    </div>
                    {/* </h5> */}
                </Col>
                <Col sm={12}>
                    <div className="new-chat-participants">
                        <div
                            className={
                                "new-chat-participant unselectable-text" +
                                (disableModal ? "" : " clickable-text")
                            }
                        >
                            <UserAvatar
                                theme={loggedUser.avatarBg}
                                className="xs"
                                text={findUpper(loggedUser.displayName)}
                            ></UserAvatar>
                            <span className="tb-lead">&nbsp;{loggedUser.displayName}</span>
                        </div>
                        {selectedUsers.map((item) => (
                            <div
                                className={
                                    "new-chat-participant unselectable-text" +
                                    (disableModal ? "" : " clickable-text")
                                }
                                onClick={(e) => onSelectChange(item)}
                                key={`participant-${item.id}`}
                            >
                                <UserAvatar
                                    theme={item.avatarBg}
                                    className="xs"
                                    text={findUpper(item.displayName)}
                                ></UserAvatar>
                                <span className="tb-lead">&nbsp;{item.displayName}</span>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="pt-3">
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="newCall.settings.title" />
                        </label>
                        <div className="form-control custom-control custom-control-sm custom-checkbox" style={{border:"none"}}>
                            <input
                                type="checkbox"
                                disabled={disableModal}
                                className="custom-control-input"
                                onChange={(e) => setOpenCall(!openCall)}
                                checked={openCall}
                                id="openCall"
                            />
                            <label className="custom-control-label" htmlFor="openCall">
                                <Trans i18nKey="newCall.settings.open" />
                            </label>
                        </div>
                        <div className="form-control custom-control custom-control-sm custom-checkbox" style={{border:"none"}}>
                            <input
                                type="checkbox"
                                disabled={disableModal}
                                className="custom-control-input"
                                onChange={(e) => setQuickStartCall(!quickStartCall)}
                                checked={quickStartCall}
                                id="quickStartCall"
                            />
                            <label className="custom-control-label" htmlFor="quickStartCall">
                                <Trans i18nKey="newCall.settings.quickStart" />
                            </label>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleToggle();
                }}
                className="modal-dialog-centered"
                size="lg"
                scrollable={true}
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="newCall.title" />
                        </h5>
                        <div className="mt-4">
                            {renderContent()}
                            
                            <Row className="gy-3 pt-2">
                                <Col size={12}>
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button
                                                color="primary"
                                                size="md"
                                                type="button"
                                                disabled={(selectedUsers.length === 0 && !openCall) || disableModal}
                                                onClick={() => {
                                                    handleConfirm();
                                                }}
                                            >
                                                <Trans i18nKey="newCall.createChat" />
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (!disableModal) {
                                                        handleToggle();
                                                    }
                                                }}
                                                className="link link-light"
                                            >
                                                <Trans i18nKey="newCall.cancel" />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default NewCallModal;
