import React from "react";
import { GetChatsContextProvider } from "../../../contexts/get-chats/GetChatsContext";
import { GetUsersContextProvider } from "../../../contexts/get-users/GetUsersContext";
import { UserContextProvider } from "../../settings/UserContext";
import { CallContextProvider } from "../call/CallContext";
import { GetChatArchivesContextProvider } from "../../../contexts/get-chat-archives/GetChatArchivesContext";
import Chat, { ChatProps } from "./Chat";

interface ChatContainerProps extends ChatProps {

}
const ChatContainer: React.FC<ChatContainerProps> = ({...props}) => {
    return (
        <UserContextProvider>
            <GetUsersContextProvider>
                <GetChatArchivesContextProvider>
                    <GetChatsContextProvider>
                        <CallContextProvider>
                            <Chat {...props} />
                        </CallContextProvider>
                    </GetChatsContextProvider>
                </GetChatArchivesContextProvider>
            </GetUsersContextProvider>
        </UserContextProvider>
    );
};
export default ChatContainer;
