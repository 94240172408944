import { z } from "zod";
import { EnumOpSchema, NumberOpSchema, StringOpSchema } from "../filters.js";
export const GroupsConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
    z.object({
        field: z.literal(`name`),
        op: StringOpSchema,
        value: z.string(),
    }),
]);
export const GroupsFiltersSchema = z.array(GroupsConditionSchema.or(z.array(GroupsConditionSchema)));
export const GroupsOrderBySchema = z.object({
    field: z.enum([`creationTime`, `name`]),
    order: z.enum([`asc`, `desc`])
});
export const GetGroupsParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: GroupsOrderBySchema.optional().default({ field: `name`, order: `asc` }),
    filters: GroupsFiltersSchema.optional().default([])
});
export const GroupItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    name: z.string().min(1),
    ldapInfo: z.object({
        ldapId: z.string().min(1),
        ldapDn: z.string().min(1),
    }).nullable(),
    memberUsers: z.object({
        id: z.string().uuid(),
        username: z.string().min(1),
        displayName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    memberGroups: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    memberComputers: z.object({
        id: z.string().uuid(),
        name: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
    memberOf: z.object({
        groupId: z.string().uuid(),
        groupName: z.string().min(1),
        ldapInfo: z.object({
            ldapId: z.string().min(1),
            ldapDn: z.string().min(1),
        }).nullable(),
    }).array(),
});
export const GetGroupsOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: GroupItemSchema.array(),
});
