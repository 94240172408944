import MainService from "./MainService";

import {
    GetStoragesParams,
    GetStoragesOutput,
    CreateStorageParams,
    CreateStorageOutput,
    EditStorageParams,
    EditStorageOutput,
    DeleteStorageParams,
    DeleteStorageOutput,
    FsCreateHomeDirParams,
    FsCreateHomeDirOutput
} from "@trantor/vdesk-api-client/dist/clients/fs";

class StS {
    private client = MainService.client.fs;

    async getStorages(params: GetStoragesParams): Promise<GetStoragesOutput> {
        return MainService.call(() => this.client.getStorages(params));
    }

    async createStorage(params: CreateStorageParams): Promise<CreateStorageOutput> {
        return MainService.call(() => this.client.createStorage(params));
    }

    async editStorage(params: EditStorageParams): Promise<EditStorageOutput> {
        return MainService.call(() => this.client.editStorage(params));
    }

    async deleteStorage(params: DeleteStorageParams): Promise<DeleteStorageOutput> {
        return MainService.call(() => this.client.deleteStorage(params));
    }

    async createHomeDir(params: FsCreateHomeDirParams): Promise<FsCreateHomeDirOutput> {
        return MainService.call(() => this.client.fsCreateHomeDir(params));
    }
}
const StoragesService = new StS();
export default StoragesService;
