import { z } from "zod";
export const GetCallHistoryParamsSchema = z.record(z.string(), z.never());
export const GetCallHistoryOutputSchema = z.object({
    endedCalls: z.object({
        callId: z.string().uuid(),
        shortCallId: z.string().min(1),
        startedBy: z.object({
            id: z.string().uuid(),
            username: z.string().min(1),
            displayName: z.string().min(1),
        }),
        dismissed: z.union([z.literal(false), z.literal(`accepted`), z.literal(`rejected`)]),
        createdAt: z.number().int(),
        startedAt: z.number().int().nullable(),
        endedAt: z.number().int(),
        linkedChatRoom: z.union([
            z.object({
                type: z.literal(`p2p`),
                id: z.string().uuid(),
                name: z.null(),
                otherUser: z.object({
                    id: z.string().uuid(),
                    username: z.string().min(1),
                    displayName: z.string().min(1),
                }),
            }),
            z.object({
                type: z.literal(`group`),
                id: z.string().uuid(),
                name: z.string().min(1),
            }),
        ]).nullable(),
        callChat: z.object({
            id: z.string().uuid(),
            fromDisplayName: z.string().min(1),
            timestamp: z.number().int(),
            text: z.string().min(1),
        }).array(),
    }).array(),
});
