import { z } from "zod";
export const FsCopyFileParamsSchema = z.object({
    id: z.string().uuid(),
    destDir: z.string().uuid(),
    name: z.string().min(1).optional(),
    overwriteDestination: z.boolean().optional().default(false),
    // TODO: at the moment, you cannot copy a file under a parent with different storage
});
export const FsCopyFileOutputSchema = z.object({
    newFileId: z.string().uuid(),
});
