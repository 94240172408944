import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { CreateAccessRuleOutputSchema } from "@trantor/vdesk-api-schemas/dist/accessRules/createAccessRule.js";
import { GetAccessRulesOutputSchema } from "@trantor/vdesk-api-schemas/dist/accessRules/getAccessRules.js";
import { EditAccessRuleOutputSchema } from "@trantor/vdesk-api-schemas/dist/accessRules/editAccessRule.js";
import { DeleteAccessRuleOutputSchema } from "@trantor/vdesk-api-schemas/dist/accessRules/deleteAccessRule.js";
export class AccessRulesClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async create(params) {
        const res = await this.call("createAccessRule", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: CreateAccessRuleOutputSchema.parse(res.body)
        };
    }
    async get(params) {
        const res = await this.call("getAccessRules", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: GetAccessRulesOutputSchema.parse(res.body)
        };
    }
    async edit(params) {
        const res = await this.call("editAccessRule", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: EditAccessRuleOutputSchema.parse(res.body),
        };
    }
    async delete(params) {
        const res = await this.call("deleteAccessRule", params);
        if (res.status !== "OK") {
            return {
                error: parseError(res)
            };
        }
        return {
            data: DeleteAccessRuleOutputSchema.parse(res.body),
        };
    }
}
