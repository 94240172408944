import { z } from "zod";
export const JSONLiteralSchema = z.union([
    z.null(),
    z.string(),
    z.number(),
    z.boolean()
]);
export const JSONValueSchema = z.lazy(() => {
    return z.union([JSONLiteralSchema, JSONValueSchema.array(), z.record(JSONValueSchema)]);
});
export const JSONObjectSchema = z.record(JSONValueSchema);
