import { Trans, useTranslation } from "react-i18next";
import { Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { UserRole } from "../../../utils/GeneralTypes";
import Icon from "../../../components/icon/Icon";
import { SubmitHandler, useForm } from "react-hook-form";
import React, { CSSProperties, Dispatch, useLayoutEffect, useMemo, useRef, useState } from "react";
import l from "../../../utils/Log";
import { Button, RSelect } from "../../../components/Component";
import { ItemOfApi } from "../../../components/ApiTable";
import { UsersService } from "../../../services";
import { EditUserParams } from "@trantor/vdesk-api-schemas/dist/users/editUser";


const quotaUnits = [
    {
        value: "b",
        label: "B",
        key: "b",
    },
    {
        value: "kb",
        label: "KiB",
        key: "kb",
    },
    {
        value: "mb",
        label: "MiB",
        key: "mb",
    },
    {
        value: "gb",
        label: "GiB",
        key: "gb",
    },
] as const;
type QuotaUnit = typeof quotaUnits[number]["value"];

const getUsers = UsersService.getUsers.bind(UsersService);
type UsersItem = ItemOfApi<typeof getUsers>;



export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

export type EditUserFormInputs = {
    mode: string;
    reValidateMode: string;
    displayName: string;
    username: string;
    role: UserRole;
    otp: `enabled` | `disabled` | `new`,
    enabled: boolean;
    password?: string;
    id: string;
    storageQuota: number;
    quotaUnit: QuotaUnit;
};
type BodyProps = {
    userData: UsersItem;
    disableModal: boolean;
    setDisableModal: Dispatch<React.SetStateAction<boolean>>;
    error: string | null;
    setError: Dispatch<React.SetStateAction<string | null>>;
    onToggle: () => void;
};
const EditUserBody: React.FC<BodyProps> = ({userData, error, setError, disableModal, setDisableModal, onToggle}) => {
    const { t } = useTranslation();
    const updateUser = UsersService.editUser.bind(UsersService);

    // Edit user form
    const editUserForm = useForm<EditUserFormInputs>({
        defaultValues: {
            mode: "onSubmit",
            reValidateMode: "onChange",
            ...userData,
            storageQuota: userData.storageQuotaInBytes?.available ?? 0,
            otp: userData.otp === `disabled` ? `disabled` : `enabled`,
        },
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });
    useLayoutEffect(() => {
        if (userData.id === editUserForm.getValues("id")) {
            return;
        }
        editUserForm.setValue("id", userData.id);
        editUserForm.setValue("username", userData.username);
        editUserForm.setValue("enabled", userData.enabled);
        editUserForm.setValue("role", userData.role);
        editUserForm.setValue("otp", userData.otp === `disabled` ? `disabled` : `enabled`);
        editUserForm.setValue("displayName", userData.displayName);
        // setSubmitQuotaFromDataQuota();
        editUserForm.setValue("storageQuota", userData.storageQuotaInBytes.available);
        editUserForm.setValue("password", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, editUserForm]);

    function setSubmitQuotaFromDataQuota() {
        const quota = userData.storageQuotaInBytes?.available ?? 0;
        if (quota < 1024) {
            editUserForm.setValue("quotaUnit", "b");
        } else if (quota < 1024 * 1024) {
            editUserForm.setValue("quotaUnit", "kb");
            editUserForm.setValue("storageQuota", userData.storageQuotaInBytes.available / 1024);
        } else if (quota < 1024 * 1024 * 1024) {
            editUserForm.setValue("quotaUnit", "mb");
            editUserForm.setValue("storageQuota", userData.storageQuotaInBytes.available / (1024 * 1024));
        } else {
            editUserForm.setValue("quotaUnit", "gb");
            editUserForm.setValue("storageQuota", userData.storageQuotaInBytes.available / (1024 * 1024 * 1024));
        }
    }
    function deriveStorageQuotaInBytes (quota: number): number {
        if (editUserForm.getValues("quotaUnit") === "gb") {
            return quota * 1024 * 1024 * 1024;
        } else if (editUserForm.getValues("quotaUnit") === "mb") {
            return quota * 1024 * 1024;
        } else if (editUserForm.getValues("quotaUnit") === "kb") {
            return quota * 1024;
        } else {
            return quota;
        }
    }
    // submit function to update a new item
    const onEditSubmit: SubmitHandler<EditUserFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();
        try {
            const editedUser: EditUserParams = {
                id: submitData.id,
                username: submitData.username !== "" ? submitData.username : undefined,
                displayName: submitData.displayName !== "" ? submitData.displayName : undefined,
                role: submitData.role,
                otp: submitData.otp,
                enabled: submitData.enabled,
                storageQuotaInBytes: /* deriveStorageQuotaInBytes */(submitData.storageQuota),
            };
            if (submitData.password !== undefined && submitData.password.length > 0) {
                editedUser.password = submitData.password;
            }
            await updateUser(editedUser);
            onToggle();
        } catch (err) {
            l.error("failed to update user", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };

    return <div className="mt-4">
        <Form
            className="row gy-4"
            onSubmit={editUserForm.handleSubmit(onEditSubmit, (p, e) => l.error("-- ERR:", p, e))}
        >
            <Row className="gy-3 py-3">
                <Col md={6}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="usersManagement.username" />
                        </label>
                        <input
                            disabled={disableModal}
                            className="form-control"
                            type="text"
                            defaultValue={userData.username}
                            placeholder={t("usersManagement.usernamePlaceholder").toString()}
                            {...editUserForm.register("username")}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="usersManagement.displayName" />
                        </label>
                        <input
                            disabled={disableModal}
                            className="form-control"
                            type="text"
                            defaultValue={userData.displayName}
                            placeholder={t("usersManagement.displayNamePlaceholder").toString()}
                            {...editUserForm.register("displayName")}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="usersManagement.password" />
                        </label>
                        <input
                            disabled={disableModal}
                            className="form-control"
                            type="password"
                            // defaultValue={formData.password}
                            placeholder={t("usersManagement.passwordPlaceholder").toString()}
                            {...editUserForm.register("password", { required: false })}
                        />
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="usersManagement.role" />
                        </label>
                        <div className="form-control-wrap">
                            <RSelect
                                isDisabled={disableModal}
                                options={[
                                    { value: "regular", label: t(`roleLabels.regular`) },
                                    { value: "admin", label: t(`roleLabels.admin`) },
                                ]}
                                defaultValue={{
                                    value: userData.role,
                                    label: t(`roleLabels.${userData.role}`),
                                }}
                                onChange={(e: { value: UserRole }) =>
                                    editUserForm.setValue("role", e.value)
                                }
                            />
                        </div>
                    </div>
                </Col>
                <Col size={6}>
                    <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                            disabled={disableModal}
                            type="checkbox"
                            className="custom-control-input"
                            id="enabledEdit"
                            {...editUserForm.register("enabled", { value: userData.enabled })}
                        />
                        <label className="custom-control-label" htmlFor="enabledEdit">
                            <Trans i18nKey="usersManagement.enabledLabel" />
                        </label>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="usersManagement.otpLabel" />
                        </label>
                        <div className="form-control-wrap">
                            <RSelect
                                isDisabled={disableModal}
                                options={userData.otp === `disabled` ? [
                                    { value: "enabled", label: t(`usersManagement.editUserModal.otpEnabled`) },
                                    { value: "disabled", label: t(`usersManagement.editUserModal.otpDisabled`) },
                                ] : [
                                    { value: "enabled", label: t(`usersManagement.editUserModal.otpEnabled`) },
                                    { value: "disabled", label: t(`usersManagement.editUserModal.otpDisabled`) },
                                    { value: "new", label: t(`usersManagement.editUserModal.otpNew`) },
                                ]}
                                defaultValue={userData.otp === `disabled` ? {
                                    value: `disabled`,
                                    label: t(`usersManagement.editUserModal.otpDisabled`),
                                } : {
                                    value: `enabled`,
                                    label: t(`usersManagement.editUserModal.otpEnabled`),
                                }}
                                onChange={(e: { value: `enabled` | `disabled` | `new` }) =>
                                    editUserForm.setValue("otp", e.value)
                                }
                            />
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="form-label">
                            <Trans i18nKey="usersManagement.storageQuotaInBytes" />
                        </label>
                        <div className="d-flex">
                            <input
                                disabled={disableModal}
                                className="form-control"
                                type="text"
                                placeholder={t("usersManagement.storageQuotaInBytes").toString()}
                                {...editUserForm.register("storageQuota", {valueAsNumber: true})}
                            />
                            {/* <RSelect
                                isDisable={disableModal}
                                options={quotaUnits}
                                className="w-100px"
                                placeholder="UNIT"
                                // value={quotaUnit}
                                // value={editUserForm.getValues("quotaUnit")}
                                onChange={(e: { value: QuotaUnit }) => {
                                    editUserForm.setValue("quotaUnit", e.value);
                                }}
                            /> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="gy-3 py-3">
                <Col size={12}>
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                            <Button color="primary" size="md" type="submit" disabled={disableModal}>
                                <Trans i18nKey="usersManagement.updateUser" />
                            </Button>
                        </li>
                        <li>
                            <a
                                href="#cancel"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!disableModal) {
                                        onToggle();
                                    }
                                }}
                                className="link link-light"
                            >
                                <Trans i18nKey="usersManagement.cancel" />
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Form>
    </div>;
}



type UserEditModalProps = {
    data: UsersItem | null;
    isOpen: boolean;
    onToggle: () => void;
};
function UserEditModal(props: Readonly<UserEditModalProps>){
    const { t } = useTranslation();

    // const [formData, setFormData] = useState<{
    //     id: string;
    //     displayName: string;
    //     username: string;
    //     role: UserRole;
    //     enabled: boolean;
    //     password?: string;
    // }>({
    //     id: "",
    //     displayName: "",
    //     username: "",
    //     role: "regular",
    //     enabled: true,
    //     password: undefined,
    // });
    const prevData = useRef<UsersItem | null>(null);
    const myData = useMemo(() => {
        if (props.data !== null) {
            prevData.current = props.data;
            return props.data;
        }
        return prevData.current;
    }, [props.data]);

    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    const [error, setError] = useState<string | null>(null);
    return <Modal
            isOpen={props.isOpen}
            toggle={() => {
                setDisableModal(false);
                props.onToggle();
            }}
            className="modal-dialog-centered"
            size="lg"
        >
            <ModalBody>
                <Toast isOpen={error !== null} style={errorToastStyle}>
                    <ToastHeader
                        toggle={() => {
                            setError(null);
                            setDisableModal(false);
                        }}
                    >
                        {t("errorToast.title")}
                    </ToastHeader>
                    <ToastBody>
                        <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                    </ToastBody>
                </Toast>
                <a
                    href="#cancel"
                    onClick={(ev) => {
                        ev.preventDefault();
                        setDisableModal(false);
                        setError(null);
                        props.onToggle();
                    }}
                    className="close"
                >
                    <Icon name="cross-sm" />
                </a>
                <div className="p-2">
                    <h5 className="title">
                        <Trans i18nKey="usersManagement.editUser" />
                    </h5>
                    {myData !== null
                    ? <EditUserBody
                        userData={myData}
                        disableModal={disableModal}
                        setDisableModal={setDisableModal}
                        error={error}
                        setError={setError}
                        onToggle={props.onToggle}
                    />
                    : <></>}
                </div>
            </ModalBody>
        </Modal>
};

export default UserEditModal;
