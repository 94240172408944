import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
let LogoLight2x = require("../../images/logo2x.png");
let LogoDark2x = require("../../images/logo-dark2x.png");

// const Logo = (withName = true) => {
//   return (
//     <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
//       <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
//       <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
//       {withName && <>
//         <p className="nk-header-brand-name">
//           &nbsp;VDesk<sup>&copy;</sup>
//         </p>
//       </>}
//     </Link>
//   );
// };
interface LogoProps {
    withName?: boolean;
    className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "", withName = true }) => {
    const logoLinkClass = classNames({
        "logo-link": !withName,
        "logo-link-flex": withName,
    });

    return (
        <Link to={`${process.env.PUBLIC_URL}/`} className={className + logoLinkClass}>
            <img className="logo-light logo-img" src={LogoLight2x} alt="logo" />
            <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
            {withName && (
                <span className="nk-header-brand-name">
                    &nbsp;VDesk<sup>&copy;</sup>
                </span>
            )}
        </Link>
    );
};

export default Logo;
