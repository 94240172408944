import React from "react";
import Icon from "../icon/Icon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import FaIcon from "../icon/FaIcon";

interface LinkItemProps {
  compact?: Boolean;
  icon?: string;
  faIcon?: IconName;
  [key: string]: any; // TODO remove any
}
export const LinkItem: React.FC<LinkItemProps> = ({ compact, icon, faIcon, ...props }) => {
  const liClasses = classNames({
    [`${props.className}`]: props.className,
  });
  const aClasses = classNames({
    compact: compact,
  });
  return (
    <li className={liClasses}>
      {props.tag !== "a" ? (
        <Link to={process.env.PUBLIC_URL + props.link} {...props} className={aClasses}>
          {icon && <Icon name={icon} />}
          {faIcon && <FaIcon icon={faIcon} />}
          <span>{props.text || props.children}</span>
        </Link>
      ) : (
        <a href={process.env.PUBLIC_URL + props.link} onClick={(ev) => ev.preventDefault()} className={aClasses}>
          {icon && <Icon name={icon} />}
          {faIcon && <FaIcon icon={faIcon} />}
          <span>{props.text || props.children}</span>
        </a>
      )}
    </li>
  );
};

interface LinkListProps {
  [key: string]: any; // TODO remove any
}
export const LinkList: React.FC<LinkListProps> = ({ ...props }) => {
  const listClasses = classNames({
    "link-list": !props.opt,
    "link-list-opt": props.opt,
    [`${props.className}`]: props.className,
  });
  return <ul className={listClasses}>{props.children}</ul>;
};
