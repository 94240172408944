import { BaseClient } from "./base.js";
import { parseError } from "../libs/errors.js";
import { GetStatsOutputSchema } from "@trantor/vdesk-api-schemas/dist/getStats.js";
export class StatsClient extends BaseClient {
    constructor(apiEndpoint, token) {
        super(apiEndpoint, token);
    }
    async getStats(params) {
        const res = await this.call(`getStats`, params);
        if (res.status !== `OK`) {
            return {
                error: parseError(res),
            };
        }
        return {
            data: GetStatsOutputSchema.parse(res.body),
        };
    }
}
