import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import "./pages/app/call/CallStyles.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { IsGlobalErrorOrElement } from "./utils/Utils";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { FsBackendOptions } from "i18next-fs-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import it from "./locales/it/translation.json";
import en from "./locales/en/translation.json";
import gsap from "gsap";
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import l from "./utils/Log";

import {
    RecoilRoot,
    //   atom,
    //   selector,
    //   useRecoilState,
    //   useRecoilValue,
} from "recoil";
import { SupportedLanguage, SupportedLanguageSchema, getCurrentLang, supportedLanguages } from "./utils/lang";

gsap.registerPlugin(Flip, ScrollTrigger);

const rootElement = document.getElementById("root");
if (rootElement !== null){
    const rootComponent = ReactDOM.createRoot(rootElement);

    const languageDetector = new LanguageDetector();

    languageDetector.addDetector({
        name: `trantorLanguageDetector`,

        lookup: (): SupportedLanguage => {
            return getCurrentLang();
        },

        cacheUserLanguage: (newLang) => {
            const parsed = SupportedLanguageSchema.safeParse(newLang);

            if (parsed.success) {
                window.localStorage.setItem(`trantor.vdesk.locale`, parsed.data);
            }
        },
    });

    void i18next
        .use(initReactI18next)
        .use(languageDetector)
    // .use(FsBackend)
        .init<FsBackendOptions>({
        // backend: {
        //   // path where resources get loaded from, or a function
        //   // returning a path:
        //   // function(lngs, namespaces) { return customPath; }
        //   // the returned path will interpolate lng, ns if provided like giving a static path
        //   loadPath: "/locales/{{lng}}/{{ns}}.json",

            //   // path to post missing resources
            //   // addPath: "/locales/{{lng}}/{{ns}}.missing.json",

            //   // if you use i18next-fs-backend as caching layer in combination with i18next-chained-backend, you can optionally set an expiration time
            //   // an example on how to use it as cache layer can be found here: https://github.com/i18next/i18next-fs-backend/blob/master/example/caching/app.js
            //   // expirationTime: 60 * 60 * 1000
            // },
            // other i18next options:
            supportedLngs: supportedLanguages,
            // lng: localStorage.getItem("i18nextLng") ?? "en",
            fallbackLng: "en",
            ns: "translation",
            nsSeparator: "|",
            defaultNS: "translation",
            debug: process.env.NODE_ENV === "development",
            resources: {
                it: {
                    translation: it,
                },
                en: {
                    translation: en,
                },
            },
            detection: {
                order: [`trantorLanguageDetector`],
                caches: [`trantorLanguageDetector`],
            },
        });

    rootComponent.render(
        <>
            <RecoilRoot>
                <Suspense fallback={<div />}>
                    <Router basename="/">
                        <Routes>
                            <Route path="*" element={<IsGlobalErrorOrElement element={App} />} />
                        </Routes>
                    </Router>
                </Suspense>
            </RecoilRoot>
        </>
    );}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(l.info);
