import React, { useState, createContext, useContext, useEffect } from "react";
import { CallData, CallContextType, CallStatus, CallEventType } from "./CallTypes";
import { v4 as uuid } from "uuid";
import { UserContext } from "../../settings/UserContext";
import l from "../../../utils/Log";
import { TempCallStreamData } from "../../../components/call/CallVideoScreens";
import { UserData } from "../../../utils/GeneralTypes";

export const CallContext = createContext<CallContextType>({} as CallContextType);

export const CallContextProvider = (props: any) => {
    const [call, setCall] = useState<CallData>();
    const { loggedInUser: loggedInUserState, state: { users } } = useContext(UserContext);
    const [loggedIn] = loggedInUserState;

    useEffect(() => {
        l.info("Something changed in call, here is the old one", call);
    }, [call]);

    const regenerateCallList = (howManyCalls: number) => {
        const sd: TempCallStreamData[] = [];
        for (let i = 0; i < howManyCalls; i++) {
            sd.push({
                id: uuid(),
                url: `https://picsum.photos/16${i < 10 ? "0" : ""}${i}/900`,
                selected: i === 0,
                micOff: i > 0 && i % 2 === 0,
                videoOff: i > 0 && i % 3 === 0,
                user: users[i % 20] as UserData,
            });
        }
        return sd;
    };
    // const listenCookieChange = (callback: Function, interval = 1000) => {
    //     let lastCookie = document.cookie;
    //     setInterval(() => {
    //         let cookie = document.cookie;
    //         if (cookie !== lastCookie) {
    //             try {
    //                 callback({ oldValue: lastCookie, newValue: cookie });
    //             } finally {
    //                 lastCookie = cookie;
    //             }
    //         }
    //     }, interval);
    // };
    // useEffect(() => {
    //     listenCookieChange(({ oldValue, newValue }: { oldValue: string; newValue: string }) => {
    //         console.log(`Cookie change detected by CallContext from \n"${oldValue}"\nto\n"${newValue}"`);
    //     }, 1500);
    // });

    const initActiveCall = () => {
        if (call === undefined) {
            l.info("init start");
            const initialLog = [
                {
                    date: new Date(),
                    type: CallEventType.callCreated,
                },
            ];
            setCall({
                id: uuid(),
                status: CallStatus.created,
                owner: loggedIn,
                participants: [loggedIn],
                videoStreams: regenerateCallList(1),
                invited: [],
                callLog: initialLog,
                micOff: false,
                videoOff: true,
                gridOn: false,
                infoOff: true,
                risedHand: false,
                fullscreen: false,
            });
            l.info("init done");
        } else {
            throw new Error("Attempted to init already existing call");
        }
    };
    const toggleMic = () => {
        const c = call as CallData;
        const mic = !c.micOff;
        setCall({
            ...c,
            micOff: mic,
        });
    };
    const toggleVideo = () => {
        const c = call as CallData;
        const vid = !c.videoOff;
        setCall({
            ...c,
            videoOff: vid,
        });
    };
    const toggleGridView = () => {
        const c = call as CallData;
        const gr = !c.gridOn;
        setCall({
            ...c,
            gridOn: gr,
        });
    };
    const toggleInfo = () => {
        const c = call as CallData;
        const info = !c.infoOff;
        setCall({
            ...c,
            infoOff: info,
        });
    };
    const toggleRaisedHand = () => {
        const c = call as CallData;
        const hand = !c.risedHand;
        setCall({
            ...c,
            risedHand: hand,
        });
    };
    const toggleFullscreen = () => {
        const c = call as CallData;
        const fs = !c.fullscreen;
        setCall({
            ...c,
            fullscreen: fs,
        });
    };
    const destroyActiveCall = () => {
        l.info("destroy start");
        if (call) {
            setCall(undefined);
        } else {
            throw Error("Attempted to destroy an inexistent call");
        }
        l.info("destroy done");
    };

    // Add a user to a call
    const inviteUserToActiveCall = (id: string) => {
        if (!call) {
            // l.error("Unable to invite user to inexistent call");
            throw new Error("Unable to invite user to inexistent call");
        }
        const c = call;
        const newCallData = {
            ...(c as CallData),
            status: CallStatus.connecting,
        };
        newCallData.callLog.push({
            date: new Date(),
            type: CallEventType.participantInvited,
            data: { id },
        });
        const userToAdd = users.find((u) => u.id === id);
        if (!userToAdd) {
            l.error(`User to invite not found (id: ${id})`);
            newCallData.callLog.push({
                date: new Date(),
                type: CallEventType.error,
                data: { message: `User to invite not found (id: ${id})` },
            });
            setCall({
                ...newCallData,
                status: CallStatus.broken,
            });
            l.error(`User to invite not found (id: ${id})`, call);
        } else {
            const foundInInvited = newCallData.invited.find((u) => u.id === id);
            const foundInParticipants = newCallData.participants.find((u) => u.id === id);
            if (foundInInvited) {
                l.error(`User already invited (id: ${id})`, call);
            } else if (foundInParticipants) {
                l.error(`User already participating (id: ${id})`, call);
            } else {
                newCallData.invited.push(userToAdd);
                setCall(newCallData);
            }
        }
    };
    //   const addUserToActiveCall = (id: string) => {
    //     const c = call as CallData;
    //     const newCallData = {
    //       ...c,
    //       status: CallStatus.connecting,
    //     };
    //     const userToAdd = c.invited.find((u) => u.id === id);
    //     const newInvited = [...c.invited];
    //     newInvited.push(userToAdd as UserData);
    //     newCallData.invited = newInvited;
    //     const newLog = [...c.callLog];
    //     newLog.push({
    //       date: new Date(),
    //       type: CallEventType.participantInvited,
    //     });
    //     newCallData.callLog = newLog;
    //     setCall(newCallData);
    //   };
    const removeUserFromActiveCall = (id: string) => {
        const c = call as CallData;
        const newCallData = {
            ...c,
            status: c.participants.length > 1 ? c.status : CallStatus.connecting,
        };
        const userToAdd = users.find((u) => u.id === id);
        const newParticipants = [...c.participants];
        newParticipants.push(userToAdd as UserData);
        newCallData.participants = newParticipants;
        const newLog = [...c.callLog];
        newLog.push({
            date: new Date(),
            type: CallEventType.participantInvited,
        });
        newCallData.callLog = newLog;
        setCall(newCallData);
    };
    const getActiveCallStatus = () => call?.status || CallStatus.nonExistent;
    const isCallStartable = (): boolean => {
        const s = call?.status;
        return s === CallStatus.created || false;
    };
    const isCallEndable = (): boolean => {
        const s = call?.status;
        return s === CallStatus.inProgress || s === CallStatus.connecting;
    };
    const updateVideoStreams = (vv: TempCallStreamData[]) => {
        const c = call as CallData;
        const newCallData = {
            ...c,
            videoStreams: vv,
        };
        setCall(newCallData);
    };
    const MOCKdecrementVideoCalls = () => {
        const newList =
            (call as CallData).videoStreams.length >= 0
                ? regenerateCallList((call as CallData).videoStreams?.length - 1)
                : [];
        updateVideoStreams(newList);
    };
    const MOCKincrementVideoCalls = () => {
        const newList = regenerateCallList((call as CallData).videoStreams?.length + 1);
        updateVideoStreams(newList);
    };
    const MOCKdeleteNumber3 = () => {
        const a = [...(call as CallData).videoStreams];
        a.splice(2, 1);
        updateVideoStreams(a);
    };
    return (
        <CallContext.Provider
            value={{
                //activeCallState: [call, setCall],
                activeCallData: call as CallData,
                activeCallStatus: getActiveCallStatus(),
                initActiveCall: initActiveCall,
                destroyActiveCall: destroyActiveCall,
                inviteUserToActiveCall: inviteUserToActiveCall,
                removeUserFromActiveCall: removeUserFromActiveCall,
                toggleMic: toggleMic,
                toggleVideo: toggleVideo,
                toggleGridView: toggleGridView,
                toggleInfo: toggleInfo,
                isCallStartable: isCallStartable,
                isCallEndable: isCallEndable,
                toggleRaisedHand: toggleRaisedHand,
                toggleFullscreen: toggleFullscreen,
                decrementVideoCalls: MOCKdecrementVideoCalls,
                incrementVideoCalls: MOCKincrementVideoCalls,
                deleteNumber3: MOCKdeleteNumber3,
            }}
        >
            {props.children}
        </CallContext.Provider>
    );
};
