
import { useCallback, useEffect, useRef, useState } from "react";



export function asyncVoid<P extends any[]>(action: (...args: P) => Promise<void>): (...args: P) => void {
    return (...args) => {
        action(...args).catch((error: unknown) => {
            console.error(`ASYNC VOID ERROR:`, error);
        });
    };
}


/**
 * Wrapper that handles execution, state and error handling of a Promise. TO be used for user inputs with BE calls
 * @param cb function (or Promise) to be handled
 * @param onError callback in case of throw from cb()
 * @returns A tuple comprised of the wrapping function to be used to trigger the original cb(), and a reactive variable that indicates when cb() is executing if it's a Promise
 */
export function useHandler<P extends any[]>(cb: (...params: P) => void | Promise<void>, onError: ((err: unknown) => void) = (() => {/* Do nothing */ })): [(...params: P) => Promise<void>, boolean] {
    const [loading, setLoading] = useState(false);

    const loadingRef = useRef(loading);

    const onErrorRef = useRef(onError);
    onErrorRef.current = onError;

    const cbRef = useRef(cb);
    cbRef.current = cb;

    const handler = useCallback(async (...params: P) => {
        const isLoading = loadingRef.current;
        const action = cbRef.current;
        const onErrorCallback = onErrorRef.current;

        if (isLoading) {
            return;
        }

        setLoading(true);
        loadingRef.current = true;

        try {
            await action(...params);
            setLoading(false);
            loadingRef.current = false;
        }
        catch (err) {
            setLoading(false);
            loadingRef.current = false;

            try {
                onErrorCallback(err);
            }
            catch {
                // do nothing
            }
        }
    }, []);

    return [handler, loading];
}



export function useDelayedLoading(loading: boolean, afterMs = 100, minDurationMs = 500): boolean {
    const afterMsRef = useRef(afterMs);
    const minDurationMsRef = useRef(minDurationMs);
    
    const [delayedLoading, setDelayedLoading] = useState(false);
    const delayedLoadingRef = useRef(false);

    const counterRef = useRef(0);

    const loadingRef = useRef(loading);
    loadingRef.current = loading;

    useEffect(() => {
        if (afterMsRef.current === 0 && minDurationMsRef.current === 0) {
            setDelayedLoading(loading);
            delayedLoadingRef.current = loading;
            return;
        }

        const l = loading;
        const d = delayedLoadingRef.current;

        if (l && d) {
            // do nothing
        }
        else if (l && !d) {
            const c = counterRef.current + 1;
            counterRef.current = c;

            setTimeout(() => {
                if (counterRef.current === c && loadingRef.current && delayedLoadingRef.current !== true) {
                    setDelayedLoading(true);
                    delayedLoadingRef.current = true;

                    const turnOffDelayedLoading = (): void => {
                        if (counterRef.current === c && loadingRef.current !== true && delayedLoadingRef.current) {
                            setDelayedLoading(false);
                            delayedLoadingRef.current = false;
                        }
                        else if (counterRef.current === c && loadingRef.current && delayedLoadingRef.current) {
                            setTimeout(() => {
                                turnOffDelayedLoading();
                            }, minDurationMsRef.current);
                        }
                    };

                    turnOffDelayedLoading();
                }
            }, afterMsRef.current);
        }
        else if (!l && d) {
            // do nothing
        }
        else if (!l && !d) {
            // do nothing
        }
    }, [loading]);

    if (afterMsRef.current === 0 && minDurationMsRef.current === 0) {
        return loading;
    }

    return delayedLoading;
}
