import { z } from "zod";
import { EnumOpSchema, NumberOpSchema } from "../filters.js";
import { NotificationBodySchema } from "./common.js";
export const NotificationsConditionSchema = z.union([
    z.object({
        field: z.literal(`id`),
        op: EnumOpSchema,
        value: z.string(),
    }),
    z.object({
        field: z.literal(`creationTime`),
        op: NumberOpSchema,
        value: z.number().int(),
    }),
    z.object({
        field: z.literal(`read`),
        op: EnumOpSchema,
        value: z.boolean(),
    }),
]);
export const NotificationsFiltersSchema = z.array(NotificationsConditionSchema.or(z.array(NotificationsConditionSchema)));
export const NotificationsOrderBySchema = z.object({
    field: z.enum([`creationTime`]),
    order: z.enum([`asc`, `desc`])
});
export const GetNotificationsParamsSchema = z.object({
    page: z.number().int().min(1).optional().default(1),
    itemsPerPage: z.number().int().min(1).max(500).optional().default(10),
    orderBy: NotificationsOrderBySchema.optional().default({ field: `creationTime`, order: `desc` }),
    filters: NotificationsFiltersSchema.optional().default([]),
});
export const NotificationItemSchema = z.object({
    id: z.string().uuid(),
    creationTime: z.number().int(),
    read: z.boolean(),
    body: NotificationBodySchema,
});
export const GetNotificationsOutputSchema = z.object({
    page: z.number().int().min(1),
    itemsPerPage: z.number().int().min(1).max(500),
    totalItems: z.number().int().min(0),
    totalPages: z.number().int().min(1),
    items: NotificationItemSchema.array(),
});
