import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { SessionData, UserRole, sessionDataFromJSON } from "../utils/GeneralTypes";
import { canAccess, checkSessionValidity } from "../utils/Utils";
import P from "../utils/Path";
import l from "../utils/Log";
import { getCookie, removeCookie } from "typescript-cookie";

interface PrivateRouteProps {
    element: React.ComponentType<any>;
    restrictAccess?: UserRole;
    redirectOverride?: string;
    [key: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    element: Element,
    restrictAccess = "regular",
    redirectOverride = "",
    ...props
}) => {
    const cookie = getCookie("sessionData");
    const sessionData: SessionData = sessionDataFromJSON(cookie ? JSON.parse(cookie) : {});
    const location = useLocation();
    let redirectRoute; //`${process.env.PUBLIC_URL}/auth-login`;
    //     l.log(`About to process Private Routing:
    //     sessionValidity:${checkSessionValidity(sessionData)}
    //     routeRestrictions:${checkRouteRestrictions(sessionData.loggedUser, restrictAccess)}
    //     sessionData:
    // ${JSON.stringify(sessionData, null, 1)}`);
    if (!checkSessionValidity(sessionData)) {
        l.error(`ROUTING
    > PrivateRoute
        > Session not valid: Cleaning cookies and redirecting!`);
        removeCookie("sessionData", { path: "/" });
        redirectRoute = Object.freeze(!!redirectOverride ? redirectOverride : P.getPath("login", true));
        return (
            <Navigate
                replace
                to={redirectRoute}
                state={{ fromRoot: false, fromPrivateRoute: true, originLocation: location }}
            />
        );
    } else if (sessionData.loggedUser && !canAccess(sessionData.loggedUser, restrictAccess)) {
        l.error(
            `ROUTING
    > PrivateRoute
        > restrictions required redirect`,
            sessionData.loggedUser
        );
        redirectRoute = Object.freeze(!!redirectOverride ? redirectOverride : "/");
        return (
            <Navigate
                replace
                to={redirectRoute}
                state={{ fromRoot: false, fromPrivateRoute: true, originLocation: location }}
            />
        );
    }

    return <Element {...props} />;
};
export default PrivateRoute;
