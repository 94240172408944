
export function playSound(sourcePath: string, opts: { volume?: number, loop?: boolean, stopAfterMs?: number } = {}): void {
    const audio = new Audio();
    audio.src = sourcePath;
    audio.loop = opts.loop === true;
    
    if (opts.volume !== undefined) {
        audio.volume = opts.volume;
    }
    
    audio.play().catch(() => { /* do nothing */ });

    if (opts.stopAfterMs !== undefined) {
        window.setTimeout(() => {
            audio.pause();
            audio.remove();
        }, opts.stopAfterMs);
    }
}
