import { Trans, useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import Icon from "../../../../components/icon/Icon";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import {
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    UserAvatar,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { findUpper, themeFromUsername } from "../../../../utils/Utils";
import { UserContext } from "../../../settings/UserContext";
import RenameChatModal from "./RenameChatModal";
import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";
import { useCronState } from "../../../../utils/CronJob";
import { UsersService } from "../../../../services";
import { GetUsersOutput } from "@trantor/vdesk-api-schemas/dist/users/getUsers";
import { EditChatParams } from "@trantor/vdesk-api-schemas/dist/chats/editChat";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

interface AddChatParticipantsModalProps {
    isOpen: boolean;
    onToggle: () => void;
    onConfirm: (originalChat: ChatItem, newUsers: NonNullable<EditChatParams["participants"]>) => Promise<void>;
    originalChat: ChatItem;
}
const AddChatParticipantsModal: React.FC<AddChatParticipantsModalProps> = ({
    isOpen,
    onToggle,
    onConfirm,
    originalChat,
}: AddChatParticipantsModalProps) => {
    const { t } = useTranslation();
    const loggedUser = useContext(UserContext).loggedInUser[0];
    const [searchText, setSearchText] = useState<string>("");
    const defaultResponse: GetUsersOutput = {
        items: [],
        itemsPerPage: 1,
        page: 1,
        totalItems: 1,
        totalPages: 1,
    }
    const [getUsersResponse, triggerGetUsers/* ,, manualGetUsersLoader */] = useCronState<GetUsersOutput>(500, defaultResponse, async () => {
        const response = await UsersService.getUsers({ // TODO: Add pagination support
            page: 1,
            itemsPerPage: 500,
            orderBy: {
                field: "displayName",
                order: "desc",
            },
            filters: [
                {
                    field: "id",
                    op: "neq",
                    value: loggedUser.id,
                },
                {
                    field: "enabled",
                    op: "eq",
                    value: true,
                },
                [
                    {
                        field: "username",
                        op: "inci",
                        value: searchText,
                    },
                    {
                        field: "displayName",
                        op: "inci",
                        value: searchText,
                    },
                ]
            ]
        });

        const items = response.items.filter(e => originalChat.participants.some(f => f.user.id === e.id) !== true);

        return {
            page: 1,
            itemsPerPage: 500,
            totalItems: items.length,
            totalPages: 1,
            items,
        };
    });

    const [selectedUsers, setSelectedUsers] = useState<GetUsersOutput["items"]>([]);

    useEffect(() => {
        if (isOpen) {
            setSelectedUsers([]);
        }
    }, [isOpen]);
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    const onSelectAllChange = (): void => {
        if (selectedUsers.length < getUsersResponse.totalItems) {
            setSelectedUsers([...selectedUsers, ...getUsersResponse.items]);
        }
        if (selectedUsers.length > 0) {
            setSelectedUsers([]);
        }
    };
    const selectAllValue = () => getUsersResponse.totalItems > 0 && selectedUsers.length === getUsersResponse.totalItems;
    const onSelectChange = (user: GetUsersOutput["items"][number]): void => {
        if (disableModal) {
            return;
        }
        const foundInData = getUsersResponse.items.find((u) => user.id === u.id);
        const foundInSelected = selectedUsers.find((u) => user.id === u.id);
        if (foundInData && !foundInSelected) {
            const newSelectedUsers = [...selectedUsers];
            newSelectedUsers.push(foundInData);
            setSelectedUsers(newSelectedUsers);
        }
        if (foundInSelected) {
            const newSelectedUsers = [...selectedUsers];
            const i = newSelectedUsers.findIndex((u) => u.id === user.id);
            newSelectedUsers.splice(i, 1);
            setSelectedUsers(newSelectedUsers);
        }
    };
    const isUserSelected = (user: GetUsersOutput["items"][number]) => {
        return selectedUsers.some((u) => u.id === user.id);
    };
    const resetModal = () => {
        setSearchText("");
        setSelectedUsers([]);
        setDisableModal(false);
        setError(null);
    };
    const handleToggle = () => {
        resetModal();
        onToggle();
    };
    const handleConfirm = () => {
        if (originalChat && originalChat.name) {
            onConfirm(originalChat, usersToEditChatParticipants(selectedUsers))
                .then(() => {
                    handleToggle();
                    return {};
                })
                .catch((err: string) => {
                    setError(`${err}`);
                    setDisableModal(true);
                });
        } else {
            setRenameChatModal(true);
        }
    };
    const [renameChatModal, setRenameChatModal] = useState<boolean>(false);

    const usersToEditChatParticipants = (user: GetUsersOutput["items"]): NonNullable<EditChatParams["participants"]> => {
        return user.map(u => ({
            userId: u.id,
            moderator: false,
        }))
    }

    const handleConfirmWithRenaming = (name: string) => {
        onConfirm(
            {
                ...originalChat,
                name,
            },
            usersToEditChatParticipants(selectedUsers)
        )
            .then(() => {
                handleToggle();
                setRenameChatModal(false);
                return {};
            })
            .catch((err: string) => {
                setError(`${err}`);
                setDisableModal(true);
                setRenameChatModal(false);
            });
    };
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {
                    handleToggle();
                }}
                className="modal-dialog-centered"
                size="lg"
                scrollable={true}
            >
                <ModalBody>
                    <Toast isOpen={error !== null} style={errorToastStyle}>
                        <ToastHeader
                            toggle={() => {
                                setError(null);
                                setDisableModal(false);
                            }}
                        >
                            {t("errorToast.title")}
                        </ToastHeader>
                        <ToastBody>
                            <Trans i18nKey={[`errorCode.${error ?? ""}`, `errorCode.generic`]} />
                        </ToastBody>
                    </Toast>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleToggle();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <div className="p-2">
                        <h5 className="title">
                            <Trans i18nKey="addParticipants.title" />
                        </h5>
                        <div className="mt-4">
                            {/* <Head title={t("addParticipants.settingsUsers")}></Head> */}
                            <Content className="compact">
                                <Row>
                                    <Col md={12} className="pb-3">
                                        <div className="form-group">
                                            <label className="form-label">
                                                <Trans i18nKey="newChat.search" />
                                            </label>
                                            <input
                                                disabled={disableModal}
                                                className="form-control"
                                                type="text"
                                                placeholder={t("newChat.searchPlaceholder").toString()}
                                                value={searchText}
                                                onChange={(e) => {
                                                    setSearchText(e.target.value);
                                                    triggerGetUsers();
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <DataTable className="card-stretch">
                                    <DataTableBody compact>
                                        <DataTableHead>
                                            <DataTableRow className="nk-tb-col-check">
                                                <div className="custom-control custom-control-sm custom-checkbox notext">
                                                    <input
                                                        type="checkbox"
                                                        disabled={disableModal}
                                                        className="custom-control-input"
                                                        onChange={() => onSelectAllChange()}
                                                        checked={selectAllValue()}
                                                        id="uid"
                                                    />
                                                    <label className="custom-control-label" htmlFor="uid"></label>
                                                </div>
                                            </DataTableRow>
                                            <DataTableRow>
                                                <span className="sub-text">
                                                    <Trans i18nKey="usersManagement.displayName" />
                                                </span>
                                            </DataTableRow>
                                            {/* <DataTableRow>
                                                <span className="sub-text">
                                                    <Trans i18nKey="usersManagement.username" />
                                                </span>
                                            </DataTableRow> */}
                                        </DataTableHead>
                                        {/*Head*/}
                                        {getUsersResponse.items.map((item) => {
                                            return (
                                                <DataTableItem key={item.id}>
                                                    <DataTableRow className="nk-tb-col-check">
                                                        <div className="custom-control custom-control-sm custom-checkbox notext">
                                                            <input
                                                                type="checkbox"
                                                                disabled={disableModal}
                                                                className="custom-control-input"
                                                                checked={isUserSelected(item)}
                                                                id={item.id + "uid1"}
                                                                key={Math.random()}
                                                                onChange={(e) => e.preventDefault()}
                                                                onClick={() => onSelectChange(item)}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={item.id + "uid1"}
                                                            ></label>
                                                        </div>
                                                    </DataTableRow>
                                                    <DataTableRow>
                                                        <div
                                                            className={
                                                                "user-card unselectable-text" +
                                                                (disableModal ? "" : " clickable-text")
                                                            }
                                                            onClick={() => onSelectChange(item)}
                                                        >
                                                            <UserAvatar
                                                                theme={themeFromUsername(item.username)}
                                                                className="xs"
                                                                text={findUpper(item.displayName)}
                                                            ></UserAvatar>
                                                            <div className="user-info">
                                                                <span className="tb-lead">{item.displayName}</span>
                                                            </div>
                                                        </div>
                                                    </DataTableRow>
                                                    {/* <DataTableRow>
                                                        <div
                                                            className={
                                                                "user-card unselectable-text" +
                                                                (disableModal ? "" : " clickable-text")
                                                            }
                                                            onClick={(e) => onSelectChange(item)}
                                                        >
                                                            <div className="user-info">
                                                                <span className="tb-lead">{item.username}</span>
                                                            </div>
                                                        </div>
                                                    </DataTableRow> */}
                                                </DataTableItem>
                                            );
                                        })}
                                    </DataTableBody>
                                    {getUsersResponse.items.length === 0 && (
                                        <div className="card-inner">
                                            <div className="text-center">
                                                <span className="text-silent">
                                                    <Trans i18nKey="usersManagement.noDataFound" />
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </DataTable>
                                <Row>
                                    <Col sm={12} className="pt-3">
                                        {/* <h5 className="title"> */}
                                        <div className="form-group">
                                            <label className="form-label">
                                                <Trans i18nKey="addParticipants.participants" />
                                            </label>
                                        </div>
                                        {/* </h5> */}
                                    </Col>
                                    <Col sm={12}>
                                        <div className="new-chat-participants">
                                            {selectedUsers.length > 0 ? (
                                                selectedUsers.map((item) => (
                                                    <div
                                                        className={
                                                            "new-chat-participant unselectable-text" +
                                                            (disableModal ? "" : " clickable-text")
                                                        }
                                                        key={"participant " + item.id}
                                                        onClick={() => onSelectChange(item)}
                                                    >
                                                        <UserAvatar
                                                            theme={themeFromUsername(item.username)}
                                                            className="xs"
                                                            text={findUpper(item.displayName)}
                                                        ></UserAvatar>
                                                        <span className="tb-lead">&nbsp;{item.displayName}</span>
                                                    </div>
                                                ))
                                            ) : (
                                                <span>&hellip;</span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Content>
                            <Row className="gy-3 pt-2">
                                <Col size={12}>
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button
                                                color="primary"
                                                size="md"
                                                type="button"
                                                disabled={selectedUsers.length === 0 || disableModal}
                                                onClick={() => {
                                                    handleConfirm();
                                                }}
                                            >
                                                <Trans i18nKey="addParticipants.createChat" />
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (!disableModal) {
                                                        handleToggle();
                                                    }
                                                }}
                                                className="link link-light"
                                            >
                                                <Trans i18nKey="addParticipants.cancel" />
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <RenameChatModal
                isOpen={renameChatModal}
                onToggle={() => setRenameChatModal(!renameChatModal)}
                originalChat={originalChat}
                onCallback={handleConfirmWithRenaming}
                isWarning
            />
        </>
    );
};

export default AddChatParticipantsModal;
