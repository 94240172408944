import React, { useRef } from "react"
import { sleep, useCronState } from "../CronJob";
import { Button } from "../../components/Component";

const Sandbox = () => {
    const counter = useRef(0);
    const someFakeGet = async () => {
        const c = counter.current += 1;
        await sleep(1000);
        return c;
    }

    const [counted, trigger, loading, manualLoading] = useCronState(3000, null, async () => {
        const temp = await someFakeGet();
        
        return temp;
    })

    return <div>
        <code>
            <p>
                Counter       : {JSON.stringify(counter.current)}
            </p>
            <p>
                Loading       : {JSON.stringify(loading)}
            </p>
            <p>
                Manual loading: {JSON.stringify(manualLoading)}
            </p>
            <p>
                Counted       : {JSON.stringify(counted)}
            </p>
        </code>
        <Button
            onClick={() => {
                trigger();
            }}
        >
            +
        </Button>
    </div>;
}

export default Sandbox;