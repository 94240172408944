import { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { Button, RSelect } from "../../../../components/Component";
import Icon from "../../../../components/icon/Icon";
import l from "../../../../utils/Log";
import { ConnectionProtocol } from "@trantor/vdesk-api-schemas/dist/computers/common";
import { ComputersContext } from "../../../../contexts/get-computers/ComputersContext";
import { EditComputerParams } from "@trantor/vdesk-api-schemas/dist/computers/editComputer";
import { ComputersService } from "../../../../services";
import { ItemOfApi } from "../../../../components/ApiTable";
import StoragesService from "../../../../services/StorageService";
import { EditStorageParams } from "@trantor/vdesk-api-schemas/dist/fs/editStorage";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};

export type EditStorageFormInputs = {
    mode: string;
    reValidateMode: string;

    id: string;
    name: string;
    endpoint: string;
    accessKeyId: string;
    secretAccessKey: string;
    bucket: string;
    region: string;
};
const getStorages = StoragesService.getStorages.bind(StoragesService);
type StoragesItem = ItemOfApi<typeof getStorages>;
interface EditStorageModalProps {
    isOpen: boolean;
    onToggle: () => void;
    data: StoragesItem | null;
}

const toFormState = (data: StoragesItem | null): EditStorageFormInputs => ({
    mode: "onSubmit",
    reValidateMode: "onChange",
    
    id: data?.id ?? "",
    name: data?.name ?? "",
    endpoint: data?.endpoint ?? "",
    accessKeyId: data?.accessKeyId ?? "",
    secretAccessKey: data?.secretAccessKey ?? "",
    bucket: data?.bucket ?? "",
    region: data?.region ?? "",
});

const EditStorageModal: React.FC<EditStorageModalProps> = ({ isOpen, onToggle, data: computerData }) => {
    const { t } = useTranslation();
    const prevComputerData = useRef<StoragesItem | null>(null);
    const myData = useMemo(() => {
        if (computerData !== null) {
            prevComputerData.current = computerData;
            return computerData;
        }
        return prevComputerData.current;
    }, [computerData])

    const updateStorage = StoragesService.editStorage.bind(StoragesService);

    const editStorageForm = useForm<EditStorageFormInputs>({
        defaultValues: toFormState(myData),
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });
    
    useEffect(() => {
        if (myData === null || myData.id === editStorageForm.getValues("id")) {
            return;
        }

        editStorageForm.reset(toFormState(myData));
    }, [editStorageForm, myData]);

    /**
     * Repopulate the form when the modal visibility changes
     */
    useEffect(() => {
        editStorageForm.reset(toFormState(myData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [disableModal, setDisableModal] = useState<boolean>(false);
    
    // submit function to update a new item
    const onEditSubmit: SubmitHandler<EditStorageFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            const editedStorage: EditStorageParams = {
                id: submitData.id,
                name: submitData.name !== "" ? submitData.name : undefined,
                endpoint: submitData.endpoint !== "" ? submitData.endpoint : undefined,
                accessKeyId: submitData.accessKeyId !== "" ? submitData.accessKeyId : undefined,
                secretAccessKey: submitData.secretAccessKey !== "" ? submitData.secretAccessKey : undefined,
                bucket: submitData.bucket !== "" ? submitData.bucket : undefined,
                region: submitData.region !== "" ? submitData.region : undefined,
            }

            await updateStorage(editedStorage);

            onToggle();
        } catch (err) {
            l.error("failed to update storage", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };

    const [error, setError] = useState<string | null>(null);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            setDisableModal(false);
            onToggle();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    setError(null);
                    onToggle();
                }}
                className="close"
            >
                <Icon name="cross-sm" />
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="storagesManagement.editModal.title" />
                </h5>
                <div className="mt-4">
                    <Form
                        className="row gy-4"
                        onSubmit={editStorageForm.handleSubmit(onEditSubmit, (p, e) => l.error("-- ERR:", p, e))}
                    >
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.name}
                                    {...editStorageForm.register("name")}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.endpoint" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.endpoint}
                                    {...editStorageForm.register("endpoint")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.accessKeyId" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.accessKeyId}
                                    {...editStorageForm.register("accessKeyId")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.secretAccessKey" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="password"
                                    defaultValue={myData?.secretAccessKey}
                                    {...editStorageForm.register("secretAccessKey")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.bucket" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.bucket}
                                    {...editStorageForm.register("bucket")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="storagesManagement.attributes.region" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={myData?.region}
                                    {...editStorageForm.register("region")}
                                />
                            </div>
                        </Col>
                        
                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button color="primary" size="md" type="submit" disabled={disableModal}>
                                            <Trans i18nKey="storagesManagement.editModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    onToggle();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="storagesManagement.editModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default EditStorageModal;
