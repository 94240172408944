import React, { useState, useEffect } from "react";
import menu, { IMenuItem } from "./MenuData";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../components/icon/Icon";
import { Trans } from "react-i18next";
import FaIcon from "../../components/icon/FaIcon";

// const MenuHeader: React.FC<Props> = ({ item }) => {
//   return (
//     <li className="nk-menu-heading">
//       <h6 className="overline-title text-primary">{item as ReactFragment}</h6>
//     </li>
//   );
// };

const MenuItem: React.FC<IMenuItem> = ({
    subMenu,
    subPanel,
    text,
    link,
    newTab,
    badge,
    icon,
    faIcon,
    headActive,
    disabled,
}) => {
    const location = useLocation();
    function isActive() {
        if (link !== undefined) {
            return location.pathname.includes(process.env.PUBLIC_URL + link);
        }
        return false;
    }
    return (
        <li
            className={`nk-menu-item unselectable-text${subMenu ? " has-sub" : ""}${
                isActive() ? " active current-page" : ""
            }${headActive ? " active current-page" : ""}`}
        >
            {newTab ? (
                <Link
                    className={`nk-menu-link unselectable-text${disabled ? ` unclickable-text` : ``}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={!disabled ? `${process.env.PUBLIC_URL + link}` : ``}
                >
                    <span className="nk-menu-text">{text}</span>
                    {subPanel && <span className="nk-menu-badge">Hot</span>}
                </Link>
            ) : subMenu ? (
                <React.Fragment>
                    <a
                        href="#toggle"
                        className={`nk-menu-link nk-menu-toggle unselectable-text${
                            disabled ? ` unclickable-text` : ``
                        }`}
                        onClick={(ev) => {
                            ev.preventDefault();
                        }}
                    >
                        <span className="nk-menu-text">
                            <Trans i18nKey={text} />
                        </span>
                    </a>
                    <MenuSub subMenu={subMenu} />
                </React.Fragment>
            ) : (
                <Link
                    className={`nk-menu-link unselectable-text${disabled ? ` unclickable-text` : ``}`}
                    to={!disabled ? `${process.env.PUBLIC_URL + link}` : ``}
                >
                    {icon && (
                        <div className={`nk-menu-icon`}>
                            <Icon name={`${icon}`}></Icon>
                        </div>
                    )}
                    {faIcon && (
                        <div className={`nk-menu-icon`}>
                            <FaIcon icon={`${faIcon}`}></FaIcon>
                        </div>
                    )}
                    <span className="nk-menu-text">
                        <Trans i18nKey={text} />
                    </span>
                    {badge && <span className="nk-menu-badge">{badge}</span>}
                </Link>
            )}
        </li>
    );
};

const MenuSub: React.FC<IMenuItem> = ({ subMenu }) => {
    return (
        <ul className="nk-menu-sub">
            {subMenu?.map((sub: any, index: number) => (
                <MenuItem item={sub} key={index} />
            ))}
        </ul>
    );
};

const checkMenuUrl = (data: IMenuItem): IMenuItem | undefined => {
    // TODO check if this stuff works in any capacity (I'm guessing NOT)
    if (data?.subMenu)
        for (const node of data.subMenu) {
            if (window.location.pathname.indexOf(process.env.PUBLIC_URL + node.link) >= 0) {
                return node;
            } else {
                const newNode = node.subMenu ? checkMenuUrl(node) : undefined;
                if (newNode) return newNode;
            }
        }
    return undefined;
};

const findActiveHead = (): IMenuItem => {
    // TODO check if this stuff works in any capacity (I'm guessing NOT)
    let found: IMenuItem = { link: "" };
    menu.forEach((item) => {
        let finding =
            item.subMenu &&
            item.subMenu.find((s) => window.location.pathname.indexOf(process.env.PUBLIC_URL + s.link) >= 0);
        if (finding) {
            found = item;
        } else if (item.subMenu) {
            item.subMenu.forEach((p) => {
                if (p.subMenu) {
                    let finding = checkMenuUrl(p);
                    if (finding) {
                        found = item;
                    }
                }
            });
        }
    });
    return found;
};

const Menu = () => {
    const [head, setHead] = useState("VDesk");
    let findingActiveHead = findActiveHead();

    useEffect(() => {
        if (findingActiveHead) {
            setHead(findingActiveHead.text || "");
        }
    }, [window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ul className="nk-menu nk-menu-main ui-s2">
            {menu.map((item, index) => {
                if (item.text === head) {
                    return (
                        <MenuItem
                            key={index}
                            subMenu={item.subMenu}
                            subPanel={item.subPanel}
                            text={item.text}
                            link={item.link}
                            newTab={item.newTab}
                            badge={item.badge}
                            icon={item.icon}
                            disabled={item.disabled}
                            headActive={true}
                        />
                    );
                } else
                    return (
                        <MenuItem
                            key={index}
                            subMenu={item.subMenu}
                            subPanel={item.subPanel}
                            text={item.text}
                            link={item.link}
                            newTab={item.newTab}
                            badge={item.badge}
                            icon={item.icon}
                            disabled={item.disabled}
                        />
                    );
            })}
        </ul>
    );
};

export default Menu;
