import React, { useEffect } from "react";
import Icon from "../../components/icon/Icon";
import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";
import { IMenuItem } from "./MenuData";
import { Trans } from "react-i18next";
import ChatDropdown from "../header/dropdown/chat/Chat";
import LanguageHead from "../header/dropdown/language/Language";
import User from "../header/dropdown/user/User";

const MenuItem: React.FC<IMenuItem> = ({
    icon,
    link,
    text,
    sub,
    newTab,
    sidebarToggle,
    mobileView,
    badge,
    ...props
}: IMenuItem) => {
    let currentUrl;

    if (window.location.pathname !== undefined) {
        currentUrl = window.location.pathname;
    } else {
        currentUrl = null;
    }

    const toggleActionSidebar = () => {
        if (!sub && !newTab && mobileView) {
            sidebarToggle && sidebarToggle();
        }
    };

    const menuHeight = (elementsCollection: HTMLCollection): any => {
        let partialHeights: number[] = [];
        const elements: Element[] = Array.from(elementsCollection);
        elements.forEach((element) => {
            let margin =
                parseInt(window.getComputedStyle(element).marginTop.slice(0, -2)) +
                parseInt(window.getComputedStyle(element).marginBottom.slice(0, -2));
            let padding =
                parseInt(window.getComputedStyle(element).paddingTop.slice(0, -2)) +
                parseInt(window.getComputedStyle(element).paddingBottom.slice(0, -2));
            let height = element.clientHeight + margin + padding;
            partialHeights.push(height);
        });
        const totalHeights = partialHeights.reduce((sum, value) => (sum += value), 0);
        return totalHeights;
    };

    const makeParentActive = (el: HTMLElement, childHeight?: number) => {
        let element = el.parentElement?.parentElement?.parentElement;
        let wrap = el.parentElement?.parentElement;
        if (element?.classList && element.classList[0] === "nk-menu-item") {
            element.classList.add("active");
            const subMenuHeight = el.parentNode?.children && menuHeight(el.parentNode?.children);
            if (wrap && subMenuHeight) {
                wrap.style.height = subMenuHeight + childHeight - 50 + "px";
                makeParentActive(element);
            }
        }
    };

    useEffect(() => {
        var element = document.getElementsByClassName("nk-menu-item active current-page");
        var arrayElement = [...(element as any)];

        arrayElement.forEach((dom) => {
            if (dom.parentElement.parentElement.parentElement.classList[0] === "nk-menu-item") {
                dom.parentElement.parentElement.parentElement.classList.add("active");
                const subMenuHeight = menuHeight(dom.parentNode.children);
                dom.parentElement.parentElement.style.height = subMenuHeight + "px";
                makeParentActive(dom.parentElement.parentElement.parentElement, subMenuHeight);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const menuToggle: React.MouseEventHandler<HTMLAnchorElement> = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const self = (e.target as HTMLElement).closest(".nk-menu-toggle");
        var parent = self?.parentElement as HTMLElement;
        var subMenu = self?.nextSibling as HTMLElement;
        var subMenuItem = subMenu.childNodes;
        var parentSiblings = parent.parentElement?.childNodes;
        var parentMenu = parent.closest(".nk-menu-wrap");
        //For Sub Menu Height
        var subMenuHeight = menuHeight(subMenuItem as any);
        // Get parent elements
        const getParents = (el: any, parentSelector?: any) => {
            let myParentSelector = parentSelector || document.querySelector(".nk-menu");
            if (myParentSelector === undefined) {
                myParentSelector = document;
            }
            let parents = [];
            let p = el.parentNode;
            while (p !== myParentSelector) {
                let o = p;
                parents.push(o);
                p = o.parentNode;
            }
            parents.push(myParentSelector);
            return parents;
        };
        let parentMenus = getParents(self);
        if (!parent?.classList.contains("active") && parentSiblings) {
            const parentSiblingsArray = Array.from(parentSiblings);
            // For Parent Siblings
            parentSiblingsArray.forEach((sibling) => {
                (sibling as HTMLElement).classList.remove("active");
                if (typeof sibling.childNodes[1] !== "undefined") {
                    (sibling.childNodes[1] as HTMLElement).style.height = "0";
                }
            });
            if (parentMenu !== null && parentMenu !== undefined) {
                if (!parentMenu.classList.contains("sub-opened")) {
                    parentMenu.classList.add("sub-opened");

                    for (let l = 0; l < parentMenus.length; l++) {
                        if (typeof parentMenus !== "undefined") {
                            if (parentMenus[l].classList.contains("nk-menu-wrap")) {
                                parentMenus[l].style.height = subMenuHeight + parentMenus[l].clientHeight + "px";
                            }
                        }
                    }
                }
            }
            // For Current Element
            parent?.classList.add("active");
            subMenu.style.height = subMenuHeight + "px";
        } else {
            parent?.classList.remove("active");
            if (parentMenu !== null) {
                parentMenu?.classList.remove("sub-opened");
                for (let k = 0; k < parentMenus.length; k++) {
                    if (typeof parentMenus !== "undefined") {
                        if (parentMenus[k].classList.contains("nk-menu-wrap")) {
                            parentMenus[k].style.height = parentMenus[k].clientHeight - subMenuHeight + "px";
                        }
                    }
                }
            }
            subMenu.style.height = "0";
        }
    };

    const menuItemClass = classNames({
        "nk-menu-item": true,
        "has-sub": sub,
        "active current-page": currentUrl === process.env.PUBLIC_URL + link,
    });
    return (
        <li className={menuItemClass} onClick={() => toggleActionSidebar()}>
            {newTab ? (
                <Link
                    to={`${process.env.PUBLIC_URL + link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nk-menu-link"
                >
                    {icon ? (
                        <span className="nk-menu-icon">
                            <Icon name={icon} />
                        </span>
                    ) : null}
                    <span className="nk-menu-text">
                        <Trans i18nKey={text} />
                    </span>
                </Link>
            ) : (
                <NavLink
                    to={`${process.env.PUBLIC_URL + link}`}
                    className={`nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
                    onClick={sub ? menuToggle : undefined}
                >
                    {icon ? (
                        <span className="nk-menu-icon">
                            <Icon name={icon} />
                        </span>
                    ) : null}
                    <span className="nk-menu-text">
                        <Trans i18nKey={text} />
                    </span>
                    {badge && <span className="nk-menu-badge">{badge}</span>}
                </NavLink>
            )}
            {sub ? (
                <div className="nk-menu-wrap">
                    <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView} />
                </div>
            ) : null}
        </li>
    );
};

const MenuSub = ({ icon, link, text, sub, sidebarToggle, mobileView, ...props }: IMenuItem) => {
    return (
        <ul className="nk-menu-sub" style={props.style}>
            {sub &&
                sub.map((item: IMenuItem, i: number) => (
                    <MenuItem
                        key={i}
                        link={item.link}
                        icon={item.icon}
                        text={item.text}
                        sub={item.subMenu}
                        badge={item.badge}
                        newTab={item.newTab}
                        sidebarToggle={sidebarToggle}
                        mobileView={mobileView}
                    />
                ))}
        </ul>
    );
};

const MobileMenu = ({ data, sidebarToggle, mobileView }: IMenuItem) => {
    return (
        <ul className="nk-menu mobile nk-menu-main ui-s2 d-flex flex-column align-items-stretch justify-content-start">
            {data.map((item: IMenuItem, i: number) => (
                <MenuItem
                    key={item.text || "s" + i}
                    link={item.link}
                    icon={item.icon}
                    text={item.text}
                    sub={item.subMenu}
                    badge={item.badge}
                    sidebarToggle={sidebarToggle}
                    mobileView={mobileView}
                />
            ))}
            <li className="nk-menu-item d-flex align-items-center justify-content-start pt-1 pb-1 ps-4">
                <ChatDropdown isMobile sidebarToggle={sidebarToggle} />
            </li>
            <li className="nk-menu-item d-flex align-items-center justify-content-start pt-1 pb-1 ps-4">
                <LanguageHead />
            </li>
            <li className="nk-menu-item d-flex align-items-center justify-content-start pt-1 pb-1 ps-4">
                <User isMobile sidebarToggle={sidebarToggle} />
            </li>
        </ul>
    );
};

export default MobileMenu;
