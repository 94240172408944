
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NonPromise<T> = T extends Promise<any> ? never : T;



export function scope<S>(cb: () => NonPromise<S>): NonPromise<S>;
export function scope<S, E>(cb: () => NonPromise<S>, fallback: () => NonPromise<E>): (NonPromise<S> | NonPromise<E>);
export function scope<S, E>(cb: () => NonPromise<S>, fallback?: () => NonPromise<E>): (NonPromise<S> | NonPromise<E>) {
    try {
        return cb();
    }
    catch (err) {
        if (fallback === undefined) {
            throw err;
        }

        return fallback();
    }
}



export async function scopeAsync<S>(cb: () => (Promise<S> | S)): Promise<S>;
export async function scopeAsync<S, E>(cb: () => (Promise<S> | S), fallback: () => (Promise<E> | E)): Promise<S | E>;
export async function scopeAsync<S, E>(cb: () => (Promise<S> | S), fallback?: () => (Promise<E> | E)): Promise<S | E> {
    try {
        return await cb();
    }
    catch (err) {
        if (fallback === undefined) {
            throw err;
        }

        return await fallback();
    }
}
