
import { z } from "zod";
import forge from "node-forge";



export async function u8arrToBase64(buffer: Uint8Array): Promise<string> {
    const base64url = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(new Blob([buffer]));
    });

    return base64url.slice(base64url.indexOf(`,`) + 1);
}



export function randomBytesHex(count: number): string {
    z.number().int().min(0).parse(count);

    if (count === 0) {
        return ``;
    }

    return forge.util.bytesToHex(forge.random.getBytesSync(count)).toLowerCase();
}
