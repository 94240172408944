import React, { createContext, useEffect, useReducer } from "react";
import useSWR from "swr";
import { ChatsArchiveService } from "../../services";
import { GetChatArchivesContextType } from "./GetChatArchivesContextTypes";
import chatsReducer, { INITIAL_STATE } from "./reducer";
import { ChatArchiveItem } from "@trantor/vdesk-api-schemas/dist/chats/archives/getChatArchives";
import { useNavigate, useParams } from "react-router";
import P from "../../utils/Path";

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const GetChatArchivesContext = createContext<GetChatArchivesContextType>({} as GetChatArchivesContextType);

const UPDATE_INTERVAL_MILLIS = 5000;

interface GetChatsContextProviderProps {
    children?: React.ReactNode;
}

const chatsFetcher = async (): Promise<ChatArchiveItem[]> => {
    const chats = await ChatsArchiveService.getArchivedChats({
        orderBy: {
            field: "creationTime",
            order: "desc",
        }
    });

    return chats.items/* .filter((chat: ChatArchiveItem) => {
        const participantIds = chat.participants.map((p) => p.userId);

        return participantIds.includes(loggedInUser.id);
    }) */;
};

export const GetChatArchivesContextProvider: React.FC<GetChatsContextProviderProps> = (props) => {
    const navigate = useNavigate();
    const { chatArchiveId } = useParams();

    const [state, reducer] = useReducer(chatsReducer, INITIAL_STATE);

    const { data: chats } = useSWR("getArchivedChats", () => chatsFetcher(), {
        refreshInterval: UPDATE_INTERVAL_MILLIS,
    });

    useEffect(() => {
        if (chats !== undefined) {
            reducer({
                type: "UPDATE_CHATS",
                chats,
            });
        }
    }, [chats]);

    async function deleteChat(id: string): Promise<void> {
        await ChatsArchiveService.deleteArchivedChat({ id }).then(() => {
            if (chatArchiveId !== undefined && chatArchiveId === id) {
                navigate(P.getPath(`archive`), {replace: true});
            }
        });
    }

    return (
        <GetChatArchivesContext.Provider
            value={{
                store: [state, reducer],
                deleteChat,
            }}
        >
            {props.children}
        </GetChatArchivesContext.Provider>
    );
};
