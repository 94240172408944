import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Form, Modal, ModalBody, Row, Toast, ToastBody, ToastHeader } from "reactstrap";
import { RSelect } from "../../../../components/Component";
import Icon from "../../../../components/icon/Icon";
import { ComputersContext } from "../../../../contexts/get-computers/ComputersContext";
import { ConnectionProtocol } from "@trantor/vdesk-api-schemas/dist/computers/common.js";
import l from "../../../../utils/Log";
import { ComputersService } from "../../../../services";

export const errorToastStyle: CSSProperties = {
    position: "absolute",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10000,
    width: "95%",
};
interface CreateComputerModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateComputerModal: React.FC<CreateComputerModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const createComputer = ComputersService.createComputer.bind(ComputersService);
    
    type CreateComputerFormInputs = {
        name: string;
        connectionProtocol: ConnectionProtocol;
        connectionHostname: string;
        connectionPort: string;
        wolMacAddress: string;
        wolTimer: string;
        memberOf: string[];
    };

    const createGroupForm = useForm<CreateComputerFormInputs>({
        defaultValues: {
            name: "",
            connectionProtocol: "vdesk",
            connectionHostname: "localhost",
            connectionPort: "",
            wolMacAddress: "",
            wolTimer: "",
            memberOf: [],
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    });

    const [formData, setFormData] = useState<{
        name: string;
        connectionProtocol: ConnectionProtocol;
        connectionHostname: string;
        connectionPort: string;
        wolMacAddress: string;
        wolTimer: string;
        memberOf: string[];
    }>({
        name: "",
        connectionProtocol: "vdesk",
        connectionHostname: "",
        connectionPort: "",
        wolMacAddress: "",
        wolTimer: "",
        memberOf: [],
    });

    const resetForm = useCallback((): void => {
        setFormData({
            name: "",
            connectionProtocol: "vdesk",
            connectionHostname: "",
            connectionPort: "",
            wolMacAddress: "",
            wolTimer: "",
            memberOf: [],
        });
        createGroupForm.setValue("name", "");
    }, [createGroupForm]);

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen, resetForm]);

    const onFormNewSubmit: SubmitHandler<CreateComputerFormInputs> = async (submitData, ev): Promise<void> => {
        ev?.preventDefault();

        try {
            console.log("submitData", submitData);

            const { id } = await createComputer({
                name: submitData.name,
                connectionParams: {
                    proto: submitData.connectionProtocol,
                    hostname: submitData.connectionHostname,
                    port: parseInt(submitData.connectionPort, 10),
                },
                macAddress: (submitData.wolMacAddress === ``) ? null : submitData.wolMacAddress,
                wakeOnLanTimeInSeconds: (submitData.wolTimer === ``) ? null : parseInt(submitData.wolTimer, 10),
            });

            l.info("computer created", { id });
            onClose();
        } catch (err) {
            l.error("failed to create computer", { submitData, err });
            setError(`${err}`);
            setDisableModal(true);
        }
    };
    const [error, setError] = useState<string | null>(null);
    const [disableModal, setDisableModal] = useState<boolean>(false);
    return <Modal
        isOpen={isOpen}
        toggle={() => {
            onClose();
            resetForm();
        }}
        className="modal-dialog-centered"
        size="lg"
    >
        <ModalBody>
            <Toast isOpen={error !== null} style={errorToastStyle}>
                <ToastHeader
                    toggle={() => {
                        setError(null);
                        setDisableModal(false);
                    }}
                >
                    {t("errorToast.title")}
                </ToastHeader>
                <ToastBody>
                    <Trans i18nKey={[`errorCode.${error}`, `errorCode.generic`]} />
                </ToastBody>
            </Toast>
            <a
                href="#cancel"
                onClick={(ev) => {
                    ev.preventDefault();
                    setDisableModal(false);
                    onClose();
                    resetForm();
                    resetForm();
                }}
                className="close"
            >
                <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
                <h5 className="title">
                    <Trans i18nKey="computersManagement.formModal.title" />
                </h5>
                <div className="mt-4">
                    <Form className="row gy-4" onSubmit={createGroupForm.handleSubmit(onFormNewSubmit)}>
                        <Col md={12}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.name" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.name}
                                    placeholder={t("computersManagement.attributes.namePlaceholder").toString()}
                                    {...createGroupForm.register("name", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.connectionProtocol" />
                                </label>
                                <RSelect
                                    isDisabled={disableModal}
                                    options={[
                                        { value: "vdesk", label: "vdesk" },
                                    ]}
                                    defaultValue={{
                                        value: formData?.connectionProtocol,
                                        label: formData?.connectionProtocol,
                                    }}
                                    onChange={(e: { value: string }) =>
                                        createGroupForm.setValue("connectionProtocol", e.value as ConnectionProtocol)
                                    }
                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.connectionHostname" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="text"
                                    defaultValue={formData.connectionHostname}
                                    {...createGroupForm.register("connectionHostname", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.connectionPort" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="number"
                                    defaultValue={formData.connectionPort}
                                    {...createGroupForm.register("connectionPort", {
                                        required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.wolMacAddress" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="string"
                                    defaultValue={formData.wolMacAddress}
                                    {...createGroupForm.register("wolMacAddress", {
                                        // required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="form-group">
                                <label className="form-label">
                                    <Trans i18nKey="computersManagement.attributes.wolTimer" />
                                </label>
                                <input
                                    disabled={disableModal}
                                    className="form-control"
                                    type="number"
                                    defaultValue={formData.wolTimer}
                                    {...createGroupForm.register("wolTimer", {
                                        // required: "This field is required",
                                    })}
                                />
                            </div>
                        </Col>

                        <Row className="gy-3 py-3">
                            <Col size={12}>
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    <li>
                                        <Button disabled={disableModal} color="primary" size="md" type="submit">
                                            <Trans i18nKey="computersManagement.formModal.confirmBtnText" />
                                        </Button>
                                    </li>
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                if (!disableModal) {
                                                    resetForm();
                                                    onClose();
                                                }
                                            }}
                                            className="link link-light"
                                        >
                                            <Trans i18nKey="computersManagement.formModal.cancelBtnText" />
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </ModalBody>
    </Modal>
};

export default CreateComputerModal;
