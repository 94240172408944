import React, { ReactNode, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
    BlockBetween,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Button,
    Col,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableItem,
    DataTableRow,
    Row,
} from "../../components/Component";
import { Trans, useTranslation } from "react-i18next";
import { useCronState } from "../../utils/CronJob";
import StatsService from "../../services/StatsService";
import { GetStatsOutput } from "@trantor/vdesk-api-client/dist/clients/stats";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import { isDevelopment } from "../../utils/Log";
import FaIcon from "../../components/icon/FaIcon";
import { DateTime } from "luxon";
import { getCurrentLang } from "../../utils/lang";
import { DefaultSpinner } from "../../components/default-spinner";
import { formatNumber } from "../../utils/Utils";
ChartJS.register([ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement]);

const defaultColors = {
    red: "#e85347",
    yellow: "#f4bd0e",
    green: "#1ee0ac",
    cyan: "#09c2de",
    blue: "#559bfb",
    azure: "#1676fb",
    indigo: "#2c3782",
    purple: "#816bff",
    pink: "#ff63a5",
    orange: "#ffa353",
    teal: "#20c997",
};
const defaultOptions = {
    aspectRatio: 1.5,
    rotation: 0,
};
const StatisticsPage: React.FC = () => {
    const { t: origT } = useTranslation();
    const t = (code: string): string => {
        return origT(code).toString();
    };
    
    const [demoMode, setDemoMode] = useState(false);
    type TempDemoDataType = {
        numberOfOnlineUsers: {
            lastMonths: {
                month: number,
                year: number,
                value: number | null,
            }[],
        },
        averageSessionDurationInMs: {
            lastMonths: {
                month: number,
                year: number,
                value: number | null,
            }[],
        },
        numberOfCalls: {
            lastMonths: {
                month: number,
                year: number,
                value: number | null,
            }[],
        },
    }
    const additionalDemoData: TempDemoDataType = {
        numberOfOnlineUsers: {
            lastMonths: [
                {
                    month: DateTime.now().month,
                    year: DateTime.now().year,
                    value: 66
                },
                {
                    month: DateTime.now().minus({month: 1}).month,
                    year: DateTime.now().minus({month: 1}).year,
                    value: 77
                },
                {
                    month: DateTime.now().minus({month: 2}).month,
                    year: DateTime.now().minus({month: 2}).year,
                    value: 45
                },
                {
                    month: DateTime.now().minus({month: 3}).month,
                    year: DateTime.now().minus({month: 3}).year,
                    value: 56
                },
                {
                    month: DateTime.now().minus({month: 4}).month,
                    year: DateTime.now().minus({month: 4}).year,
                    value: 23
                },
                {
                    month: DateTime.now().minus({month: 5}).month,
                    year: DateTime.now().minus({month: 5}).year,
                    value: 67
                },
                {
                    month: DateTime.now().minus({month: 6}).month,
                    year: DateTime.now().minus({month: 6}).year,
                    value: 71
                },
                {
                    month: DateTime.now().minus({month: 7}).month,
                    year: DateTime.now().minus({month: 7}).year,
                    value: 68
                },
                {
                    month: DateTime.now().minus({month: 8}).month,
                    year: DateTime.now().minus({month: 8}).year,
                    value: 55
                },
                {
                    month: DateTime.now().minus({month: 9}).month,
                    year: DateTime.now().minus({month: 9}).year,
                    value: 73
                },
                {
                    month: DateTime.now().minus({month: 10}).month,
                    year: DateTime.now().minus({month: 10}).year,
                    value: 87
                },
                {
                    month: DateTime.now().minus({month: 11}).month,
                    year: DateTime.now().minus({month: 11}).year,
                    value: 85
                },
                {
                    month: DateTime.now().minus({month: 12}).month,
                    year: DateTime.now().minus({month: 12}).year,
                    value: 90
                },
            ]
        },
        averageSessionDurationInMs: {
            lastMonths: [
                {
                    month: DateTime.now().month,
                    year: DateTime.now().year,
                    value: 7514145
                },
                {
                    month: DateTime.now().minus({month: 1}).month,
                    year: DateTime.now().minus({month: 1}).year,
                    value: 5453113
                },
                {
                    month: DateTime.now().minus({month: 2}).month,
                    year: DateTime.now().minus({month: 2}).year,
                    value: 4343314
                },
                {
                    month: DateTime.now().minus({month: 3}).month,
                    year: DateTime.now().minus({month: 3}).year,
                    value: 4351345
                },
                {
                    month: DateTime.now().minus({month: 4}).month,
                    year: DateTime.now().minus({month: 4}).year,
                    value: 4313456
                },
                {
                    month: DateTime.now().minus({month: 5}).month,
                    year: DateTime.now().minus({month: 5}).year,
                    value: 3242342
                },
                {
                    month: DateTime.now().minus({month: 6}).month,
                    year: DateTime.now().minus({month: 6}).year,
                    value: 7125133
                },
                {
                    month: DateTime.now().minus({month: 7}).month,
                    year: DateTime.now().minus({month: 7}).year,
                    value: 6845346
                },
                {
                    month: DateTime.now().minus({month: 8}).month,
                    year: DateTime.now().minus({month: 8}).year,
                    value: 5534533
                },
                {
                    month: DateTime.now().minus({month: 9}).month,
                    year: DateTime.now().minus({month: 9}).year,
                    value: 7334567
                },
                {
                    month: DateTime.now().minus({month: 10}).month,
                    year: DateTime.now().minus({month: 10}).year,
                    value: 8756657
                },
                {
                    month: DateTime.now().minus({month: 11}).month,
                    year: DateTime.now().minus({month: 11}).year,
                    value: 8557855
                },
                {
                    month: DateTime.now().minus({month: 12}).month,
                    year: DateTime.now().minus({month: 12}).year,
                    value: 9056755
                },
            ]
        },
        numberOfCalls: {
            lastMonths: [
                {
                    month: DateTime.now().month,
                    year: DateTime.now().year,
                    value: 10
                },
                {
                    month: DateTime.now().minus({month: 1}).month,
                    year: DateTime.now().minus({month: 1}).year,
                    value: 53
                },
                {
                    month: DateTime.now().minus({month: 2}).month,
                    year: DateTime.now().minus({month: 2}).year,
                    value: 44
                },
                {
                    month: DateTime.now().minus({month: 3}).month,
                    year: DateTime.now().minus({month: 3}).year,
                    value: 45
                },
                {
                    month: DateTime.now().minus({month: 4}).month,
                    year: DateTime.now().minus({month: 4}).year,
                    value: 43
                },
                {
                    month: DateTime.now().minus({month: 5}).month,
                    year: DateTime.now().minus({month: 5}).year,
                    value: 32
                },
                {
                    month: DateTime.now().minus({month: 6}).month,
                    year: DateTime.now().minus({month: 6}).year,
                    value: 13
                },
                {
                    month: DateTime.now().minus({month: 7}).month,
                    year: DateTime.now().minus({month: 7}).year,
                    value: 68
                },
                {
                    month: DateTime.now().minus({month: 8}).month,
                    year: DateTime.now().minus({month: 8}).year,
                    value: 55
                },
                {
                    month: DateTime.now().minus({month: 9}).month,
                    year: DateTime.now().minus({month: 9}).year,
                    value: 73
                },
                {
                    month: DateTime.now().minus({month: 10}).month,
                    year: DateTime.now().minus({month: 10}).year,
                    value: 199
                },
                {
                    month: DateTime.now().minus({month: 11}).month,
                    year: DateTime.now().minus({month: 11}).year,
                    value: null
                },
                {
                    month: DateTime.now().minus({month: 12}).month,
                    year: DateTime.now().minus({month: 12}).year,
                    value: null
                },
            ]
        },
    }
    const demoData: (GetStatsOutput & TempDemoDataType) | null = {
        averageSessionDurationInMs: {
            ...additionalDemoData.averageSessionDurationInMs,
            lastWeek: 9685965,
            lastMonth:6737885,
            lastThreeMonths: 3233232,
            lastSixMonths: 9932232,
        },
        numberOfCalls: {
            ...additionalDemoData.numberOfCalls,
            alreadyStarted: 3,
            finishedOrCanceled: 23,
            notYetStarted: 5,
        },
        numberOfChatRooms: {
            p2p: 33,
            p2pArchived: 66,
            group: 13,
            groupArchived: 23,
        },
        numberOfEnabledUsers: 87,
        numberOfLdapUsers: 17,
        numberOfOnlineUsers: {
            ...additionalDemoData.numberOfOnlineUsers,
            rightNow: 15,
            lastWeek: 50,
            lastMonth: 81,
        },
        totalNumberOfRegisteredUsers: 90,
    }
    const [ stats ] = useCronState<(GetStatsOutput & TempDemoDataType) | null>(500, null, async () => {
        let data: (GetStatsOutput & TempDemoDataType) | null = null;
        if (demoMode) {
            data = {
                ...demoData,
                averageSessionDurationInMs:{
                    ...demoData.averageSessionDurationInMs,
                    ...additionalDemoData.averageSessionDurationInMs,
                },
                numberOfOnlineUsers: {
                    ...demoData.numberOfOnlineUsers,
                    ...additionalDemoData.numberOfOnlineUsers,
                },
                numberOfCalls: {
                    ...demoData.numberOfCalls,
                    ...additionalDemoData.numberOfCalls,
                }
            }
        } else {
            const auditData = await StatsService.getAudit();
            data = { //TODO: Remove when shema is coherent
                ...auditData,
                averageSessionDurationInMs:{
                    ...auditData.averageSessionDurationInMs,
                    ...additionalDemoData.averageSessionDurationInMs, //TODO: Remove when shema is coherent
                },
                numberOfOnlineUsers: {
                    ...auditData.numberOfOnlineUsers,
                    ...additionalDemoData.numberOfOnlineUsers, //TODO: Remove when shema is coherent
                },
                numberOfCalls: {
                    ...auditData.numberOfCalls,
                    ...additionalDemoData.numberOfCalls, //TODO: Remove when shema is coherent
                }
            }
        }
        return data;
    });
    return (
        <>
            <Head title={t("pageTitles.settingsStatistics")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                <Trans i18nKey="settingsStatistics.title" />
                            </BlockTitle>
                        </BlockHeadContent>
                        {isDevelopment()
                        ? <BlockHeadContent>
                            <Button
                                className="ms-3"
                                onClick={() => {
                                    setDemoMode(!demoMode);
                                }}
                                color={demoMode ? "danger" : "success"}
                            >
                                <FaIcon icon="bug" />
                                &nbsp;
                                DEV: {demoMode?`Disengage`:`Engage`} demo mode
                                &nbsp;
                                <FaIcon icon="bug" />
                            </Button>
                        </BlockHeadContent>
                        : undefined}
                    </BlockBetween>
                </BlockHead>
                {/* <Col size={12} className="pb-2">
                    <h4>
                        <Trans i18nKey="statsTable.totalNumberOfRegisteredUsersRoot" />
                    </h4>
                </Col> */}
                <Row className="gy-3 mb-5">
                    <Col lg={12} xl={6} xxl={4}>
                        <LoadingWrapper loading={stats === null}>
                            <Pie
                                datasetIdKey="enabledUsers"
                                options={{
                                    ...defaultOptions,
                                    responsive: true,
                                }}
                                data={{
                                    labels: [t("statsTable.numberOfEnabledUsers"), t("statsTable.numberOfDisabledUsers")],
                                    datasets: [{
                                        data: stats !== null
                                            ? [stats.numberOfEnabledUsers, stats.totalNumberOfRegisteredUsers - stats.numberOfEnabledUsers]
                                            : undefined,
                                        backgroundColor: [defaultColors.green, defaultColors.red]}],
                                }}
                            />
                        </LoadingWrapper>
                    </Col>
                    <Col lg={12} xl={6} xxl={4}>
                        <LoadingWrapper loading={stats === null}>
                            <Pie
                                datasetIdKey="ldapUsers"
                                options={defaultOptions}
                                data={{
                                    labels: [t("statsTable.numberOfNonLdapUsers"), t("statsTable.numberOfLdapUsers")],
                                    datasets: [{
                                        data: stats !== null
                                            ? [stats.totalNumberOfRegisteredUsers - stats.numberOfLdapUsers, stats.numberOfLdapUsers]
                                            : undefined,
                                        backgroundColor: [defaultColors.cyan, defaultColors.azure]}],
                                }}
                            />
                        </LoadingWrapper>
                    </Col>
                    <Col lg={12} xl={6} xxl={4}>
                        <LoadingWrapper loading={stats === null}>
                            <Bar
                                datasetIdKey="numberOfOnlineUsers"
                                options={defaultOptions}
                                data={{
                                    labels: [
                                        ...stats?.numberOfOnlineUsers.lastMonths.map(m => {
                                            const monthString = DateTime.local(m.year, m.month, 1).toFormat(`LLLL yyyy`, {locale: getCurrentLang()});
                                            const outString = monthString[0]?.toLocaleUpperCase() + monthString.slice(1);
                                            return outString;
                                        }).reverse() ?? [,,,,,,,,,,,],
                                        t("statsTable.numberOfOnlineUsers.rightNow"),
                                    ],
                                    datasets: [{
                                        label: t("statsTable.numberOfOnlineUsers.title"),
                                        data: stats !== null
                                            ? [
                                                ...stats?.numberOfOnlineUsers.lastMonths.map(m => m.value !== null ? m.value : 0).reverse() ?? [,,,,,,,,,,,],
                                                stats.numberOfOnlineUsers.rightNow
                                            ]
                                            : undefined,
                                        backgroundColor: [defaultColors.teal]}],
                                }}
                            />
                        </LoadingWrapper>
                    </Col>
                    <Col lg={12} xl={6} xxl={4}>
                        <LoadingWrapper loading={stats === null}>
                            <Bar
                                datasetIdKey="averageSessionDurationInMs"
                                options={{
                                    ...defaultOptions,
                                }}
                                data={{
                                    labels: [
                                        ...(stats?.averageSessionDurationInMs.lastMonths.map(m => {
                                            const monthString = DateTime.local(m.year, m.month, 1).toFormat(`LLLL yyyy`, {locale: getCurrentLang()});
                                            const outString = monthString[0]?.toLocaleUpperCase() + monthString.slice(1);
                                            return outString;
                                        }).reverse() ?? [,,,,,,,,,,,]) // TODO: Find a way to say "minutes" or some other translated string after the value
                                    ],
                                    datasets: [{
                                        label: t("statsTable.averageSessionDurationInMs.title"),
                                        data: stats !== null
                                            ? [...stats?.averageSessionDurationInMs.lastMonths.map(m => m.value !== null ? m.value / 1000 / 60 : 0).reverse() ?? [,,,,,,,,,,,]]
                                            : undefined,
                                        backgroundColor: [defaultColors.purple]}],
                                }}
                            />
                        </LoadingWrapper>
                    </Col>
                    <Col lg={12} xl={6} xxl={4}>
                        <LoadingWrapper loading={stats === null}>
                            {(
                                stats?.numberOfChatRooms.p2p === 0 &&
                                stats?.numberOfChatRooms.p2pArchived === 0 &&
                                stats?.numberOfChatRooms.group === 0 &&
                                stats?.numberOfChatRooms.groupArchived === 0
                            )
                            ? <div className="absolute-overlay">
                                <FaIcon icon="ban" />
                                <Trans i18nKey="statsTable.noChatsAvailable" />
                            </div>
                            : undefined}
                            <Pie
                                datasetIdKey="numberOfChatRooms"
                                options={defaultOptions}
                                data={{
                                    labels: [
                                        t("statsTable.numberOfChatRooms.p2p"),
                                        t("statsTable.numberOfChatRooms.p2pArchived"),
                                        t("statsTable.numberOfChatRooms.group"),
                                        t("statsTable.numberOfChatRooms.groupArchived")
                                    ],
                                    datasets: [
                                        {
                                            data: stats !== null
                                                ? [
                                                    stats.numberOfChatRooms.p2p,
                                                    stats.numberOfChatRooms.p2pArchived,
                                                    stats.numberOfChatRooms.group,
                                                    stats.numberOfChatRooms.groupArchived,
                                                ]
                                                : undefined,
                                            backgroundColor: [defaultColors.azure, defaultColors.indigo, defaultColors.pink, defaultColors.purple]
                                        },
                                    ],
                                }}
                            />
                        </LoadingWrapper>
                    </Col>
                    <Col lg={12} xl={6} xxl={4}>
                        <LoadingWrapper loading={stats === null}>
                            <Bar
                                datasetIdKey="numberOfCalls"
                                options={defaultOptions}
                                data={{
                                    labels: [
                                        ...(stats?.numberOfCalls.lastMonths.map(m => {
                                            const monthString = DateTime.local(m.year, m.month, 1).toFormat(`LLLL yyyy`, {locale: getCurrentLang()});
                                            const outString = monthString[0]?.toLocaleUpperCase() + monthString.slice(1);
                                            return outString;
                                        }).reverse() ?? [,,,,,,,,,,,])
                                    ],
                                    datasets: [{
                                        label: t("statsTable.numberOfCalls.graphTitle"),
                                        data: stats !== null
                                            ? [...stats?.numberOfCalls.lastMonths.map(m => m.value !== null ? m.value : 0).reverse() ?? [,,,,,,,,,,,]]
                                            : undefined,
                                        backgroundColor: [defaultColors.yellow]}]
                                }}
                            />
                        </LoadingWrapper>
                    </Col>
                    <Col lg={12} xl={6} xxl={4} className="offset-xxl-4 offset-xl-3">
                        <div style={{position: "relative"}}>
                            {stats === null ||
                            (
                                stats.numberOfCalls.notYetStarted === 0 &&
                                stats.numberOfCalls.alreadyStarted === 0
                            )
                            ? <div className="absolute-overlay">
                                    {stats === null
                                    ? <>
                                        <DefaultSpinner />
                                        <Trans i18nKey="statsTable.loading" />
                                    </>
                                    :
                                    <>
                                        <FaIcon icon="ban" />
                                        <Trans i18nKey="statsTable.noCallsAvailable" />
                                    </>}
                            </div>
                            : undefined}
                            <Pie
                                datasetIdKey="numberOfCalls"
                                options={defaultOptions}
                                data={{
                                    labels: [
                                        t("statsTable.numberOfCalls.notYetStarted"),
                                        t("statsTable.numberOfCalls.alreadyStarted"),
                                    ],
                                    datasets: [
                                        {
                                            animation:{
                                                loop: false
                                            },
                                            data: stats !== null
                                                ? [
                                                    stats.numberOfCalls.notYetStarted,
                                                    stats.numberOfCalls.alreadyStarted,
                                                ]
                                                : undefined,
                                            backgroundColor: [defaultColors.orange, defaultColors.green]
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row className="gy-3 mb-5">
                    <Col xl={12} xxl={12}>
                        <DataTable scrollHorizontally>
                            <DataTableBody compact>
                                <DataTableHead>
                                    <DataTableRow>
                                        &nbsp;
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.totalNumberOfRegisteredUsers" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfEnabledUsers" />
                                        &nbsp;
                                        /
                                        &nbsp;
                                        <Trans i18nKey="statsTable.numberOfDisabledUsers" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfLdapUsers" />
                                        &nbsp;
                                        /
                                        &nbsp;
                                        <Trans i18nKey="statsTable.numberOfNonLdapUsers" />
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.totalNumberOfRegisteredUsersRoot" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null ? stats.totalNumberOfRegisteredUsers : <Trans i18nKey="statsTable.notAvailable" />}
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null
                                        ? `${stats.numberOfEnabledUsers}/${stats.totalNumberOfRegisteredUsers - stats.numberOfEnabledUsers}`
                                        : <Trans i18nKey="statsTable.notAvailable" />}
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null
                                        ? `${stats.numberOfLdapUsers}/${stats.totalNumberOfRegisteredUsers - stats.numberOfLdapUsers}`
                                        : <Trans i18nKey="statsTable.notAvailable" />}
                                    </DataTableRow>
                                </DataTableItem>
                            </DataTableBody>
                        </DataTable>
                    </Col>
                    <Col xl={12} xxl={12}>
                        <DataTable scrollHorizontally>
                            <DataTableBody compact>
                                <DataTableHead>
                                    <DataTableRow>
                                        &nbsp;
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfOnlineUsers.rightNow" />
                                    </DataTableRow>
                                    {stats?.numberOfOnlineUsers.lastMonths.map(m => {
                                        const monthString = DateTime.local(m.year, m.month, 1).toFormat(`LLLL yyyy`, {locale: getCurrentLang()});
                                        const outString = monthString[0]?.toLocaleUpperCase() + monthString.slice(1);

                                        return <DataTableRow>{outString}</DataTableRow>;
                                    })}
                                </DataTableHead>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfOnlineUsers.title" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null ? stats.numberOfOnlineUsers.rightNow : <Trans i18nKey="statsTable.notAvailable" />}
                                    </DataTableRow>
                                    {stats?.numberOfOnlineUsers.lastMonths.map(m => <DataTableRow>
                                        {stats !== null
                                        ? `${m.value}`
                                        : <Trans i18nKey="statsTable.notAvailable" />}
                                    </DataTableRow>)}
                                </DataTableItem>
                            </DataTableBody>
                        </DataTable>
                    </Col>
                    <Col xl={12} xxl={12}>
                        <DataTable scrollHorizontally>
                            <DataTableBody compact>
                                <DataTableHead>
                                    <DataTableRow>
                                        &nbsp;
                                    </DataTableRow>
                                    {stats?.averageSessionDurationInMs.lastMonths.map(m => {
                                        const monthString = DateTime.local(m.year, m.month, 1).toFormat(`LLLL yyyy`, {locale: getCurrentLang()});
                                        const outString = monthString[0]?.toLocaleUpperCase() + monthString.slice(1);

                                        return <DataTableRow>{outString}</DataTableRow>;
                                    })}
                                </DataTableHead>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.averageSessionDurationInMs.title" />
                                    </DataTableRow>
                                    {stats?.averageSessionDurationInMs.lastMonths.map(m => <DataTableRow>
                                        {
                                            stats !== null ?
                                                m.value !== null ?
                                                    `${formatNumber(m.value/1000/60, 2)}`
                                                    : <Trans i18nKey="statsTable.notAvailable" />
                                                : <Trans i18nKey="statsTable.notAvailable" />
                                        }
                                    </DataTableRow>)}
                                </DataTableItem>
                            </DataTableBody>
                        </DataTable>
                    </Col>
                    {/*
|                     P2P (archiviate)            Di gruppo (archiviate)
| Numero chat              5 (2)                          7 (8)
                    */}
                    <Col xl={12} xxl={12}>
                        <DataTable scrollHorizontally>
                            <DataTableBody compact>
                                <DataTableHead>
                                    <DataTableRow>
                                        &nbsp;
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfChatRooms.p2p" />
                                        &nbsp;
                                        (
                                        <Trans i18nKey="statsTable.numberOfChatRooms.p2pArchived" />
                                        )
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfChatRooms.group" />
                                        &nbsp;
                                        (
                                        <Trans i18nKey="statsTable.numberOfChatRooms.groupArchived" />
                                        )
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfChatRooms.title" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null
                                        ? `${stats.numberOfChatRooms.p2p} (${stats.numberOfChatRooms.p2pArchived})`
                                        : <Trans i18nKey="stats.table.notAvailable" />}
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null
                                        ? `${stats.numberOfChatRooms.group} (${stats.numberOfChatRooms.groupArchived})`
                                        : <Trans i18nKey="stats.table.notAvailable" />}
                                    </DataTableRow>
                                </DataTableItem>
                            </DataTableBody>
                        </DataTable>
                    </Col>
                    <Col xl={12} xxl={12}>
                        <DataTable scrollHorizontally>
                            <DataTableBody compact>
                                <DataTableHead>
                                    <DataTableRow>
                                        &nbsp;
                                    </DataTableRow>
                                    {stats?.numberOfCalls.lastMonths.map(m => {
                                        const monthString = DateTime.local(m.year, m.month, 1).toFormat(`LLLL yyyy`, {locale: getCurrentLang()});
                                        const outString = monthString[0]?.toLocaleUpperCase() + monthString.slice(1);

                                        return <DataTableRow>{outString}</DataTableRow>;
                                    })}
                                </DataTableHead>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfCalls.title" />
                                    </DataTableRow>
                                    {stats?.numberOfCalls.lastMonths.map(m => <DataTableRow>
                                        {
                                            stats !== null ?
                                                m.value !== null ?
                                                    `${formatNumber(m.value, 2)}`
                                                    : <Trans i18nKey="statsTable.notAvailable" />
                                                : <Trans i18nKey="statsTable.notAvailable" />
                                                }
                                    </DataTableRow>)}
                                </DataTableItem>
                            </DataTableBody>
                        </DataTable>
                    </Col>
                    <Col xl={12} xxl={12}>
                        <DataTable scrollHorizontally>
                            <DataTableBody compact>
                                <DataTableHead>
                                    <DataTableRow>
                                        &nbsp;
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfCalls.notYetStarted" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfCalls.alreadyStarted" />
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableItem>
                                    <DataTableRow>
                                        <Trans i18nKey="statsTable.numberOfCalls.titleRightNow" />
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null ?
                                            stats.numberOfCalls.notYetStarted
                                            : <Trans i18nKey="stats.table.notAvailable" />
                                        }
                                    </DataTableRow>
                                    <DataTableRow>
                                        {stats !== null ?
                                            stats.numberOfCalls.alreadyStarted
                                            : <Trans i18nKey="stats.table.alreadyStarted" />
                                        }
                                    </DataTableRow>
                                </DataTableItem>
                            </DataTableBody>
                        </DataTable>
                    </Col>
                </Row>
            </Content>
        </>
    );
};
export default StatisticsPage;


export const LoadingWrapper: React.FC<{
    loading: boolean,
    children: ReactNode,
}> = ({loading, children}) => {
    return <div
        style={{position: "relative"}}
    >
        {loading
        ? <div className="absolute-overlay">
            {/* <div className="text-center"> */}
                <DefaultSpinner />
                <Trans i18nKey="statsTable.loading" />
            {/* </div> */}
        </div>
        : undefined}
        {children}
    </div>
}