import { z } from "zod";
export class AssertionError extends Error {
    errMessage;
    constructor(errMessage) {
        super(`Assertion Failed: ${errMessage}`);
        this.name = `AssertionError`;
        this.errMessage = errMessage;
    }
}
export function assert(condition, errMessage) {
    if (condition !== true) {
        throw new AssertionError(errMessage ?? ``);
    }
}
export function assertNever(input) {
    void input;
    assert(false);
}
export function assertType(input) {
    void input;
}
export function unreachable(msg) {
    throw new Error(`UNREACHABLE: ${msg ?? ""}`);
}
/*
    checkArrayType<T>(arr: any[], check: (elem: unknown, index: number, arr: any[]) => boolean): boolean

    RETURNS: true if arr is an array where all elements are of type T
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkArrayType(arr, check) {
    return arr.every((e, i, a) => check(e, i, a));
}
/*
    isString(str: unknown): boolean

    RETURNS: true if str is a string
*/
export function isString(str) {
    return typeof str === "string";
}
/*
    isNum(n: unknown): boolean

    RETURNS: true if n is a number
*/
export function isNum(n) {
    return typeof n === "number";
}
/*
    isBool(b: unknown): boolean

    RETURNS: true if b is a valid boolean (either it's true or false)
*/
export function isBool(b) {
    return typeof b === "boolean";
}
/*
    isFullString(str: string): boolean

    RETURNS: true if str is a non-empty string
*/
export function isFullString(s) {
    return s.length > 0;
}
/*
    isUUID(s: string): boolean

    RETURNS: true if `s` is a valid UUID
*/
export function isUUID(s) {
    return z.string().uuid().safeParse(s).success;
}
/*
    isInt(num: number): boolean

    RETURNS: true if num is an integer
*/
export function isInt(n) {
    return Number.isInteger(n);
}
/*
    isIntRange(min: number, max: number): (n: number) => boolean

    RETURNS: a function that takes num as input and returns true if that num is a valid integer and min <= num <= max

    EXAMPLE:
        const numToValidate = 123;
        if (isIntRange(100, 200)(numToValidate)) {
            // numToValidate is an integer between 100 and 200
        }

        const nums = [1, 2, 3, 4];
        if (nums.every(isIntRange(1, 10))) {
            // every element of nums array is an integer between 1 and 10
        }

        const isUInt8 = isIntRange(0, 255);
        if ([1, 2, 3, 4].every(isUInt8)) {
            // every element of [1, 2, 3, 4] array is a valid uint8 integer
        }

    REQUIRES: isInt()
*/
export function isIntRange(min, max) {
    return (n) => {
        return [n, min, max].every(isInt) && max >= min && n >= min && n <= max;
    };
}
/*
    isNetPort(num: number): boolean

    RETURNS: true if num is a valid integer and a valid TCP/UDP port number (1 <= num <= 65535)

    REQUIRES: isIntRange()
*/
export function isNetPort(num) {
    return isIntRange(1, 65535)(num);
}
/*
    isArr(arr: unknown): boolean

    RETURNS: true if arr is a valid JavaScript Array
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArr(arr) {
    return Array.isArray(arr);
}
/*
    isHostname(str: string): boolean

    RETURNS: true in case input is a valid hostname (i.e.: it's either a valid IPv4 address, a valid IPv6 address, or a valid DNS name)

    REQUIRES: assert(), isIPv6(), isString(), isDigitChar(), isAlphaChar(), isIntRange()
*/
export function isHostname(str) {
    try {
        // if input is a valid IPv6 address, we return true
        if (isIPv6(str)) {
            return true;
        }
        // now we can validate the input according to https://en.wikipedia.org/wiki/Hostname
        // P.S.: the following validation catches both IPv4 addresses and DNS names
        // we normalize the string making it all lower-case
        assert(isString(str));
        const input = str.toLowerCase();
        // total length cannot be more than 253 chars and it cannot be empty
        assert(isIntRange(1, 253)(input.length));
        // we split the string into labels (a hostname is made up by several labels separated by a dot ".")
        const splitted = input.split(".");
        // each label must be max 63 chars long, and it cannot be empty
        assert(splitted.map(e => e.length).every(isIntRange(1, 63)));
        // a label can only contains ASCII letters (a-z, A-Z), digits (0-9) and dashes ("-")
        for (const label of splitted) {
            const chars = label.split("");
            assert(chars.every(c => isAlphaChar(c) || isDigitChar(c) || c === "-"));
        }
        // a label cannot start nor end with a dash
        assert(splitted.every(e => e.startsWith("-") !== true && e.endsWith("-") !== true));
        return true;
    }
    catch {
        return false;
    }
}
/*
    isAlphaChar(c: unknown): boolean

    RETURNS: true if c is a single-char ASCII string and it's either between a-z or A-Z

    REQUIRES: isString()
*/
export function isAlphaChar(c) {
    const isValidString = (c) => isString(c) && c.length === 1;
    try {
        if (isValidString(c)) {
            const code = c.toLowerCase().charCodeAt(0);
            const a = "a".charCodeAt(0);
            const z = "z".charCodeAt(0);
            return code >= a && code <= z;
        }
        else {
            return false;
        }
    }
    catch {
        return false;
    }
}
/*
    isIPv4(str: string): boolean

    RETURNS: true if str is a valid IPv4 address

    REQUIRES: assert(), isFullString(), isIntRange()
*/
export function isIPv4(str) {
    try {
        assert(isFullString(str));
        const splitted = str.split(".");
        assert(splitted.length === 4);
        for (const e of splitted) {
            const parsed = parseInt(e, 10);
            assert(isIntRange(0, 255)(parsed));
            assert(e === String(parsed));
        }
        return true;
    }
    catch {
        return false;
    }
}
/*
    isDigitChar(c: string): boolean

    RETURNS: true if c is a single-char ASCII string and it's between 0-9

    REQUIRES: assert(), isString()
*/
export function isDigitChar(c) {
    try {
        assert(c.length === 1);
        const code = c.charCodeAt(0);
        const zero = "0".charCodeAt(0);
        const nine = "9".charCodeAt(0);
        return code >= zero && code <= nine;
    }
    catch {
        return false;
    }
}
/*
    isHexChar(input: string): boolean

    RETURNS: true if c is a single-char ASCII string and it's either between 0-9, between a-f, or between A-F

    REQUIRES: assert(), isString(), isDigitChar()
*/
export function isHexChar(input) {
    try {
        assert(input.length === 1);
        const c = input.toLowerCase();
        const code = c.charCodeAt(0);
        const a = "a".charCodeAt(0);
        const f = "f".charCodeAt(0);
        return (code >= a && code <= f) || isDigitChar(c);
    }
    catch {
        return false;
    }
}
/*
    isFullIPv6(input: string): boolean

    RETURNS: true if input is a valid IPv6 address in uncompressed form (i.e.: without '::' shortcuts)

    REQUIRES: assert(), isFullString(), isHexChar()
*/
export function isFullIPv6(input) {
    try {
        assert(isFullString(input));
        const splitted = input.split(":");
        assert(splitted.length === 8);
        assert(splitted.every(e => e.length >= 1 && e.length <= 4));
        assert(splitted.every(e => e.split("").every(isHexChar)));
        return true;
    }
    catch {
        return false;
    }
}
/*
    isIPv6(str: string): boolean

    RETURNS: true if input is a valid IPv6 address

    REQUIRES: assert(), isFullString(), isFullIPv6()
*/
export function isIPv6(str) {
    try {
        assert(isFullString(str));
        let input = str;
        if (input.includes("::") !== true) {
            return isFullIPv6(input);
        }
        assert(input.replace("::", "").includes("::") !== true);
        const splitted = input.split(":");
        const wholes = splitted.filter(isFullString);
        assert(wholes.length < 7);
        if (input === "::") {
            return true;
        }
        if (input.startsWith("::")) {
            input = input.replace("::", "0:".repeat(8 - wholes.length));
        }
        else if (input.endsWith("::")) {
            input = input.replace("::", ":0".repeat(8 - wholes.length));
        }
        else {
            input = input.replace("::", ":0".repeat(8 - wholes.length) + ":");
        }
        return isFullIPv6(input);
    }
    catch {
        return false;
    }
}
