import { ChatItem } from "@trantor/vdesk-api-schemas/dist/chats/getChats";

type SetChatsAction = { type: "SET_CHATS", chats: ChatItem[] | null };
type UpdateChatsAction = { type: "UPDATE_CHATS", chats: ChatItem[] | null };
type SetSelectedChatAction = { type: "SET_SELECTED_CHAT", chatId: string | null };

export type ChatsAction = SetChatsAction | UpdateChatsAction | SetSelectedChatAction;

export type ChatsState = {
    chats: ChatItem[] | null,
    selectedChatId: string | null;
}

export const INITIAL_STATE: Readonly<ChatsState> = {
    chats: null,
    selectedChatId: null,
};

export default function reducer(state: ChatsState, action: ChatsAction): ChatsState {
    switch (action.type) {
        case "SET_CHATS":
            return { chats: action.chats, selectedChatId: null };
        case "UPDATE_CHATS":
            return {
                ...state,
                chats: action.chats,
            };
        case "SET_SELECTED_CHAT":
            return { ...state, selectedChatId: action.chatId };
    }
}